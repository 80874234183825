const SCROLL_TO_OFFSET_TOP = 12; // 12px as in say mt-3

export const EditableList = {
  data() {
    return {
      editingId: null
    };
  },
  methods: {
    isEditingAnyItem() {
      return !!this.editingId;
    },
    isEditingItem(id) {
      return id === this.editingId;
    },
    editItem(id) {
      this.scrollItemIntoView(id);
      this.editingId = id;
    },
    closeItem() {
      this.editingId = null;
    },
    scrollItemIntoView(id) {
      this.$vuetify.goTo(`#item-${id}`, {
        offset: SCROLL_TO_OFFSET_TOP
      });
    }
  }
};
