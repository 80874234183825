<template>
  <div class="p-relative">
    <filled-heart
      class="filled-heart"
      :fill-percent="questionStat.average"
    />
    <span
      class="p-absolute rating title white--text font-weight-bold"
    >
      {{ formatPercent(questionStat.average) }}
    </span>
  </div>
</template>

<script>
import FilledHeart from '@/components/Visualisations/FilledHeart';
import { questionVisualisationHelper } from '@/mixins/SuperUtils';

export default {
  components: { FilledHeart },
  mixins: [questionVisualisationHelper],
  props: {
    questionStat: {
      required: true,
      type: Object
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/main.scss';
.rating {
  left: 50%;
  top: 50%;

  /* This translate ensures the percentage appears in visual center */
  transform: translate(-45%, -60%);
}

.filled-heart {
  width: 40%;
  left: 50%;
  transform: translateX(-50%);
}
</style>
