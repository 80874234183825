<template>
  <div class="d-flex">
    <component
      :is="componentType"
      :value="value"
      :items="languages"
      :multiple="multiple"
      item-text="name"
      item-value="code"
      chips
      v-bind="$attrs"
      @change="handleChange"
    >
      <template
        v-for="(config, i) in templateConfigs"
        v-slot:[config.slot]="{ item }"
      >
        <component
          :is="config.component"
          v-if="item.name"
          :key="item.name || i"
          :language="item"
        />
      </template>
    </component>
    <div v-if="showHelper">
      <v-tooltip right max-width="350">
        <template v-slot:activator="{ on }">
          <div class="ml-2 mt-6" v-on="on">
            <v-icon color="primary">help</v-icon>
          </div>
        </template>
        <span>
          <slot name="tooltip">
            This is the default tooltip
          </slot>
        </span>
      </v-tooltip>
    </div>
  </div>
</template>

<script>
import LanguageItem from './LanguageItem';
import LanguageChip from './LanguageChip';

export default {
  name: 'LanguageSelect',
  components: { LanguageItem, LanguageChip },
  props: {
    value: {
      type: [String, Array],
      required: true
    },
    multiple: {
      type: Boolean,
      default: false
    },
    autocomplete: {
      type: Boolean,
      default: false
    },
    languages: {
      type: Array,
      required: true
    },
    showHelper: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    componentType() {
      return this.autocomplete ? 'v-autocomplete' : 'v-select';
    },
    templateConfigs() {
      return [
        { slot: 'selection', component: 'language-chip' },
        { slot: 'item', component: 'language-item' }
      ];
    }
  },
  methods: {
    handleChange(value) {
      this.$emit('change', value);
      this.$emit('input', value);
    }
  }
};
</script>
