var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      staticClass: "mx-auto",
      attrs: {
        width: "60%",
        viewBox: `0 0 ${_vm.maxBarWidth} ${_vm.visualisationHeight}`
      }
    },
    [
      _vm._l(_vm.imageUrls, function(url, index) {
        return _c("image", {
          key: `image_${index}`,
          attrs: {
            x: 0,
            y: _vm.barYPosition(index),
            width: _vm.imageWidth,
            height: _vm.barHeight,
            href: url
          }
        })
      }),
      _vm._l(_vm.options, function(option, index) {
        return _c("rect", {
          key: `background_${index}`,
          staticClass: "background",
          attrs: {
            x: _vm.imageWidth + _vm.barPaddingX,
            y: _vm.barYPosition(index),
            width: _vm.maxBarWidth - _vm.imageWidth - _vm.barPaddingX,
            height: _vm.barHeight
          }
        })
      }),
      _vm._l(_vm.options, function(option, index) {
        return _c("rect", {
          key: `foreground_${index}`,
          attrs: {
            x: _vm.imageWidth + _vm.barPaddingX,
            y: _vm.barYPosition(index),
            width: _vm.barWidth(option.value),
            height: _vm.barHeight,
            fill: _vm.$vuetify.theme.currentTheme.primary
          }
        })
      }),
      _vm._l(_vm.options, function(option, index) {
        return _c(
          "text",
          {
            key: `label_${index}`,
            staticClass: "text",
            attrs: {
              x: _vm.textLeft + _vm.imageWidth + _vm.barPaddingX,
              y: _vm.textYPosition(index)
            }
          },
          [_vm._v(" " + _vm._s(option.label) + " ")]
        )
      }),
      _vm._l(_vm.options, function(option, index) {
        return _c(
          "text",
          {
            key: `option_${index}`,
            staticClass: "text",
            attrs: {
              x: _vm.textRight + _vm.imageWidth + _vm.barPaddingX,
              y: _vm.textYPosition(index),
              "text-anchor": "end"
            }
          },
          [_vm._v(" " + _vm._s(_vm.percent(option.value)) + " ")]
        )
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }