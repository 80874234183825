<template>
  <v-card elevation="0" outlined>
    <v-card-title>
      Export Settings
    </v-card-title>
    <v-card-text class="pb-0">
      <v-btn-toggle
        v-model="responsePreference"
        mandatory
        dense
        :class="buttonGroupBottomMargin"
      >
        <v-btn small>
          All Responses
        </v-btn>
        <v-btn small>
          Responses Between Dates
        </v-btn>
      </v-btn-toggle>
      <div v-if="showDatePickers" class="d-flex">
        <v-menu
          v-for="(picker, i) in pickers"
          :key="i"
          :ref="picker.menuRef"
          v-model="picker.menu"
          :close-on-content-click="false"
          :return-value.sync="picker.model"
          transition="scale-transition"
          offset-y
          min-width="290px"
          :disabled="loading"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              :value="formatDateTime(picker.model)"
              :label="picker.label"
              class="pr-8 flex-grow-0"
              prepend-icon="event"
              readonly
              v-bind="attrs"
              :disabled="loading"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="picker.model"
            no-title
            scrollable
            @change="confirmForPicker(picker)"
          />
        </v-menu>
      </div>
      <v-checkbox
        v-model="includeBinaries"
        label="Include images and audio"
        :disabled="loading"
        class="mt-n1"
      ></v-checkbox>
      <p class="mb-0">Export Language</p>
      <v-btn-toggle
        mandatory
        class="mb-4"
        @change="languageButtonPressed($event)"
      >
        <v-btn v-for="language in availableLanguages" :key="language">
          {{ language }}
        </v-btn>
      </v-btn-toggle>
    </v-card-text>
    <v-card-actions class="ml-2 pt-0">
      <v-btn
        small
        color="primary"
        :loading="loading"
        @click="downloadData"
      >
        Start Export
      </v-btn>
    </v-card-actions>
    <v-divider class="mt-4"></v-divider>
    <v-card-title>Exports</v-card-title>
    <v-simple-table>
      <template>
        <thead>
          <tr>
            <th
              v-for="(header, i) in exportHistoryTableHeaders"
              :key="i"
            >
              {{ header }}
            </th>
          </tr>
        </thead>
        <tbody v-if="hasExportJobs">
          <tr v-for="item in exportJobs" :key="item.id">
            <td>
              {{
                formatDateTime(item.createdAt, { useTimeAgo: true })
              }}
            </td>
            <td>{{ formatDateTime(item.rangeStart) }}</td>
            <td>{{ formatDateTime(item.rangeEnd) }}</td>
            <td>{{ item.includeBinaries ? 'Yes' : 'No' }}</td>
            <td>{{ item.exportLanguage }}</td>
            <td>
              <v-chip
                small
                class="text-caption white--text"
                :color="stateColour(item.state)"
              >
                {{ formatExportState(item.state) }}
              </v-chip>
            </td>
            <td>
              <v-btn
                v-if="item.resultUrl"
                :href="item.resultUrl"
                color="primary"
                small
              >
                <v-icon left>get_app</v-icon> Download
              </v-btn>
              <v-progress-circular
                v-else-if="isPending(item.state)"
                indeterminate
                color="primary"
              ></v-progress-circular>
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td
              class="text-center"
              :colspan="exportHistoryTableHeaders.length"
            >
              Exports will appear here.
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </v-card>
</template>

<script>
import { datetimeDate, datetimeOffset, dateFormat } from '@/utils';
import moment from 'moment';
import { mapActions } from 'vuex';

const ResponsePreference = Object.freeze({
  ALL_RESPONSES: 0,
  RESPONSES_BETWEEN_DATES: 1
});

export default {
  name: 'SurveyDataExportCard',
  props: {
    startDate: {
      type: String,
      required: true
    },
    endDate: {
      type: String,
      required: true
    },
    survey: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    pickers: [],
    includeBinaries: true,
    includeArchived: false,
    loading: false,
    responsePreference: ResponsePreference.ALL_RESPONSES,
    exportJobs: [],
    exportLanguage: undefined
  }),
  computed: {
    hasExportJobs() {
      return this.exportJobs.length > 0;
    },
    exportHistoryTableHeaders() {
      return [
        'Requested',
        'From',
        'To',
        'Includes Images/Audio',
        'Export Language',
        'State',
        'Download'
      ];
    },
    includeAllResponses() {
      return (
        this.responsePreference === ResponsePreference.ALL_RESPONSES
      );
    },
    pickerDisabled() {
      return this.loading || this.includeAllResponses;
    },
    showDatePickers() {
      return !this.includeAllResponses;
    },
    buttonGroupBottomMargin() {
      return this.includeAllResponses ? 'mb-5' : 'mb-4';
    },
    exportBody() {
      if (this.includeAllResponses) {
        return {
          surveyId: this.survey.id,
          rangeStart: null,
          rangeEnd: null,
          includeBinaries: this.includeBinaries,
          includeArchived: this.includeArchived,
          exportLanguage: this.exportLanguage
        };
      } else {
        // Model for a picker is ISO 8601 format YYYY-MM-DD
        // The server expects OffsetDateTime 2020-05-31T14:00:00Z
        // Use the picker model and the associated prop to
        // create an OffsetDateTime string
        const from = `${this.pickers.start.model +
          datetimeOffset(this.startDate)}`;
        const to = `${this.pickers.end.model +
          datetimeOffset(this.endDate)}`;
        return {
          surveyId: this.survey.id,
          rangeStart: from,
          rangeEnd: to,
          includeBinaries: this.includeBinaries,
          includeArchived: this.includeArchived,
          exportLanguage: this.exportLanguage
        };
      }
    },
    availableLanguages() {
      return [
        this.survey.primaryLanguage,
        ...this.survey.secondaryLanguages
      ];
    }
  },
  created() {
    const from = datetimeDate(this.startDate);
    const to = datetimeDate(this.endDate);
    this.pickers = {
      start: {
        model: from,
        label: 'Start Date',
        menu: false,
        menuRef: 'startMenu'
      },
      end: {
        model: to,
        label: 'End Date',
        menu: false,
        menuRef: 'endMenu'
      }
    };

    this.reloadExportJobs();
    this.reloadInterval = setInterval(
      () => this.reloadExportJobs(),
      10000
    );
  },
  destroyed() {
    clearInterval(this.reloadInterval);
  },
  methods: {
    ...mapActions('survey', ['fetchExportJobs']),
    formatExportState(state) {
      return state.replace('_', ' ');
    },
    isPending(state) {
      return ['PENDING', 'PREPARING', 'UPLOADING'].includes(state);
    },
    reloadExportJobs() {
      this.fetchExportJobs(this.survey).then(response => {
        this.exportJobs = response.data.sort(
          (j1, j2) => moment(j2.createdAt) - moment(j1.createdAt)
        );
      });
    },
    formatDateTime(dateTime, config = {}) {
      const m = moment(dateTime);
      if (m.isSameOrBefore('0001')) return '—';
      if (m.isSameOrAfter('9998')) return '—';

      return m.isSame(moment(), 'day') && config.useTimeAgo
        ? m.fromNow()
        : m.format(dateFormat.default);
    },
    stateColour(state) {
      switch (state) {
        case 'COMPLETE':
          return 'success';
        case ('FAILED', 'TIMED_OUT'):
          return 'error';
        default:
          return 'normal';
      }
    },
    downloadData() {
      this.loading = true;
      this.$store
        .dispatch('survey/exportResponseData', {
          survey: this.survey,
          body: this.exportBody
        })
        .then(() => {
          this.reloadExportJobs();
          setTimeout(
            (() => {
              this.loading = false;
            }).bind(this),
            500
          );
        });
    },
    confirmForPicker(picker) {
      // When there are multiple refs on a component instance,
      // querying $refs will return an array.
      // Call save on v-menu instance matching query.
      this.$refs[picker.menuRef][0].save(picker.model);
    },
    languageButtonPressed(event) {
      this.exportLanguage = this.availableLanguages[event];
    }
  }
};
</script>
