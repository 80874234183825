<template>
  <div class="input-wrapper">
    <PincodeInput
      v-model="code"
      placeholder=""
      :class="{ shake: animated }"
      :autofocus="autoFocus"
      :length="accessCodeLength"
      :secure="secure"
      :character-preview="false"
      @input="onInput"
    />
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import PincodeInput from 'vue-pincode-input';

export default {
  name: 'AccessCodeInput',
  components: { PincodeInput },
  props: {
    surveyId: {
      type: String,
      required: true
    },
    secure: {
      type: Boolean,
      default: true
    },
    autoFocus: {
      type: Boolean,
      default: true
    },
    characterPreview: {
      type: Boolean,
      default: false
    },
    accessCodeLength: {
      type: Number,
      default: 4
    }
  },
  data() {
    return {
      code: '',
      animated: false
    };
  },
  methods: {
    ...mapActions({
      validateCode: 'publicDisplay/validateCode'
    }),
    onInput() {
      // Don't do anything until we have enough digits.
      if (this.code.length < this.accessCodeLength) {
        return;
      }

      this.validateCode({
        surveyId: this.surveyId,
        accessCode: this.code
      }).then(isValid => {
        if (isValid) {
          this.$emit('access-granted', this.code);
        } else {
          this.$emit('access-denied', this.code);
          this.reset();
          this.playAnimation();
        }
      });
    },
    reset() {
      this.code = '';
    },
    playAnimation() {
      this.animated = true;
      setTimeout(() => {
        this.animated = false;
      }, 1000);
    }
  }
};
</script>

<style lang="scss">
div.vue-pincode-input-wrapper {
  border: none;
  box-shadow: none;
}

input.vue-pincode-input {
  box-shadow: none;
  border-bottom: 20px solid white;
  border-radius: 0px;
  font-size: 64px;
  min-width: 120px;
  color: white;
  margin: 36px 20px;
}

input.vue-pincode-input:focus {
  box-shadow: none;
  border-bottom: 20px solid rgba(179, 76, 121, 0.95);
}

.shake {
  animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  transform: translate3d(0, 0, 0);
}

@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }
  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }
  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }
  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}
</style>
