<template>
  <v-row>
    <v-col
      v-for="(genderOption, index) in genderOptions"
      :key="index"
      class="d-flex justify-center"
    >
      <v-card flat class="text-center" height="100%">
        <v-card-text class="pt-0">
          <component
            :is="componentMap[genderOption]"
            :class="genderOption"
          />
          <div class="text-h6 font-weight-bold black--text">
            {{ totalNumber(genderOption) }}
          </div>
          <div class="text-subtitle-1">
            {{ averageNumber(genderOption) }}
          </div>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import GenderMale from '!!vue-svg-loader!../../../assets/svgs/GenderMale.svg';
import GenderFemale from '!!vue-svg-loader!../../../assets/svgs/GenderFemale.svg';
import GenderNonBinary from '!!vue-svg-loader!../../../assets/svgs/GenderNonBinary.svg';
import { questionVisualisationHelper } from '@/mixins/SuperUtils';

export default {
  components: {
    GenderMale,
    GenderFemale,
    GenderNonBinary
  },
  mixins: [questionVisualisationHelper],
  props: {
    questionStat: {
      required: true,
      type: Object
    }
  },
  computed: {
    componentMap() {
      const result = {
        male: 'gender-male',
        female: 'gender-female'
      };
      return this.questionStat.includeNonBinary
        ? Object.assign(result, { nonBinary: 'gender-non-binary' })
        : result;
    },
    genderOptions() {
      return Object.keys(this.componentMap);
    }
  },
  methods: {
    averageNumber(gender) {
      return this.formatPercent(
        this.calculateAvgFor(this.totalNumber(gender))
      );
    },
    totalNumber(gender) {
      if (gender === 'male') return this.questionStat.numberOfMale;
      else if (gender === 'female')
        return this.questionStat.numberOfFemale;
      else return this.questionStat.numberOfNonBinary;
    }
  }
};
</script>

<style scoped lang="scss">
@import '../../../scss/main.scss';
svg {
  height: 70px;

  &.male path {
    fill: map-get($grey, 'darken-1');
  }

  &.female path {
    fill: map-get($grey, 'base');
  }
}
</style>
