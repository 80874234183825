var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    {
      staticClass: "short-link-redirect-container",
      attrs: { "fill-height": "", fluid: "" }
    },
    [
      _c(
        "v-row",
        { attrs: { align: "center", justify: "center" } },
        [
          _c("v-progress-circular", {
            attrs: { color: "primary", indeterminate: "" }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }