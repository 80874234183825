var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "h-100 w-100 d-flex flex-column", class: _vm.pageLayout },
    [
      !_vm.fullscreen
        ? _c(
            "div",
            { staticClass: "text-h3 font-weight-bold text-center" },
            [
              _c("localised-text", {
                attrs: {
                  language: _vm.displayLanguage,
                  string: _vm.questionStat.title,
                  color: _vm.theme.textColor,
                  wrap: ""
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm._t("visualisation")
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }