<template>
  <v-card flat>
    <v-container class="fill-height">
      <v-row class="justify-center text-center align-center">
        <v-img :src="getSmileyFaceImgUrl" max-width="137px" />
      </v-row>
      <v-card-text
        class="text-h6 font-weight-bold black--text text-center pt-2 pr-9"
      >
        {{ averageEmotionDisplayName }}
      </v-card-text>
    </v-container>
  </v-card>
</template>

<script>
export default {
  props: {
    questionStat: {
      required: true,
      type: Object
    },
    displayLanguage: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      emotions: {
        ANGRY: { weight: 1, displayName: 'Angry' },
        SAD: { weight: 2, displayName: 'Sad' },
        UNHAPPY: { weight: 3, displayName: 'Unhappy' },
        INDIFFERENT: { weight: 4, displayName: 'Indifferent' },
        AWE: { weight: 5, displayName: 'Awe' },
        HAPPY: { weight: 6, displayName: 'Happy' },
        ECSTATIC: { weight: 7, displayName: 'Ecstatic' }
      }
    };
  },
  computed: {
    averageEmotionKey() {
      return Object.keys(this.emotions).find(
        emotionKey =>
          this.emotions[emotionKey].weight ===
          this.averageEmotionValue
      );
    },
    averageEmotionValue() {
      const { wordSelections, totalResponses } = this.questionStat;

      // Get the total across all weights
      const totalEmotion = wordSelections.reduce((total, element) => {
        const key = element.word.languageMap.EN;
        return total + this.emotions[key].weight * element.count;
      }, 0);
      return Math.round(totalEmotion / totalResponses);
    },
    averageEmotionDisplayName() {
      return this.emotions[this.averageEmotionKey].displayName;
    },
    getSmileyFaceImgUrl() {
      const filename = this.averageEmotionKey.toLowerCase();
      return require(`../../../assets/pngs/smiley-faces/${filename}.png`);
    }
  }
};
</script>
