<template>
  <div>
    <div v-if="questions.length > 0">
      <draggable
        v-if="questionsDraggable"
        v-model="questions"
        @start="startedDraggingQuestion"
        @end="updateOrderForDraggedQuestion"
      >
        <question
          v-for="question in questions"
          :key="question.id"
          v-bind="questionComponentProps(question)"
          v-on="questionComponentEventHandlers"
        ></question>
      </draggable>
      <question
        v-for="question in questions"
        v-else
        :id="question.id ? `question-${question.id}` : 'question'"
        :key="question.id"
        v-bind="questionComponentProps(question)"
        v-on="questionComponentEventHandlers"
      ></question>

      <div class="fab-container p-absolute" style="width:56px;">
        <v-tooltip :disabled="!questionInForm" top>
          <template v-slot:activator="{ on, attrs }">
            <div class="p-fixed" v-bind="attrs" v-on="on">
              <v-btn
                color="primary"
                :disabled="!!questionInForm"
                :style="addQuestionButtonStyles"
                fab
                @click="addQuestion"
              >
                <v-icon>add</v-icon>
              </v-btn>
            </div>
          </template>
          <span>
            Close open question before adding a new question
          </span>
        </v-tooltip>
      </div>
    </div>
    <add-question-card v-else @add-question="addQuestion" />

    <question-preview-dialog
      v-if="showQuestionPreviewDialog"
      :question-type="questionPreviewType"
      :show="showQuestionPreviewDialog"
      @close-question-preview="closeQuestionPreview"
    />
  </div>
</template>

<script>
import draggable from 'vuedraggable';
import Question from './Question';
import QuestionModel from '@/models/Question';
import QuestionPreviewDialog from './QuestionPreviewDialog';
import AddQuestionCard from './NoData/AddQuestionCard';
import { mapState, mapActions } from 'vuex';

const SCROLL_TO_OFFSET_TOP = 12; // 12px as in say mt-3

export default {
  name: 'QuestionList',
  components: {
    draggable,
    Question,
    QuestionPreviewDialog,
    AddQuestionCard
  },
  props: {
    targetLanguage: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      showQuestionPreviewDialog: false,
      questionPreviewType: undefined,
      draggedQuestion: undefined,
      questionsDraggable: true
    };
  },
  computed: {
    ...mapState('survey', ['survey']),
    ...mapState('question', ['questionInForm']),
    questionComponentEventHandlers() {
      return {
        'show-question-preview': this.showQuestionPreview,
        'edit-question': this.editQuestion,
        'close-question-form': this.closeQuestionForm,
        'focus-duplicate': this.focusDuplicateQuestion
      };
    },
    addQuestionButtonStyles() {
      return {
        'pointer-events': 'auto',
        cursor: this.questionInForm ? 'not-allowed' : null
      };
    },
    questions: {
      get() {
        return this.survey.questions.filter(
          question => question.userConfigurable
        );
      },
      set() {} // Catches vue-draggable attempts to modify questions when re-ordering
    },
    isQuestionFormOpen() {
      return !!this.questionInForm;
    },
    shouldRemoveQuestion() {
      return Boolean(
        this.questionInForm &&
          !this.questionInForm.id &&
          !this.questionInForm.hasUnsavedChanges
      );
    }
  },
  mounted() {
    // Close the question form if it was left open on the last visit.
    // This addresses an issue where users could not create new
    // questions after leaving the page with the form open as it
    // resulted in the add question button being disabled (due to
    // the presumption there was a question open/being edited)
    if (this.isQuestionFormOpen) this.closeQuestionForm();
  },
  methods: {
    ...mapActions({
      updateSurvey: 'survey/updateSurvey',
      fetchSurvey: 'survey/fetchSurvey',
      addNewQuestion: 'survey/addNewQuestion',
      deleteQuestion: 'question/deleteQuestion',
      setQuestionInForm: 'question/setQuestionInForm'
    }),
    focusDuplicateQuestion(duplicateQuestionId) {
      const duplicateQuestion = this.questions.find(
        question => question.id == duplicateQuestionId
      );
      this.editQuestion(duplicateQuestion);
    },
    questionComponentProps(question) {
      return {
        question: question,
        'target-language': this.targetLanguage,
        index: this.visibleIndex(question),
        editing: this.isOpenForEditing(question)
      };
    },
    isOpenForEditing(question) {
      if (this.questionInForm && this.questionInForm.id)
        return question.id == this.questionInForm.id;
      else return question == this.questionInForm;
    },
    addQuestion() {
      this.addBlankQuestion();
    },
    addBlankQuestion() {
      const blankQuestion = new QuestionModel({
        survey: this.survey,
        language: this.targetLanguage
      });
      this.addNewQuestion(blankQuestion).then(question =>
        this.editQuestion(question)
      );
    },
    editQuestion(question) {
      if (this.isQuestionFormOpen) {
        this.closeQuestionForm();
      }
      this.setQuestionInForm(question);
      this.$nextTick().then(() => {
        // this must happen in $nextTick otherwise the previously
        // opened questions TaskForm height will be added to the
        // amount to scroll
        this.scrollQuestionIntoView(question);
      });
      this.questionsDraggable = false;
    },
    closeQuestionForm() {
      if (this.shouldRemoveQuestion) {
        this.deleteQuestion(this.isQuestionFormOpen);
      }
      this.setQuestionInForm(undefined);
      this.questionsDraggable = true;
    },
    showQuestionPreview(questionType) {
      this.questionPreviewType = questionType;
      this.showQuestionPreviewDialog = true;
    },
    closeQuestionPreview() {
      this.showQuestionPreviewDialog = false;
      this.questionPreviewType = undefined;
    },
    visibleIndex(question) {
      return this.questions.indexOf(question) + 1;
    },
    startedDraggingQuestion(dragEvent) {
      this.draggedQuestion = this.questions[dragEvent.oldIndex];
    },
    scrollQuestionIntoView(question) {
      const questionComponentNodeId = question.id
        ? `#question-${question.id}`
        : '#question';

      this.$vuetify.goTo(questionComponentNodeId, {
        offset: SCROLL_TO_OFFSET_TOP
      });
    },
    updateOrderForDraggedQuestion(sortEvent) {
      const question = this.draggedQuestion;
      if (!question.id) return;
      const oldIndex = this.survey.questionOrder.indexOf(question.id);
      const newIndex = sortEvent.newIndex + 1;

      // Ignore if the order hasn't changed
      if (newIndex === oldIndex) return;

      this.survey.questions.splice(oldIndex, 1);
      this.survey.questions.splice(newIndex, 0, question);

      this.survey.questionOrder.splice(oldIndex, 1);
      this.survey.questionOrder.splice(newIndex, 0, question.id);
      // TODO: Remove the need to specify this [MUSE-756]
      this.survey.base64EncodedImageData = null;
      this.survey.imageDeleted = false;

      this.updateSurvey(this.survey);
    }
  }
};
</script>

<style lang="scss" scoped>
.fab-container {
  top: 0vh;
  right: -5rem;
}

.add-question-btn {
  pointer-events: auto;
}
</style>
