<template>
  <div>
    <div class="d-flex">
      <v-col
        v-for="(circle, index) in circles"
        :key="index"
        cols="12"
        md="6"
        class="d-flex flex-column justify-center py-0"
      >
        <circle-svg
          :size="600"
          :scale="circle.average"
          :colors="circleColors(circle)"
        />
        <div class="align-self-center">
          <div class="text-h3 font-weight-bold mb-3">
            <localised-text
              :language="displayLanguage"
              :string="circle.label"
              :color="theme.textColor"
            />
          </div>
          <div class="text-h4 text-center">
            {{ formatPercent(circle.average) }}
          </div>
        </div>
      </v-col>
    </div>
  </div>
</template>

<script>
import colors from 'vuetify/lib/util/colors';
import CircleSvg from '@/components/Visualisations/CircleSvg';
import { questionVisualisationHelper } from '../../mixins/SuperUtils';
import LocalisedText from '@/components/Common/LocalisedText';

export default {
  name: 'CirclesProportionalPublicDisplayItem',
  components: {
    CircleSvg,
    LocalisedText
  },
  mixins: [questionVisualisationHelper],
  props: {
    questionStat: {
      required: true,
      type: Object
    },
    displayLanguage: {
      type: String,
      required: true
    },
    theme: {
      type: Object,
      required: true
    }
  },
  computed: {
    circles() {
      return [
        {
          average: this.questionStat.average,
          majority: this.questionStat.average > 0.5,
          label: this.questionStat.leftWord
        },
        {
          average: 1 - this.questionStat.average,
          majority: this.questionStat.average < 0.5,
          label: this.questionStat.rightWord
        }
      ];
    }
  },
  methods: {
    circleColors(circle) {
      return [
        {
          offset: 0,
          stopColor: circle.majority
            ? this.$vuetify.theme.defaults.light.primary
            : colors.grey.lighten1
        }
      ];
    }
  }
};
</script>
