<template>
  <v-chip class="white--text px-7" :color="color">
    <v-icon class="mr-2" small>{{ icon }}</v-icon>
    {{ statusName }}
  </v-chip>
</template>

<script>
export default {
  name: 'StatusChip',
  props: {
    status: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      statusDisplay: {
        DRAFT: { name: 'Draft', icon: 'list_alt', color: 'primary' },
        PUBLISHED: {
          name: 'Published',
          icon: 'check_circle',
          color: 'success'
        },
        ARCHIVED: { name: 'Archived', icon: 'folder', color: 'grey' }
      }
    };
  },
  computed: {
    icon() {
      return this.statusDisplay[this.status].icon;
    },
    color() {
      return this.statusDisplay[this.status].color;
    },
    statusName() {
      // TODO: Refactor to enum and add multiple languages
      return this.statusDisplay[this.status].name;
    }
  }
};
</script>
