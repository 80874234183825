<template>
  <v-row>
    <v-col
      v-for="wordNumber in words.length"
      :key="wordNumber"
      cols="3"
    >
      <v-text-field
        class="text-body-2"
        :value="word(wordNumber)"
        :label="inputLabel(wordNumber)"
        :disabled="disabled"
        @input="updateWord(wordNumber, $event)"
      ></v-text-field>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  name: 'WordListConfig',
  props: {
    labelWord: {
      type: String,
      default: () => undefined,
      required: false
    },
    labels: {
      type: Array,
      required: false,
      default: () => undefined
    },
    words: {
      type: Array,
      required: true
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    ...mapGetters('survey', ['primaryLanguage']),
    targetLanguage() {
      return this.primaryLanguage;
    }
  },
  methods: {
    inputLabel(wordNumber) {
      if (this.labelWord) {
        return `${this.labelWord} ${wordNumber}`;
      } else if (this.labels) {
        return this.labels[wordNumber - 1];
      } else {
        return `Word ${wordNumber}`;
      }
    },
    word(wordNumber) {
      return this.words[wordNumber - 1].languageMap[
        this.targetLanguage
      ];
    },
    updateWord(wordNumber, value) {
      this.words[wordNumber - 1].languageMap[
        this.targetLanguage
      ] = value;
      this.wordUpdated();
    },
    wordUpdated() {
      this.$emit('words-updated', this.words);
    }
  }
};
</script>
