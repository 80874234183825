<template>
  <v-row>
    <v-col cols="7">
      <p class="mb-0 text-caption grey--text text--darken-2">
        Sliders
      </p>
      <v-row v-for="i in sliderCount" :key="i">
        <v-col cols="12" class="pt-10 pb-0">
          <span class="text-body-2">{{ i }}.</span>
          <v-text-field
            v-model="
              configModel.sliders[i - 1].title.languageMap[
                primaryLanguage
              ]
            "
            class="text-body-2 mt-n10 pl-5"
            label="Subtitle"
            @change="configUpdated()"
          />
        </v-col>
        <v-col cols="4" class="py-0">
          <v-text-field
            v-model="
              configModel.sliders[i - 1].left.languageMap[
                primaryLanguage
              ]
            "
            class="text-body-2 pl-5"
            label="Left Word"
            @change="configUpdated()"
          />
        </v-col>
        <v-col cols="4" class="py-0">
          <v-text-field
            v-model="
              configModel.sliders[i - 1].middle.languageMap[
                primaryLanguage
              ]
            "
            class="text-body-2 pl-5"
            label="Middle Word"
            @change="configUpdated()"
          />
        </v-col>
        <v-col cols="4" class="py-0">
          <v-text-field
            v-model="
              configModel.sliders[i - 1].right.languageMap[
                primaryLanguage
              ]
            "
            class="text-body-2 pl-5"
            label="Right Word"
            @change="configUpdated()"
          />
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="5">
      <slider-settings-controller
        class="ml-6 mr-10"
        :count="sliderCount"
        :range="configModel.sliderRange"
        title="Number of sliders"
        :disabled="questionHasResponses"
        @set-value="setSliderCount"
      />
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from 'vuex';
import SliderSettingsController from './SliderSettingsController.vue';

export default {
  name: 'SliderConfig',
  components: { SliderSettingsController },
  props: {
    value: {
      type: Object,
      required: true
    },
    questionHasResponses: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      configModel: {},
      sliderCount: this.value.sliders.length
    };
  },
  computed: {
    ...mapGetters('survey', ['primaryLanguage'])
  },
  created() {
    this.configModel = {
      questionType: this.value.questionType,
      sliders: [...this.value.sliders],
      sliderRange: { min: 1, max: 3 }
    };
  },
  methods: {
    configUpdated() {
      this.$emit('input', this.configModel);
      this.$emit('change', this.configModel);
    },
    setSliderCount(sliderCount) {
      this.sliderCount = sliderCount;
      this.adjustSlidersToMatchCount();
      this.configUpdated();
    },
    adjustSlidersToMatchCount() {
      if (this.configModel.sliders.length < this.sliderCount) {
        while (this.configModel.sliders.length < this.sliderCount) {
          this.addEmptySlider();
        }
      } else if (this.configModel.sliders.length > this.sliderCount) {
        while (this.configModel.sliders.length > this.sliderCount) {
          this.removeLastSlider();
        }
      }
    },
    addEmptySlider() {
      this.configModel.sliders.push({
        title: { languageMap: { [this.primaryLanguage]: '' } },
        left: { languageMap: { [this.primaryLanguage]: '' } },
        middle: { languageMap: { [this.primaryLanguage]: '' } },
        right: { languageMap: { [this.primaryLanguage]: '' } }
      });
    },
    removeLastSlider() {
      this.configModel.sliders.pop();
    }
  }
};
</script>
