<template>
  <div>
    <v-card-text class="py-0 font-weight-bold">
      <v-row
        v-for="rankedWord in rankedWords"
        :key="rankedWord.word.languageMap[primaryLanguage]"
        class="text-subtitle-1"
      >
        <v-col
          cols="12"
          md="7"
          class="py-2 font-weight-medium black--text"
        >
          <localised-text
            :language="displayLanguage"
            :string="rankedWord.word"
          />
        </v-col>
        <v-col cols="12" md="5" class="py-2 text-right">
          <span class="black--text">{{ rankedWord.count }}</span>
          ({{ rankedWord.percentage.toFixed(1) }}%)
        </v-col>
      </v-row>
    </v-card-text>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import LocalisedText from '@/components/Common/LocalisedText';

export default {
  components: {
    LocalisedText
  },
  props: {
    questionStat: {
      required: true,
      type: Object
    },
    limit: {
      required: false,
      type: Number,
      default: 5
    },
    displayLanguage: {
      type: String,
      required: true
    }
  },
  computed: {
    ...mapState('stats', ['stats']),
    primaryLanguage() {
      return this.stats.languages[0];
    },
    rankedWords() {
      return this.wordSelections
        .slice()
        .sort((a, b) => {
          const countComparator = a.count - b.count;
          if (countComparator != 0) return countComparator;
          // Sort alphabetically if the count is the same,
          // so they don't switch around randomly.
          const aWord = a.word.languageMap[this.displayLanguage];
          const bWord = b.word.languageMap[this.displayLanguage];
          return bWord > aWord ? 1 : -1;
        })
        .reverse();
    },
    wordSelections() {
      return this.questionStat.wordSelections.map(
        (wordSelection, index) => ({
          count: wordSelection.count,
          word: wordSelection.word,
          percentage:
            (wordSelection.count / this.questionStat.totalResponses) *
            100,
          index: index
        })
      );
    }
  }
};
</script>

<style lang="scss" scoped>
.row:nth-child(odd) {
  background-color: var(--v-primary-transparent-base);
}
</style>
