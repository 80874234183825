var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("v-card", { staticClass: "p-relative pa-5 pl-7 mb-4" }, [
    _c("div", [_c("v-skeleton-loader", { attrs: { type: "text@1" } })], 1)
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }