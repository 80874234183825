<template>
  <video
    width="100%"
    height="100%"
    class="mt-5 question-preview-video"
    autoplay
    loop
  >
    <source :src="resourceLink(questionType)" type="video/mp4" />
    Your browser does not support HTML video.
  </video>
</template>
<script>
export default {
  name: 'QuestionPreviewVideo',
  props: {
    questionType: {
      type: String,
      required: true
    }
  },
  methods: {
    closeDialog() {
      this.$emit('close-question-preview');
    },
    resourceLink(value) {
      return encodeURIComponent(
        `videos/question_previews/${value}.mp4`
      );
    }
  }
};
</script>
