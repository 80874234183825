<template>
  <word-list-config
    :words="words"
    :label-word="'Circle'"
    :disabled="questionHasResponses"
    @words-updated="wordsUpdated"
  />
</template>

<script>
import WordListConfig from './WordListConfig';

export default {
  name: 'CircleQuestionConfig',
  components: { WordListConfig },
  props: {
    value: {
      type: Object,
      required: true
    },
    questionHasResponses: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return { words: this.value.options };
  },
  methods: {
    configUpdated() {
      const config = {
        questionType: this.value.questionType,
        options: this.words
      };
      this.$emit('input', config);
      this.$emit('change', config);
    },
    wordsUpdated() {
      this.configUpdated();
    }
  }
};
</script>
