<template>
  <div>
    <v-row class="flex-column">
      <v-col>
        <span class="text-caption grey--text text--darken-2"
          >Background</span
        >
      </v-col>

      <v-col class="pt-0">
        <v-btn-toggle v-model="value.drawingQuestionType">
          <v-btn
            v-for="button in buttonToggleButtons"
            :key="button.key"
            :value="button.key"
            class="text-capitalize text-body-2"
            height="32"
            :disabled="questionHasResponses"
          >
            <v-icon left>
              {{ button.icon }}
            </v-icon>
            {{ button.label }}
          </v-btn>
        </v-btn-toggle>
      </v-col>
    </v-row>
    <drawing-image-config
      v-if="value.drawingQuestionType === drawingQuestionTypes.IMAGE"
      :value="value"
      :question-has-responses="questionHasResponses"
    />
  </div>
</template>

<script>
import { DrawingQuestionType } from '@/enums';
import DrawingImageConfig from './DrawingImageConfig';
export default {
  name: 'DrawingConfig',
  components: { DrawingImageConfig },
  props: {
    value: {
      type: Object,
      required: true
    },
    questionHasResponses: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    drawingQuestionTypes() {
      return DrawingQuestionType;
    },
    buttonToggleButtons() {
      const icons = [
        'crop_landscape',
        'accessibility',
        'crop_original'
      ];
      return Object.keys(this.drawingQuestionTypes).map(
        (key, idx) => ({
          key,
          icon: icons[idx],
          label: key.toLowerCase()
        })
      );
    }
  }
};
</script>
