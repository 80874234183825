var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "6" } },
            [
              _c(
                "p",
                { staticClass: "mb-0 text-caption grey--text text--darken-2" },
                [_vm._v(" Response Options ")]
              ),
              _c(
                "v-row",
                _vm._l(_vm.optionCount, function(i) {
                  return _c(
                    "v-col",
                    { key: i, attrs: { cols: "6" } },
                    [
                      _c("span", { staticClass: "text-body-2" }, [
                        _vm._v(_vm._s(i) + ".")
                      ]),
                      _c("v-text-field", {
                        staticClass: "text-body-2 mt-n10 pl-5 mb-n8",
                        attrs: {
                          "v-model": _vm.option(i),
                          value: _vm.option(i),
                          disabled: _vm.questionHasResponses,
                          height: "34"
                        },
                        on: {
                          input: function($event) {
                            return _vm.updateTitle(i, $event)
                          },
                          focus: function($event) {
                            _vm.optionsFocused = true
                          },
                          blur: function($event) {
                            _vm.optionsFocused = false
                          }
                        }
                      }),
                      _c("image-upload-card", {
                        staticClass: "ml-4 mt-8",
                        attrs: {
                          "image-id": _vm.image(i),
                          "organisation-id": _vm.survey.organisationId,
                          "image-type": _vm.imageTypes.QUESTION_IMAGE,
                          "image-height": 180
                        },
                        on: {
                          "image-uploaded": function($event) {
                            return _vm.handleImageUploaded(i, $event)
                          },
                          "delete-image": function($event) {
                            return _vm.handleDeleteImage(i)
                          }
                        }
                      })
                    ],
                    1
                  )
                }),
                1
              )
            ],
            1
          ),
          _c("v-col", { attrs: { cols: "6" } }, [
            _c(
              "div",
              { staticClass: "d-flex" },
              [
                _c("word-settings-controller", {
                  staticClass: "ml-6 mr-10",
                  attrs: {
                    count: _vm.optionCount,
                    range: _vm.config.wordRange,
                    title: "Options",
                    disabled: _vm.questionHasResponses,
                    "multiple-choice-class": true,
                    orientation: "horizontal"
                  },
                  on: { "set-value": _vm.setOptionCount }
                }),
                _c(
                  "div",
                  [
                    _c(
                      "p",
                      {
                        staticClass:
                          "pt-3 mb-0 pb-2 text-caption grey--text text--darken-2"
                      },
                      [_vm._v(" Selection Type ")]
                    ),
                    _c(
                      "v-btn-toggle",
                      {
                        model: {
                          value: _vm.value.canSelectMultiple,
                          callback: function($$v) {
                            _vm.$set(_vm.value, "canSelectMultiple", $$v)
                          },
                          expression: "value.canSelectMultiple"
                        }
                      },
                      _vm._l(_vm.buttonLabels, function(button, idx) {
                        return _c(
                          "v-btn",
                          {
                            key: idx,
                            staticClass: "text-capitalize text-body-2",
                            attrs: {
                              value: button.canSelectMultiple,
                              small: "",
                              disabled: _vm.questionHasResponses
                            }
                          },
                          [_vm._v(" " + _vm._s(button.label) + " ")]
                        )
                      }),
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }