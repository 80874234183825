<template>
  <div>
    <div class="d-flex justify-space-around">
      <div
        class="d-flex align-self-center justify-self-center label text-h3 text-right"
      >
        <localised-text
          :language="displayLanguage"
          :string="circles[0].label"
          class="w-100"
          :color="theme.textColor"
        />
      </div>
      <div class="d-flex mb-12 justify-content-center text-center">
        <circle-svg
          v-for="(circle, index) in circles"
          :key="index"
          :size="400"
          :opacity="0.7"
          :colors="circle.colors"
          :x-translation="circle.xTranslation"
        />
      </div>
      <div
        class="d-flex align-self-center justify-self-center justify-content-center label"
      >
        <span class="text-h3 text-left">
          <localised-text
            :language="displayLanguage"
            :string="circles[1].label"
            :color="theme.textColor"
          />
        </span>
      </div>
    </div>
    <div class="d-flex justify-center text-h3 font-weight-bold pt-12">
      {{ formatPercent(overlap) }}
    </div>
  </div>
</template>

<script>
import CircleSvg from '@/components/Visualisations/CircleSvg';
import LocalisedText from '@/components/Common/LocalisedText';
import { questionVisualisationHelper } from '@/mixins/SuperUtils';

export default {
  name: 'CirclesOverlapPublicDisplayItem',
  components: {
    CircleSvg,
    LocalisedText
  },
  mixins: [questionVisualisationHelper],
  props: {
    questionStat: {
      required: true,
      type: Object
    },
    displayLanguage: {
      type: String,
      required: true
    },
    theme: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      MAX_TRANSLATION: 25
    };
  },
  computed: {
    overlap() {
      return this.questionStat.average;
    },
    circles() {
      return [
        {
          label: this.questionStat.leftWord,
          xTranslation:
            -this.MAX_TRANSLATION +
            this.overlap * (this.MAX_TRANSLATION * 2),
          colors: [
            {
              offset: 0,
              stopColor: this.theme.primaryColor
            }
          ]
        },
        {
          label: this.questionStat.rightWord,
          xTranslation:
            this.MAX_TRANSLATION -
            this.overlap * (this.MAX_TRANSLATION * 2),
          colors: [
            { offset: 0, stopColor: this.theme.secondaryColor }
          ]
        }
      ];
    }
  }
};
</script>

<style lang="scss" scoped>
.label {
  width: 400px !important;
}

.w-100 {
  width: 100% !important;
}
</style>
