<template>
  <v-dialog :value="showDialog" persistent max-width="560">
    <v-card>
      <v-card-title>{{ title }}</v-card-title>
      <member-form
        v-if="show"
        :member="memberInForm"
        :organisation="organisation"
        @close="$emit('update:show', false)"
      />
    </v-card>
  </v-dialog>
</template>

<script>
import MemberForm from './Edit/MemberForm.vue';
import { naiveClone } from '@/utils';

export default {
  name: 'MemberDialog',
  components: { MemberForm },
  props: {
    member: {
      type: Object,
      required: true
    },
    show: {
      type: Boolean,
      default: false
    },
    organisation: {
      type: Object,
      required: true
    }
  },
  computed: {
    memberInForm() {
      return this.member.id ? naiveClone(this.member) : {};
    },
    showDialog() {
      return this.show == false ? this.show : this.member;
    },
    title() {
      return `${this.member.id ? 'Edit' : 'Add'} Organisation Member`;
    }
  }
};
</script>
