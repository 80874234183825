import ApiService from '../../../api';

const actions = {
  fetchStats: {
    handler(context, stats) {
      return ApiService.getApi()
        .get('/stats/survey', stats.surveyId, {
          params: {
            startDate: stats.startDate,
            endDate: stats.endDate
          }
        })
        .then(response => {
          context.commit('setStats', response.data);
        });
    }
  },
  setStats: {
    handler(context, stats) {
      context.commit('setStats', stats);
      return stats;
    }
  },
  fetchPublicDisplayStats: {
    handler(context, surveyId) {
      return ApiService.getApi()
        .get(`/stats/survey/${surveyId}/publicDisplay`)
        .then(response => {
          context.commit('setPublicDisplayStats', response.data);
        });
    }
  }
};

export default actions;
