<template>
  <div v-if="hasIssues">
    <v-row class="mx-0">
      <v-col cols="1" class="px-0">
        <v-icon size="24" color="error" class="mr-2">
          error
        </v-icon>
      </v-col>
      <v-col>
        <p
          v-for="(issue, i) of surveyIssues"
          :key="i"
          class="issue my-0"
        >
          {{ issue }}
        </p>
      </v-col>
    </v-row>
  </div>

  <div v-else-if="!isPublished && !hasIssues">
    <v-row class="mx-0">
      <v-col cols="1" class="px-0">
        <v-icon size="24" color="success" class="mr-2">
          check_circle
        </v-icon>
      </v-col>

      <v-col>
        <p class="issue my-0">Ready to publish</p>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { surveyHelper } from '@/mixins/SuperUtils';

export default {
  name: 'SurveyIssues',
  mixins: [surveyHelper],
  props: {
    survey: {
      type: Object,
      required: true
    },
    surveyIssues: {
      type: Array,
      required: true
    }
  },
  computed: {
    hasIssues() {
      return this.issues.length > 0;
    }
  }
};
</script>

<style lang="scss" scoped>
.issue {
  line-height: 1.75;
}
</style>
