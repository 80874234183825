<template>
  <v-row>
    <v-col cols="8" class="pa-6">
      <emotion-wheel
        :slices="chartSlices"
        cutout
        filled
        show-labels
      />
    </v-col>
    <v-col cols="4" class="d-flex flex-column justify-center pa-0">
      <emotion-wheel-legend
        :items="legendItems"
        :display-language="displayLanguage"
      />
    </v-col>
  </v-row>
</template>

<script>
import { mapState } from 'vuex';
import EmotionWheel from '@/components/Visualisations/EmotionWheel';
import EmotionWheelLegend from '@/components/Visualisations/EmotionWheelLegend';
import { translateStringOrFallback } from '@/utils/language';

export default {
  name: 'EmotionWheelVisualisation',
  components: {
    EmotionWheel,
    EmotionWheelLegend
  },
  props: {
    questionStat: {
      required: true,
      type: Object
    },
    displayLanguage: {
      type: String,
      required: true
    }
  },
  computed: {
    ...mapState('stats', ['stats']),
    primaryLanguage() {
      return this.stats.languages[0];
    },
    chartSlices() {
      return this.values.map((value, index) => {
        return {
          colour: this.emotionColours(index),
          index: index,
          label: translateStringOrFallback(
            this.emotionLabel(index),
            this.displayLanguage,
            this.survey ? this.primaryLanguage : this.displayLanguage
          ).string,
          value: value.average
        };
      });
    },
    legendItems() {
      return this.values.map((_, index) => {
        return {
          colour: this.emotionColours(index),
          label: this.emotionLabel(index),
          value: this.emotionPercent(index)
        };
      });
    },
    values() {
      return this.questionStat.values;
    }
  },
  methods: {
    emotionColours(index) {
      return this.values[index].colour;
    },
    emotionLabel(index) {
      return this.values[index].label;
    },
    emotionPercent(index) {
      const percentage = this.values[index].average * 100;
      return `${percentage.toFixed(0)}%`;
    }
  }
};
</script>
