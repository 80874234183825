<template>
  <div>
    <v-row
      v-for="(item, i) in items"
      :key="`emotion-${i}`"
      class="ma-0 pa-0"
    >
      <v-col cols="2" class="ma-0 my-n1 pa-0">
        <div class="pt-1">
          <svg width="24" height="24">
            <circle
              cx="12"
              cy="12"
              r="10"
              :fill="item.colour"
              fill-opacity="0.5"
              :stroke="item.colour"
              stroke-width="2"
            />
          </svg>
        </div>
      </v-col>
      <v-col cols="7" class="pa-0">
        <localised-text
          :language="displayLanguage"
          :string="item.label"
        />
      </v-col>
      <v-col cols="3" class="pa-0">
        <div class="font-weight-bold">{{ item.value }}</div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import LocalisedText from '@/components/Common/LocalisedText';

export default {
  name: 'EmotionWheelLegend',
  components: {
    LocalisedText
  },
  props: {
    items: {
      type: Array,
      required: true
    },
    displayLanguage: {
      type: String,
      required: true
    }
  }
};
</script>
