<template>
  <div>
    <v-tooltip v-if="tooltip" top>
      <template v-slot:activator="{ on }">
        <v-btn
          class="question-form-action-button"
          x-small
          min-width="24px"
          max-width="24px"
          :color="button.colors"
          v-on="on"
          @click="$emit('click')"
        >
          <v-icon size="18">{{ button.icon }}</v-icon>
        </v-btn>
      </template>
      <span>{{ button.tooltipText }}</span>
    </v-tooltip>
    <v-btn
      v-else
      class="question-form-action-button"
      x-small
      min-width="24px"
      max-width="24px"
      :color="button.colors"
      @click="$emit('click')"
    >
      <v-icon size="18">{{ button.icon }}</v-icon>
    </v-btn>
  </div>
</template>

<script>
import { QuestionFormActions } from '@/enums';

const deleteQuestionButton = {
  icon: 'delete_outline',
  colors: 'error',
  tooltipText: 'Delete question',
  action: QuestionFormActions.DELETE.toLowerCase()
};
const clearResponsesButton = {
  icon: 'speaker_notes_off',
  colors: 'grey lighten-5 grey--text text--darken-1',
  tooltipText: 'Clear question responses',
  action: QuestionFormActions.CLEAR_RESPONSES.toLowerCase().replaceAll(
    '_',
    '-'
  )
};
const archiveQuestionButton = {
  icon: 'archive',
  colors: 'grey lighten-5 grey--text text--darken-1',
  tooltipText: 'Archive question',
  action: QuestionFormActions.ARCHIVE.toLowerCase()
};
const duplicateQuestionButton = {
  icon: 'content_copy',
  colors: 'grey lighten-5 grey--text text--darken-1',
  tooltipText: 'Duplicate question',
  action: QuestionFormActions.DUPLICATE.toLowerCase()
};

export default {
  props: {
    action: {
      type: String,
      required: true,
      validator: value =>
        Object.values(QuestionFormActions).includes(value)
    },
    tooltip: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  computed: {
    button() {
      if (this.action == QuestionFormActions.DELETE)
        return deleteQuestionButton;
      else if (this.action == QuestionFormActions.CLEAR_RESPONSES)
        return clearResponsesButton;
      else if (this.action == QuestionFormActions.ARCHIVE)
        return archiveQuestionButton;
      else return duplicateQuestionButton;
    }
  }
};
</script>
