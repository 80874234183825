<template>
  <svg
    width="60%"
    class="mx-auto"
    :viewBox="`0 0 ${maxBarWidth} ${visualisationHeight}`"
  >
    <rect
      v-for="(option, index) of options"
      :key="`background_${index}`"
      x="0"
      :y="barYPosition(index)"
      width="100%"
      :height="barHeight"
      class="background"
    />
    <rect
      v-for="(option, index) of options"
      :key="`foreground_${index}`"
      x="0"
      :y="barYPosition(index)"
      :width="barWidth(option.value)"
      :height="barHeight"
      :fill="$vuetify.theme.currentTheme.primary"
    />
    <text
      v-for="(option, index) of options"
      :key="`label_${index}`"
      :x="textLeft"
      :y="textYPosition(index)"
      class="text"
    >
      {{ option.label }}
    </text>
    <text
      v-for="(option, index) of options"
      :key="`option_${index}`"
      :x="textRight"
      :y="textYPosition(index)"
      class="text"
      text-anchor="end"
    >
      {{ percent(option.value) }}
    </text>
  </svg>
</template>

<script>
import { mapGetters } from 'vuex';
import { translateStringOrFallback } from '@/utils/language';

export default {
  name: 'MultipleChoicePublicDisplayItem',
  props: {
    questionStat: {
      required: true,
      type: Object
    },
    displayLanguage: {
      required: true,
      type: String
    }
  },
  data: () => ({
    barHeight: 48,
    barPadding: 8,
    textLeft: 16,
    textSize: 12
  }),
  computed: {
    ...mapGetters('survey', ['primaryLanguage']),
    maxBarWidth() {
      return 700;
    },
    textRight() {
      return this.maxBarWidth - this.textLeft;
    },
    visualisationHeight() {
      return (this.barHeight + this.barPadding) * this.options.length;
    },
    options() {
      const totalResponses = this.questionStat.totalResponses;
      return this.questionStat.wordSelections.map(option => ({
        label: translateStringOrFallback(
          option.word,
          this.displayLanguage,
          this.survey ? this.primaryLanguage : this.displayLanguage
        ).string,
        value: isNaN(option.count / totalResponses)
          ? 0
          : option.count / totalResponses
      }));
    }
  },
  methods: {
    barWidth(value) {
      return value * this.maxBarWidth;
    },
    percent(value) {
      return `${(value * 100).toFixed(0)}%`;
    },
    barYPosition(index) {
      return index * (this.barHeight + this.barPadding);
    },
    textYPosition(index) {
      return (
        index * (this.barHeight + this.barPadding) +
        this.textSize * 0.5 +
        this.barHeight * 0.5
      );
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../scss/main.scss';
.background {
  fill: map-get($grey, 'lighten-1');
}
.text {
  fill: map-get($shades, 'white');
}
</style>
