<template>
  <div>
    <v-row>
      <v-col class="d-flex justify-center">
        <v-card flat class="text-center" height="100%">
          <v-card-text class="pt-0">
            <circle-svg
              v-for="(circle, index) in circles"
              :key="index"
              :size="150"
              :opacity="0.5"
              :colors="circle.colors"
              :x-translation="circle.xTranslation"
            />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        v-for="(circle, index) in circles"
        :key="index"
        cols="12"
        md="6"
        class="d-flex justify-center"
      >
        <span class="text-h6 font-weight-bold">
          <localised-text
            :language="displayLanguage"
            :string="circle.label"
          />
        </span>
      </v-col>
    </v-row>
    <div class="text-h6 text-center grey--text text--darken-2">
      {{ formatPercent(overlap) }}
    </div>
  </div>
</template>

<script>
import colors from 'vuetify/lib/util/colors';
import CircleSvg from '@/components/Visualisations/CircleSvg';
import LocalisedText from '@/components/Common/LocalisedText';
import { questionVisualisationHelper } from '@/mixins/SuperUtils';

export default {
  components: {
    CircleSvg,
    LocalisedText
  },
  mixins: [questionVisualisationHelper],
  props: {
    questionStat: {
      required: true,
      type: Object
    },
    displayLanguage: {
      type: String,
      required: true
    }
  },
  computed: {
    overlap() {
      return this.questionStat.average;
    },
    circles() {
      return [
        {
          label: this.questionStat.leftWord,
          xTranslation: 50 * this.overlap,
          colors: [
            {
              offset: 0,
              stopColor: this.$vuetify.theme.defaults.light.primary
            }
          ]
        },
        {
          label: this.questionStat.rightWord,
          xTranslation: -50 * this.overlap,
          colors: [{ offset: 0, stopColor: colors.grey.darken1 }]
        }
      ];
    }
  }
};
</script>
