var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      staticClass: "question-form-action-dialog",
      attrs: { value: true, persistent: "", width: "500" }
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            { staticClass: "mb-3 font-weight-bold" },
            [_vm._t("title")],
            2
          ),
          _c(
            "v-card-subtitle",
            { staticClass: "d-flex align-center font-weight-bold" },
            [
              _c("question-type-icon", {
                staticClass: "mr-3",
                attrs: { "question-type": _vm.questionType }
              }),
              _vm._v(
                " " +
                  _vm._s(_vm.questionOrderIndex) +
                  ". " +
                  _vm._s(_vm.questionTitle) +
                  " "
              )
            ],
            1
          ),
          _c(
            "v-card-text",
            { staticClass: "question-form-action-dialog__content" },
            [_vm._t("content")],
            2
          ),
          _c(
            "v-card-actions",
            { staticClass: "pa-3" },
            [
              _c("v-spacer"),
              _vm._t("actions", function() {
                return [
                  _c(
                    "v-btn",
                    {
                      attrs: { small: "" },
                      on: {
                        click: function($event) {
                          return _vm.$emit("close")
                        }
                      }
                    },
                    [_vm._v("cancel")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: { small: "", color: "error" },
                      on: {
                        click: function($event) {
                          return _vm.$emit("confirm")
                        }
                      }
                    },
                    [
                      _vm._t("confirm-btn-text", function() {
                        return [_vm._v(" confirm ")]
                      })
                    ],
                    2
                  )
                ]
              })
            ],
            2
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }