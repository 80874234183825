<template>
  <div>
    <div class="primary-language-select">
      <language-select
        :value="primaryLanguage"
        :languages="languages"
        prepend-icon="language"
        label="Primary Language"
        presistent-hint
        hint="Required *"
        show-helper
        :rules="validationRules.primaryLanguage"
        @change="onUpdatePrimaryLanguage"
      >
        <template v-slot:tooltip>
          Primary Language is the language used to build your survey
          and will be used as the basis for translating secondary
          languages
        </template>
      </language-select>
    </div>
    <language-select
      :value="secondaryLanguages"
      :languages="secondaryLanguageOptions"
      multiple
      autocomplete
      prepend-icon="translate"
      label="Secondary Languages"
      :menu-props="{ offsetY: true }"
      @change="onUpdateSecondaryLanguage"
    />
  </div>
</template>

<script>
import { LanguageOptions } from '@/utils/LanguageOptions';
import LanguageSelect from './LanguageSelect';

export default {
  name: 'LanguageSelector',
  components: {
    LanguageSelect
  },
  props: {
    primaryLanguage: {
      type: String,
      required: true
    },
    secondaryLanguages: {
      type: Array,
      required: true
    }
  },
  computed: {
    languages() {
      return Object.values(LanguageOptions);
    },
    secondaryLanguageOptions() {
      return this.languages.filter(
        option => option.code !== this.primaryLanguage
      );
    },
    validationRules() {
      return {
        primaryLanguage: [v => !!v || 'Primary language is required']
      };
    }
  },
  methods: {
    findLanguage(languageCode) {
      return this.languages.find(
        language => language.code === languageCode
      );
    },
    onUpdatePrimaryLanguage(language) {
      const currentPrimaryLanguage = this.primaryLanguage;
      this.$emit('update:primary-language', language);
      if (this.secondaryLanguages.includes(language)) {
        const updatedSecondaryLanguages = this.secondaryLanguages
          .filter(secondaryLanguage => secondaryLanguage !== language)
          .concat(currentPrimaryLanguage)
          .sort(this.languageSort);
        this.$emit(
          'update:secondary-languages',
          updatedSecondaryLanguages
        );
      }
    },
    onUpdateSecondaryLanguage(languages) {
      this.$emit(
        'update:secondary-languages',
        languages.sort(this.languageSort)
      );
    },
    languageSort(languageCode1, languageCode2) {
      const languageName1 = this.findLanguage(languageCode1).name,
        languageName2 = this.findLanguage(languageCode2).name;

      if (languageName1 < languageName2) {
        return -1;
      }
      if (languageName1 > languageName2) {
        return 1;
      }
      return 0;
    }
  }
};
</script>

<style lang="scss" scoped>
.primary-language-select {
  width: 240px;
}
::v-deep .v-input__prepend-outer {
  margin-top: 8px;
}
::v-deep .v-select__slot .v-input__append-inner {
  margin-top: 8px;
}
</style>
