<template>
  <div class="p-relative">
    <heart-svg class="background" :colors="backgroundColor" />
    <heart-svg
      class="p-absolute foreground"
      :colors="fillColor"
      :scale="fillPercent"
    />
  </div>
</template>

<script>
import colors from 'vuetify/lib/util/colors';
import HeartSvg from '@/components/Visualisations/HeartSvg';

export default {
  name: 'FilledHeart',
  components: { HeartSvg },
  props: {
    fillPercent: {
      type: Number,
      required: false,
      default: 1.0
    },
    fillColor: {
      type: Array,
      required: false,
      default: () => [{ offset: 0, stopColor: colors.red.darken1 }]
    },
    backgroundColor: {
      type: Array,
      required: false,
      default: () => [{ offset: 0, stopColor: colors.grey.lighten1 }]
    }
  }
};
</script>

<style lang="scss" scoped>
.foreground {
  left: 0;
  top: 0;
}
</style>
