<template>
  <canvas ref="bar-chart"></canvas>
</template>

<script>
import Chart from 'chart.js';

export default {
  name: 'VerticalBarChart',
  props: {
    data: {
      required: true,
      type: Array
    },
    config: {
      required: true,
      type: Object
    }
  },
  mounted() {
    this.createChart();
  },
  methods: {
    createChart() {
      this.$refs['bar-chart'].height = 200;
      new Chart(this.$refs['bar-chart'], {
        type: 'bar',
        data: {
          labels: this.config.labels,
          datasets: [
            {
              data: this.data,
              backgroundColor: this.$vuetify.theme.currentTheme
                .primary
            }
          ]
        },
        options: {
          legend: {
            display: false
          },
          scales: {
            xAxes: [
              {
                gridLines: {
                  display: false
                },
                ticks: {
                  autoSkip: false,
                  maxRotation: 0,
                  fontFamily: 'museo-sans'
                }
              }
            ],
            yAxes: [
              {
                ticks: {
                  display: false,
                  beginAtZero: true
                },
                gridLines: {
                  display: false
                }
              }
            ]
          }
        }
      });
    }
  }
};
</script>
