var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("page-header-bar", [
        !_vm.dataLoading
          ? _c("div", { staticClass: "d-flex p-relative white--text" }, [
              _c("div", { staticClass: "d-flex flex-column" }, [
                _c(
                  "div",
                  { staticClass: "fab-container p-absolute" },
                  [
                    _c(
                      "v-btn",
                      {
                        staticClass: "floating-button",
                        attrs: {
                          color: "grey lighten-2 black--text",
                          dark: "",
                          small: "",
                          fab: ""
                        },
                        on: {
                          click: function($event) {
                            return _vm.closeSurveyTranslator()
                          }
                        }
                      },
                      [
                        _c("v-icon", [
                          _vm._v(
                            " " +
                              _vm._s(_vm.translation ? "close" : "arrow_back") +
                              " "
                          )
                        ])
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "d-flex mr-8" },
                  [
                    _c("div", [
                      _c("h5", { staticClass: "text-h5 mb-2" }, [
                        _vm._v(" " + _vm._s(_vm.surveyTitle) + " ")
                      ])
                    ]),
                    _c(
                      "v-tooltip",
                      {
                        attrs: { top: "" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "activator",
                              fn: function({ on }) {
                                return [
                                  _c(
                                    "div",
                                    _vm._g({ staticClass: "d-flex" }, on),
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass:
                                            "ml-4 mb-2 align-self-center",
                                          attrs: {
                                            color:
                                              "grey lighten-5 grey--text text--darken-1",
                                            "min-width": "24px",
                                            "max-width": "24px",
                                            height: "24px",
                                            "x-small": ""
                                          },
                                          on: {
                                            click: function($event) {
                                              _vm.editSurvey = true
                                            }
                                          }
                                        },
                                        [
                                          _c(
                                            "v-icon",
                                            { attrs: { small: "" } },
                                            [_vm._v("create")]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          1569703698
                        )
                      },
                      [_c("span", [_vm._v("Edit Survey")])]
                    )
                  ],
                  1
                ),
                _c("div", { staticClass: "text-subtitle-1" }, [
                  _vm._v(" " + _vm._s(_vm.getSurveyActivePeriodString) + " ")
                ])
              ]),
              _c("div", { staticClass: "d-flex-grow-1 flex-column ml-8" }, [
                _c(
                  "div",
                  { staticClass: "d-flex align-center" },
                  [
                    _c("v-icon", { staticClass: "white--text mr-2" }, [
                      _vm._v(" format_list_numbered ")
                    ]),
                    _c(
                      "div",
                      { staticClass: "mr-2 font-weight-regular text-h6" },
                      [
                        _vm._v(
                          " " +
                            _vm._s(_vm.survey.userQuestionCount) +
                            " " +
                            _vm._s(
                              _vm.survey.userQuestionCount === 1
                                ? "Question"
                                : "Questions"
                            ) +
                            " "
                        )
                      ]
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "d-flex mt-1" },
                  [
                    _c("v-icon", { staticClass: "white--text mr-2" }, [
                      _vm._v(" record_voice_over ")
                    ]),
                    _c("h6", { staticClass: "text-subtitle-1" }, [
                      _vm._v(" " + _vm._s(_vm.responseCount) + " ")
                    ]),
                    _c(
                      "v-tooltip",
                      {
                        attrs: { right: "" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "activator",
                              fn: function({ on }) {
                                return [
                                  _c(
                                    "div",
                                    _vm._g(
                                      { staticClass: "d-flex align-center" },
                                      on
                                    ),
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass: "ml-4",
                                          attrs: {
                                            color:
                                              "grey lighten-5 grey--text text--darken-1",
                                            disabled: !_vm.hasResponses,
                                            "min-width": "24px",
                                            "max-width": "24px",
                                            height: "24px",
                                            "x-small": ""
                                          },
                                          on: {
                                            click: function($event) {
                                              _vm.showClearResponsesDialog = true
                                            }
                                          }
                                        },
                                        [
                                          _c(
                                            "v-icon",
                                            { attrs: { small: "" } },
                                            [_vm._v("speaker_notes_off")]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          1826874861
                        )
                      },
                      [_c("span", [_vm._v("Clear Survey Responses")])]
                    )
                  ],
                  1
                )
              ])
            ])
          : _vm._e()
      ]),
      _vm.dataLoading
        ? _c(
            "div",
            [
              _c(
                "v-container",
                { staticClass: "pa-2" },
                [_c("question-list-loader")],
                1
              )
            ],
            1
          )
        : _c(
            "v-container",
            { staticClass: "pa-2" },
            [
              _c(
                "v-tabs",
                {
                  staticClass: "pa-8",
                  attrs: { grow: "" },
                  model: {
                    value: _vm.activeTab,
                    callback: function($$v) {
                      _vm.activeTab = $$v
                    },
                    expression: "activeTab"
                  }
                },
                [
                  _vm._l(_vm.tabs, function(tab, i) {
                    return _c(
                      "div",
                      { key: tab.href, staticClass: "d-flex flex-grow-1" },
                      [
                        !tab.isDisabled
                          ? _c(
                              "v-tab",
                              { attrs: { href: tab.href } },
                              [
                                _c("v-icon", { staticClass: "mr-2" }, [
                                  _vm._v(_vm._s(tab.icon))
                                ]),
                                _vm._v(_vm._s(tab.title) + " ")
                              ],
                              1
                            )
                          : _c(
                              "v-tooltip",
                              {
                                key: i,
                                attrs: { bottom: "" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function({ on }) {
                                        return [
                                          _c(
                                            "div",
                                            _vm._g(
                                              {
                                                staticClass:
                                                  "d-flex flex-grow-1"
                                              },
                                              _vm.hasResponses ? {} : on
                                            ),
                                            [
                                              _c(
                                                "v-tab",
                                                {
                                                  attrs: {
                                                    href: tab.href,
                                                    disabled: tab.isDisabled
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "v-icon",
                                                    { staticClass: "mr-2" },
                                                    [_vm._v(_vm._s(tab.icon))]
                                                  ),
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(tab.title) +
                                                      " "
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  true
                                )
                              },
                              [
                                _c("span", [
                                  _vm._v(" " + _vm._s(tab.tabToolTipText) + " ")
                                ])
                              ]
                            )
                      ],
                      1
                    )
                  }),
                  _c(
                    "v-tab-item",
                    { attrs: { value: "questions" } },
                    [
                      _c("survey-summary-card", {
                        staticClass: "mt-4",
                        attrs: {
                          survey: _vm.survey,
                          "selected-language": _vm.selectedLanguage
                        },
                        on: {
                          "language-clicked": _vm.setDisplayLanguage,
                          "show-survey-preview": function($event) {
                            _vm.showPreviewDialog = true
                          },
                          "edit-survey-disclaimer": function($event) {
                            _vm.showDisclaimerDialog = true
                          }
                        }
                      }),
                      !_vm.translation
                        ? _c("questions-view", {
                            attrs: {
                              survey: _vm.survey,
                              questions: _vm.questions,
                              "question-table-key": _vm.questionTableKey,
                              "data-loading": _vm.dataLoading
                            }
                          })
                        : _c("survey-translator", {
                            attrs: {
                              survey: _vm.survey,
                              "translation-language": _vm.translation
                            },
                            on: {
                              "update-translations": _vm.updateTranslations,
                              close: _vm.closeTranslate
                            }
                          })
                    ],
                    1
                  ),
                  _c(
                    "v-tab-item",
                    { attrs: { value: "dashboard" } },
                    [
                      _c("div", { staticClass: "d-flex" }, [
                        _c("div", { staticClass: "flex-row mt-6" }, [
                          _c(
                            "p",
                            { staticClass: "grey--text text--darken-1 mb-0" },
                            [
                              _vm._v(" Public Access URL "),
                              _c(
                                "v-tooltip",
                                {
                                  attrs: { top: "" },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "activator",
                                      fn: function({ on }) {
                                        return [
                                          _c(
                                            "v-icon",
                                            _vm._g(
                                              {
                                                staticClass: "mb-1",
                                                attrs: {
                                                  color: "primary",
                                                  size: "18"
                                                }
                                              },
                                              on
                                            ),
                                            [_vm._v(" help ")]
                                          )
                                        ]
                                      }
                                    }
                                  ])
                                },
                                [
                                  _c("span", [
                                    _vm._v(
                                      " Share this link to offer access to the dashboard without needing to log into muse. "
                                    )
                                  ])
                                ]
                              )
                            ],
                            1
                          ),
                          _c(
                            "span",
                            [
                              _c(
                                "a",
                                {
                                  staticClass: "mr-2",
                                  attrs: {
                                    href: _vm.publicDashboardAccessUrl,
                                    target: "_blank"
                                  }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.publicDashboardAccessUrl) +
                                      " "
                                  )
                                ]
                              ),
                              _c(
                                "v-btn",
                                {
                                  staticClass: "x-small-square",
                                  attrs: { "x-small": "" },
                                  on: { click: _vm.copyPublicDashboardUrl }
                                },
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      attrs: {
                                        "x-small": "",
                                        icon: "",
                                        tile: ""
                                      }
                                    },
                                    [_vm._v(" content_copy ")]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ])
                      ]),
                      _c("dashboard-view", {
                        attrs: { "display-language": _vm.displayLanguage },
                        on: { "fetch-stats-with-date": _vm.fetchStats }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-tab-item",
                    { attrs: { value: "public-display" } },
                    [
                      _c("public-display-config-view", {
                        staticClass: "mt-4",
                        attrs: { survey: _vm.survey }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-tab-item",
                    { attrs: { value: "export" } },
                    [
                      _c("survey-data-export-card", {
                        staticClass: "mt-4",
                        attrs: {
                          "start-date": _vm.survey.surveyStart,
                          "end-date": _vm.survey.surveyEnd,
                          survey: _vm.survey
                        }
                      })
                    ],
                    1
                  )
                ],
                2
              ),
              _c("survey-preview-dialog", {
                attrs: {
                  show: _vm.showPreviewDialog,
                  "selected-survey": _vm.survey,
                  "questions-list": _vm.previewQuestions
                },
                on: {
                  "update:show": function($event) {
                    _vm.showPreviewDialog = $event
                  }
                }
              }),
              _c("survey-dialog", {
                attrs: {
                  show: _vm.editSurvey,
                  survey: _vm.survey,
                  organisations: _vm.organisations
                },
                on: {
                  "update:show": function($event) {
                    _vm.editSurvey = $event
                  },
                  save: _vm.handleSurveySaved
                }
              }),
              _c("survey-disclaimer-dialog", {
                attrs: { survey: _vm.survey },
                model: {
                  value: _vm.showDisclaimerDialog,
                  callback: function($$v) {
                    _vm.showDisclaimerDialog = $$v
                  },
                  expression: "showDisclaimerDialog"
                }
              }),
              _c("survey-action-dialog", {
                attrs: {
                  show: _vm.showActionDialog,
                  survey: _vm.survey,
                  action: _vm.surveyAction
                },
                on: {
                  "update:show": function($event) {
                    _vm.showActionDialog = $event
                  },
                  "dialog-action-confirmed": _vm.handleDialogActionConfirmed
                }
              }),
              _c("clear-survey-responses-confirmation-dialog", {
                attrs: {
                  show: _vm.showClearResponsesDialog,
                  survey: _vm.survey
                },
                on: {
                  "update:show": function($event) {
                    _vm.showClearResponsesDialog = $event
                  },
                  "clear-responses": function($event) {
                    return _vm.clearSurveyResponses()
                  },
                  cancel: function($event) {
                    _vm.showClearResponsesDialog = false
                  }
                }
              })
            ],
            1
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }