var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-card-title",
        { staticClass: "justify-center text-h1 font-weight-medium" },
        [_vm._v(" " + _vm._s(_vm.questionStatAverage) + " ")]
      ),
      _c(
        "v-card-text",
        { staticClass: "text-center text-h6 grey--text text--darken-2" },
        [_vm._v(" average ")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }