var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-row",
        { staticClass: "justify-center align-center" },
        [_c("v-img", { attrs: { src: _vm.getImgUrl, "max-width": "100px" } })],
        1
      ),
      _c(
        "v-row",
        [
          _c(
            "v-card-text",
            { staticClass: "text-h6 font-weight-bold text-center pb-0" },
            [_vm._v(" " + _vm._s(_vm.sound) + " ")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }