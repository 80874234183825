var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("v-card", { staticClass: "pa-4", attrs: { flat: "" } }, [
    _c(
      "div",
      { staticClass: "d-flex flex-column h-100" },
      [
        _c(
          "v-card-title",
          { staticClass: "pa-0 mb-2 font-weight-bold" },
          [
            _c("localised-text", {
              attrs: {
                language: _vm.displayLanguage,
                string: _vm.questionStat.title
              }
            })
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "grey--text text--darken-1" },
          [
            _c("question-type-icon", {
              staticClass: "mr-2",
              attrs: { "question-type": _vm.questionType }
            }),
            _c("span", [_vm._v(_vm._s(_vm.questionTypeDisplayName))])
          ],
          1
        ),
        _c("div", { staticClass: "grey--text text--lighten-1 mb-4" }, [
          _vm._v(
            " " +
              _vm._s(_vm.questionStat.totalResponses) +
              " out of " +
              _vm._s(_vm.totalRespondents) +
              " respondents "
          )
        ]),
        _c(
          "v-card",
          { staticClass: "flex-grow-1", attrs: { flat: "" } },
          [
            _c("v-container", { staticClass: "d-flex flex-column h-100" }, [
              _c(
                "div",
                { staticClass: "my-auto" },
                [
                  _vm.hasResponses
                    ? _vm._t("item")
                    : _c("no-responses-placeholder")
                ],
                2
              )
            ])
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }