var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "p-relative" },
    [
      _c("filled-heart", {
        staticClass: "filled-heart",
        attrs: { "fill-percent": _vm.questionStat.average }
      }),
      _c(
        "span",
        { staticClass: "p-absolute rating title white--text font-weight-bold" },
        [
          _vm._v(
            " " + _vm._s(_vm.formatPercent(_vm.questionStat.average)) + " "
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }