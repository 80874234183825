<template>
  <div>
    <duplicate-question-list-item
      v-for="(question, i) in questions"
      :key="question.id"
      :index="i + 1"
      :title="getTitle(question.titleMap)"
      :type="getType(question.type)"
      :duplicate="question.duplicate"
      @toggle-duplication="$emit('toggle-duplication', question)"
    />
  </div>
</template>

<script>
import DuplicateQuestionListItem from './DuplicateQuestionListItem';
import QuestionTypeConfig from '@/utils/QuestionTypeConfig';

export default {
  name: 'DuplicateQuestionList',
  components: {
    DuplicateQuestionListItem
  },
  props: {
    questions: {
      type: Array,
      required: true
    },
    primaryLanguage: {
      type: String,
      required: true
    }
  },
  methods: {
    getTitle(titleMap) {
      return titleMap[this.primaryLanguage];
    },
    getType(type) {
      return {
        value: type,
        name: QuestionTypeConfig[type].displayName
      };
    }
  }
};
</script>
