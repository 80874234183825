<template>
  <question-form-action-dialog
    v-bind="$props"
    @close="$emit('close')"
    @confirm="$emit('confirm')"
  >
    <template v-slot:title>
      Delete Question
    </template>
    <template v-slot:content>
      <p>
        This question has
        <span class="font-weight-bold black--text">{{
          responseCountText
        }}</span
        >. If you choose to delete the question, all response data
        will be lost.
      </p>
    </template>
    <template v-slot:confirm-btn-text>
      delete
    </template>
  </question-form-action-dialog>
</template>

<script>
import QuestionFormActionDialog from './QuestionFormActionDialog';
import QuestionActionConfirmationDialog from '@/mixins/QuestionActionConfirmationDialog';
export default {
  components: {
    QuestionFormActionDialog
  },
  mixins: [QuestionActionConfirmationDialog],
  props: {
    questionTitle: {
      type: String,
      required: true
    },
    questionType: {
      type: String,
      required: true
    },
    questionOrderIndex: {
      type: Number,
      required: true
    },
    questionResponseCount: {
      type: Number,
      required: true
    }
  }
};
</script>
