<template>
  <div>
    <v-row class="grey lighten-1">
      <v-spacer></v-spacer>
      <v-col :cols="getWidth" class="white text-center my-0 px-0">
        <gender-male class="male" />
      </v-col>
      <v-spacer></v-spacer>
    </v-row>
    <v-row>
      <v-col class="text-h6 font-weight-bold text-center">
        {{ percentage }}%
      </v-col>
    </v-row>
  </div>
</template>

<script>
import GenderMale from '!!vue-svg-loader!../../../assets/svgs/GenderMale.svg';

export default {
  components: {
    GenderMale
  },
  props: {
    questionStat: {
      required: true,
      type: Object
    }
  },
  computed: {
    getWidth() {
      const minWidth = 2;
      const width = Math.round(
        ((100 - this.questionStat.percentage) / 100) * 10
      );
      return minWidth + width;
    },
    percentage() {
      return this.questionStat.average === 0
        ? 0
        : (this.questionStat.average * 100).toFixed(0);
    }
  }
};
</script>

<style scoped lang="scss">
@import '../../../scss/main.scss';
svg {
  height: 100px;

  &.male path {
    fill: map-get($grey, 'darken-1');
  }
}
</style>
