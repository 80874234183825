var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { id: "public-display-no-data", "fill-height": "", fluid: "" } },
    [
      _c(
        "v-row",
        { attrs: { align: "center", justify: "center" } },
        [
          _c("v-col", { attrs: { align: "center", justify: "center" } }, [
            !_vm.hasQuestions
              ? _c("div", [
                  _c("div", { staticClass: "text-h1 6rem mb-6" }, [
                    _vm._v("No questions")
                  ]),
                  _c(
                    "h2",
                    [
                      _vm._v(" Please add questions to the Public Display"),
                      _c("br"),
                      _vm._v("for "),
                      _c(
                        "router-link",
                        {
                          staticClass: "link",
                          attrs: { to: _vm.publicDisplayConfigLink }
                        },
                        [_vm._v(_vm._s(_vm.surveyTitle))]
                      ),
                      _vm._v(". ")
                    ],
                    1
                  )
                ])
              : !_vm.hasResponses
              ? _c("div", [
                  _c("div", { staticClass: "text-h1 6rem mb-6" }, [
                    _vm._v("No responses")
                  ]),
                  _c(
                    "h2",
                    [
                      _c(
                        "router-link",
                        {
                          staticClass: "link",
                          attrs: { to: _vm.publicDisplayConfigLink }
                        },
                        [_vm._v(_vm._s(_vm.surveyTitle))]
                      ),
                      _vm._v(" currently has no responses. ")
                    ],
                    1
                  )
                ])
              : _c(
                  "div",
                  [
                    _c("v-img", {
                      attrs: {
                        "max-width": "150",
                        src: require("../../assets/logo.png")
                      }
                    })
                  ],
                  1
                )
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }