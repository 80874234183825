<template>
  <div>
    <word-ladder
      :question-stat="questionStat"
      :display-language="displayLanguage"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import WordLadder from '@/components/Visualisations/WordLadder';

export default {
  name: 'TopWordsPublicDisplayItem',
  components: {
    WordLadder
  },
  props: {
    questionStat: {
      required: true,
      type: Object
    },
    limit: {
      required: false,
      type: Number,
      default: 5
    },
    displayLanguage: {
      type: String,
      required: true
    }
  },
  computed: {
    ...mapGetters('survey', ['primaryLanguage']),
    rankedWords() {
      return this.wordSelections
        .slice()
        .sort((a, b) => a.count - b.count)
        .reverse();
    },
    wordSelections() {
      return this.questionStat.wordSelections.map(
        (wordSelection, index) => ({
          count: wordSelection.count,
          word: wordSelection.word,
          percentage:
            (wordSelection.count / this.questionStat.totalResponses) *
            100,
          index: index
        })
      );
    }
  }
};
</script>

<style lang="scss" scoped>
.row:nth-child(odd) {
  background-color: var(--v-primary-transparent-base);
}
</style>
