var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { staticClass: "d-flex justify-center" },
            [
              _c(
                "v-card",
                {
                  staticClass: "text-center",
                  attrs: { flat: "", height: "100%" }
                },
                [
                  _c(
                    "v-card-text",
                    { staticClass: "pt-0" },
                    _vm._l(_vm.circles, function(circle, index) {
                      return _c("circle-svg", {
                        key: index,
                        attrs: {
                          size: 150,
                          opacity: 0.5,
                          colors: circle.colors,
                          "x-translation": circle.xTranslation
                        }
                      })
                    }),
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-row",
        _vm._l(_vm.circles, function(circle, index) {
          return _c(
            "v-col",
            {
              key: index,
              staticClass: "d-flex justify-center",
              attrs: { cols: "12", md: "6" }
            },
            [
              _c(
                "span",
                { staticClass: "text-h6 font-weight-bold" },
                [
                  _c("localised-text", {
                    attrs: {
                      language: _vm.displayLanguage,
                      string: circle.label
                    }
                  })
                ],
                1
              )
            ]
          )
        }),
        1
      ),
      _c(
        "div",
        { staticClass: "text-h6 text-center grey--text text--darken-2" },
        [_vm._v(" " + _vm._s(_vm.formatPercent(_vm.overlap)) + " ")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }