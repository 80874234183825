var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("word-ladder", {
        attrs: {
          "question-stat": _vm.questionStat,
          "display-language": _vm.displayLanguage
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }