var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.loading
        ? _c(
            "div",
            _vm._l(5, function(index) {
              return _c("organisation-list-item-loader", { key: index })
            }),
            1
          )
        : _vm._l(_vm.organisations, function(organisation) {
            return _c("organisation-list-item", {
              key: organisation.id,
              attrs: { organisation: organisation }
            })
          })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }