<template>
  <v-card>
    <v-container>
      <v-row>
        <!-- Questions -->
        <v-col class="ms-4">
          <p class="bold">Questions</p>
          <p class="mt-n3">
            {{ survey.userQuestionCount }}
            {{
              survey.userQuestionCount === 1
                ? 'Question'
                : 'Questions'
            }}
          </p>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <div class="d-inline-flex" v-on="on">
                <v-btn
                  class="mt-4 grey--text text--darken-2 grey lighten-3 text-capitalize mt-n2"
                  height="25"
                  :disabled="!surveyHasQuestions"
                  v-on="on"
                  @click="$emit('show-survey-preview')"
                >
                  <v-icon small left color="grey darken-2">
                    play_circle_outline
                  </v-icon>
                  Preview Survey
                </v-btn>
              </div>
            </template>
            <span>{{ getbuttonTooltipText() }}</span>
          </v-tooltip>
        </v-col>
        <v-divider vertical></v-divider>
        <!-- Disclaimer -->
        <v-col class="bold ms-4 me-4">
          <p class="bold">Disclaimer</p>
          <div class="d-flex">
            <div class="mt-n2">
              <p
                class="d-flex small-text grey--text text--darken-1 bold justify-content-center"
              >
                Include Disclaimer
              </p>
              <v-switch
                v-model="survey.showDisclaimer"
                class="mt-n3"
                color="success"
                hide-details
                @change="toggleShowDisclaimer($event)"
              ></v-switch>
            </div>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <div class="d-inline-flex" v-on="on">
                  <v-btn
                    :disabled="!survey.showDisclaimer"
                    class="ml-6 mt-4 align-self-end"
                    height="30"
                    v-on="on"
                    @click="$emit('edit-survey-disclaimer')"
                  >
                    Edit
                  </v-btn>
                </div>
              </template>
              <span>Survey Disclaimer</span>
            </v-tooltip>
          </div>
        </v-col>
        <!-- Translations -->
        <v-divider
          v-if="surveyHadMultipleLanguages"
          vertical
        ></v-divider>
        <v-col v-if="surveyHadMultipleLanguages" class="ms-4">
          <p class="bold">Language Translations</p>
          <div
            v-if="displayMissingLanguageCodes"
            class="d-flex small-text mt-n2"
          >
            <p class="pr-1">Missing</p>
            <strong>
              {{
                languageTranslationStatus
                  .filter(language => !language.complete)
                  .map(language => language.code)
                  .join(' ')
              }}
            </strong>
            <p class="pl-1">translations</p>
          </div>
          <survey-language-status
            :language-status="languageTranslationStatus"
            :highlight="selectedLanguage"
            :class="displayMissingLanguageCodes ? 'mt-n2' : 'mt-8'"
            show-completed
            @language-clicked="$emit('language-clicked', $event)"
          />
        </v-col>
        <v-divider vertical></v-divider>
        <!-- Status -->
        <v-col class="ms-4">
          <p class="bold mb-2">Publish Survey</p>
          <p
            class="small-text grey--text text--darken-1 bold justify-content-center"
          >
            <span v-if="!surveyPublished">Survey is in draft</span>
            <span v-else>Survey is published</span>
          </p>
          <v-switch
            v-model="surveyPublished"
            class="mt-n3"
            color="success"
            hide-details
            @change="togglePublishSurvey($event)"
          ></v-switch>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import { surveyHelper } from '@/mixins/SuperUtils';
import SurveyLanguageStatus from '@/components/Common/SurveyLanguageStatus';
import { SurveyStatus } from '@/enums';
import { mapActions } from 'vuex';

export default {
  name: 'SurveySummaryCard',
  components: {
    SurveyLanguageStatus
  },
  mixins: [surveyHelper],
  props: {
    survey: {
      type: Object,
      required: true
    },
    selectedLanguage: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      surveyPublished: false
    };
  },
  computed: {
    surveyHasQuestions() {
      return this.survey.userQuestionCount > 0;
    },
    surveyHadMultipleLanguages() {
      return this.survey.secondaryLanguages.length > 0;
    },
    displayMissingLanguageCodes() {
      // we dont want to display the missing codes message if we have full translations for each language
      return (
        Object.keys(this.survey.incompleteQuestionTranslations)
          .length > 0
      );
    }
  },
  mounted() {
    this.surveyPublished =
      this.survey.status === SurveyStatus.PUBLISHED;
  },
  methods: {
    ...mapActions({ updateSurvey: 'survey/updateSurvey' }),
    getbuttonTooltipText() {
      return this.surveyHasQuestions
        ? 'Survey Preview'
        : 'Add a question to preview the survey';
    },
    togglePublishSurvey(newValue) {
      this.surveyPublished = newValue;
      this.survey.status = this.surveyPublished
        ? SurveyStatus.PUBLISHED
        : SurveyStatus.DRAFT;
      this.updateSurvey(this.survey);
    },
    toggleShowDisclaimer(newValue) {
      this.survey.showDisclaimer = newValue;
      this.updateSurvey(this.survey);
    }
  }
};
</script>

<style scoped>
.bold {
  font-weight: 700 !important;
}
.small-text {
  font-size: 12px !important;
}
</style>
