import Vue from 'vue';
import VueRouter from 'vue-router';
import OrganisationHomePage from '../pages/OrganisationHomePage';
import SurveyPage from '../pages/SurveyPage';
import ErrorPage from '../pages/ErrorPage';
import OrganisationsPage from '../pages/OrganisationsPage';
import OrganisationSettingsPage from '../pages/OrganisationSettingsPage';
import PublicDashboardPage from '../pages/PublicDashboardPage';
import PublicDashboardAccessCodePage from '../pages/PublicDashboardAccessCodePage';
import PublicDashboardShortLinkPage from '../pages/PublicDashboardShortLinkPage';
import PublicDisplayPage from '../pages/PublicDisplayPage';
import PublicDisplayAccessCodePage from '../pages/PublicDisplayAccessCodePage';
import PublicDisplayShortLinkPage from '../pages/PublicDisplayShortLinkPage';

import { getInstance } from 'auth0-js';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'organisationHome',
    component: OrganisationHomePage
  },
  {
    path: '/surveys/:id',
    name: 'survey',
    component: SurveyPage
  },
  {
    path: '/organisations',
    name: 'organisations',
    component: OrganisationsPage
  },
  {
    path: '/organisation/:id',
    name: 'organisation',
    component: OrganisationSettingsPage
  },
  {
    path: '/error/email-unverified',
    name: 'error',
    component: ErrorPage,
    props: {
      title: 'Email verification required',
      button: 'Try Again',
      message:
        'Before you can start using muse, you need to verify your email address. ' +
        'Check your inbox for the verification email!'
    }
  },
  {
    path: '/error/no-organisations',
    name: 'error',
    component: ErrorPage,
    props: {
      title: 'You are not a member of an organisation',
      button: 'Try Again',
      message:
        'Before you can start using muse, you need to be added to an organisation. ' +
        'Check with your muse administrator to resolve this issue.'
    }
  },

  {
    path: '/tv/:shortId/:accessCode',
    name: 'public-display-short-link',
    component: PublicDisplayShortLinkPage,
    meta: {
      public: true,
      layout: 'FullScreenLayout'
    }
  },
  {
    path: '/surveys/:id/tv/access-code',
    name: 'public-display-access-code',
    component: PublicDisplayAccessCodePage,
    meta: {
      public: true,
      layout: 'FullScreenLayout'
    }
  },
  {
    path: '/surveys/:id/tv',
    name: 'public-display',
    component: PublicDisplayPage,
    meta: {
      public: true,
      layout: 'FullScreenLayout'
    },
    props: route => ({ accessCode: route.query.accessCode })
  },
  {
    path: '/dashboard/:shortId/:accessCode',
    name: 'public-dashboard-short-link',
    component: PublicDashboardShortLinkPage,
    meta: {
      public: true
    }
  },
  {
    path: '/surveys/:id/dashboard/access-code',
    name: 'public-dashboard-access-code',
    component: PublicDashboardAccessCodePage,
    meta: {
      public: true
    }
  },
  {
    path: '/surveys/:id/dashboard',
    name: 'public-dashboard',
    component: PublicDashboardPage,
    meta: {
      public: true
    },
    props: route => ({
      accessCode: route.query.accessCode
    })
  }
];

const router = new VueRouter({
  routes
});

router.beforeEach(async (to, from, next) => {
  if (to.matched.some(record => record.meta.public)) {
    return next();
  }

  const authService = getInstance();
  await authService.finishedLoading();
  if (authService.isAuthenticated) {
    if (!authService.user.email_verified) {
      // Send all unverified users to the unverified error page
      // but don't send users on their way to the error to the
      // error, or they'll get stuck in a loop.
      return to.path !== '/error/email-unverified'
        ? next('/error/email-unverified')
        : next();
    }

    if (to.path === '/error/email-unverified') {
      // Never send a verified user to the unverified error page
      return next('/');
    }

    return next();
  }

  authService.loginWithRedirect({
    appState: { targetUrl: to.fullPath }
  });
});

export default router;
