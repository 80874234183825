<template>
  <div>
    <v-card class="pa-5 px-12 mb-6">
      <v-row no-gutters class="d-flex justify-space-between">
        <div class="text-h5">Organisation Details</div>
        <v-btn
          x-small
          color="grey lighten-5"
          max-width="34px"
          min-height="34px"
          @click="requestEdit"
        >
          <v-icon color="grey darken-1">edit</v-icon>
        </v-btn>
      </v-row>
      <v-row no-gutters class="mb-4">
        <v-col cols="4">
          <p
            class="text-subtitle-1 grey--text text--darken-1 mb-1 mt-2"
          >
            Organisation Name
          </p>
          <p class="black--text pb-0 mb-0">
            {{ organisation.name }}
          </p>
        </v-col>
        <v-col cols="8">
          <p
            class="text-subtitle-1 grey--text text--darken-1 mb-1 mt-2"
          >
            Address
          </p>
          <p
            v-if="organisation.address"
            class="black--text pb-0 mb-0"
          >
            {{ addressOrPlaceholder }}
          </p>
          <p v-else class="grey--text text--lighten-1 pb-0 mb-0">
            (Address not set)
          </p>
        </v-col>
      </v-row>
      <v-row no-gutters class="mb-4">
        <v-col cols="4">
          <p
            class="text-subtitle-1 grey--text text--darken-1 mb-1 mt-2"
          >
            Primary Language
          </p>
          <p class="black--text pb-0 mb-0">
            <language-chip :language="primaryLanguage" />
          </p>
        </v-col>
        <v-col v-if="hasSecondaryLanguages" cols="8">
          <p
            class="text-subtitle-1 grey--text text--darken-1 mb-1 mt-2"
          >
            Secondary Languages
          </p>
          <div>
            <language-chip
              v-for="(secondaryLanguage, i) in secondaryLanguages"
              :key="i"
              :language="secondaryLanguage"
              class="mr-2"
            />
          </div>
        </v-col>
      </v-row>
      <v-row no-gutters class="flex-column mb-4">
        <p
          class="text-subtitle-1 grey--text text--darken-1 mb-1 mt-2"
        >
          iPad Username
        </p>
        <p class="black--text pb-0 mb-0">
          {{ organisation.ipadUsername }}
        </p>
      </v-row>
    </v-card>
    <organisation-dialog
      :organisation="organisation"
      :show.sync="organisationDialog"
    />
    <member-table :organisation="organisation" />
  </div>
</template>

<script>
import LanguageChip from '@/components/Common/LanguageChip';
import MemberTable from './MemberTable';
import OrganisationDialog from './OrganisationDialog';
import { getLanguage } from '@/utils/LanguageOptions';
import { mapGetters } from 'vuex';

export default {
  name: 'OrganisationSettings',
  components: {
    LanguageChip,
    MemberTable,
    OrganisationDialog
  },
  data: () => ({
    organisationDialog: false
  }),
  computed: {
    ...mapGetters('organisation', ['organisation']),
    primaryLanguage() {
      return getLanguage(this.organisation.primaryLanguage);
    },
    secondaryLanguages() {
      return this.organisation.secondaryLanguages
        ? this.organisation.secondaryLanguages.map(language =>
            getLanguage(language)
          )
        : [];
    },
    hasSecondaryLanguages() {
      return this.secondaryLanguages.length > 0;
    }
  },
  methods: {
    requestEdit() {
      this.organisationDialog = true;
    }
  }
};
</script>
