var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.dropzoneConfig
    ? _c(
        "div",
        [
          _c(
            "vue-dropzone",
            {
              attrs: {
                config: _vm.dropzoneConfig,
                "existing-file-url": _vm.imageSrc
              },
              on: {
                "added-file": _vm.handleAddedFile,
                sending: _vm.handleSending,
                success: _vm.handleSuccessfulUpload
              }
            },
            [
              _c(
                "v-card",
                _vm._b(
                  {
                    staticClass: "dashed clickable",
                    attrs: { height: _vm.imageHeight }
                  },
                  "v-card",
                  _vm.cardProps,
                  false
                ),
                [
                  _c("v-card-text", { staticClass: "pa-0 fill-height" }, [
                    _vm.hasNoImage
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "fill-height d-flex justify-space-around align-center"
                          },
                          [
                            _c(
                              "v-btn",
                              {
                                attrs: { color: "primary", dark: "", fab: "" }
                              },
                              [_c("v-icon", [_vm._v("add")])],
                              1
                            )
                          ],
                          1
                        )
                      : _c(
                          "div",
                          { staticClass: "image-preview" },
                          [
                            _vm.imageId && !_vm.disable
                              ? _c(
                                  "v-btn",
                                  {
                                    attrs: {
                                      color: "error",
                                      "x-small": "",
                                      dark: "",
                                      fab: "",
                                      absolute: "",
                                      right: ""
                                    },
                                    on: {
                                      click: function($event) {
                                        $event.stopPropagation()
                                        return _vm.deleteImage.apply(
                                          null,
                                          arguments
                                        )
                                      }
                                    }
                                  },
                                  [_c("v-icon", [_vm._v("close")])],
                                  1
                                )
                              : _vm._e(),
                            _c("v-img", {
                              ref: "vImage",
                              attrs: {
                                "aspect-ratio": "1.33",
                                src: _vm.imageSrc
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "placeholder",
                                    fn: function() {
                                      return [
                                        _c(
                                          "v-row",
                                          {
                                            staticClass:
                                              "grey lighten-2 fill-height ma-0",
                                            attrs: {
                                              align: "center",
                                              justify: "center"
                                            }
                                          },
                                          [
                                            _c("v-progress-circular", {
                                              attrs: {
                                                indeterminate: "",
                                                color: "grey lighten-5"
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ]
                                    },
                                    proxy: true
                                  }
                                ],
                                null,
                                false,
                                3115273984
                              )
                            })
                          ],
                          1
                        )
                  ])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "text-caption",
              class: [_vm.error ? "red--text" : "grey--text"]
            },
            [_vm._v(" Images must be 2048px x 1536px ")]
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }