<template>
  <v-card
    v-if="!editing"
    class="p-relative pa-4 mb-4"
    hover
    @click.stop="openForEditing()"
  >
    <v-tooltip v-if="question.hasUnsavedChanges" top>
      <template v-slot:activator="{ on }">
        <div class="unsaved-changes-badge p-absolute" v-on="on">
          <v-badge color="red" dot />
        </div>
      </template>
      <span>Question is missing required details</span>
    </v-tooltip>
    <div class="d-flex justify-space-between align-center">
      <div :class="[questionDataStyle.index, 'mr-5']">
        {{ index }}.
      </div>
      <div class="flex-grow-1">
        <div
          :class="[
            questionDataStyle.text,
            'text-h6 grey--text text--darken-3 mb-2'
          ]"
        >
          {{ questionText }}
        </div>
        <div
          :class="questionTypeStyles"
          @click.stop="handleTypeClicked"
        >
          <question-type-icon
            v-bind="questionTypeIconProps"
            :question-type="questionType.value"
            class="mr-2 question-type-icon"
          />
          <span :class="questionDataStyle.type">{{
            questionType.displayName
          }}</span>
        </div>
      </div>
      <div :class="[questionDataStyle.theme]">
        {{ questionTheme }}
      </div>
    </div>
  </v-card>

  <question-form
    v-else
    :question="question"
    class="mb-4"
    :index="index"
    :target-language="targetLanguage"
    @close-question-form="closeQuestionForm"
    @focus-duplicate="$emit('focus-duplicate', $event)"
  />
</template>

<script>
import QuestionForm from '../Edit/QuestionForm';
import QuestionTypeIcon from '@/components/Common/QuestionTypeIcon';
import QuestionTypeConfig from '@/utils/QuestionTypeConfig';
import QuestionThemeConfig from '@/utils/QuestionThemeConfig';

const QuestionPropertyDefault = Object.freeze({
  text: 'Question',
  type: 'Question Type',
  theme: 'Theme'
});

export default {
  name: 'Question',
  components: {
    QuestionForm,
    QuestionTypeIcon
  },
  props: {
    question: {
      type: Object,
      required: true
    },
    index: {
      type: Number,
      required: true
    },
    editing: {
      type: Boolean,
      required: true
    },
    targetLanguage: {
      type: String,
      required: true
    }
  },
  computed: {
    questionText() {
      return this.question.textValid(this.targetLanguage)
        ? this.question.languageText(this.targetLanguage)
        : QuestionPropertyDefault.text;
    },
    questionTheme() {
      return this.question.themeValid()
        ? QuestionThemeConfig[this.question.theme].displayName
        : QuestionPropertyDefault.theme;
    },
    questionType() {
      const type = this.question.config.questionType;
      const typeDisplayName = this.question.typeValid()
        ? QuestionTypeConfig[type].displayName
        : QuestionPropertyDefault.type;

      return {
        value: type,
        displayName: typeDisplayName
      };
    },
    questionTypeIconProps() {
      return !this.question.typeValid()
        ? { color: 'grey lighten-1' }
        : undefined;
    },
    questionTypeStyles() {
      return [
        this.question.typeValid()
          ? 'question-type-content has-pointer'
          : '',
        'd-inline-block'
      ];
    },
    questionDataStyle() {
      function getClass(truthy) {
        return truthy ? 'complete' : 'incomplete';
      }
      return {
        index: getClass(!this.question.hasUnsavedChanges),
        text: getClass(this.question.textValid(this.targetLanguage)),
        type: getClass(this.question.typeValid()),
        theme: getClass(this.question.themeValid())
      };
    }
  },
  methods: {
    handleTypeClicked() {
      this.question.typeValid()
        ? this.showQuestionPreview()
        : this.openForEditing();
    },
    openForEditing() {
      this.$emit('edit-question', this.question);
    },
    closeQuestionForm() {
      this.$emit('close-question-form', this.question);
    },
    showQuestionPreview() {
      this.$emit('show-question-preview', this.questionType.value);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../scss/main.scss';

.unsaved-changes-badge {
  // Move the indicator to 30px off the right hand edge of the relative parent
  // and 1px off the top edge
  right: 30px;
  top: 1px;
}

.has-pointer {
  cursor: pointer;
}

.question-type-content {
  > span {
    line-height: 1.5;
  }

  &:hover {
    .question-type-icon,
    span {
      color: var(--v-primary-base) !important;
    }
  }
}

.complete {
  color: map-get($grey, 'darken-1');
  &.title {
    color: map-get($grey, 'darken-4');
  }
}

.incomplete {
  color: map-get($grey, 'lighten-1');
}
</style>
