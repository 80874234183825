var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "question-form-action-dialog",
    _vm._b(
      {
        on: {
          close: function($event) {
            return _vm.$emit("close")
          },
          confirm: function($event) {
            return _vm.$emit("confirm")
          }
        },
        scopedSlots: _vm._u([
          {
            key: "title",
            fn: function() {
              return [_vm._v(" Clear Responses ")]
            },
            proxy: true
          },
          {
            key: "content",
            fn: function() {
              return [
                _c("p", [
                  _vm._v(" This survey has "),
                  _c("span", { staticClass: "font-weight-bold black--text" }, [
                    _vm._v(" " + _vm._s(_vm.responseCountText))
                  ]),
                  _vm._v(
                    ". If you choose to clear the responses, they will no longer be accessible and the data will be permanently deleted. "
                  )
                ])
              ]
            },
            proxy: true
          },
          {
            key: "confirm-btn-text",
            fn: function() {
              return [_vm._v(" clear responses ")]
            },
            proxy: true
          }
        ])
      },
      "question-form-action-dialog",
      _vm.$props,
      false
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }