<template>
  <div class="rating-1-to-5-question-config">
    <v-checkbox
      v-model="configModel.overrideLabels"
      label="Override Labels"
      :disabled="questionHasResponses"
      @change="configUpdated()"
    />
    <v-row>
      <v-col cols="2" class="pt-0">
        <v-text-field
          v-model="configModel.left.languageMap[primaryLanguage]"
          class="text-body-2"
          label="Left Label"
          :disabled="!value.overrideLabels"
          :filled="!value.overrideLabels"
          @change="configUpdated()"
        />
      </v-col>
      <v-col cols="2" class="pt-0">
        <v-text-field
          v-model="configModel.right.languageMap[primaryLanguage]"
          class="text-body-2"
          label="Right Label"
          :disabled="!value.overrideLabels"
          :filled="!value.overrideLabels"
          @change="configUpdated()"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  name: 'Rating1To5QuestionConfig',
  props: {
    value: {
      type: Object,
      required: true
    },
    questionHasResponses: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return { configModel: {} };
  },
  computed: {
    ...mapGetters('survey', ['primaryLanguage'])
  },
  watch: {
    value() {
      Object.assign(this.configModel, this.value);
    }
  },
  created() {
    Object.assign(this.configModel, this.value);
  },
  methods: {
    configUpdated() {
      this.$emit('input', this.configModel);
      this.$emit('change', this.configModel);
    }
  }
};
</script>
