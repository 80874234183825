<template>
  <v-row>
    <v-col cols="12" md="6" class="justify-center">
      <v-row
        v-for="(genderOption, genderIndex) in genderOptions"
        :key="genderIndex"
        class="people-container"
      >
        <v-col
          v-for="(ageGroup, ageIndex) in ageGroupOptions"
          :key="ageIndex"
          cols="12"
          md="4"
        >
          <v-card flat class="text-center" height="100%">
            <v-card-text
              :class="componentMargin[ageGroup]"
              class="pt-0"
            >
              <component
                :is="componentMap[genderOption]"
                :class="componentClass(genderOption, ageGroup)"
              />
              <div
                class="text-no-wrap text-subtitle-1 black--text font-weight-bold"
              >
                {{ getAverage(genderOption, ageGroup) }}
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12" md="6" class="d-flex justify-center">
      <v-row>
        <v-col cols="12" md="12">
          <v-container class="fill-height">
            <v-card flat class="text-center align-center">
              <v-card-text class="black--text pt-0 mt-4">
                <div class="text-center title font-weight-bold">
                  {{ average.groupSize }}
                </div>
                <v-row>
                  was the average group size
                </v-row>
              </v-card-text>
            </v-card>
          </v-container>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import GenderMale from '!!vue-svg-loader!../../../assets/svgs/GenderMale.svg';
import GenderFemale from '!!vue-svg-loader!../../../assets/svgs/GenderFemale.svg';

export default {
  components: {
    GenderMale,
    GenderFemale
  },
  props: {
    questionStat: {
      required: true,
      type: Object
    }
  },
  computed: {
    componentMap() {
      return {
        male: 'gender-male',
        female: 'gender-female'
      };
    },
    componentMargin() {
      return {
        adult: 'mt-0',
        teen: 'mt-2',
        child: 'mt-4'
      };
    },
    genderOptions() {
      return Object.keys(this.componentMap);
    },
    ageGroupOptions() {
      return ['adult', 'teen', 'child'];
    },
    average() {
      const {
        totalResponses,
        numberOfMaleAdults,
        numberOfMaleTeens,
        numberOfMaleChildren,
        numberOfFemaleAdults,
        numberOfFemaleTeens,
        numberOfFemaleChildren
      } = this.questionStat;

      const totalNumber = [
        numberOfMaleAdults,
        numberOfMaleTeens,
        numberOfMaleChildren,
        numberOfFemaleAdults,
        numberOfFemaleTeens,
        numberOfFemaleChildren
      ].reduce((total, element) => (total += element), 0);

      return {
        groupSize: this.averageAndRoundOff(
          totalNumber,
          totalResponses
        ),
        male: {
          adult: this.averageAndRoundOff(
            numberOfMaleAdults,
            totalResponses
          ),
          teen: this.averageAndRoundOff(
            numberOfMaleTeens,
            totalResponses
          ),
          child: this.averageAndRoundOff(
            numberOfMaleChildren,
            totalResponses
          )
        },
        female: {
          adult: this.averageAndRoundOff(
            numberOfFemaleAdults,
            totalResponses
          ),
          teen: this.averageAndRoundOff(
            numberOfFemaleTeens,
            totalResponses
          ),
          child: this.averageAndRoundOff(
            numberOfFemaleChildren,
            totalResponses
          )
        }
      };
    }
  },
  methods: {
    averageAndRoundOff(value, responses, decimalPlaces = 1) {
      return value === 0
        ? 0
        : (value / responses).toFixed(decimalPlaces);
    },
    getAverage(genderOption, ageGroup) {
      return this.average[genderOption][ageGroup];
    },
    componentClass(genderOption, ageGroup) {
      return `${genderOption} ${ageGroup}`;
    }
  }
};
</script>

<style scoped lang="scss">
@import '../../../scss/main.scss';
svg {
  &.adult {
    height: 60px;
  }

  &.teen {
    height: 52px;
  }

  &.child {
    height: 44px;
  }

  &.male path {
    fill: map-get($grey, 'darken-1');
  }

  &.female path {
    fill: map-get($grey, 'base');
  }
}

.people-container {
  max-height: 110px;
}
</style>
