<template>
  <svg :viewBox="`0 0 ${size} ${size}`">
    <path
      v-for="(slice, index) of slices"
      :key="`slice_${index}`"
      :d="slicePath(index, slice.value)"
      :fill="slice.colour"
      :fill-opacity="fillOpacity"
      :stroke="slice.colour"
      :stroke-width="strokeWidth"
    />
    <path
      v-for="(slice, index) of slices"
      :id="`curve_${index}`"
      :key="`curve_${index}`"
      :d="textPath(index, slice.value)"
      fill="transparent"
    />
    <text
      v-for="slice of labelledSlices"
      :key="`text_${slice.index}`"
      class="label"
      text-anchor="middle"
    >
      <textPath
        startOffset="25%"
        :xlink:href="`#curve_${slice.index}`"
      >
        {{ slice.label }}
      </textPath>
    </text>
  </svg>
</template>

<script>
export default {
  name: 'EmotionWheel',
  props: {
    cutout: {
      type: Boolean,
      default: false
    },
    filled: {
      type: Boolean,
      default: false
    },
    offsetRadius: {
      type: Number,
      default: 8
    },
    showLabels: {
      type: Boolean,
      default: false
    },
    size: {
      type: Number,
      default: 400
    },
    slices: {
      type: Array,
      required: true
    },
    strokeWidth: {
      type: Number,
      default: 3
    }
  },
  data: () => ({
    textOffset: 4,
    fontSize: 16
  }),
  computed: {
    origin() {
      return {
        x: this.size * 0.5,
        y: this.size * 0.5
      };
    },
    innerMagnitude() {
      return this.outerMagnitude * 0.9;
    },
    outerMagnitude() {
      return (4 / 3) * Math.tan(Math.PI / (2 * this.slices.length));
    },
    radius() {
      return {
        min: this.cutout ? this.size * 0.1 : 0,
        max: this.size * 0.5 - this.textSize
      };
    },
    sliceAngle() {
      return (Math.PI * 2) / this.slices.length;
    },
    sliceOffsetAngle() {
      return this.sliceAngle * 0.5;
    },
    textSize() {
      return this.textOffset + this.fontSize;
    },
    labelledSlices() {
      return this.showLabels
        ? this.slices.filter(slice => this.renderSlice(slice.value))
        : [];
    },
    fillOpacity() {
      return this.filled ? 0.5 : 0;
    }
  },
  methods: {
    renderSlice(value) {
      return value >= 0.2;
    },
    slicePath(index, value) {
      // map the 0..1 value to the range min..max
      const radius = this.convertToNewRange(
        value,
        0,
        1,
        this.radius.min,
        this.radius.max
      );

      // adjust radius for border width
      const innerRadius = this.radius.min + this.strokeWidth;
      const outerRadius = Math.max(
        radius - this.strokeWidth,
        innerRadius
      );

      // inner left position
      const posA = this.calcSlicePoint(index, innerRadius);

      // outer left position
      const posB = this.calcSlicePoint(index, outerRadius);

      // outer right position
      const posC = this.calcSlicePoint(index + 1, outerRadius);

      // inner right position
      const posD = this.calcSlicePoint(index + 1, innerRadius);

      // inner left control point
      const conA = this.cutout
        ? this.calcLeftControlPoint(posA, this.innerMagnitude)
        : this.calcRightControlPoint(posA, this.innerMagnitude);

      // outer left control point
      const conB = this.calcLeftControlPoint(
        posB,
        this.outerMagnitude
      );

      // outer right control point
      const conC = this.calcRightControlPoint(
        posC,
        this.outerMagnitude
      );

      // inner right control point
      const conD = this.cutout
        ? this.calcRightControlPoint(posD, this.innerMagnitude)
        : this.calcLeftControlPoint(posD, this.innerMagnitude);

      // calculate offset
      const offset = this.calcOffset(index);

      // MOVE to A
      // draw a LINE to B
      // draw a CURVE to C using control points b and c
      // draw a LINE to D
      // draw a CURVE to A using control points d and a
      // end
      return `
        ${this.svgMoveTo(posA, offset)}
        ${this.svgLineTo(posB, offset)}
        ${this.svgCurveTo(posC, conB, conC, offset)}
        ${this.svgLineTo(posD, offset)}
        ${this.svgCurveTo(posA, conD, conA, offset)}
        ${this.svgEnd()}`;
    },
    textPath(index, value) {
      // map the 0..1 value to the range min..max
      const radius = this.convertToNewRange(
        value,
        0,
        1,
        this.radius.min,
        this.radius.max
      );

      // adjust radius for text offset
      const textRadius = radius + this.textOffset;

      // left position
      const posA = this.calcSlicePoint(index, textRadius);

      // right position
      const posB = this.calcSlicePoint(index + 1, textRadius);

      // set control point vector magnitude
      const magnitude = this.outerMagnitude * 1.1;

      // left control point
      const conA = this.calcLeftControlPoint(posA, magnitude);

      // right control point
      const conB = this.calcRightControlPoint(posB, magnitude);

      // calculate offset
      const offset = this.calcOffset(index);

      // MOVE to A
      // draw a CURVE to B using control points a and b
      // end
      return `
        ${this.svgMoveTo(posA, offset)}
        ${this.svgCurveTo(posB, conA, conB, offset)}
        ${this.svgEnd()}`;
    },
    averagePoint(pointA, pointB) {
      return {
        x: (pointA.x + pointB.x) * 0.5,
        y: (pointA.y + pointB.y) * 0.5
      };
    },
    calcSlicePoint(index, radius) {
      // calculate the position on a circle at this angle with the given radius
      return {
        x: Math.sin(index * this.sliceAngle) * radius,
        y: -Math.cos(index * this.sliceAngle) * radius
      };
    },
    calcLeftControlPoint(point, magnitude) {
      // determine the left perpendicular with a length multiplied by magnitude
      return {
        x: point.x - point.y * magnitude,
        y: point.y + point.x * magnitude
      };
    },
    calcRightControlPoint(point, magnitude) {
      // determine the right perpendicular with a length multiplied by magnitude
      return {
        x: point.x + point.y * magnitude,
        y: point.y - point.x * magnitude
      };
    },
    calcOffset(index) {
      // calculate the offset to shift this slice away from the origin, to create
      // the "spread out" appearance for the slices
      const offsetAngle =
        index * this.sliceAngle + this.sliceOffsetAngle;
      return {
        x: Math.sin(offsetAngle) * this.offsetRadius,
        y: -Math.cos(offsetAngle) * this.offsetRadius
      };
    },
    convertToNewRange(value, oldMin, oldMax, newMin, newMax) {
      // convert the range [oldMin..oldMax] to [newMin..newMax]
      // eg.
      // value = 0.5, oldMin = 0, oldMax = 1, newMin = 50, newMax = 200
      //  ((0.5 - 0) * (200 - 50)) / (1 - 0) + 50
      //   = (0.5 * 150) / 1 + 50
      //   = 75 + 50
      //   = 125
      return (
        ((value - oldMin) * (newMax - newMin)) / (oldMax - oldMin) +
        newMin
      );
    },
    svgMoveTo(position, offset) {
      return `M ${this.origin.x + position.x + offset.x}
                ${this.origin.y + position.y + offset.y}`;
    },
    svgLineTo(position, offset) {
      return `L ${this.origin.x + position.x + offset.x}
                ${this.origin.y + position.y + offset.y}`;
    },
    svgCurveTo(position, controlA, controlB, offset) {
      return `C ${this.origin.x + controlA.x + offset.x}
                ${this.origin.y + controlA.y + offset.y}
                ${this.origin.x + controlB.x + offset.x}
                ${this.origin.y + controlB.y + offset.y}
                ${this.origin.x + position.x + offset.x}
                ${this.origin.y + position.y + offset.y}`;
    },
    svgEnd() {
      return 'Z';
    }
  }
};
</script>

<style scoped lang="scss">
@import '../../scss/main.scss';
svg .label {
  fill: map-get($grey, 'lighten-1');
}
</style>
