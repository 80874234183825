var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("grid-dimensions", {
        attrs: { "public-display-config": _vm.publicDisplayConfig },
        on: {
          "options-changed": function($event) {
            return _vm.$emit("options-changed")
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }