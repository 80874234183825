var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("p", { staticClass: "grey--text text--darken-1 mb-2 mt-4" }, [
        _vm._v("Grid Dimensions")
      ]),
      _c(
        "v-row",
        _vm._l(_vm.gridDimensionOptions, function(gridOption) {
          return _c(
            "v-card",
            {
              key: gridOption.key,
              class: _vm.cardClass(_vm.gridDimensions == gridOption.key),
              on: {
                click: function($event) {
                  return _vm.onClick(gridOption)
                }
              }
            },
            [
              _c("v-img", {
                attrs: {
                  value: gridOption.key,
                  src: gridOption.imageSrc,
                  alt: gridOption.altText
                }
              }),
              _c("v-row", { staticClass: "justify-center mt-4" }, [
                _vm._v(" " + _vm._s(gridOption.description) + " ")
              ])
            ],
            1
          )
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }