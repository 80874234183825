<template>
  <div>
    <p class="grey--text text--darken-1 mb-2 mt-4">Grid Dimensions</p>
    <v-row>
      <v-card
        v-for="gridOption in gridDimensionOptions"
        :key="gridOption.key"
        :class="cardClass(gridDimensions == gridOption.key)"
        @click="onClick(gridOption)"
      >
        <v-img
          :value="gridOption.key"
          :src="gridOption.imageSrc"
          :alt="gridOption.altText"
        />
        <v-row class="justify-center mt-4">
          {{ gridOption.description }}
        </v-row>
      </v-card>
    </v-row>
  </div>
</template>

<script>
import QuestionTypeConfig from '@/utils/QuestionTypeConfig';

export default {
  name: 'GridDimensions',
  components: {},
  props: {
    publicDisplayConfig: {
      type: Object,
      required: true
    }
  },
  computed: {
    questionType() {
      return this.publicDisplayConfig.question.config.questionType;
    },
    gridDimensionOptions() {
      const path = 'img/slide-buttons';
      return [
        {
          key: 'ONE_BY_ONE_FULLSCREEN',
          description: '1x1 – Fullscreen',
          imageSrc: `${path}/1x1_fullscreen.png`
        },
        {
          key: 'ONE_BY_ONE',
          description: '1x1 – Include Title',
          imageSrc: `${path}/1x1.png`
        },
        {
          key: 'THREE_BY_TWO',
          description: '3x2 – Include Title',
          imageSrc: `${path}/3x2.png`
        },
        {
          key: 'FOUR_BY_THREE',
          description: '4x3 – Include Title',
          imageSrc: `${path}/4x3.png`
        }
      ];
    },
    gridDimensions: {
      get() {
        return (
          this.publicDisplayConfig.options?.gridDimensions ??
          this.gridDimensionOptions[0].key
        );
      },
      set(value) {
        this.publicDisplayConfig.options.gridDimensions = value;
        this.$emit('options-changed');
      }
    }
  },
  mounted() {
    if (!this.publicDisplayConfig.options) {
      this.publicDisplayConfig.options = QuestionTypeConfig[
        this.questionType
      ].defaultPublicDisplayOptions();
    }
  },
  methods: {
    onClick(gridOption) {
      this.gridDimensions = gridOption.key;
    },
    cardClass(active) {
      const activeClasses = 'white--text primary';
      const inactiveClasses =
        'grey lighten-2 grey--text text--darken-2';
      return `card-width ml-4 pa-4 mb-4 d-flex flex-column theme--light ${
        active ? activeClasses : inactiveClasses
      }`;
    }
  }
};
</script>
<style scoped>
.card-width {
  width: 12em;
}
</style>
