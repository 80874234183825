var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "d-flex flex-column" },
    _vm._l(_vm.questionStat.values, function(value, index) {
      return _c(
        "div",
        { key: index, class: `py-${_vm.paddingY}` },
        [
          _c(
            "v-row",
            [
              _c("localised-text", {
                staticClass: "pl-12 text flex-item",
                style: { fontSize: `${_vm.sliderTitleSize}px` },
                attrs: { language: _vm.displayLanguage, string: value.label }
              })
            ],
            1
          ),
          _c("slider-svg", {
            staticClass: "mx-12",
            class: `mt-${_vm.marginTop}`,
            attrs: {
              theme: _vm.theme,
              width: _vm.responsiveWidth,
              height: _vm.sliderHeight,
              "tick-height": _vm.sliderTickHeight,
              "slider-value": value.average,
              "middle-tick": !!value.middleWord,
              "total-sliders": _vm.questionStat.values.length
            }
          }),
          _c(
            "div",
            { staticClass: "d-flex justify-space-between" },
            _vm._l(_vm.sliderPoints(index), function(sliderPoint, _index) {
              return _c(
                "div",
                {
                  key: _index,
                  staticClass: "text-body-1 text-center font-weight-bold label",
                  class: `mt-${_vm.marginTop / 1.5}`
                },
                [
                  _c("localised-text", {
                    attrs: {
                      language: _vm.displayLanguage,
                      string: sliderPoint.text
                    }
                  })
                ],
                1
              )
            }),
            0
          )
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }