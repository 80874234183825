<template functional>
  <div class="primary">
    <v-container class="py-8 px-12">
      <slot></slot>
    </v-container>
  </div>
</template>

<script>
export default {
  name: 'PageHeaderBar'
};
</script>
