<template>
  <v-row justify="center" align="end" class="fill-height">
    <div class="pb-6">
      {{ leftLabel }}
    </div>
    <div class="mx-2">
      <vertical-bar-chart :data="chartData" :config="chartConfig" />
    </div>
    <div
      class="d-flex flex-column justify-space-between fill-height py-6"
    >
      <div class="text-center">
        <div class="text-body-1 grey--text text--darken-2">
          Average
        </div>
        <div
          class="text-h6 black--text text-no-wrap font-weight-bold"
        >
          {{ questionAverage }}
        </div>
      </div>
      {{ rightLabel }}
    </div>
  </v-row>
</template>

<script>
import VerticalBarChart from '@/components/Visualisations/VerticalBarChart';

export default {
  name: 'Rating1To5Visualisation',
  components: { VerticalBarChart },
  props: {
    questionStat: {
      required: true,
      type: Object
    },
    displayLanguage: {
      type: String,
      required: true
    }
  },
  computed: {
    chartData() {
      return Object.values(this.questionStat.ratings);
    },
    chartConfig() {
      return {
        labels: Object.keys(this.questionStat.ratings)
      };
    },
    questionAverage() {
      return Math.round(this.questionStat.average);
    },
    leftLabel() {
      return this.questionStat.leftLabel.languageMap[
        this.displayLanguage
      ];
    },
    rightLabel() {
      return this.questionStat.rightLabel.languageMap[
        this.displayLanguage
      ];
    }
  }
};
</script>
