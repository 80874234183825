import ApiService from '@/api';
import axios from 'axios';

const publicDisplayAxios = axios.create({
  baseURL: `${process.env.VUE_APP_API_URL}/publicDisplay/`
});

export default {
  namespaced: true,
  state: {
    publicDisplayConfig: undefined,
    configReady: false
  },
  mutations: {
    setPublicDisplayConfig: (state, config) => {
      state.publicDisplayConfig = config;
    },
    setConfigLoading: state => {
      state.configReady = false;
    },
    setConfigReady: state => {
      state.configReady = true;
    }
  },
  actions: {
    fetchPublicDisplayConfig(context, surveyId) {
      context.commit('setConfigLoading');
      ApiService.getApi()
        .get(`/publicDisplayConfig/${surveyId}`)
        .then(response => {
          context.commit('setPublicDisplayConfig', response.data);
          context.commit('setConfigReady');
        });
    },
    updateQuestionPublicDisplayConfig(context, config) {
      return ApiService.getApi()
        .put('/publicDisplayConfig/question', config.question.id, {
          customTitle: config.customTitle,
          options: config.options
        })
        .then(response => {
          return response.status == 200;
        });
    },
    fetchPublicSurveyInfo: {
      handler(context, identifier) {
        return publicDisplayAxios
          .get(`info`, { params: identifier })
          .then(response => {
            return response.data;
          });
      }
    },
    fetchDisplayStats: {
      handler(context, { surveyId, accessCode }) {
        return publicDisplayAxios
          .get(surveyId, {
            headers: { accessCode }
          })
          .then(response => {
            return response.data;
          });
      }
    },
    validateCode: {
      handler(context, { surveyId, accessCode }) {
        return publicDisplayAxios
          .get(surveyId, {
            headers: { accessCode }
          })
          .then(response => {
            return response.status == 200;
          });
      }
    }
  },
  getters: {}
};
