var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { staticClass: "loader pa-0", attrs: { outlined: "" } },
    [
      _c(
        "v-container",
        { staticClass: "pa-0" },
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { staticClass: "pt-0" },
                [
                  _c("v-skeleton-loader", {
                    staticClass: "header",
                    attrs: { type: "card-avatar" }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-row",
            { staticClass: "body px-4 py-0 d-flex align-items-center" },
            [
              _c(
                "v-col",
                { staticClass: "d-flex", attrs: { cols: "6" } },
                [
                  _c("v-skeleton-loader", {
                    staticClass: "question-count align-self-center",
                    attrs: { type: "heading" }
                  })
                ],
                1
              ),
              _c(
                "v-col",
                { staticClass: "d-flex justify-end", attrs: { cols: "6" } },
                [
                  _c("v-skeleton-loader", {
                    staticClass: "language-chip",
                    attrs: { type: "chip" }
                  }),
                  _c("v-skeleton-loader", {
                    staticClass: "language-chip ml-2",
                    attrs: { type: "chip" }
                  }),
                  _c("v-skeleton-loader", {
                    staticClass: "language-chip ml-2",
                    attrs: { type: "chip" }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-row",
            { staticClass: "pt-1" },
            [
              _c(
                "v-col",
                { attrs: { cols: "3" } },
                [
                  _c("v-skeleton-loader", {
                    staticClass: "survey-issue-status-chip ml-4",
                    attrs: { type: "chip" }
                  })
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "9" } },
                [
                  _c("v-skeleton-loader", {
                    staticClass: "survey-issues pr-4",
                    attrs: { type: "text@2" }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                [
                  _c("v-skeleton-loader", {
                    staticClass: "survey-status-chip ml-4 pb-2 pt-1",
                    attrs: { type: "chip" }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }