<template>
  <div>
    <page-header-bar>
      <div v-if="!dataLoading" class="d-flex p-relative white--text">
        <div class="d-flex flex-column">
          <div class="fab-container p-absolute">
            <v-btn
              class="floating-button"
              color="grey lighten-2 black--text"
              dark
              small
              fab
              @click="closeSurveyTranslator()"
            >
              <v-icon>
                {{ translation ? 'close' : 'arrow_back' }}
              </v-icon>
            </v-btn>
          </div>
          <div class="d-flex mr-8">
            <div>
              <h5 class="text-h5 mb-2">
                {{ surveyTitle }}
              </h5>
            </div>
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <div class="d-flex" v-on="on">
                  <v-btn
                    color="grey lighten-5 grey--text text--darken-1"
                    class="ml-4 mb-2 align-self-center"
                    min-width="24px"
                    max-width="24px"
                    height="24px"
                    x-small
                    @click="editSurvey = true"
                  >
                    <v-icon small>create</v-icon>
                  </v-btn>
                </div>
              </template>
              <span>Edit Survey</span>
            </v-tooltip>
          </div>
          <div class="text-subtitle-1">
            {{ getSurveyActivePeriodString }}
          </div>
        </div>
        <div class="d-flex-grow-1 flex-column ml-8">
          <div class="d-flex align-center">
            <v-icon class="white--text mr-2">
              format_list_numbered
            </v-icon>
            <div class="mr-2 font-weight-regular text-h6">
              {{ survey.userQuestionCount }}
              {{
                survey.userQuestionCount === 1
                  ? 'Question'
                  : 'Questions'
              }}
            </div>
          </div>
          <div class="d-flex mt-1">
            <v-icon class="white--text mr-2">
              record_voice_over
            </v-icon>
            <h6 class="text-subtitle-1">
              {{ responseCount }}
            </h6>
            <v-tooltip right>
              <template v-slot:activator="{ on }">
                <div class="d-flex align-center" v-on="on">
                  <v-btn
                    color="grey lighten-5 grey--text text--darken-1"
                    class="ml-4"
                    :disabled="!hasResponses"
                    min-width="24px"
                    max-width="24px"
                    height="24px"
                    x-small
                    @click="showClearResponsesDialog = true"
                  >
                    <v-icon small>speaker_notes_off</v-icon>
                  </v-btn>
                </div>
              </template>
              <span>Clear Survey Responses</span>
            </v-tooltip>
          </div>
        </div>
      </div>
    </page-header-bar>
    <div v-if="dataLoading">
      <v-container class="pa-2">
        <question-list-loader />
      </v-container>
    </div>
    <v-container v-else class="pa-2">
      <v-tabs v-model="activeTab" class="pa-8" grow>
        <div
          v-for="(tab, i) in tabs"
          :key="tab.href"
          class="d-flex flex-grow-1"
        >
          <v-tab v-if="!tab.isDisabled" :href="tab.href">
            <v-icon class="mr-2">{{ tab.icon }}</v-icon
            >{{ tab.title }}
          </v-tab>

          <v-tooltip v-else :key="i" bottom>
            <template v-slot:activator="{ on }">
              <div
                class="d-flex flex-grow-1"
                v-on="hasResponses ? {} : on"
              >
                <v-tab :href="tab.href" :disabled="tab.isDisabled">
                  <v-icon class="mr-2">{{ tab.icon }}</v-icon>
                  {{ tab.title }}
                </v-tab>
              </div>
            </template>
            <span>
              {{ tab.tabToolTipText }}
            </span>
          </v-tooltip>
        </div>
        <v-tab-item value="questions">
          <survey-summary-card
            class="mt-4"
            :survey="survey"
            :selected-language="selectedLanguage"
            @language-clicked="setDisplayLanguage"
            @show-survey-preview="showPreviewDialog = true"
            @edit-survey-disclaimer="showDisclaimerDialog = true"
          />
          <questions-view
            v-if="!translation"
            :survey="survey"
            :questions="questions"
            :question-table-key="questionTableKey"
            :data-loading="dataLoading"
          />
          <survey-translator
            v-else
            :survey="survey"
            :translation-language="translation"
            @update-translations="updateTranslations"
            @close="closeTranslate"
          />
        </v-tab-item>
        <v-tab-item value="dashboard">
          <div class="d-flex">
            <div class="flex-row mt-6">
              <p class="grey--text text--darken-1 mb-0">
                Public Access URL
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-icon
                      class="mb-1"
                      color="primary"
                      size="18"
                      v-on="on"
                    >
                      help
                    </v-icon>
                  </template>
                  <span>
                    Share this link to offer access to the dashboard
                    without needing to log into muse.
                  </span>
                </v-tooltip>
              </p>
              <span>
                <a
                  :href="publicDashboardAccessUrl"
                  target="_blank"
                  class="mr-2"
                >
                  {{ publicDashboardAccessUrl }}
                </a>
                <v-btn
                  x-small
                  class="x-small-square"
                  @click="copyPublicDashboardUrl"
                >
                  <v-icon x-small icon tile>
                    content_copy
                  </v-icon>
                </v-btn>
              </span>
            </div>
          </div>

          <dashboard-view
            :display-language="displayLanguage"
            @fetch-stats-with-date="fetchStats"
          />
        </v-tab-item>
        <v-tab-item value="public-display">
          <public-display-config-view class="mt-4" :survey="survey" />
        </v-tab-item>
        <v-tab-item value="export">
          <survey-data-export-card
            class="mt-4"
            :start-date="survey.surveyStart"
            :end-date="survey.surveyEnd"
            :survey="survey"
          />
        </v-tab-item>
      </v-tabs>
      <survey-preview-dialog
        :show.sync="showPreviewDialog"
        :selected-survey="survey"
        :questions-list="previewQuestions"
      />
      <survey-dialog
        :show.sync="editSurvey"
        :survey="survey"
        :organisations="organisations"
        @save="handleSurveySaved"
      />
      <survey-disclaimer-dialog
        v-model="showDisclaimerDialog"
        :survey="survey"
      />
      <survey-action-dialog
        :show.sync="showActionDialog"
        :survey="survey"
        :action="surveyAction"
        @dialog-action-confirmed="handleDialogActionConfirmed"
      />
      <clear-survey-responses-confirmation-dialog
        :show.sync="showClearResponsesDialog"
        :survey="survey"
        @clear-responses="clearSurveyResponses()"
        @cancel="showClearResponsesDialog = false"
      />
    </v-container>
  </div>
</template>

<script>
import PublicDisplayConfigView from '@/components/PublicDisplay/PublicDisplayConfigView';
import PageHeaderBar from '@/components/Common/PageHeaderBar';
import QuestionsView from '@/components/Survey/View/QuestionsView';
import DashboardView from '@/components/Dashboard/DashboardView';
import SurveyPreviewDialog from '@/components/Survey/View/SurveyPreviewDialog';
import SurveyDialog from '@/components/Survey/SurveyDialog';
import SurveyTranslator from '@/components/Survey/Edit/SurveyTranslator';
import SurveyDataExportCard from '@/components/Survey/View/SurveyDataExportCard';
import SurveyDisclaimerDialog from '@/components/Survey/Edit/SurveyDisclaimerDialog';
import ClearSurveyResponsesConfirmationDialog from '@/components/Survey/Edit/ClearSurveyResponsesConfirmationDialog';
import SurveyActionDialog from '@/components/Survey/SurveyActionDialog';
import { surveyHelper } from '@/mixins/SurveyHelper';
import { mapActions, mapState } from 'vuex';
import { SurveyStatus } from '@/enums/index';
import QuestionListLoader from '@/components/Survey/View/Loader/QuestionListLoader';
import SurveySummaryCard from '@/components/Survey/View/SurveySummaryCard';
import moment from 'moment-timezone';

export default {
  name: 'SurveyPage',
  components: {
    PageHeaderBar,
    SurveyPreviewDialog,
    QuestionsView,
    PublicDisplayConfigView,
    DashboardView,
    SurveyDialog,
    SurveyTranslator,
    SurveyDataExportCard,
    QuestionListLoader,
    SurveyDisclaimerDialog,
    SurveyActionDialog,
    ClearSurveyResponsesConfirmationDialog,
    SurveySummaryCard
  },
  mixins: [surveyHelper],
  data: () => ({
    activeTab: 'questions',
    questionTableKey: 0,
    numLoadingCards: 4,
    dataLoading: true,
    translation: undefined,
    editSurvey: false,
    showDisclaimerDialog: false,
    showPreviewDialog: false,
    showActionDialog: false,
    showClearResponsesDialog: false,
    surveyAction: '',
    displayLanguage: ''
  }),
  computed: {
    ...mapState('survey', ['survey']),
    ...mapState('stats', ['stats']),
    ...mapState('organisation', ['organisations']),
    tabs() {
      const QUESTIONS_TAB = {
        title: 'Questions',
        icon: 'format_list_numbered',
        href: '#questions',
        isDisabled: false
      };
      const DASHBOARD_TAB = {
        title: 'Dashboard',
        icon: 'bar_chart',
        href: '#dashboard',
        tabToolTipText:
          'Dashboard will be available once the survey has responses',
        isDisabled: !this.hasResponses
      };
      const PUBLIC_DISPLAY_TAB = {
        title: 'Public Display',
        icon: 'tv',
        href: '#public-display',
        isDisabled: false
      };
      const EXPORT_DATA_TAB = {
        title: 'Export Data',
        icon: 'save_alt',
        href: '#export',
        tabToolTipText:
          'Export data will be available once the survey has responses',
        isDisabled: !this.hasResponses
      };

      return [
        QUESTIONS_TAB,
        DASHBOARD_TAB,
        PUBLIC_DISPLAY_TAB,
        EXPORT_DATA_TAB
      ].filter(t => !t.isHidden);
    },
    questions() {
      return this.survey.questions;
    },
    surveyId() {
      return this.$route.params.id;
    },
    surveyTitle() {
      return this.survey ? this.survey.title : '';
    },
    questionStats() {
      return this.stats ?? [];
    },
    isPublished() {
      if (this.dataLoading) {
        return false;
      }

      return this.survey.status === SurveyStatus.PUBLISHED;
    },
    hasResponses() {
      // Check for existence of survey first as it may not have loaded
      return !this.dataLoading && this.survey.responseCount > 0;
    },
    disabledHref() {
      return `#${this.activeTab}`;
    },
    previewQuestions() {
      return this.questions.filter(
        question =>
          question.userConfigurable &&
          question.config.questionType !== 'NO_TYPE'
      );
    },
    selectedLanguage() {
      // If we're on the questions tab, then default to the primary language
      // unless we're translating, else go with the selected display languae
      if (this.activeTab == 'questions') {
        return this.translation ?? this.survey.primaryLanguage;
      } else {
        return this.displayLanguage;
      }
    },
    responseCount() {
      const responses = this.survey.responseCount;
      return responses === 1
        ? '1 response'
        : `${responses} responses`;
    },
    publicDashboardAccessUrl() {
      const protocol = window.location.protocol;
      const hostname = window.location.hostname;
      const port =
        window.location.port != '' ? `:${window.location.port}` : '';
      return `${protocol}//${hostname}${port}/#/dashboard/${this.survey.shortId}/${this.survey.publicDisplayAccessCode}`;
    },
    getSurveyActivePeriodString() {
      let format = 'DD MMM YYYY';
      let startDate = moment(this.survey.surveyStart)
        .tz(this.survey.timezone)
        .format(format);
      let endDate = moment(this.survey.surveyEnd)
        .tz(this.survey.timezone)
        .format(format);

      return startDate + ' until ' + endDate;
    }
  },
  mounted() {
    this.fetchSurvey(this.surveyId).then(() => {
      this.dataLoading = false;
      this.displayLanguage = this.survey.primaryLanguage;
    });
    // TODO: Move to DashboardView
    const stats = {
      surveyId: this.surveyId
    };
    this.$store.dispatch('stats/fetchStats', stats);

    if (this.$route.query.tab) {
      if (this.tabs.includes(this.$route.query.tab)) {
        this.activeTab = this.$route.query.tab;
      }
    } else {
      this.activeTab = 'questions';
    }
  },
  methods: {
    ...mapActions({
      fetchSurvey: 'survey/fetchSurvey',
      updateSurvey: 'survey/updateSurvey',
      saveQuestion: 'question/saveQuestion',
      clearResponses: 'survey/clearResponses'
    }),
    updateSurveyStatus() {
      this.survey.status =
        this.survey.status == SurveyStatus.PUBLISHED
          ? SurveyStatus.DRAFT
          : SurveyStatus.PUBLISHED;
    },
    updateTranslations(question, onFinish) {
      if (Array.isArray(question)) {
        const promises = question.map(q => this.saveQuestion(q));
        Promise.all(promises).then(() => {
          this.fetchSurvey(this.surveyId).then(() => onFinish());
        });
      } else {
        this.saveQuestion(question).then(() => onFinish());
      }
    },
    closeSurveyTranslator() {
      if (this.translation) {
        this.translation = undefined;
        this.displayLanguage = this.survey.primaryLanguage;
        return;
      }
      this.$router.go(-1);
    },
    setDisplayLanguage(language) {
      this.displayLanguage = language;

      if (this.activeTab === 'questions') {
        this.translate(language);
      }
    },
    translate(language) {
      if (this.translation !== undefined) {
        if (
          language === this.survey.primaryLanguage ||
          language === this.translation
        ) {
          return this.closeTranslate();
        }
      }
      if (language === this.survey.primaryLanguage) {
        return alert('Cannot translate the primary language');
      }
      this.translation = language;
    },
    closeTranslate() {
      this.translation = undefined;
    },
    fetchStats(dates) {
      const stats = {
        surveyId: this.surveyId,
        startDate: dates.startDate,
        endDate: dates.endDate
      };
      this.$store.dispatch('stats/fetchStats', stats);
    },
    showSurveyPublishDialog(action) {
      this.surveyAction = action;
      this.showActionDialog = true;
    },
    handleSurveySaved(survey) {
      survey.base64EncodedImageData = null;
      survey.imageDeleted = false;
      this.updateSurvey(survey);
    },
    handleDialogActionConfirmed() {
      const survey = { ...this.survey };
      survey.base64EncodedImageData = null;
      survey.imageDeleted = false;

      survey.status =
        survey.status == SurveyStatus.PUBLISHED
          ? SurveyStatus.DRAFT
          : SurveyStatus.PUBLISHED;

      this.updateSurveyStatus();
      this.updateSurvey(survey);
    },
    closeClearResponsesDialog() {
      this.showClearResponsesDialog = false;
    },
    clearSurveyResponses() {
      this.showClearResponsesDialog = false;
      this.clearResponses(this.survey).then(() => {
        this.fetchSurvey(this.surveyId);
      });
    },
    copyPublicDashboardUrl() {
      navigator.clipboard.writeText(this.publicDashboardAccessUrl);
    },
    toggleShowDisclaimer(newValue) {
      this.survey.showDisclaimer = newValue;
      this.updateSurvey(this.survey);
    }
  }
};
</script>
<style lang="scss" scoped>
.controls {
  padding: 2em 2em 0 2em;
}
.fab-container {
  left: -5em;
  top: 2em;
}
.small-text {
  font-size: 12px;
}
</style>
