<template>
  <short-link-view target-page="tv" />
</template>

<script>
import ShortLinkView from '../components/Common/ShortLinkView.vue';

export default {
  components: { ShortLinkView }
};
</script>
