<template>
  <div class="question-form-action-butons">
    <div class="question-form-action-butons__content">
      <question-form-action-button
        v-for="(action, i) in actions"
        :key="i"
        :action="action"
        class="ml-1"
        @click="$emit(action.toLowerCase().replaceAll('_', '-'))"
      />
    </div>
  </div>
</template>

<script>
import QuestionFormActionButton from './QuestionFormActionButton';
import { QuestionFormActions } from '@/enums';

export default {
  name: 'QuestionFormActionButtons',
  components: {
    QuestionFormActionButton
  },
  props: {
    questionHasResponses: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    actions() {
      const buttons = [QuestionFormActions.DELETE];
      if (this.questionHasResponses) {
        buttons.unshift(QuestionFormActions.CLEAR_RESPONSES);
      }
      buttons.unshift(QuestionFormActions.DUPLICATE);
      return buttons;
    }
  }
};
</script>

<style scoped lang="scss">
.question-form-action-butons__content {
  display: flex;
  :first-child {
    margin-left: 0;
  }
}
</style>
