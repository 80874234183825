<template>
  <v-dialog
    v-model="show"
    width="100vh"
    @click:outside="cancelDialog"
  >
    <v-card>
      <v-card-title fill-width class="text-capitalize justify-center">
        {{ getQuestionTitle() }}
      </v-card-title>
      <v-divider></v-divider>
      <v-list class="word-responses-list">
        <v-list-item-group class="mt-2 mb-2 ml-4 mr-4">
          <v-list-item
            v-for="(response,
            index) in questionStat.responsesWithTimes"
            :key="index"
            class="text-response-background"
            inactive
            :selectable="interactionEnabled"
            :ripple="interactionEnabled"
            two-line
          >
            <v-list-item-content>
              <v-row>
                <v-col cols="3">
                  <v-list-item-title>
                    {{
                      formatDateTimeString(
                        response.createdAt,
                        'ddd D MMM YYYY'
                      )
                    }}
                  </v-list-item-title>
                  <v-list-item-subtitle
                    class="wrap-text mt-2 grey--text"
                  >
                    {{
                      formatDateTimeString(
                        response.createdAt,
                        'HH:mm'
                      )
                    }}
                  </v-list-item-subtitle>
                </v-col>
                <v-col>
                  {{ response.value }}
                </v-col>
              </v-row>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
      <v-divider></v-divider>
      <v-card-actions>
        <v-container class="d-flex justify-end mr-4 mb-n2">
          <v-btn @click="$emit('cancel')">
            Cancel
          </v-btn>
        </v-container>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex';
import moment from 'moment-timezone';

export default {
  name: 'FreeTextResponsesModal',
  props: {
    questionStat: {
      required: true,
      type: Object
    },
    show: {
      required: true,
      default: false,
      type: Boolean
    },
    displayLanguage: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      interactionEnabled: false
    };
  },
  computed: {
    ...mapGetters('survey', ['survey'])
  },
  methods: {
    cancelDialog() {
      this.$emit('cancel');
    },
    getQuestionTitle() {
      return this.questionStat.title.languageMap[
        this.displayLanguage
      ];
    },
    formatDateTimeString(timestamp, formatString) {
      var isoDate = moment.utc(timestamp).format();
      return moment
        .tz(isoDate, this.survey.timezone)
        .format(formatString);
    }
  }
};
</script>

<style scoped lang="scss">
.wrap-text {
  -webkit-line-clamp: unset !important;
  white-space: normal;
}
.word-responses-list {
  height: 75vh;
  overflow-y: scroll;
}
.text-response-background:nth-child(odd) {
  background-color: #eee;
}
.action-box {
  display: flex;
  align-items: center !important;
}
</style>
