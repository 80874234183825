<template>
  <div class="text-center">
    <div
      v-for="(rankedWord, i) in rankedWords"
      :key="`word-${i}`"
      class="word my-0"
    >
      <localised-text
        :language="displayLanguage"
        :string="rankedWord.word"
      />
    </div>
  </div>
</template>

<script>
import LocalisedText from '@/components/Common/LocalisedText.vue';
export default {
  name: 'WordLadder',
  components: { LocalisedText },
  props: {
    questionStat: {
      type: Object,
      required: true
    },
    displayLanguage: {
      type: String,
      required: true
    }
  },
  computed: {
    rankedWords() {
      return this.questionStat.wordSelections
        .slice()
        .sort((a, b) => {
          const countComparator = a.count - b.count;
          if (countComparator != 0) return countComparator;
          // Sort alphabetically if the count is the same,
          // so they don't switch around randomly.
          const aWord = a.word.languageMap[this.displayLanguage];
          const bWord = b.word.languageMap[this.displayLanguage];
          return bWord > aWord ? 1 : -1;
        })
        .reverse();
    }
  }
};
</script>

<style scoped lang="scss">
$max-font-size: 11vh;
$font-size-decrement: 1;

.word {
  line-height: 0.95;
}

@for $i from 0 through 7 {
  .word:nth-child(#{$i + 1}) {
    font-size: $max-font-size - ($i / $font-size-decrement);
    line-height: $max-font-size - ($i / ($font-size-decrement * 1.3));
  }
}

.word:nth-child(1) {
  color: #7862ff;
}

.word:nth-child(n + 2) {
  color: #e46a7d;
}

.word:nth-child(n + 3) {
  color: #d6637b;
}

.word:nth-child(n + 5) {
  color: #c5597a;
}

.word:nth-child(n + 7) {
  color: #b15179;
}
</style>
