<template>
  <v-card outlined height="200">
    <v-row>
      <v-col class="d-flex align-end top">
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          dark
          fab
          @click="$emit('add-question')"
        >
          <v-icon>add</v-icon>
        </v-btn>
        <v-spacer></v-spacer>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="d-flex align-start bottom">
        <v-spacer></v-spacer>
        <p class="text-h5">Add Question</p>
        <v-spacer></v-spacer>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
export default {
  name: 'AddQuestionCard'
};
</script>

<style scoped>
div.v-card.v-card--outlined {
  border-style: dashed;
}

.top {
  height: 110px;
}

.bottom {
  height: 90px;
}
</style>
