var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-row",
    {
      staticClass: "pa-2",
      class: _vm.componentContainerClasses,
      attrs: { "no-gutters": "" }
    },
    [
      _c(
        "v-col",
        { staticClass: "font-weight-bold fixed", attrs: { cols: "auto" } },
        [_vm._v(" " + _vm._s(_vm.index) + ". ")]
      ),
      _c("v-col", { staticClass: "d-flex justify-space-between" }, [
        _c("div", [
          _c("div", { staticClass: "font-weight-bold" }, [
            _vm._v(_vm._s(_vm.title))
          ]),
          _c(
            "div",
            { staticClass: "text-subtitle-2" },
            [
              _c("question-type-icon", {
                attrs: {
                  size: 16,
                  color: _vm.questionTypeIconColour,
                  "question-type": _vm.type.value
                }
              }),
              _c("span", { class: _vm.questionTypeTextColour }, [
                _vm._v(" " + _vm._s(_vm.type.name) + " ")
              ])
            ],
            1
          )
        ]),
        _c(
          "div",
          { staticClass: "my-auto mr-2" },
          [
            _c(
              "v-btn",
              {
                staticClass: "px-0",
                attrs: {
                  "min-width": "20",
                  "max-width": "20",
                  "x-small": "",
                  color: _vm.duplicate ? "error" : "success"
                },
                on: {
                  click: function($event) {
                    return _vm.$emit("toggle-duplication")
                  }
                }
              },
              [
                _c("v-icon", { attrs: { small: "" } }, [
                  _vm._v(_vm._s(_vm.includeDuplicateQuestionToggleIcon))
                ])
              ],
              1
            )
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }