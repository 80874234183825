<template>
  <transition name="header-image">
    <div :key="imageId" class="d-flex flex-nowrap">
      <v-img
        v-if="blob"
        class="white--text align-end pa-4"
        height="120px"
        gradient="rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)"
        :src="blob"
      >
        <slot></slot>
      </v-img>
      <div
        v-else
        class="grey lighten-1 align-end image-placeholder pa-4"
      >
        <slot></slot>
      </div>
    </div>
  </transition>
</template>

<script>
import axios from 'axios';

export default {
  name: 'SurveyCardHeaderContainer',
  props: {
    imageId: {
      type: String,
      required: false,
      default: undefined
    }
  },
  data: () => ({
    blob: undefined
  }),
  computed: {
    imageUrl() {
      return `${process.env.VUE_APP_API_URL}/image/${this.imageId}/blob`;
    }
  },
  watch: {
    imageId() {
      this.reloadImage();
    }
  },
  mounted() {
    this.reloadImage();
  },
  methods: {
    reloadImage() {
      if (this.imageId == undefined) return;
      axios
        .get(this.imageUrl, {
          responseType: 'blob'
        })
        .then(response => {
          this.blob = window.URL.createObjectURL(response.data);
        })
        .catch(error => {
          if (error.response.status != 404) throw error; // Ignore 404s
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.image-placeholder {
  height: 120px;
  width: 100%;
  border-top-right-radius: inherit;
  border-top-left-radius: inherit;
}

.header-image-enter-active,
.header-image-leave-active {
  transition: opacity 0.25s;
}
.header-image-enter,
.header-image-leave-to {
  opacity: 0;
}
</style>
