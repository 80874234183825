<template>
  <v-dialog
    v-model="show"
    :width="width"
    persistent
    @keydown.esc.stop="$emit('cancel')"
  >
    <v-card>
      <v-card-title class="text-capitalize">
        {{ title }}
      </v-card-title>
      <slot name="body">
        <v-card-text>
          This is the default content for the body slot
        </v-card-text>
      </slot>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-container class="d-flex justify-end">
          <v-btn v-if="cancel" class="mr-4" @click="$emit('cancel')">
            Cancel
          </v-btn>

          <slot name="actions">
            <v-btn color="primary" @click="$emit('confirm')">
              Confirm
            </v-btn>
          </slot>
        </v-container>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'ActionModal',
  props: {
    show: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      required: true
    },
    width: {
      type: Number,
      default: 560
    },
    cancel: {
      type: Boolean,
      default: true
    }
  }
};
</script>
