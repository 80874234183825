var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("question-list", {
    staticClass: "mt-4",
    attrs: { "target-language": _vm.survey.primaryLanguage }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }