<template>
  <v-card class="pa-0 mb-4" hover>
    <!-- Row 1: Slide header -->
    <div class="d-flex align-center" @click.stop="edit">
      <!-- Index in list -->
      <div
        class="grey lighten-2 pa-5 pl-6 pr-6 mr-4"
        :class="editing ? 'rounded-tl' : 'rounded-l'"
      >
        <div class="grey--text text--darken-2">
          {{ questionIndex }}
        </div>
      </div>
      <!-- Icon -->
      <question-type-icon
        v-bind="questionTypeIconProps"
        :question-type="questionType.value"
        :class="{ 'grey--text text--lighten-1': !visible }"
        class="mr-4 question-type-icon"
      />
      <!-- Slide title -->
      <div
        class="text-h6"
        :class="{ 'grey--text text--lighten-1': !visible }"
      >
        {{ questionText }}
      </div>
      <!-- Show/hide button -->
      <div v-if="!editing" class="ml-auto mr-4">
        <v-btn
          v-if="visible"
          class="square grey--text text--darken-1"
          @click="hideQuestion"
        >
          <v-icon>visibility_off</v-icon>
        </v-btn>
        <v-btn
          v-else
          class="square grey--text text--darken-1"
          @click="showQuestion"
        >
          <v-icon>visibility</v-icon>
        </v-btn>
      </div>
    </div>
    <!-- Row 2: Edit form (if open) -->
    <div v-if="editing" class="pa-2">
      <div class="d-flex">
        <div class="mr-16"></div>
        <public-display-question-form
          :question-public-display-config="
            questionPublicDisplayConfig
          "
        ></public-display-question-form>
      </div>
      <div class="d-flex pa-2">
        <v-btn
          v-shortkey="['esc']"
          class="ml-auto"
          small
          @click="close"
          @shortkey="close"
        >
          Close
        </v-btn>
      </div>
    </div>
  </v-card>
</template>

<script>
import QuestionTypeIcon from '../Common/QuestionTypeIcon';
import QuestionTypeConfig from '../../utils/QuestionTypeConfig';
import PublicDisplayQuestionForm from './PublicDisplayQuestionForm';
import { EditableItem } from '@/mixins/EditableItem';

const QuestionPropertyDefault = Object.freeze({
  text: 'Question',
  type: 'Question Type'
});

export default {
  name: 'PublicDisplayQuestion',
  components: {
    QuestionTypeIcon,
    PublicDisplayQuestionForm
  },
  mixins: [EditableItem],
  props: {
    questionPublicDisplayConfig: {
      type: Object,
      required: true
    },
    visible: {
      type: Boolean,
      required: true
    },
    index: {
      type: Number,
      required: false,
      default: -1
    },
    targetLanguage: {
      type: String,
      required: true
    }
  },
  computed: {
    question() {
      return this.questionPublicDisplayConfig.question;
    },
    questionText() {
      return this.question.textValid(this.targetLanguage)
        ? this.question.languageText(this.targetLanguage)
        : QuestionPropertyDefault.text;
    },
    questionType() {
      const type = this.question.config.questionType;
      const typeDisplayName = this.question.typeValid()
        ? QuestionTypeConfig[type].displayName
        : QuestionPropertyDefault.type;

      return {
        value: type,
        displayName: typeDisplayName
      };
    },
    questionIndex() {
      return this.visible ? this.index : '-';
    },
    questionTypeIconProps() {
      return !this.question.typeValid()
        ? { color: 'grey lighten-1' }
        : undefined;
    }
  },
  methods: {
    showQuestion() {
      this.$emit('show-public-display-question', this.question);
    },
    hideQuestion() {
      this.$emit('hide-public-display-question', this.question);
    }
  }
};
</script>

<style lang="scss" scoped>
.v-btn.square {
  width: 36px;
  min-width: 36px !important;
}
</style>
