<template>
  <access-code-view title="Dashboard" target-page="dashboard" />
</template>

<script>
import AccessCodeView from '../components/Common/AccessCodeView.vue';

export default {
  components: { AccessCodeView },
  mixins: [],
  props: {},
  data: () => {
    return {};
  }
};
</script>
