var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    _vm._l(_vm.items, function(item, i) {
      return _c(
        "div",
        {
          key: `emotion-${i}`,
          staticClass: "legend-item d-flex mb-12 align-start",
          class: _vm.legendItemClass
        },
        [
          _c(
            "p",
            { staticClass: "emotion-label text-left text-h3 my-0" },
            [
              _c("localised-text", {
                attrs: {
                  language: _vm.displayLanguage,
                  string: item.label,
                  color: _vm.textColor
                }
              })
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "slice-percentage font-weight-bold text-h3 mx-16",
              class: _vm.slicePercentageClass,
              style: _vm.slicePercentageStyle(item)
            },
            [_vm._v(" " + _vm._s(item.value) + " ")]
          )
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }