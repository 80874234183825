<template>
  <svg
    viewBox="0 0 50 50"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    :height="`${size}px`"
    :style="style"
  >
    <linearGradient
      :id="gradientIdString"
      :gradientTransform="`rotate(${gradientRotation})`"
    >
      <stop
        v-for="color in colors"
        :key="color.offset"
        :offset="color.offset"
        :stop-color="color.stopColor"
      />
    </linearGradient>
    <path
      :fill="`url(#${gradientIdString})`"
      :opacity="opacity"
      d="M25,50 C11.19288,50 0,38.80712 0,25 C0,11.19288 11.19288,0 25,0 C38.80712,0 50,11.19288 50,25 C50,38.80712 38.80712,50 25,50 Z"
    ></path>
  </svg>
</template>

<script>
export default {
  name: 'CircleSvg',
  components: {},
  props: {
    size: {
      type: Number,
      required: true
    },
    colors: {
      type: Array,
      required: true
    },
    scale: {
      type: Number,
      required: false,
      default: 1
    },
    opacity: {
      type: Number,
      required: false,
      default: 1.0
    },
    xTranslation: {
      type: Number,
      required: false,
      default: 0
    },
    gradientRotation: {
      type: Number,
      required: false,
      default: 0
    }
  },
  data() {
    return {
      gradientId: null
    };
  },
  computed: {
    style() {
      return {
        transform: [
          `scale(${this.scale})`,
          `translateX(${this.xTranslation}%)`
        ].join(' ')
      };
    },
    gradientIdString() {
      return `circle-gradient-${this.gradientId}`;
    }
  },
  mounted() {
    this.gradientId = this._uid;
  }
};
</script>
