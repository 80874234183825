<template>
  <div>
    <v-card-title class="pt-0 text-h5 font-weight-medium">
      {{ region }}
    </v-card-title>
    <v-card-text class="py-0 font-weight-bold">
      <v-row
        v-for="(countryCount, i) in topNCountries"
        :key="i"
        class="text-subtitle-1"
      >
        <v-col
          cols="12"
          md="7"
          class="py-2 font-weight-medium black--text"
        >
          {{ countryName(countryCount.word) }}
        </v-col>
        <v-col cols="12" md="5" class="py-2 text-right">
          <span class="black--text">
            {{ countryCount.count }}
          </span>
          ({{ percentage(countryCount.count) }}%)
        </v-col>
      </v-row>
    </v-card-text>
  </div>
</template>
<script>
import { getName } from 'country-list';

export default {
  props: {
    questionStat: {
      required: true,
      type: Object
    },
    region: {
      required: false,
      default: '',
      type: String
    },
    limit: {
      required: false,
      type: Number,
      default: 5
    },
    displayLanguage: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      totalCount: Object.values(
        this.questionStat.wordSelections
      ).reduce((total, sum) => {
        return total + sum.count;
      }, 0),
      locationCounts: this.questionStat.wordSelections
    };
  },
  computed: {
    topNCountries() {
      return [...this.locationCounts] // Work on a simple clone
        .sort((a, b) => a.count - b.count)
        .reverse()
        .slice(0, this.limit);
    }
  },
  methods: {
    countryName(word) {
      return getName(word.languageMap[this.displayLanguage]);
    },
    percentage(count) {
      return count === 0
        ? 0
        : Math.round((count / this.totalCount) * 100);
    }
  }
};
</script>

<style lang="scss" scoped>
.row:nth-child(odd) {
  background-color: var(--v-primary-transparent-base);
}
</style>
