<template>
  <v-dialog
    :value="true"
    class="editing-question-with-responses-dialog"
    persistent
    max-width="600"
  >
    <v-card>
      <v-card-title class="mb-3 font-weight-bold">
        Editing question with responses
      </v-card-title>
      <v-card-text
        class="editing-question-with-responses-dialog__content"
      >
        <p>
          Questions that already have responses can not have their
          Question Type or configuration (e.g. words) changed as this
          will cause the response data to no longer match the question
          being asked.
        </p>
        <p>
          If you’d like to edit the Question Type or configuration,
          you must remove existing responses
          <question-form-action-button
            class="d-inline-block mx-1"
            :action="questionFormActions.CLEAR_RESPONSES"
            :tooltip="false"
          />
          for the question and then edit it.
        </p>
      </v-card-text>
      <v-card-actions class="pa-3">
        <v-spacer />
        <v-btn small @click="$emit('close')">close</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import QuestionFormActionButton from './QuestionFormActionButton';
import { QuestionFormActions } from '@/enums';
export default {
  components: {
    QuestionFormActionButton
  },
  computed: {
    questionFormActions() {
      return QuestionFormActions;
    }
  }
};
</script>

<style scoped lang="scss">
.editing-question-with-responses-dialog__content {
  :last-child {
    margin-bottom: 0;
  }
  ::v-deep .question-form-action-button {
    cursor: default;
  }
}
</style>
