<template>
  <v-container id="public-display-no-data" fill-height fluid>
    <v-row align="center" justify="center">
      <v-col align="center" justify="center">
        <!-- Shown if there are no questions in the Public Display -->
        <div v-if="!hasQuestions">
          <div class="text-h1 6rem mb-6">No questions</div>
          <h2>
            Please add questions to the Public Display<br />for
            <router-link class="link" :to="publicDisplayConfigLink">{{
              surveyTitle
            }}</router-link
            >.
          </h2>
        </div>

        <!-- Shown if the survey has no responses -->
        <div v-else-if="!hasResponses">
          <div class="text-h1 6rem mb-6">No responses</div>
          <h2>
            <router-link class="link" :to="publicDisplayConfigLink">{{
              surveyTitle
            }}</router-link>
            currently has no responses.
          </h2>
        </div>

        <!-- Fallback if there's something else amiss -->
        <div v-else>
          <v-img max-width="150" src="../../assets/logo.png"></v-img>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'NoPublicDisplayData',
  components: {},
  props: {
    surveyTitle: {
      type: String,
      required: true
    },
    surveyId: {
      type: String,
      required: true
    },
    hasQuestions: {
      type: Boolean,
      required: true
    },
    hasResponses: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    publicDisplayConfigLink() {
      return `/surveys/${this.surveyId}?tab=public-display`;
    }
  }
};
</script>

<style lang="scss" scoped>
#public-display-no-data {
  background-color: black;
  color: white;
  height: 100vh;
}

.link {
  text-decoration: none;
}

.link:hover {
  text-decoration: underline;
}
</style>
