<template>
  <div>
    <page-header-bar>
      <div class="fab-container p-absolute">
        <v-btn
          class="floating-button ml-n13"
          color="grey lighten-2 black--text"
          dark
          small
          fab
          @click="backToOrganisations"
        >
          <v-icon>arrow_back</v-icon>
        </v-btn>
      </div>
      <v-row no-gutters>
        <v-col class="text-h5 white--text font-weight-medium">
          {{ organisation.name }}
        </v-col>
      </v-row>
    </page-header-bar>
    <v-container class="pa-8">
      <organisation-settings-loader v-if="!loaded" />
      <organisation-settings v-else :organisation="organisation" />
    </v-container>
    <organisation-dialog
      :organisation="organisation"
      :show.sync="organisationDialog"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import PageHeaderBar from '@/components/Common/PageHeaderBar';
import OrganisationSettings from '@/components/Organisation/OrganisationSettings';
import OrganisationSettingsLoader from '@/components/Organisation/Loader/OrganisationSettingsLoader';
import OrganisationDialog from '@/components/Organisation/OrganisationDialog';

export default {
  name: 'OrganisationSettingsPage',
  components: {
    PageHeaderBar,
    OrganisationSettings,
    OrganisationSettingsLoader,
    OrganisationDialog
  },
  data: () => ({
    organisationDialog: false
  }),
  computed: {
    ...mapGetters('organisation', [
      'organisation',
      'organisations',
      'loaded'
    ]),
    ...mapGetters('currentUser', [
      'currentUserIsSuper',
      'currentUserHasManyOrganisations'
    ]),
    organisationId() {
      return this.$route.params.id;
    }
  },
  created() {
    this.fetchOrganisation(this.organisationId);
  },
  methods: {
    ...mapActions('organisation', [
      'fetchOrganisation',
      'fetchOrganisations'
    ]),
    backToOrganisations() {
      // If the user only belongs to a single organisation,
      // send them to survey grid rather than organisations list.
      const gotoListing =
        this.currentUserIsSuper ||
        this.currentUserHasManyOrganisations;
      this.$router.push(gotoListing ? '/organisations' : '/');
    }
  }
};
</script>
