<template>
  <div class="h-100 w-100 d-flex flex-column" :class="pageLayout">
    <div
      v-if="!fullscreen"
      class="text-h3 font-weight-bold text-center"
    >
      <localised-text
        :language="displayLanguage"
        :string="questionStat.title"
        :color="theme.textColor"
        wrap
      />
    </div>
    <slot name="visualisation"></slot>
  </div>
</template>

<script>
import QuestionTypeConfig from '@/utils/QuestionTypeConfig';
import LocalisedText from '../Common/LocalisedText.vue';

export default {
  name: 'PublicDisplaySlide',
  components: { LocalisedText },
  props: {
    questionStat: {
      type: Object,
      required: true
    },
    displayLanguage: {
      type: String,
      required: true
    },
    theme: {
      type: Object,
      required: true
    }
  },
  computed: {
    questionType() {
      return this.questionStat.questionType;
    },
    fullscreen() {
      const maybeGridDimensions = this.questionStat
        .publicDisplayOptions?.gridDimensions;
      return (
        this.questionType == 'DRAWING' &&
        (maybeGridDimensions?.includes('FULLSCREEN') ?? false)
      );
    },
    questionTypeDisplayName() {
      return QuestionTypeConfig[this.questionType].displayName;
    },
    pageLayout() {
      return this.fullscreen ? '' : 'px-12 py-6';
    }
  }
};
</script>

<style lang="scss" scoped>
.v-application .text-h3 {
  font-size: 56px !important;
  padding-top: 60px;
}

.v-card__title {
  word-break: normal;
}
</style>
