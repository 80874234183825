import QuestionTypeConfig from '../utils/QuestionTypeConfig';

export default Object.freeze(
  Object.assign(
    { NO_TYPE: 'NO_TYPE' },
    ...Object.keys(QuestionTypeConfig).map(type => ({
      [type]: type
    }))
  )
);
