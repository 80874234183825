<template>
  <v-container class="p absolute">
    <v-tabs class="pa-5" grow>
      <v-tab disabled>
        <v-icon class="mr-2">format_list_numbered</v-icon>Questions
      </v-tab>
      <v-tab disabled>
        <v-icon class="mr-2">bar_chart</v-icon>Dashboard
      </v-tab>
      <v-tab disabled>
        <v-icon class="mr-2">save_alt</v-icon>Export Data
      </v-tab>
    </v-tabs>
    <div
      v-for="loader in loaderCount"
      :key="loader"
      class="pb-4 pl-5"
    >
      <question-loader />
    </div>
  </v-container>
</template>

<script>
import QuestionLoader from './QuestionLoader';

export default {
  name: 'QuestionListLoader',
  components: {
    QuestionLoader
  },
  data: () => ({
    loaderCount: 5
  })
};
</script>
