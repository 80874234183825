<template>
  <div class="pt-8 p-relative">
    <v-row>
      <v-col cols="6">
        <v-row>
          <div class="text-subtitle-1 font-weight-bold mb-4">
            Show responses from:
          </div>
        </v-row>
        <v-row>
          <v-col cols="6">
            <v-menu
              ref="startDateMenu"
              v-model="startDateMenu"
              :close-on-content-click="false"
              :return-value.sync="startDate"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  :value="formattedPickerDate(startDate)"
                  label="Start date"
                  prepend-icon="event"
                  class="date-field mt-n2 mr-2"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="startDate"
                no-title
                scrollable
                @change="handleChangeStartDate"
              />
            </v-menu>
          </v-col>
          <v-col cols="6">
            <v-menu
              ref="endDateMenu"
              v-model="endDateMenu"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  :value="formattedPickerDate(endDate)"
                  label="End date"
                  prepend-icon="event"
                  readonly
                  v-bind="attrs"
                  class="date-field mt-n2"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="endDate"
                no-title
                scrollable
                @change="handleChangeEndDate"
              />
            </v-menu>
          </v-col>
        </v-row>
      </v-col>
      <v-spacer />
      <v-col cols="4">
        <v-row>
          <div class="text-subtitle-1 font-weight-bold mb-6">
            Language for statistics
          </div>
        </v-row>
        <v-row>
          <survey-language-status
            :language-status="translationStatus"
            :highlight="selectedLanguage"
            @language-clicked="changeSelectedLanguage"
          />
        </v-row>
      </v-col>
      <v-spacer />
    </v-row>
    <v-row>
      <v-col
        v-for="(questionStat, index) in questionStats"
        :key="index"
        cols="12"
        sm="6"
        class="d-flex flex-column"
      >
        <dashboard-item
          class="flex-grow-1"
          v-bind="{ questionStat }"
          :total-respondents="surveyResponseCount"
          :display-language="selectedLanguage"
        >
          <template v-slot:item>
            <component
              :is="visualisationComponentForStat(questionStat)"
              :display-language="selectedLanguage"
              v-bind="{ questionStat }"
            />
          </template>
        </dashboard-item>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { surveyHelper } from '@/mixins/SuperUtils';
import QuestionTypeConfig from '@/utils/QuestionTypeConfig';
import SurveyLanguageStatus from '@/components/Common/SurveyLanguageStatus';
import CirclesOverlapDashboardItem from './Items/CirclesOverlapDashboardItem';
import CirclesProportionalDashboardItem from './Items/CirclesProportionalDashboardItem';
import ClockDashboardItem from './Items/ClockDashboardItem';
import CountryDashboardItem from './Items/CountryDashboardItem';
import DashboardItem from './DashboardItem';
import EmotionWheelDashboardItem from './Items/EmotionWheelDashboardItem';
import EnclosingWallsDashboardItem from './Items/EnclosingWallsDashboardItem';
import FreeTextDashboardItem from './Items/FreeTextDashboardItem';
import GenderDashboardItem from './Items/GenderDashboardItem';
import HeartDashboardItem from './Items/HeartDashboardItem';
import LanguageSelectDashboardItem from './Items/LanguageSelectDashboardItem';
import MultipleChoiceDashboardItem from './Items/MultipleChoiceDashboardItem';
import MultipleChoiceImageDashboardItem from './Items/MultipleChoiceImageDashboardItem.vue';
import NumberFlippersDashboardItem from './Items/NumberFlippersDashboardItem';
import PeopleCountNoKidsDashboardItem from './Items/PeopleCountNoKidsDashboardItem';
import PeopleCountDashboardItem from './Items/PeopleCountDashboardItem';
import PostcodeDashboardItem from './Items/PostcodeDashboardItem';
import Rating0To10DashboardItem from './Items/Rating0To10DashboardItem';
import Rating1To5DashboardItem from './Items/Rating1To5DashboardItem';
import SliderDashboardItem from './Items/SliderDashboardItem';
import SmileyFaceDashboardItem from './Items/SmileyFaceDashboardItem';
import SpikyBallDashboardItem from './Items/SpikyBallDashboardItem';
import TopWordsDashboardItem from './Items/TopWordsDashboardItem';
import WaveLineDashboardItem from './Items/WaveLineDashboardItem';
import YesNoDashboardItem from './Items/YesNoDashboardItem';
import HorizontalBarChart from '@/components/Visualisations/HorizontalBarChart';
import { datetimeDate } from '@/utils';
import moment from 'moment';
import { mapState } from 'vuex';

export default {
  name: 'DashboardView',
  components: {
    CirclesOverlapDashboardItem,
    CirclesProportionalDashboardItem,
    ClockDashboardItem,
    CountryDashboardItem,
    DashboardItem,
    EmotionWheelDashboardItem,
    EnclosingWallsDashboardItem,
    FreeTextDashboardItem,
    GenderDashboardItem,
    HeartDashboardItem,
    LanguageSelectDashboardItem,
    MultipleChoiceDashboardItem,
    MultipleChoiceImageDashboardItem,
    NumberFlippersDashboardItem,
    PeopleCountNoKidsDashboardItem,
    PeopleCountDashboardItem,
    PostcodeDashboardItem,
    Rating0To10DashboardItem,
    Rating1To5DashboardItem,
    SliderDashboardItem,
    SmileyFaceDashboardItem,
    SpikyBallDashboardItem,
    TopWordsDashboardItem,
    WaveLineDashboardItem,
    YesNoDashboardItem,
    HorizontalBarChart,
    SurveyLanguageStatus
  },
  mixins: [surveyHelper],
  props: {
    displayLanguage: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      startDate: null,
      endDate: null,
      startDateMenu: false,
      endDateMenu: false,
      selectedLanguage: this.displayLanguage
    };
  },
  computed: {
    ...mapState('stats', ['stats']),
    surveyResponseCount() {
      return this.stats.surveyResponseCount;
    },
    questionStats() {
      return this.stats
        ? this.stats.questionStats.filter(stat =>
            this.visualisationExistsForStat(stat)
          )
        : [];
    },
    translationStatus() {
      // TODO: This function replaces the languageTranslationHelper mixin,
      // which requires the survey to be loaded (and therefore complicates
      // thing for the PublishDashboardPage when adding the language switcher)
      // Should re-work the SurveyLanguageStatus components to be less reliant
      // on this information when the completion status of translations is not
      // required
      return this.stats.languages.map(language => {
        return { code: language, completed: true };
      });
    }
  },
  created() {
    const now = new Date();
    if (moment(now).isBefore(this.stats.surveyStart)) {
      this.startDate = datetimeDate(this.stats.firstSubmissionDate);
      this.endDate = datetimeDate(this.stats.lastSubmissionDate);
    } else if (moment(now).isSameOrAfter(this.stats.surveyStart)) {
      this.startDate = datetimeDate(this.stats.surveyStart);
      this.endDate = datetimeDate(this.stats.surveyEnd);
    }
  },
  methods: {
    visualisationComponentForStat(questionStat) {
      const type = QuestionTypeConfig[questionStat.questionType];
      return type && type.visualisationComponent
        ? type.visualisationComponent
        : undefined;
    },
    visualisationExistsForStat(questionStat) {
      return (
        this.visualisationComponentForStat(questionStat) !== undefined
      );
    },
    questionType(question) {
      return QuestionTypeConfig[question.questionType].displayName;
    },
    handleChangeStartDate() {
      this.$refs.startDateMenu.save(this.startDate);
      this.$emit('fetch-stats-with-date', {
        startDate: this.startDate,
        endDate: this.endDate
      });
    },
    handleChangeEndDate() {
      this.$refs.endDateMenu.save(this.endDate);
      this.$emit('fetch-stats-with-date', {
        startDate: this.startDate,
        endDate: this.endDate
      });
    },
    formattedPickerDate(date) {
      return moment(date).format('ddd DD MMM YYYY');
    },
    changeSelectedLanguage(language) {
      this.selectedLanguage = language;
    }
  }
};
</script>
