var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.tooltip
        ? _c(
            "v-tooltip",
            {
              attrs: { top: "" },
              scopedSlots: _vm._u(
                [
                  {
                    key: "activator",
                    fn: function({ on }) {
                      return [
                        _c(
                          "v-btn",
                          _vm._g(
                            {
                              staticClass: "question-form-action-button",
                              attrs: {
                                "x-small": "",
                                "min-width": "24px",
                                "max-width": "24px",
                                color: _vm.button.colors
                              },
                              on: {
                                click: function($event) {
                                  return _vm.$emit("click")
                                }
                              }
                            },
                            on
                          ),
                          [
                            _c("v-icon", { attrs: { size: "18" } }, [
                              _vm._v(_vm._s(_vm.button.icon))
                            ])
                          ],
                          1
                        )
                      ]
                    }
                  }
                ],
                null,
                false,
                3334579223
              )
            },
            [_c("span", [_vm._v(_vm._s(_vm.button.tooltipText))])]
          )
        : _c(
            "v-btn",
            {
              staticClass: "question-form-action-button",
              attrs: {
                "x-small": "",
                "min-width": "24px",
                "max-width": "24px",
                color: _vm.button.colors
              },
              on: {
                click: function($event) {
                  return _vm.$emit("click")
                }
              }
            },
            [
              _c("v-icon", { attrs: { size: "18" } }, [
                _vm._v(_vm._s(_vm.button.icon))
              ])
            ],
            1
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }