import Vue from 'vue';
import axios from 'axios';
import VueAxios from 'vue-axios';
import { getInstance as auth0 } from 'auth0-js';

class Api {
  constructor() {
    Vue.use(VueAxios, axios);
    Vue.axios.defaults.baseURL = process.env.VUE_APP_API_URL;
    Vue.axios.interceptors.request.use(
      async config => {
        let authToken = await this.getToken();
        config.headers['Authorization'] = `Bearer ${authToken}`;
        return config;
      },
      err => Promise.reject(err)
    );
  }

  async get(resource, slug = '', params) {
    let path = resource;
    if (slug !== '') {
      path += `/${slug}`;
    }

    return Vue.axios.get(path, params);
  }

  async getSurveyExport(survey, body) {
    const url = process.env.VUE_APP_API_URL + '/exportJob';
    return axios({
      url,
      method: 'POST',
      data: body
    });
  }

  async getSurveyExportJobs(survey) {
    const url = `${process.env.VUE_APP_API_URL}/exportJob?surveyId=${survey.id}`;
    return Vue.axios.get(url);
  }

  async post(resource, data) {
    return Vue.axios.post(`${resource}`, data);
  }

  async put(resource, slug, data) {
    return Vue.axios.put(`${resource}/${slug}`, data);
  }

  async duplicateQuestion(question) {
    return Vue.axios.post(
      `question/duplicate?id=${question.id}`,
      question
    );
  }

  async delete(resource, ids) {
    const uri = Array.isArray(ids)
      ? `${resource}?${resource}Ids=${ids}`
      : `${resource}/${ids}`;

    return Vue.axios.delete(uri);
  }

  async getToken() {
    await auth0().finishedLoading();
    return auth0().isAuthenticated
      ? await auth0().getTokenSilently()
      : undefined;
  }
}

const ApiService = {
  init() {
    this.api = new Api();
  },

  getApi() {
    return this.api;
  }
};

export default ApiService;
