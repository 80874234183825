var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { staticClass: "mb-0 text-caption grey--text text--darken-2" },
            [_vm._v(" Emotions ")]
          )
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "flex-column" },
        [
          _c(
            "v-col",
            {
              staticClass: "d-flex justify-space-between pt-0",
              attrs: { cols: "10" }
            },
            _vm._l(_vm.emotions, function(emotion) {
              return _c("div", { key: emotion }, [
                _vm._v(" " + _vm._s(emotion) + " ")
              ])
            }),
            0
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }