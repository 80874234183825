var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("word-list-config", {
    attrs: {
      words: _vm.words,
      "label-word": "Circle",
      disabled: _vm.questionHasResponses
    },
    on: { "words-updated": _vm.wordsUpdated }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }