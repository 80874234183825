var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    { attrs: { viewBox: `0 0 ${_vm.size} ${_vm.size}` } },
    [
      _vm._l(_vm.slices, function(slice, index) {
        return _c("path", {
          key: `slice_${index}`,
          attrs: {
            d: _vm.slicePath(index, slice.value),
            fill: slice.colour,
            "fill-opacity": _vm.fillOpacity,
            stroke: slice.colour,
            "stroke-width": _vm.strokeWidth
          }
        })
      }),
      _vm._l(_vm.slices, function(slice, index) {
        return _c("path", {
          key: `curve_${index}`,
          attrs: {
            id: `curve_${index}`,
            d: _vm.textPath(index, slice.value),
            fill: "transparent"
          }
        })
      }),
      _vm._l(_vm.labelledSlices, function(slice) {
        return _c(
          "text",
          {
            key: `text_${slice.index}`,
            staticClass: "label",
            attrs: { "text-anchor": "middle" }
          },
          [
            _c(
              "textPath",
              {
                attrs: {
                  startOffset: "25%",
                  "xlink:href": `#curve_${slice.index}`
                }
              },
              [_vm._v(" " + _vm._s(slice.label) + " ")]
            )
          ]
        )
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }