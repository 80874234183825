<template>
  <v-col v-if="verticalOrientation" class="text-center">
    <p
      :class="[
        'mb-2 grey--text text-caption',
        !disabled
          ? multipleChoiceClass
            ? 'text--darken-2'
            : 'text--lighten-1'
          : ''
      ]"
    >
      {{ title }}
    </p>
    <v-btn
      :disabled="disabled || isMax"
      small
      @click="update(++number)"
    >
      <v-icon small>arrow_upward</v-icon>
    </v-btn>
    <p :class="['my-4', disabled ? 'grey--text' : '']">
      {{ number }}
    </p>
    <v-btn
      :disabled="disabled || isMin"
      small
      @click="update(--number)"
    >
      <v-icon small>arrow_downward</v-icon>
    </v-btn>
  </v-col>
  <div v-else>
    <p
      :class="[
        'mt-3 mb-2 grey--text text-caption',
        !disabled
          ? multipleChoiceClass
            ? 'text--darken-2'
            : 'text--lighten-1'
          : ''
      ]"
    >
      {{ title }}
    </p>
    <div class="d-flex align-center">
      <v-btn
        :disabled="disabled || isMin"
        small
        class="square"
        @click="update(--number)"
      >
        <v-icon small>remove</v-icon>
      </v-btn>
      <p
        :class="[
          'my-0',
          'mx-3',
          'text-body-2',
          disabled ? 'grey--text' : ''
        ]"
      >
        {{ number }}
      </p>
      <v-btn
        :disabled="disabled || isMax"
        small
        class="square"
        @click="update(++number)"
      >
        <v-icon small>add</v-icon>
      </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  name: 'WordSettingsController',
  props: {
    title: {
      type: String,
      required: true
    },
    count: {
      type: Number,
      required: true
    },
    range: {
      type: Object,
      required: true
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    multipleChoiceClass: {
      type: Boolean,
      required: false,
      defalt: false
    },
    orientation: {
      type: String,
      required: false,
      default: 'vertical'
    }
  },
  data() {
    return {
      number: this.count
    };
  },
  computed: {
    isMax() {
      return this.number === this.range.max;
    },
    isMin() {
      return this.number === this.range.min;
    },
    verticalOrientation() {
      return this.orientation === 'vertical';
    }
  },
  methods: {
    update(val) {
      this.$emit('set-value', val);
    }
  }
};
</script>

<style lang="scss" scoped>
.v-btn.square {
  min-width: 28px !important;
  width: 28px !important;
}
</style>
