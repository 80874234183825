<template>
  <v-row>
    <v-col
      v-for="(input, i) in inputs"
      :key="i"
      cols="4"
      class="pt-0 pb-2"
    >
      <v-text-field
        :value="localTime(input.model)"
        :label="input.label"
        prepend-icon="event"
        readonly
        @focus="setPicker(input.picker)"
      ></v-text-field>
    </v-col>
    <v-dialog
      :value="Boolean(picker)"
      width="300"
      @click:outside="setPicker(undefined)"
    >
      <v-date-picker
        v-model="currentDateModel"
        :min="earliestDate"
        @input="setPicker(undefined)"
      ></v-date-picker>
    </v-dialog>
  </v-row>
</template>

<script>
import moment from 'moment';
import { dateFormat } from '@/utils';

export default {
  name: 'SurveyTimeframeSelector',
  props: {
    surveyStart: {
      type: String,
      required: true
    },
    surveyEnd: {
      type: String,
      required: true
    }
  },
  data: () => ({
    picker: undefined
  }),
  computed: {
    isStartDate() {
      return this.picker === 'start';
    },
    currentDateModel: {
      get() {
        return this.localTime(
          this.isStartDate ? this.surveyStart : this.surveyEnd,
          dateFormat.iso8601
        );
      },
      set(val) {
        this.update(val, this.isStartDate);
      }
    },
    earliestDate() {
      return this.isStartDate
        ? undefined
        : this.localTime(this.surveyStart, dateFormat.iso8601);
    },
    inputs() {
      const capitalise = str => {
        return str.substring(0, 1).toUpperCase() + str.substring(1);
      };
      return ['start', 'end'].map(val => ({
        model: this[`survey${capitalise(val)}`],
        label: `${capitalise(val)} Date`,
        picker: val
      }));
    }
  },
  watch: {
    surveyStart() {
      this.enforceOrderedDates();
    }
  },
  methods: {
    setPicker(val) {
      this.picker = val;
    },
    localTime(time, format = dateFormat.default) {
      return (time ? moment(time) : moment()).local().format(format);
    },
    enforceOrderedDates() {
      const start = moment(this.surveyStart);
      const end = moment(this.surveyEnd);
      if (start.isAfter(end, 'day')) {
        this.update(this.surveyStart, false);
      }
    },
    update(value, start = true) {
      this.$emit(
        `update:survey-${start ? 'start' : 'end'}`,
        moment(
          `${value} ${start ? '00:00' : '23:59'}`,
          'YYYY-MM-DD HH:mm'
        ).format()
      );
    }
  }
};
</script>

<style scoped>
::v-deep .theme--light.v-btn.v-btn--disabled.v-btn--active {
  color: white !important;
}
</style>
