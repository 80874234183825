<template>
  <div>
    <v-checkbox
      v-model="value.hideQuestionTitle"
      class="mb-n4 mt-2"
      label="Hide question title"
    />
    <v-row>
      <v-col cols="8">
        <v-textarea
          :value="prompt.languageMap[primaryLanguage]"
          label="Prompt Text"
          @input="configUpdated($event)"
        >
        </v-textarea>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'GuidanceConfig',
  props: {
    value: {
      type: Object,
      required: true
    },
    targetLanguage: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      prompt: this.value.prompt
    };
  },
  computed: {
    primaryLanguage() {
      return this.targetLanguage;
    }
  },
  methods: {
    configUpdated(newValue) {
      this.prompt.languageMap[this.primaryLanguage] = newValue;
      const config = {
        questionType: this.value.questionType,
        hideQuestionTitle: this.value.hideQuestionTitle,
        prompt: this.prompt
      };
      this.$emit('input', config);
      this.$emit('change', config);
    }
  }
};
</script>
