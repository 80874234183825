<template>
  <div>
    <vue-word-cloud
      :style="visualisationSize"
      :words="wordCountPairs"
      :color="weight"
      font-family="museo-sans"
      :rotation="wordRotation"
      rotation-unit="turn"
      :spacing="1"
      :font-size-ratio="4"
      :animation-duration="0"
    />
  </div>
</template>

<script>
import VueWordCloud from 'vuewordcloud';
import stopword from 'stopword';

export default {
  components: {
    VueWordCloud
  },
  props: {
    responses: {
      type: Array,
      required: true
    },
    weights: {
      type: Array,
      required: true
    },
    width: {
      type: Number,
      required: true
    },
    height: {
      type: Number,
      required: true
    }
  },
  data: () => ({
    maxWeight: 0,
    rotation: 0
  }),
  computed: {
    customFilterWords() {
      return ['muse'];
    },
    visualisationSize() {
      return `width: ${this.width}px; height: ${this.height}px;`;
    },
    wordFilter() {
      return [
        ...stopword.ar,
        ...stopword.de,
        ...stopword.en,
        ...stopword.fr,
        ...stopword.it,
        ...this.customFilterWords
      ];
    },
    wordCounts() {
      const wordCounts = {};

      for (let response of this.responses) {
        const responseWords = response.split(' ');
        const filteredWords = stopword.removeStopwords(
          responseWords,
          this.wordFilter
        );

        for (let word of filteredWords) {
          if (!wordCounts.hasOwnProperty(word)) {
            wordCounts[word] = 1;
          } else {
            wordCounts[word]++;
          }
        }
      }

      return wordCounts;
    },
    maxWordCount() {
      return Math.max(...Object.values(this.wordCounts));
    },
    wordCountPairs() {
      const wordArray = Object.entries(this.wordCounts).map(
        ([key, value]) => {
          return [key, value];
        }
      );

      return wordArray;
    }
  },
  methods: {
    wordRotation() {
      this.rotation += 0.25;

      // keep the rotation value between 0 and 1
      if (this.rotation >= 1) this.rotation -= 1;

      // prevent upside down words
      if (this.rotation == 0.5) this.rotation += 0.25;

      return this.rotation;
    },
    weight([, weight]) {
      for (let element of this.weights) {
        if (weight / this.maxWordCount > element.percentage)
          return element.colour;
      }
    }
  }
};
</script>
