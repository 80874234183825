var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-row",
    [
      _c(
        "v-col",
        { staticClass: "justify-center", attrs: { cols: "12", md: "6" } },
        _vm._l(_vm.genderOptions, function(genderOption, genderIndex) {
          return _c(
            "v-row",
            { key: genderIndex, staticClass: "people-container" },
            _vm._l(_vm.ageGroupOptions, function(ageGroup, ageIndex) {
              return _c(
                "v-col",
                { key: ageIndex, attrs: { cols: "12", md: "4" } },
                [
                  _c(
                    "v-card",
                    {
                      staticClass: "text-center",
                      attrs: { flat: "", height: "100%" }
                    },
                    [
                      _c(
                        "v-card-text",
                        {
                          staticClass: "pt-0",
                          class: _vm.componentMargin[ageGroup]
                        },
                        [
                          _c(_vm.componentMap[genderOption], {
                            tag: "component",
                            class: _vm.componentClass(genderOption, ageGroup)
                          }),
                          _c(
                            "div",
                            {
                              staticClass:
                                "text-no-wrap text-subtitle-1 black--text font-weight-bold"
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.getAverage(genderOption, ageGroup)
                                  ) +
                                  " "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            }),
            1
          )
        }),
        1
      ),
      _c(
        "v-col",
        {
          staticClass: "d-flex justify-center",
          attrs: { cols: "12", md: "6" }
        },
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "12", md: "12" } },
                [
                  _c(
                    "v-container",
                    { staticClass: "fill-height" },
                    [
                      _c(
                        "v-card",
                        {
                          staticClass: "text-center align-center",
                          attrs: { flat: "" }
                        },
                        [
                          _c(
                            "v-card-text",
                            { staticClass: "black--text pt-0 mt-4" },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "text-center title font-weight-bold"
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.average.groupSize) + " "
                                  )
                                ]
                              ),
                              _c("v-row", [
                                _vm._v(" was the average group size ")
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }