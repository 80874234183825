<template>
  <v-dialog
    :value="true"
    class="question-form-action-dialog"
    persistent
    width="500"
  >
    <v-card>
      <v-card-title class="mb-3 font-weight-bold">
        <slot name="title" />
      </v-card-title>
      <v-card-subtitle class="d-flex align-center font-weight-bold">
        <question-type-icon
          class="mr-3"
          :question-type="questionType"
        />
        {{ questionOrderIndex }}. {{ questionTitle }}
      </v-card-subtitle>
      <v-card-text class="question-form-action-dialog__content">
        <slot name="content" />
      </v-card-text>
      <v-card-actions class="pa-3">
        <v-spacer />
        <slot name="actions">
          <v-btn small @click="$emit('close')">cancel</v-btn>
          <v-btn small color="error" @click="$emit('confirm')">
            <slot name="confirm-btn-text">
              confirm
            </slot>
          </v-btn>
        </slot>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import QuestionTypeIcon from '@/components/Common/QuestionTypeIcon';
export default {
  name: 'QuestionFormActionDialog',
  components: {
    QuestionTypeIcon
  },
  props: {
    questionTitle: {
      type: String,
      required: true
    },
    questionType: {
      type: String,
      required: true
    },
    questionOrderIndex: {
      type: Number,
      required: true
    }
  }
};
</script>

<style scoped lang="scss">
.question-form-action-dialog__content {
  :last-child {
    margin-bottom: 0;
  }
}
</style>
