<template>
  <div class="public-display-form">
    <v-form ref="form" class="mr-auto">
      <p class="grey--text text--darken-1 mb-2">Slide titles</p>
      <div v-for="(language, i) in languages" :key="language">
        <div class="d-flex align-center mb-2">
          <language-avatar
            :language="{ code: `${language}` }"
            class="mr-4"
          ></language-avatar>
          <v-text-field
            v-model="customTitle.languageMap[language]"
            hide-details
            dense
            :autofocus="i === 0"
            @input="queueSave"
          ></v-text-field>
        </div>
      </div>
      <div v-if="questionTypeDef.hasAdditionalPublicDisplayConfig">
        <component
          :is="questionTypeDef.publicDisplayOptionsComponent"
          class="d-flex flex-column flex-grow-1 justify-center"
          :public-display-config="questionPublicDisplayConfig"
          @options-changed="queueSave"
        />
      </div>
    </v-form>
  </div>
</template>

<script>
import LanguageAvatar from '@/components/Common/LanguageAvatar';
import { surveyHelper } from '@/mixins/SuperUtils';
import { mapState, mapActions } from 'vuex';
import QuestionTypeConfig from '@/utils/QuestionTypeConfig';
import DrawingPublicDisplayOptions from '@/components/Survey/Edit/PublicDisplayOptions/DrawingPublicDisplayOptions';
import PhotoPublicDisplayOptions from '@/components/Survey/Edit/PublicDisplayOptions/PhotoPublicDisplayOptions';

export default {
  name: 'PublicDisplayQuestionForm',
  components: {
    LanguageAvatar,
    DrawingPublicDisplayOptions,
    PhotoPublicDisplayOptions
  },
  mixins: [surveyHelper],
  props: {
    questionPublicDisplayConfig: {
      type: Object,
      required: true
    }
  },
  data: function() {
    return {
      saveTimerId: undefined
    };
  },
  computed: {
    ...mapState('survey', ['survey']),
    question() {
      return this.questionPublicDisplayConfig.question;
    },
    customTitle() {
      return this.questionPublicDisplayConfig.customTitle;
    },
    questionType() {
      return this.question.config.questionType;
    },
    questionTypeDef() {
      return QuestionTypeConfig[this.question.config.questionType];
    }
  },
  methods: {
    ...mapActions({
      updateQuestionPublicDisplayConfig:
        'publicDisplay/updateQuestionPublicDisplayConfig'
    }),
    queueSave() {
      if (this.saveTimerId) {
        clearTimeout(this.saveTimerId);
      }

      this.saveTimerId = setTimeout(() => {
        this.updateQuestionPublicDisplayConfig(
          this.questionPublicDisplayConfig
        );
      }, 500);
    },
    close() {
      this.$emit('close');
    }
  }
};
</script>

<style lang="scss" scoped>
.public-display-form {
  width: 60%;
}
</style>
