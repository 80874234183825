var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-card-text",
        { staticClass: "py-0 font-weight-bold" },
        _vm._l(_vm.rankedWords, function(rankedWord) {
          return _c(
            "v-row",
            {
              key: rankedWord.word.languageMap[_vm.primaryLanguage],
              staticClass: "text-subtitle-1"
            },
            [
              _c(
                "v-col",
                {
                  staticClass: "py-2 font-weight-medium black--text",
                  attrs: { cols: "12", md: "7" }
                },
                [
                  _c("localised-text", {
                    attrs: {
                      language: _vm.displayLanguage,
                      string: rankedWord.word
                    }
                  })
                ],
                1
              ),
              _c(
                "v-col",
                {
                  staticClass: "py-2 text-right",
                  attrs: { cols: "12", md: "5" }
                },
                [
                  _c("span", { staticClass: "black--text" }, [
                    _vm._v(_vm._s(rankedWord.count))
                  ]),
                  _vm._v(
                    " (" + _vm._s(rankedWord.percentage.toFixed(1)) + "%) "
                  )
                ]
              )
            ],
            1
          )
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }