import QuestionFormActionButton from '@/components/Survey/Edit/QuestionFormActionButton';
import { QuestionFormActions } from '@/enums';

export default {
  components: {
    QuestionFormActionButton
  },
  computed: {
    responseCountText() {
      return this.questionResponseCount === 1
        ? `${this.questionResponseCount} response`
        : `${this.questionResponseCount} responses`;
    },
    questionFormActions() {
      return QuestionFormActions;
    }
  }
};
