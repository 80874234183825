<template>
  <div class="d-flex flex-column">
    <v-card-text class="py-0 font-weight-bold">
      <div
        v-for="rankedRegion in rankedRegions"
        :key="rankedRegion.region"
        class="d-flex text-subtitle-1 black--text mb-1"
      >
        <div class="mr-5 font-weight-bold">
          {{ extractRegionKey(rankedRegion.region) }}xx
        </div>
        <div class="flex-grow-1">
          {{ postcodeRegion(rankedRegion.region) }}
        </div>
        <div class="grey--text text--darken-1">
          {{ rankedRegion.count }}
          ({{ rankedRegion.percentage.toFixed(1) }}%)
        </div>
      </div>
    </v-card-text>
  </div>
</template>

<script>
export default {
  props: {
    questionStat: {
      required: true,
      type: Object
    },
    displayLanguage: {
      type: String,
      required: true
    }
  },
  computed: {
    postcodes() {
      return this.questionStat.wordSelections.map(
        element => element.word.languageMap[this.displayLanguage]
      );
    },
    postcodeResponses() {
      return this.postcodes.map(postcode => ({
        postcode,
        count: this.questionStat.wordSelections.find(
          element =>
            element.word.languageMap[this.displayLanguage] == postcode
        ).count
      }));
    },
    rankedRegions() {
      return [...this.regionResponses]
        .sort((a, b) => a.count - b.count)
        .reverse()
        .slice(0, 10);
    },
    postcodeData() {
      return require('../../../assets/visualisation-data/postcodeData.json');
    },
    regionResponseCounts() {
      return this.postcodeResponses.reduce(
        postcodeResponseReducer.bind(this),
        {}
      );
    },
    regionResponses() {
      const regions = Object.keys(this.regionResponseCounts).map(
        key => ({
          count: this.regionResponseCounts[key],
          region: key,
          percentage:
            (this.regionResponseCounts[key] /
              this.questionStat.totalResponses) *
            100
        })
      );

      return regions;
    }
  },
  methods: {
    extractRegionKey(region) {
      return region.substr(0, 2);
    },
    postcodeRegion(postcode) {
      if (this.postcodeData) {
        const key = this.extractRegionKey(postcode);
        return this.postcodeData[key];
      }
    }
  }
};

function postcodeResponseReducer(
  regionResponseCountMap,
  postcodeResponseData
) {
  const regionKey = this.extractRegionKey(
    postcodeResponseData.postcode
  );

  if (regionResponseCountMap[regionKey]) {
    regionResponseCountMap[regionKey] += postcodeResponseData.count;
  } else {
    regionResponseCountMap[regionKey] = postcodeResponseData.count;
  }
  return regionResponseCountMap;
}
</script>
