<template>
  <v-menu bottom right absolute>
    <template v-slot:activator="{ on }">
      <v-btn
        class="menu-button white--text p-absolute"
        icon
        v-on="on"
      >
        <v-icon>more_vert</v-icon>
      </v-btn>
    </template>
    <v-list class="menu-list">
      <v-list-item
        v-for="(item, i) in listItems"
        :key="i"
        @click="$emit('action-click', item.action)"
      >
        <v-list-item-content :class="actionLabelTextClasses(item)">
          {{ actionLabelText(item) }}
        </v-list-item-content>
        <v-list-item-icon>
          <v-icon
            :class="actionIconClasses(item)"
            v-text="item.icon"
          />
        </v-list-item-icon>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import { SurveyStatus, SurveyActions } from '@/enums';
export default {
  props: {
    surveyStatus: {
      type: String,
      required: true,
      validator: val => SurveyStatus[val]
    }
  },
  computed: {
    listItems() {
      const surveyIsDraft = this.surveyStatus == SurveyStatus.DRAFT;
      return {
        [surveyIsDraft ? 'publish' : 'unpublish']: {
          action: surveyIsDraft
            ? SurveyActions.PUBLISH
            : SurveyActions.UNPUBLISH,
          icon: 'publish'
        },
        duplicate: {
          action: SurveyActions.DUPLICATE,
          icon: 'content_copy'
        },
        delete: {
          action: SurveyActions.DELETE,
          icon: 'delete',
          color: 'red'
        }
      };
    }
  },
  methods: {
    actionLabelText(item) {
      return item.action.toLowerCase();
    },
    actionLabelTextClasses(item) {
      return [
        { [`${item.color}--text`]: item.color },
        'text-capitalize'
      ];
    },
    actionIconClasses(item) {
      return {
        [`${item.color}--text`]: item.color,
        't-flipped-y': item.action === SurveyActions.UNPUBLISH
      };
    }
  }
};
</script>
<style scoped lang="scss">
.menu-list {
  min-width: 144px;
}

.menu-button {
  bottom: 16px;
  right: 12px;
}
</style>
