<template>
  <v-row>
    <v-col class="pa-0">
      <svg :viewBox="`0 0 ${maxBarWidth} ${visualisationHeight}`">
        <image
          v-for="(url, index) of imageUrls"
          :key="`image_${index}`"
          :x="0"
          :y="barYPosition(index)"
          :width="imageWidth"
          :height="barHeight"
          :href="url"
        />
        <rect
          v-for="(option, index) of options"
          :key="`background_${index}`"
          :x="imageWidth + barPadding"
          :y="barYPosition(index)"
          :width="maxBarWidth - imageWidth - barPadding"
          :height="barHeight"
          class="background"
        />
        <rect
          v-for="(option, index) of options"
          :key="`foreground_${index}`"
          :x="imageWidth + barPadding"
          :y="barYPosition(index)"
          :width="barWidth(option.value)"
          :height="barHeight"
          :fill="$vuetify.theme.currentTheme.primary"
        />
        <text
          v-for="(option, index) of options"
          :key="`label_${index}`"
          :x="textLeft + imageWidth + barPadding"
          :y="textYPosition(index)"
          class="text"
        >
          {{ option.label }}
        </text>
        <text
          v-for="(option, index) of options"
          :key="`option_${index}`"
          :x="textRight + imageWidth + barPadding"
          :y="textYPosition(index)"
          class="text"
          text-anchor="end"
        >
          {{ percent(option.value) }}
        </text>
      </svg>
    </v-col>
  </v-row>
</template>

<script>
import { mapState } from 'vuex';
import { translateStringOrFallback } from '@/utils/language';

export default {
  name: 'MultipleChoiceImageVisualisation',
  props: {
    questionStat: {
      required: true,
      type: Object
    },
    displayLanguage: {
      required: true,
      type: String
    }
  },
  data: () => ({
    barHeight: 48,
    barPadding: 4,
    textLeft: 16,
    textSize: 12,
    imageWidth: 64
  }),
  computed: {
    ...mapState('stats', ['stats']),
    primaryLanguage() {
      return this.stats.languages[0];
    },
    maxBarWidth() {
      const breakpoint = this.$vuetify.breakpoint.name;
      return breakpoint == 'xl'
        ? 700
        : breakpoint == 'lg'
        ? 500
        : 400;
    },
    textRight() {
      return (
        this.maxBarWidth -
        this.imageWidth -
        this.barPadding -
        this.textLeft
      );
    },
    visualisationHeight() {
      return (this.barHeight + this.barPadding) * this.options.length;
    },
    options() {
      const totalResponses = this.questionStat.totalResponses;
      return this.questionStat.wordSelections.map(option => ({
        label: translateStringOrFallback(
          option.word,
          this.displayLanguage,
          this.survey ? this.primaryLanguage : this.displayLanguage
        ).string,
        value: option.count / totalResponses
      }));
    },
    imageUrls() {
      return this.questionStat.urls;
    }
  },
  methods: {
    barWidth(value) {
      return (
        (this.maxBarWidth - this.imageWidth - this.barPadding) * value
      );
    },
    percent(value) {
      return `${(value * 100).toFixed(0)}%`;
    },
    barYPosition(index) {
      return index * (this.barHeight + this.barPadding);
    },
    textYPosition(index) {
      return (
        index * (this.barHeight + this.barPadding) +
        this.textSize * 0.5 +
        this.barHeight * 0.5
      );
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../scss/main.scss';
.background {
  fill: map-get($grey, 'lighten-1');
}
.text {
  fill: map-get($shades, 'white');
  font-weight: bold;
}
</style>
