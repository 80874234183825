<template>
  <v-row>
    <v-col
      v-for="(iconOption, index) in iconOptions"
      :key="index"
      cols="12"
      md="6"
      class="d-flex justify-center pa-0"
    >
      <v-card flat class="text-center h-100">
        <v-card-text class="pt-0">
          <component
            :is="componentMap[iconOption]"
            class="icon"
            :class="componentMap[iconOption]"
          />
          <div class="text-h6 font-weight-bold black--text">
            {{ getNumber(iconOption) }}
          </div>
          <div class="text-subtitle-1">
            {{ getAverage(iconOption) }}
          </div>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import Tick from '!!vue-svg-loader!../../../assets/svgs/Tick.svg';
import Cross from '!!vue-svg-loader!../../../assets/svgs/Cross.svg';
import { questionVisualisationHelper } from '@/mixins/SuperUtils';

export default {
  components: {
    Tick,
    Cross
  },
  mixins: [questionVisualisationHelper],
  props: {
    questionStat: {
      required: true,
      type: Object
    }
  },
  computed: {
    componentMap() {
      return {
        tick: 'tick',
        cross: 'cross'
      };
    },
    iconOptions() {
      return Object.keys(this.componentMap);
    }
  },
  methods: {
    getAverage(icon) {
      return this.formatPercent(
        this.calculateAvgFor(
          icon === 'tick'
            ? this.questionStat.numberOfYes
            : this.questionStat.numberOfNo
        )
      );
    },
    getNumber(icon) {
      return icon === 'tick'
        ? this.questionStat.numberOfYes
        : this.questionStat.numberOfNo;
    }
  }
};
</script>

<style scoped lang="scss">
@import '../../../scss/main.scss';
svg {
  height: 150px;

  &.icon {
    max-width: 50%;
  }

  &.icon.tick path {
    fill: var(--v-primary-base);
  }

  &.icon.cross path {
    fill: map-get($grey, 'lighten-1');
  }
}
</style>
