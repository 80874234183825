<template>
  <v-row>
    <v-col
      v-for="(circle, index) in circles"
      :key="index"
      cols="12"
      md="6"
      class="d-flex justify-center py-0"
    >
      <v-card flat class="text-center" height="100%">
        <v-card-text class="py-0">
          <circle-svg
            :size="200"
            :scale="circle.average"
            :colors="circleColor(circle)"
          />
          <div class="text-h6 black--text font-weight-bold">
            <localised-text
              :language="displayLanguage"
              :string="circle.label"
            />
          </div>
          <div class="text-h6">
            {{ formatPercent(circle.average) }}
          </div>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import colors from 'vuetify/lib/util/colors';
import CircleSvg from '@/components/Visualisations/CircleSvg';
import { questionVisualisationHelper } from '@/mixins/SuperUtils';
import LocalisedText from '@/components/Common/LocalisedText';

export default {
  components: {
    CircleSvg,
    LocalisedText
  },
  mixins: [questionVisualisationHelper],
  props: {
    questionStat: {
      required: true,
      type: Object
    },
    displayLanguage: {
      type: String,
      required: true
    }
  },
  computed: {
    circles() {
      return [
        {
          average: this.questionStat.average,
          majority: this.questionStat.average > 0.5,
          label: this.questionStat.leftWord
        },
        {
          average: 1 - this.questionStat.average,
          majority: this.questionStat.average < 0.5,
          label: this.questionStat.rightWord
        }
      ];
    }
  },
  methods: {
    circleColor(circle) {
      return [
        {
          offset: 0,
          stopColor: circle.majority
            ? this.$vuetify.theme.defaults.light.primary
            : colors.grey.lighten1
        }
      ];
    }
  }
};
</script>
