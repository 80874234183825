import Vue from 'vue';
import Vuetify from 'vuetify';

import 'vuetify/dist/vuetify.min.css';
import '../scss/main.scss';
import colors from 'vuetify/lib/util/colors';

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: 'md'
  },
  theme: {
    themes: {
      light: {
        primary: '#B34C79',
        secondary: '#1BCF88',
        accent: '#6A295F',
        error: colors.red.lighten1,
        success: '#009688',
        // primary with 50% opacity
        'primary-transparent': '#DAABBE'
      }
    },
    options: {
      customProperties: true
    }
  }
});
