<template>
  <v-row>
    <v-col cols="10">
      <word-list-config
        :words="words"
        :disabled="questionHasResponses"
        @words-updated="wordsUpdated"
      />
    </v-col>

    <v-col cols="2">
      <v-row class="d-flex flex-row-reverse">
        <word-settings-controller
          :count="wordCount"
          :range="config.wordRange"
          :disabled="questionHasResponses"
          title="Words"
          @set-value="setWordCount"
        />
        <word-settings-controller
          :count="slotCount"
          :range="config.slotRange"
          :disabled="questionHasResponses"
          title="Slots"
          @set-value="setSlotCount"
        />
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from 'vuex';
import WordListConfig from './WordListConfig';
import WordSettingsController from './WordSettingsController';
export default {
  name: 'TopWordsConfig',
  components: { WordListConfig, WordSettingsController },
  props: {
    value: {
      type: Object,
      required: true
    },
    questionHasResponses: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      words: this.value.options,
      wordCount: this.value.options.length,
      slotCount: this.value.slotCount,
      config: {
        wordRange: { min: 3, max: 8 },
        slotRange: { min: 1, max: 4 }
      }
    };
  },
  computed: {
    ...mapGetters('survey', ['primaryLanguage'])
  },
  methods: {
    configUpdated() {
      const config = {
        options: this.words,
        slotCount: this.slotCount,
        questionType: this.value.questionType
      };
      this.$emit('input', config);
      this.$emit('change', config);
    },
    wordsUpdated() {
      this.configUpdated();
    },
    setSlotCount(slotCount) {
      this.slotCount = slotCount;
      this.configUpdated();
    },
    setWordCount(wordCount) {
      this.wordCount = wordCount;
      this.adjustWordInputsToMatchCount();
      this.configUpdated();
    },
    adjustWordInputsToMatchCount() {
      if (this.words.length < this.wordCount) {
        while (this.words.length < this.wordCount) {
          this.addEmptyWord();
        }
      } else if (this.words.length > this.wordCount) {
        while (this.words.length > this.wordCount) {
          this.removeLastWord();
        }
      }
    },
    addEmptyWord() {
      this.words.push({
        languageMap: { [this.primaryLanguage]: '' }
      });
    },
    removeLastWord() {
      this.words.pop();
    }
  }
};
</script>
