<template>
  <div class="d-flex">
    <v-select
      v-model="selectedType"
      v-bind="$attrs"
      class="text-body-2"
      :items="questionTypes"
      item-value="value"
      item-text="displayName"
      label="Question Type"
      @input="changedType"
    >
      <template v-if="selectedType" v-slot:selection="{ item }">
        <question-type-icon
          :question-type="item.value"
          :size="18"
          class="mr-2"
        />
        <span>{{ item.displayName }}</span>
      </template>
      <template v-slot:item="{ item }">
        <question-type-icon
          :question-type="item.value"
          class="mr-3"
        />
        <span>{{ item.displayName }}</span>
      </template>
    </v-select>
    <div class="pt-3">
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn
            icon
            v-on="on"
            @click.prevent="showQuestionTypeSelectorDialog()"
          >
            <v-icon>open_in_new</v-icon>
          </v-btn>
        </template>
        <span>Select via dialog</span>
      </v-tooltip>
    </div>
  </div>
</template>

<script>
import QuestionTypeIcon from '@/components/Common/QuestionTypeIcon';
import { QuestionTypes } from '@/utils/QuestionTypeConfig';
import QuestionTypeConfig from '@/utils/QuestionTypeConfig';
export default {
  name: 'QuestionTypeSelector',
  components: {
    QuestionTypeIcon
  },
  props: {
    value: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      selectedType: this.value
    };
  },
  computed: {
    questionTypes() {
      return QuestionTypes.map(key => ({
        value: key,
        ...QuestionTypeConfig[key]
      }));
    }
  },
  watch: {
    value: {
      handler(newType) {
        this.selectedType = newType;
      }
    }
  },
  methods: {
    showQuestionTypeSelectorDialog() {
      this.$emit(
        'show-question-type-selector-dialog',
        this.selectedType
      );
    },
    changedType() {
      this.$emit('input', this.selectedType);
      this.$emit('change', this.selectedType);
    }
  }
};
</script>
