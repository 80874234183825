<template>
  <div>
    <div
      v-for="(item, i) in items"
      :key="`emotion-${i}`"
      class="legend-item d-flex mb-12 align-start"
      :class="legendItemClass"
    >
      <!-- NOTE: flex order determines whether label shows on left or right of percentage -->
      <p class="emotion-label text-left text-h3 my-0">
        <localised-text
          :language="displayLanguage"
          :string="item.label"
          :color="textColor"
        />
      </p>
      <div
        class="slice-percentage font-weight-bold text-h3 mx-16"
        :class="slicePercentageClass"
        :style="slicePercentageStyle(item)"
      >
        {{ item.value }}
      </div>
    </div>
  </div>
</template>

<script>
import LocalisedText from '@/components/Common/LocalisedText';

const Direction = {
  LEFT: 'left',
  RIGHT: 'right'
};

export default {
  name: 'EmotionWheelLegend',
  components: {
    LocalisedText
  },
  props: {
    items: {
      type: Array,
      required: true
    },
    displayLanguage: {
      type: String,
      required: true
    },
    left: {
      type: Boolean,
      default: false
    },
    right: {
      type: Boolean,
      default: false
    },
    textColor: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      // Assume left if right is not true (to cover absence of both)
      direction: this.right ? Direction.RIGHT : Direction.LEFT
    };
  },
  computed: {
    legendItemClass() {
      return this.direction;
    },
    slicePercentageClass() {
      return this.direction === Direction.LEFT ? 'ml-10' : 'mr-10';
    }
  },
  methods: {
    slicePercentageStyle(slice) {
      return `color: ${slice.colour}`;
    }
  }
};
</script>
<style lang="scss" scoped>
.slice-percentage {
  width: 2.5em;
}

.legend-item.left {
  justify-content: flex-end;

  .emotion-label {
    order: 1;
  }

  .slice-percentage {
    order: 2;
    text-align: right;
  }
}

.legend-item.right {
  justify-content: flex-start;

  .slice-percentage {
    order: 1;
  }

  .emotion-label {
    order: 2;
    text-align: left;
  }
}
</style>
