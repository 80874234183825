<template>
  <div>
    <language-avatar :language="language" />
    <span class="language ml-2">{{ language.name }}</span>
  </div>
</template>

<script>
import LanguageAvatar from './LanguageAvatar';

export default {
  name: 'LanguageItem',
  components: { LanguageAvatar },
  props: {
    language: {
      type: Object,
      required: true
    }
  }
};
</script>

<style scoped>
.language {
  line-height: 28px;
}
</style>
