var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("page-header-bar", [
        _c("div", { staticClass: "d-flex p-relative" }, [
          _c(
            "div",
            { staticClass: "flex-grow-1 d-flex justify-space-between" },
            [
              _c("h2", { staticClass: "white--text font-weight-medium" }, [
                _vm._v(" Organisations ")
              ]),
              _c(
                "div",
                { staticClass: "justify-center" },
                [
                  _vm.currentUserIsSuper
                    ? _c(
                        "v-btn",
                        {
                          attrs: { color: "accent" },
                          on: {
                            click: function($event) {
                              _vm.organisationDialog = true
                            }
                          }
                        },
                        [_vm._v(" Create Organisation ")]
                      )
                    : _vm._e()
                ],
                1
              )
            ]
          )
        ])
      ]),
      _c(
        "v-container",
        { staticClass: "pa-8" },
        [
          _c("organisation-list", {
            attrs: { organisations: _vm.organisations, loading: !_vm.loaded }
          })
        ],
        1
      ),
      _c("organisation-dialog", {
        attrs: { show: _vm.organisationDialog },
        on: {
          "update:show": function($event) {
            _vm.organisationDialog = $event
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }