var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "p",
      {
        class: [
          "mt-3 mb-2 grey--text text-caption",
          !_vm.disabled ? "text--darken-2" : ""
        ]
      },
      [_vm._v(" " + _vm._s(_vm.title) + " ")]
    ),
    _c(
      "div",
      { staticClass: "d-flex align-center" },
      [
        _c(
          "v-btn",
          {
            staticClass: "square",
            attrs: { disabled: _vm.disabled || _vm.isMin, small: "" },
            on: {
              click: function($event) {
                return _vm.update(--_vm.number)
              }
            }
          },
          [_c("v-icon", { attrs: { small: "" } }, [_vm._v("remove")])],
          1
        ),
        _c(
          "p",
          {
            class: [
              "my-0",
              "mx-3",
              "text-body-2",
              _vm.disabled ? "grey--text" : ""
            ]
          },
          [_vm._v(" " + _vm._s(_vm.number) + " ")]
        ),
        _c(
          "v-btn",
          {
            staticClass: "square",
            attrs: { disabled: _vm.disabled || _vm.isMax, small: "" },
            on: {
              click: function($event) {
                return _vm.update(++_vm.number)
              }
            }
          },
          [_c("v-icon", { attrs: { small: "" } }, [_vm._v("add")])],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }