var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    { attrs: { value: _vm.showDialog, persistent: "", "max-width": "665" } },
    [
      _c(
        "v-card",
        [
          _c("v-card-title", { staticClass: "pb-0" }, [
            _vm._v("Duplicate Survey")
          ]),
          _c("v-card-text", [
            _vm._v(" Duplicating "),
            _c("span", { staticClass: "font-weight-bold" }, [
              _vm._v(_vm._s(_vm.survey.title))
            ])
          ]),
          _c(
            "v-tabs",
            {
              staticClass: "px-6",
              attrs: { grow: "", "active-class": "active", "hide-slider": "" },
              model: {
                value: _vm.activeTab,
                callback: function($$v) {
                  _vm.activeTab = $$v
                },
                expression: "activeTab"
              }
            },
            [
              _c(
                "v-tab",
                [
                  _c("v-icon", { attrs: { left: "" } }, [_vm._v("edit")]),
                  _c("span", [_vm._v("Survey Details")])
                ],
                1
              ),
              _c(
                "v-tooltip",
                {
                  attrs: { bottom: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function({ on }) {
                        return [
                          _c(
                            "div",
                            _vm._g(
                              { staticClass: "d-flex flex-grow-1" },
                              _vm.hasNoQuestions ? on : {}
                            ),
                            [
                              _c(
                                "v-tab",
                                { attrs: { disabled: _vm.hasNoQuestions } },
                                [
                                  _c("v-icon", { attrs: { left: "" } }, [
                                    _vm._v("format_list_numbered")
                                  ]),
                                  _c("span", [
                                    _vm._v(_vm._s(_vm.questionTabTitle))
                                  ])
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ]
                      }
                    }
                  ])
                },
                [_c("span", [_vm._v(" This survey has no questions ")])]
              )
            ],
            1
          ),
          _c(
            "v-tabs-items",
            {
              model: {
                value: _vm.activeTab,
                callback: function($$v) {
                  _vm.activeTab = $$v
                },
                expression: "activeTab"
              }
            },
            [
              _c(
                "v-tab-item",
                [
                  _c("survey-form", {
                    ref: "form",
                    attrs: {
                      survey: _vm.survey,
                      organisations: _vm.organisations,
                      questions: _vm.questions,
                      duplicate: ""
                    },
                    on: {
                      close: function($event) {
                        return _vm.$emit("update:show", false)
                      }
                    }
                  })
                ],
                1
              ),
              _c(
                "v-tab-item",
                [
                  _c("duplicate-question-list", {
                    staticClass: "mx-6 scroll-area",
                    attrs: {
                      questions: _vm.questions,
                      "primary-language": _vm.survey.primaryLanguage
                    },
                    on: { "toggle-duplication": _vm.toggleDuplication }
                  }),
                  _c(
                    "v-card-actions",
                    { staticClass: "pa-6" },
                    [
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          staticClass: "mr-0",
                          attrs: { color: "error", outlined: "" },
                          on: { click: _vm.closeForm }
                        },
                        [_vm._v(" Cancel ")]
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass: "ml-4",
                          attrs: { color: "success" },
                          on: { click: _vm.submit }
                        },
                        [_vm._v(" Duplicate ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }