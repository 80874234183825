var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("localised-text", {
        attrs: {
          language: _vm.displayLanguage,
          string: _vm.questionStat.values
        }
      }),
      _c("canvas", { ref: "bar-chart" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }