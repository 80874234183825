<template>
  <div v-if="hasMultipleLanguages" class="d-flex">
    <transition-group name="language-status" appear>
      <span
        v-for="language in languageStatus"
        :key="language.code"
        class="language-status-item"
        :class="{
          highlighted: highlighted(language),
          clickable: clickable(language),
          'mr-1': !lastLanguage(language)
        }"
        @click="$emit('language-clicked', language.code)"
      >
        <language-avatar
          :language="language"
          :color="color(language)"
        />
      </span>
    </transition-group>
  </div>
</template>

<script>
import LanguageAvatar from './LanguageAvatar';

export default {
  name: 'SurveyLanguageStatus',
  components: { LanguageAvatar },
  props: {
    languageStatus: {
      type: Array,
      required: true
    },
    highlight: {
      type: String,
      required: false,
      default: undefined
    },
    showCompleted: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    hasMultipleLanguages() {
      return this.languageStatus.length > 1;
    },
    hasClickListener() {
      return !!this.$listeners['language-clicked'];
    }
  },
  methods: {
    clickable(language) {
      return this.hasClickListener && !this.highlighted(language);
    },
    highlighted(language) {
      return language.code == this.highlight;
    },
    lastLanguage(language) {
      return (
        this.languageStatus.indexOf(language) ==
        this.languageStatus.length - 1
      );
    },
    color(language) {
      if (this.hasClickListener && language.code === this.highlight) {
        return 'primary';
      } else {
        return this.showCompleted && language.complete
          ? 'success'
          : undefined;
      }
    }
  }
};
</script>

<style scoped lang="scss">
.highlighted {
  cursor: pointer;
  opacity: 100%;
}

.clickable {
  cursor: pointer;
  opacity: 65%;
  &:hover {
    opacity: 100%;
  }
}
.language-status-item {
  transition: all 0.5s;
}
.language-status-enter,
.language-status-leave-to {
  opacity: 0;
  transform: translateY(30px);
}
.language-status-leave-active {
  position: absolute;
}
</style>
