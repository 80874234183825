import Question from '../../../models/Question';
import Vue from 'vue';

const mutations = {
  setSurveys: (state, surveys) => {
    state.surveys = surveys;
  },
  setSurvey: (state, survey) => {
    survey.questions = survey.questions.map(
      question => new Question({ ...question, survey })
    );

    // Before setting a survey, check to see if there is already a survey.
    // This needs to happen because there might be new questions with that
    // unsaved changes we don't want to lose.
    if (state.survey) {
      preserveQuestionsWithUnsavedChanges();
    }

    state.survey = survey;

    // Helper function to ensure that questions with unsaved changes
    // aren't lost when fetching a survey
    function preserveQuestionsWithUnsavedChanges() {
      // Get any questions that have unsaved changes that don't have an id.
      // unsavedQuestionCreations represents a list of new questions that haven't been
      // created yet.
      let unsavedQuestionCreations = state.survey.questions.filter(
        question => !question.id && question.hasUnsavedChanges
      );

      // Get any questions that have unsaved changes, that have an id
      // and have not been sent off to the server yet to be updated with
      // new values - these are questions that are being updated but are
      // yet to be finalised
      let unsavedQuestionUpdates = state.survey.questions.filter(
        question => question.id && question.hasUnsavedChanges
      );

      // If there are any unsaved updates updates, we want to replace the matching
      // questions by id in the survey with those that are unsaved updates.
      // This is to ensure that you don't lose all unsaved updates upon creating
      // or updating a question
      unsavedQuestionUpdates.forEach(update => {
        let found = survey.questions.findIndex(
          question => question.id == update.id
        );
        if (found > -1) survey.questions[found] = update;
      });

      // If there are any unsaved new questions, we want to add those back to
      // the survey's question list
      if (unsavedQuestionCreations) {
        survey.questions = [
          ...survey.questions,
          ...unsavedQuestionCreations
        ];
      }
    }
  },
  setLoaded: (state, loaded) => (state.loaded = loaded),
  addSurvey: (state, survey) => state.surveys.push(survey),
  removeSurvey: (state, id) =>
    (state.surveys = state.surveys.filter(
      survey => survey.id !== id
    )),
  updateSurvey: (state, survey) => {
    if (state.loaded) {
      const index = state.surveys.indexOf(
        state.surveys.find(update => update.id === survey.id)
      );
      Vue.set(state.surveys, index, survey);
    } else {
      state.survey = { ...state.survey, ...survey };
    }
  },
  addNewQuestion: (state, question) => {
    Vue.set(
      state.survey.questions,
      state.survey.questions.length,
      question
    );
  },
  removeQuestion: (state, question) => {
    const index = state.survey.questions.indexOf(question);
    state.survey.questions.splice(index, 1);
  }
};

export default mutations;
