var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("action-modal", {
    attrs: { title: "Clear Responses", show: _vm.show },
    on: {
      cancel: function($event) {
        return _vm.$emit("cancel")
      }
    },
    scopedSlots: _vm._u([
      {
        key: "body",
        fn: function() {
          return [
            _c("v-card-text", [
              _vm._v(" This survey has "),
              _c("span", { staticClass: "font-weight-bold black--text" }, [
                _vm._v(" " + _vm._s(_vm.survey.responseCount))
              ]),
              _vm._v(
                " responses. If you choose to clear the responses, they will no longer be accessible and the data will be permanently deleted. "
              )
            ])
          ]
        },
        proxy: true
      },
      {
        key: "actions",
        fn: function() {
          return [
            _c(
              "v-btn",
              {
                attrs: { color: "error" },
                on: {
                  click: function($event) {
                    return _vm.$emit("clear-responses")
                  }
                }
              },
              [_vm._v(" Clear Responses ")]
            )
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }