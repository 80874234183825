<template>
  <v-tooltip top>
    <template v-slot:activator="{ on }">
      <div v-on="on">
        <v-icon left color="amber">warning</v-icon>
        <slot>{{ message }}</slot>
      </div>
    </template>
    <span>{{ message }}</span>
  </v-tooltip>
</template>

<script>
export default {
  name: 'MissingTranslation',
  props: {
    language: {
      type: String,
      required: true
    }
  },
  computed: {
    languages() {
      return {
        AR: 'Arabic',
        DE: 'German',
        EN: 'English',
        FR: 'French',
        IT: 'Italian'
      };
    },
    message() {
      return `Missing ${this.languages[this.language]} translation`;
    }
  }
};
</script>
