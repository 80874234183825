export default {
  namespaced: true,
  state: {
    config: {}
  },
  mutations: {
    addConfiguration(state, { key, config }) {
      state.config[key] = config;
    }
  }
};
