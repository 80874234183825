<template functional>
  <v-card class="pa-5 px-12 mb-4">
    <div class="pt-5">
      <v-card-title>iPad Login Details</v-card-title>
      <v-card-text>
        <v-col>
          <v-row class="">
            iPad Username
          </v-row>
          <v-row class="black--text mt-2">
            <v-skeleton-loader type="text@1" min-width="200px" />
          </v-row>
        </v-col>
      </v-card-text>
    </div>
    <div class="pt-5">
      <v-card-title>Users</v-card-title>
      <v-card-text>
        <v-simple-table>
          <template>
            <thead>
              <tr>
                <th
                  v-for="header in ['Name', 'Email', 'Role']"
                  :key="header"
                  class="text-left"
                >
                  {{ header }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="x in 5" :key="x">
                <td v-for="y in 3" :key="y">
                  <v-skeleton-loader type="text" />
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>
    </div>
  </v-card>
</template>

<script>
export default {
  name: 'OrganisationSettingsLoader'
};
</script>
