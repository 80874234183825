import Vue from 'vue';
import Vuex from 'vuex';
import organisation from './modules/organisation/index';
import question from './modules/question/index';
import survey from './modules/survey/index';
import stats from './modules/stats/index';
import config from './modules/config';
import questionResponses from './modules/questionResponses';
import publicDisplay from './modules/publicDisplay';
import publicDashboard from './modules/publicDashboard';
import currentUser from './modules/currentUser';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    // Modules here
    organisation,
    question,
    survey,
    stats,
    config,
    questionResponses,
    publicDisplay,
    publicDashboard,
    currentUser
  }
});
