import ApiService from '../../../api';

const actions = {
  fetchSurveys: {
    handler(context) {
      // Put up the full page loading indicators if we have zero data.
      if (context.state.surveys.length <= 0) {
        context.commit('setLoaded', false);
      }
      return ApiService.getApi()
        .get('survey')
        .then(response => {
          context.commit('setSurveys', response.data);
          context.commit('setLoaded', true);
        });
    }
  },
  fetchSurvey: {
    handler: function(context, surveyId) {
      return ApiService.getApi()
        .get('survey', surveyId)
        .then(response => {
          const survey = response.data;
          context.commit('setSurvey', survey);
          return survey;
        });
    }
  },
  addSurvey: {
    handler(context, survey) {
      return ApiService.getApi()
        .post('survey', survey)
        .then(response => {
          context.commit('addSurvey', response.data);
          return response.data;
        });
    }
  },
  updateSurvey: {
    handler(context, survey) {
      // This kind of nastiness can be avoided
      // by implementing a serialize method on the models
      const surveyData = { ...survey };
      if (survey.questions) {
        surveyData.questions = survey.questions.map(question =>
          Object.assign(
            {},
            ...Object.keys(question)
              .filter(key => key != 'survey')
              .map(key => ({ [key]: question[key] }))
          )
        );
      }
      context.commit('updateSurvey', survey);
      return ApiService.getApi().put('survey', survey.id, surveyData);
    }
  },
  duplicateSurvey: {
    handler(context, data) {
      const survey = {};
      Object.keys(data.survey).forEach(key => {
        if (key != 'questions') survey[key] = data.survey[key];
      });
      return ApiService.getApi()
        .post(`survey/duplicate?id=${data.id}`, survey)
        .then(response => {
          context.commit('addSurvey', response.data);
          return response.data;
        });
    }
  },
  clearResponses: {
    handler(context, survey) {
      return ApiService.getApi()
        .delete(`surveyResponses`, survey.id)
        .then(response => {
          context.commit('updateSurvey', response.data);
          return response.data;
        });
    }
  },
  deleteSurvey: {
    handler(context, surveyId) {
      return ApiService.getApi()
        .delete('survey', surveyId)
        .then(context.commit('removeSurvey', surveyId));
    }
  },
  addNewQuestion: {
    handler(context, question) {
      context.commit('addNewQuestion', question);
      return question;
    }
  },
  removeQuestion: {
    handler(context, question) {
      context.commit('removeQuestion', question);
      return question;
    }
  },
  exportResponseData: {
    handler(context, payload) {
      return ApiService.getApi().getSurveyExport(
        payload.survey,
        payload.body
      );
    }
  },
  fetchExportJobs: {
    handler(context, survey) {
      return ApiService.getApi().getSurveyExportJobs(survey);
    }
  }
};

export default actions;
