<template>
  <div>
    <div
      class="heart-container d-flex flex-column justify-center mx-auto"
    >
      <filled-heart
        :fill-percent="questionStat.average"
        class="filled-heart"
        :fill-color="theme.heart.fillColor"
        :background-color="theme.heart.backgroundColor"
      />
      <div
        class="text-h3 white--text text-center font-weight-bold pt-12"
        :class="theme.textColor"
      >
        {{ formatPercent(questionStat.average) }}
      </div>
    </div>
  </div>
</template>

<script>
import FilledHeart from '@/components/Visualisations/FilledHeart';
import { questionVisualisationHelper } from '../../mixins/SuperUtils';

export default {
  components: { FilledHeart },
  mixins: [questionVisualisationHelper],
  props: {
    questionStat: {
      required: true,
      type: Object
    },
    theme: {
      required: true,
      type: Object
    }
  }
};
</script>

<style lang="scss" scoped>
.heart-container {
  width: 50%;
}

.filled-heart {
  height: 60%;
  width: 60%;
  left: 50%;
  transform: translateX(-50%);
}
</style>
