var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.localisedString.translated
    ? _c(
        "span",
        { staticClass: "mt-1" },
        [
          _c("missing-translation", { attrs: { language: _vm.language } }, [
            _vm.localisedString.string
              ? _c("span", { style: _vm.wrapStyle }, [
                  _vm._v(" " + _vm._s(_vm.localisedString.string) + " ")
                ])
              : _vm._e()
          ])
        ],
        1
      )
    : _c("span", { class: _vm.colorClass, style: _vm.wrapStyle }, [
        _vm._v(" " + _vm._s(_vm.localisedString.string) + " ")
      ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }