export const EditableItem = {
  props: {
    editing: {
      type: Boolean,
      required: true
    }
  },
  methods: {
    edit() {
      if (!this.editing) {
        this.$emit('edit', this.$vnode.key);
      }
    },
    close() {
      if (this.editing) {
        this.$emit('close', this.$vnode.key);
      }
    }
  }
};
