<template>
  <labels-config
    :labels="labels"
    :input-labels="inputLabels"
    @labels-updated="configUpdated"
  />
</template>

<script>
import LabelsConfig from './LabelsConfig';
export default {
  name: 'CustomLabelsConfig',
  components: { LabelsConfig },
  props: {
    value: {
      type: Object,
      required: true
    },
    questionHasResponses: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return { labels: this.value.labels };
  },
  computed: {
    inputLabels() {
      return Object.keys(this.labels).map(label => `${label} label`);
    }
  },
  methods: {
    configUpdated() {
      const config = {
        questionType: this.value.questionType,
        labels: this.labels
      };
      this.$emit('input', config);
      this.$emit('change', config);
    }
  }
};
</script>
