<template>
  <div>
    <page-header-bar>
      <div class="d-flex p-relative">
        <div class="flex-grow-1 d-flex justify-space-between">
          <h2 class="white--text font-weight-medium">
            Organisations
          </h2>

          <div class="justify-center">
            <v-btn
              v-if="currentUserIsSuper"
              color="accent"
              @click="organisationDialog = true"
            >
              Create Organisation
            </v-btn>
          </div>
        </div>
      </div>
    </page-header-bar>
    <v-container class="pa-8">
      <organisation-list
        :organisations="organisations"
        :loading="!loaded"
      />
    </v-container>
    <organisation-dialog :show.sync="organisationDialog" />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import PageHeaderBar from '@/components/Common/PageHeaderBar';
import OrganisationList from '@/components/Organisation/OrganisationList';
import OrganisationDialog from '@/components/Organisation/OrganisationDialog';

export default {
  name: 'OrganisationsPage',
  components: {
    PageHeaderBar,
    OrganisationList,
    OrganisationDialog
  },
  data: () => ({
    organisationDialog: false
  }),
  computed: {
    ...mapGetters('organisation', ['organisations', 'loaded']),
    ...mapGetters('currentUser', ['currentUserIsSuper'])
  },
  mounted() {
    this.fetchOrganisations();
    this.fetchCurrentUser();
  },
  methods: {
    ...mapActions('organisation', ['fetchOrganisations']),
    ...mapActions('currentUser', ['fetchCurrentUser'])
  }
};
</script>
