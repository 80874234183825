<template>
  <v-card flat>
    <v-card-text class="pa-0">
      <v-row>
        <v-col
          v-for="(language, index) in languages"
          :key="index"
          class="pt-0 d-flex flex-column align-center"
        >
          <v-avatar
            :color="colorForLanguage(language)"
            size="100px"
            class="mb-3"
          >
            <span class="white--text text-h5">{{ language }}</span>
          </v-avatar>
          <div class="d-block text-center">
            <div class="text-h6 black--text">
              {{ languageResponses(language) }}
            </div>
            <div class="text-body-1">
              ({{ responsePercentage(language) }}%)
            </div>
          </div>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    questionStat: {
      required: true,
      type: Object
    }
  },
  computed: {
    languages() {
      return Object.keys(this.questionStat.languageCounts);
    },
    majorityLanguage() {
      let max = Math.max(
        ...Object.values(this.questionStat.languageCounts)
      );
      return this.languages.find(
        language => max == this.questionStat.languageCounts[language]
      );
    }
  },
  methods: {
    colorForLanguage(language) {
      return this.majorityLanguage.includes(language)
        ? 'primary'
        : 'grey';
    },
    responsePercentage(language) {
      const totalResponses = Object.values(
        this.questionStat.languageCounts
      ).reduce((a, b) => a + b);

      return Math.round(
        (this.languageResponses(language) / totalResponses) * 100
      );
    },
    languageResponses(language) {
      return this.questionStat.languageCounts[language];
    }
  }
};
</script>

<style scoped lang="scss">
@import '../../../scss/main.scss';
</style>
