<template>
  <div class="constrained-area-parent">
    <link
      v-for="(url, index) in urlsToUse"
      :key="index"
      rel="preload"
      :href="url"
      as="image"
    />

    <div :class="gridClass">
      <div v-for="(url, index) in urlsToUse" :key="index">
        <img :src="url" class="grid-img" />
      </div>
    </div>
  </div>
</template>

<script>
import { questionVisualisationHelper } from '../../mixins/SuperUtils';

export default {
  components: {},
  mixins: [questionVisualisationHelper],
  props: {
    questionStat: {
      required: true,
      type: Object
    },
    theme: {
      required: true,
      type: Object
    }
  },
  computed: {
    gridDimensions() {
      return (
        this.questionStat.publicDisplayOptions?.gridDimensions ??
        'FOUR_BY_THREE'
      );
    },
    urlCount() {
      return this.questionStat.urls.length;
    },
    urlsToUse() {
      return this.questionStat.urls.slice(
        0,
        this.activeConfig.maxUrlCount
      );
    },
    gridConfigs() {
      return [
        {
          key: 'FOUR_BY_THREE',
          class: 'four-by-three',
          minUrlCount: 7,
          maxUrlCount: 12
        },
        {
          key: 'THREE_BY_TWO',
          class: 'three-by-two',
          fallbackFor: ['FOUR_BY_THREE'],
          minUrlCount: 2,
          maxUrlCount: 6
        },
        {
          key: 'ONE_BY_ONE_FULLSCREEN',
          class: 'one-by-one-fullscreen',
          minUrlCount: 1,
          maxUrlCount: 1
        },
        {
          key: 'ONE_BY_ONE',
          class: 'one-by-one',
          fallbackFor: ['FOUR_BY_THREE', 'THREE_BY_TWO'],
          minUrlCount: 1,
          maxUrlCount: 1
        }
      ];
    },
    activeConfig() {
      for (const config of this.gridConfigs) {
        const isTargetConfig = this.gridDimensions === config.key;
        const validFallback =
          config.fallbackFor &&
          config.fallbackFor.includes(this.gridDimensions);
        const meetsUrlCount = this.urlCount >= config.minUrlCount;
        if (meetsUrlCount && (isTargetConfig || validFallback)) {
          return config;
        }
      }

      // Should not reach here, as a grid config should match
      return this.gridConfigs.find(
        config => config.key === 'ONE_BY_ONE'
      );
    },
    gridClass() {
      return this.activeConfig.class;
    }
  }
};
</script>

<style lang="scss" scoped>
// Sizing of parent area
.constrained-area-parent {
  max-height: 100%;
  max-width: 100%;
  position: relative;
}

.four-by-three > div > .grid-img {
  width: 100%;
  max-width: 100%;
  height: 22vh;
  max-height: 22vh;
  object-fit: contain;
}

.three-by-two > div > .grid-img {
  width: 100%;
  max-width: 100%;
  height: 35vh;
  max-height: 35vh;
  object-fit: contain;
}

.one-by-one > div > .grid-img {
  width: 100%;
  max-width: 100%;
  max-height: 75vh;
  object-fit: contain;
}

.one-by-one-fullscreen > div {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.one-by-one-fullscreen > div > .grid-img {
  height: 100%;
  max-width: 100%;
  object-fit: contain;
}

// Define dimenions of columns
.one-by-one {
  display: grid;
  grid-template-columns: 1fr;
}

.one-by-one-fullscreen {
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
  width: 100%;
  height: 100%;
}

.three-by-two {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  min-width: 0;
  max-width: 100%;
  max-height: 100%;
  gap: 10px;
}

.four-by-three {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  min-width: 0;
  max-width: 100%;
  max-height: 100%;
  gap: 10px;
}
</style>
