var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.editing
    ? _c(
        "v-card",
        {
          staticClass: "p-relative pa-4 mb-4",
          attrs: { hover: "" },
          on: {
            click: function($event) {
              $event.stopPropagation()
              return _vm.openForEditing()
            }
          }
        },
        [
          _vm.question.hasUnsavedChanges
            ? _c(
                "v-tooltip",
                {
                  attrs: { top: "" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "activator",
                        fn: function({ on }) {
                          return [
                            _c(
                              "div",
                              _vm._g(
                                {
                                  staticClass:
                                    "unsaved-changes-badge p-absolute"
                                },
                                on
                              ),
                              [
                                _c("v-badge", {
                                  attrs: { color: "red", dot: "" }
                                })
                              ],
                              1
                            )
                          ]
                        }
                      }
                    ],
                    null,
                    false,
                    247335280
                  )
                },
                [_c("span", [_vm._v("Question is missing required details")])]
              )
            : _vm._e(),
          _c(
            "div",
            { staticClass: "d-flex justify-space-between align-center" },
            [
              _c("div", { class: [_vm.questionDataStyle.index, "mr-5"] }, [
                _vm._v(" " + _vm._s(_vm.index) + ". ")
              ]),
              _c("div", { staticClass: "flex-grow-1" }, [
                _c(
                  "div",
                  {
                    class: [
                      _vm.questionDataStyle.text,
                      "text-h6 grey--text text--darken-3 mb-2"
                    ]
                  },
                  [_vm._v(" " + _vm._s(_vm.questionText) + " ")]
                ),
                _c(
                  "div",
                  {
                    class: _vm.questionTypeStyles,
                    on: {
                      click: function($event) {
                        $event.stopPropagation()
                        return _vm.handleTypeClicked.apply(null, arguments)
                      }
                    }
                  },
                  [
                    _c(
                      "question-type-icon",
                      _vm._b(
                        {
                          staticClass: "mr-2 question-type-icon",
                          attrs: { "question-type": _vm.questionType.value }
                        },
                        "question-type-icon",
                        _vm.questionTypeIconProps,
                        false
                      )
                    ),
                    _c("span", { class: _vm.questionDataStyle.type }, [
                      _vm._v(_vm._s(_vm.questionType.displayName))
                    ])
                  ],
                  1
                )
              ]),
              _c("div", { class: [_vm.questionDataStyle.theme] }, [
                _vm._v(" " + _vm._s(_vm.questionTheme) + " ")
              ])
            ]
          )
        ],
        1
      )
    : _c("question-form", {
        staticClass: "mb-4",
        attrs: {
          question: _vm.question,
          index: _vm.index,
          "target-language": _vm.targetLanguage
        },
        on: {
          "close-question-form": _vm.closeQuestionForm,
          "focus-duplicate": function($event) {
            return _vm.$emit("focus-duplicate", $event)
          }
        }
      })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }