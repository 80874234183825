<template>
  <v-row class="pa-2" no-gutters :class="componentContainerClasses">
    <v-col cols="auto" class="font-weight-bold fixed">
      {{ index }}.
    </v-col>
    <v-col class="d-flex justify-space-between">
      <div>
        <div class="font-weight-bold">{{ title }}</div>
        <div class="text-subtitle-2">
          <question-type-icon
            :size="16"
            :color="questionTypeIconColour"
            :question-type="type.value"
          />
          <span :class="questionTypeTextColour">
            {{ type.name }}
          </span>
        </div>
      </div>
      <div class="my-auto mr-2">
        <v-btn
          min-width="20"
          max-width="20"
          x-small
          class="px-0"
          :color="duplicate ? 'error' : 'success'"
          @click="$emit('toggle-duplication')"
        >
          <v-icon small>{{
            includeDuplicateQuestionToggleIcon
          }}</v-icon>
        </v-btn>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import QuestionTypeIcon from '@/components/Common/QuestionTypeIcon';

export default {
  name: 'DuplicateQuestionListItem',
  components: {
    QuestionTypeIcon
  },
  props: {
    index: {
      type: Number,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    type: {
      type: Object,
      required: true
    },
    duplicate: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    includeDuplicateQuestionToggleIcon() {
      return this.duplicate ? 'remove' : 'add';
    },
    componentContainerClasses() {
      return this.duplicate
        ? null
        : 'grey lighten-3 grey--text text--lighten-1';
    },
    questionTypeTextColour() {
      return this.duplicate
        ? 'grey--text text--darken-1'
        : 'grey--text text--lighten-1';
    },
    questionTypeIconColour() {
      return this.duplicate ? 'grey darken-1' : 'grey lighten-1';
    }
  }
};
</script>

<style lang="scss" scoped>
.fixed {
  flex-basis: 32px;
}
</style>
