<template>
  <v-dialog :value="showDialog" persistent max-width="665">
    <v-card>
      <v-card-title class="pb-0">Duplicate Survey</v-card-title>
      <v-card-text>
        Duplicating
        <span class="font-weight-bold">{{ survey.title }}</span>
      </v-card-text>
      <v-tabs
        v-model="activeTab"
        grow
        class="px-6"
        active-class="active"
        hide-slider
      >
        <v-tab>
          <v-icon left>edit</v-icon>
          <span>Survey Details</span>
        </v-tab>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <div
              class="d-flex flex-grow-1"
              v-on="hasNoQuestions ? on : {}"
            >
              <v-tab :disabled="hasNoQuestions">
                <v-icon left>format_list_numbered</v-icon>
                <span>{{ questionTabTitle }}</span>
              </v-tab>
            </div>
          </template>
          <span>
            This survey has no questions
          </span>
        </v-tooltip>
      </v-tabs>
      <v-tabs-items v-model="activeTab">
        <v-tab-item>
          <survey-form
            ref="form"
            :survey="survey"
            :organisations="organisations"
            :questions="questions"
            duplicate
            @close="$emit('update:show', false)"
          />
        </v-tab-item>
        <v-tab-item>
          <duplicate-question-list
            class="mx-6 scroll-area"
            :questions="questions"
            :primary-language="survey.primaryLanguage"
            @toggle-duplication="toggleDuplication"
          />
          <v-card-actions class="pa-6">
            <v-spacer></v-spacer>
            <v-btn
              color="error"
              class="mr-0"
              outlined
              @click="closeForm"
            >
              Cancel
            </v-btn>
            <v-btn color="success" class="ml-4" @click="submit">
              Duplicate
            </v-btn>
          </v-card-actions>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </v-dialog>
</template>

<script>
import SurveyForm from './SurveyForm';
import DuplicateQuestionList from './DuplicateQuestionList';

export default {
  name: 'SurveyDuplicateDialog',
  components: {
    SurveyForm,
    DuplicateQuestionList
  },
  props: {
    survey: {
      type: Object,
      default: () => ({})
    },
    show: {
      type: Boolean,
      required: false,
      default: null
    },
    organisations: {
      type: Array,
      required: true
    }
  },
  data: () => ({
    questions: [],
    activeTab: null
  }),
  computed: {
    showDialog() {
      if (this.show != null) {
        return this.show;
      } else {
        return this.survey;
      }
    },
    questionTabTitle() {
      const duplicateQuestionCount = this.questions.filter(
        question => question.duplicate
      ).length;
      return `Questions (${duplicateQuestionCount})`;
    },
    hasNoQuestions() {
      return this.survey.userQuestionCount === 0;
    }
  },
  watch: {
    survey() {
      this.loadQuestions();
    }
  },
  created() {
    this.loadQuestions();
  },
  methods: {
    toggleDuplication(question) {
      let updatedQuestion = this.questions.find(
        q => q.id === question.id
      );
      updatedQuestion.duplicate = !updatedQuestion.duplicate;
    },
    closeForm() {
      this.$refs.form.closeForm();
      this.activeTab = null;
      this.questions = [];
    },
    submit() {
      this.$refs.form.submit();
      this.activeTab = null;
    },
    loadQuestions() {
      this.questions = this.survey.questions
        .map(question => ({
          id: question.id,
          type: question.config.questionType,
          titleMap: question.text.languageMap,
          duplicate: true
        }))
        .filter(question => question.type !== 'LANGUAGE');
    }
  }
};
</script>

<style lang="scss" scoped>
.scroll-area {
  height: 650px;
  overflow-y: scroll;
}

.active {
  background-color: var(--v-primary-base);
  color: white !important;
}
</style>
