var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    { attrs: { value: _vm.showDialog, persistent: "", "max-width": "665" } },
    [
      _c(
        "v-card",
        [
          _c("v-card-title", { staticClass: "pb-0" }, [
            _vm._v(_vm._s(_vm.title))
          ]),
          _vm.show
            ? _c("survey-form", {
                attrs: { survey: _vm.survey, organisations: _vm.organisations },
                on: {
                  close: function($event) {
                    return _vm.$emit("update:show", false)
                  }
                }
              })
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }