var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.questions.length > 0
        ? _c(
            "div",
            [
              _vm.questionsDraggable
                ? _c(
                    "draggable",
                    {
                      on: {
                        start: _vm.startedDraggingQuestion,
                        end: _vm.updateOrderForDraggedQuestion
                      },
                      model: {
                        value: _vm.questions,
                        callback: function($$v) {
                          _vm.questions = $$v
                        },
                        expression: "questions"
                      }
                    },
                    _vm._l(_vm.questions, function(question) {
                      return _c(
                        "question",
                        _vm._g(
                          _vm._b(
                            { key: question.id },
                            "question",
                            _vm.questionComponentProps(question),
                            false
                          ),
                          _vm.questionComponentEventHandlers
                        )
                      )
                    }),
                    1
                  )
                : _vm._l(_vm.questions, function(question) {
                    return _c(
                      "question",
                      _vm._g(
                        _vm._b(
                          {
                            key: question.id,
                            attrs: {
                              id: question.id
                                ? `question-${question.id}`
                                : "question"
                            }
                          },
                          "question",
                          _vm.questionComponentProps(question),
                          false
                        ),
                        _vm.questionComponentEventHandlers
                      )
                    )
                  }),
              _c(
                "div",
                {
                  staticClass: "fab-container p-absolute",
                  staticStyle: { width: "56px" }
                },
                [
                  _c(
                    "v-tooltip",
                    {
                      attrs: { disabled: !_vm.questionInForm, top: "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function({ on, attrs }) {
                              return [
                                _c(
                                  "div",
                                  _vm._g(
                                    _vm._b(
                                      { staticClass: "p-fixed" },
                                      "div",
                                      attrs,
                                      false
                                    ),
                                    on
                                  ),
                                  [
                                    _c(
                                      "v-btn",
                                      {
                                        style: _vm.addQuestionButtonStyles,
                                        attrs: {
                                          color: "primary",
                                          disabled: !!_vm.questionInForm,
                                          fab: ""
                                        },
                                        on: { click: _vm.addQuestion }
                                      },
                                      [_c("v-icon", [_vm._v("add")])],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        1985079242
                      )
                    },
                    [
                      _c("span", [
                        _vm._v(
                          " Close open question before adding a new question "
                        )
                      ])
                    ]
                  )
                ],
                1
              )
            ],
            2
          )
        : _c("add-question-card", { on: { "add-question": _vm.addQuestion } }),
      _vm.showQuestionPreviewDialog
        ? _c("question-preview-dialog", {
            attrs: {
              "question-type": _vm.questionPreviewType,
              show: _vm.showQuestionPreviewDialog
            },
            on: { "close-question-preview": _vm.closeQuestionPreview }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }