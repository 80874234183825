var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-row",
    [
      _c(
        "v-col",
        { attrs: { cols: "7" } },
        [
          _c(
            "p",
            { staticClass: "mb-0 text-caption grey--text text--darken-2" },
            [_vm._v(" Sliders ")]
          ),
          _vm._l(_vm.sliderCount, function(i) {
            return _c(
              "v-row",
              { key: i },
              [
                _c(
                  "v-col",
                  { staticClass: "pt-10 pb-0", attrs: { cols: "12" } },
                  [
                    _c("span", { staticClass: "text-body-2" }, [
                      _vm._v(_vm._s(i) + ".")
                    ]),
                    _c("v-text-field", {
                      staticClass: "text-body-2 mt-n10 pl-5",
                      attrs: { label: "Subtitle" },
                      on: {
                        change: function($event) {
                          return _vm.configUpdated()
                        }
                      },
                      model: {
                        value:
                          _vm.configModel.sliders[i - 1].title.languageMap[
                            _vm.primaryLanguage
                          ],
                        callback: function($$v) {
                          _vm.$set(
                            _vm.configModel.sliders[i - 1].title.languageMap,
                            _vm.primaryLanguage,
                            $$v
                          )
                        },
                        expression:
                          "\n            configModel.sliders[i - 1].title.languageMap[\n              primaryLanguage\n            ]\n          "
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "v-col",
                  { staticClass: "py-0", attrs: { cols: "4" } },
                  [
                    _c("v-text-field", {
                      staticClass: "text-body-2 pl-5",
                      attrs: { label: "Left Word" },
                      on: {
                        change: function($event) {
                          return _vm.configUpdated()
                        }
                      },
                      model: {
                        value:
                          _vm.configModel.sliders[i - 1].left.languageMap[
                            _vm.primaryLanguage
                          ],
                        callback: function($$v) {
                          _vm.$set(
                            _vm.configModel.sliders[i - 1].left.languageMap,
                            _vm.primaryLanguage,
                            $$v
                          )
                        },
                        expression:
                          "\n            configModel.sliders[i - 1].left.languageMap[\n              primaryLanguage\n            ]\n          "
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "v-col",
                  { staticClass: "py-0", attrs: { cols: "4" } },
                  [
                    _c("v-text-field", {
                      staticClass: "text-body-2 pl-5",
                      attrs: { label: "Middle Word" },
                      on: {
                        change: function($event) {
                          return _vm.configUpdated()
                        }
                      },
                      model: {
                        value:
                          _vm.configModel.sliders[i - 1].middle.languageMap[
                            _vm.primaryLanguage
                          ],
                        callback: function($$v) {
                          _vm.$set(
                            _vm.configModel.sliders[i - 1].middle.languageMap,
                            _vm.primaryLanguage,
                            $$v
                          )
                        },
                        expression:
                          "\n            configModel.sliders[i - 1].middle.languageMap[\n              primaryLanguage\n            ]\n          "
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "v-col",
                  { staticClass: "py-0", attrs: { cols: "4" } },
                  [
                    _c("v-text-field", {
                      staticClass: "text-body-2 pl-5",
                      attrs: { label: "Right Word" },
                      on: {
                        change: function($event) {
                          return _vm.configUpdated()
                        }
                      },
                      model: {
                        value:
                          _vm.configModel.sliders[i - 1].right.languageMap[
                            _vm.primaryLanguage
                          ],
                        callback: function($$v) {
                          _vm.$set(
                            _vm.configModel.sliders[i - 1].right.languageMap,
                            _vm.primaryLanguage,
                            $$v
                          )
                        },
                        expression:
                          "\n            configModel.sliders[i - 1].right.languageMap[\n              primaryLanguage\n            ]\n          "
                      }
                    })
                  ],
                  1
                )
              ],
              1
            )
          })
        ],
        2
      ),
      _c(
        "v-col",
        { attrs: { cols: "5" } },
        [
          _c("slider-settings-controller", {
            staticClass: "ml-6 mr-10",
            attrs: {
              count: _vm.sliderCount,
              range: _vm.configModel.sliderRange,
              title: "Number of sliders",
              disabled: _vm.questionHasResponses
            },
            on: { "set-value": _vm.setSliderCount }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }