var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-row",
    _vm._l(_vm.words.length, function(wordNumber) {
      return _c(
        "v-col",
        { key: wordNumber, attrs: { cols: "3" } },
        [
          _c("v-text-field", {
            staticClass: "text-body-2",
            attrs: {
              value: _vm.word(wordNumber),
              label: _vm.inputLabel(wordNumber),
              disabled: _vm.disabled
            },
            on: {
              input: function($event) {
                return _vm.updateWord(wordNumber, $event)
              }
            }
          })
        ],
        1
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }