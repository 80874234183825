var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("access-code-view", {
    attrs: { title: "Public Display", "target-page": "tv" }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }