<template>
  <v-card class="p-relative pa-5 pl-7 mb-4">
    <div>
      <v-skeleton-loader type="text@1" />
    </div>
  </v-card>
</template>

<script>
export default {
  name: 'OrganisationListItemLoader'
};
</script>
