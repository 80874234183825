<template>
  <span v-if="!localisedString.translated" class="mt-1">
    <missing-translation :language="language">
      <span v-if="localisedString.string" :style="wrapStyle">
        {{ localisedString.string }}
      </span>
    </missing-translation>
  </span>
  <span v-else :class="colorClass" :style="wrapStyle">
    {{ localisedString.string }}
  </span>
</template>

<script>
import { mapGetters } from 'vuex';
import MissingTranslation from '@/components/Common/MissingTranslation';
import { translateStringOrFallback } from '@/utils/language';

export default {
  name: 'LocalisedText',
  components: {
    MissingTranslation
  },
  props: {
    language: {
      type: String,
      required: true
    },
    string: {
      // TODO: Refactor to just take an object
      // https://a2i2.atlassian.net/browse/MUSE-1150
      type: [Object, Array],
      required: true
    },
    color: {
      type: String,
      required: false,
      default: 'inherit'
    },
    wrap: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters('survey', ['primaryLanguage']),
    localisedString() {
      return translateStringOrFallback(
        this.string,
        this.language,
        this.survey ? this.primaryLanguage : this.language
      );
    },
    colorClass() {
      return this.color ? this.color : '';
    },
    wrapStyle() {
      return this.wrap ? `white-space: normal` : '';
    }
  }
};
</script>
