<template>
  <div>
    <v-row>
      <v-col cols="6">
        <p class="mb-0 text-caption grey--text text--darken-2">
          Response Options
        </p>
        <v-row>
          <v-col v-for="i in optionCount" :key="i" cols="6">
            <span class="text-body-2">{{ i }}.</span>
            <v-text-field
              :v-model="option(i)"
              class="text-body-2 mt-n10 pl-5 mb-n8"
              :value="option(i)"
              :disabled="questionHasResponses"
              height="34"
              @input="updateTitle(i, $event)"
              @focus="optionsFocused = true"
              @blur="optionsFocused = false"
            ></v-text-field>
            <image-upload-card
              class="ml-4 mt-8"
              :image-id="image(i)"
              :organisation-id="survey.organisationId"
              :image-type="imageTypes.QUESTION_IMAGE"
              :image-height="180"
              @image-uploaded="handleImageUploaded(i, $event)"
              @delete-image="handleDeleteImage(i)"
            />
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="6">
        <div class="d-flex">
          <word-settings-controller
            class="ml-6 mr-10"
            :count="optionCount"
            :range="config.wordRange"
            title="Options"
            :disabled="questionHasResponses"
            :multiple-choice-class="true"
            orientation="horizontal"
            @set-value="setOptionCount"
          />
          <div>
            <p
              class="pt-3 mb-0 pb-2 text-caption grey--text text--darken-2"
            >
              Selection Type
            </p>
            <v-btn-toggle v-model="value.canSelectMultiple">
              <v-btn
                v-for="(button, idx) in buttonLabels"
                :key="idx"
                :value="button.canSelectMultiple"
                class="text-capitalize text-body-2"
                small
                :disabled="questionHasResponses"
              >
                {{ button.label }}
              </v-btn>
            </v-btn-toggle>
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { ImageType } from '@/enums';
import WordSettingsController from './WordSettingsController.vue';
import ImageUploadCard from '@/components/Common/ImageUploadCard';

export default {
  name: 'MultipleChoiceImageConfig',
  components: {
    WordSettingsController,
    ImageUploadCard
  },
  props: {
    value: {
      type: Object,
      required: true
    },
    questionHasResponses: {
      type: Boolean,
      required: true
    },
    targetLanguage: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      options: this.value.options,
      optionCount: this.value.options.length,
      imageIds: this.value.imageIds,
      config: {
        wordRange: { min: 2, max: 8 }
      },
      buttonLabels: [
        {
          canSelectMultiple: false,
          label: 'Select One'
        },
        {
          canSelectMultiple: true,
          label: 'Select Multiple'
        }
      ],
      optionsFocused: false
    };
  },
  computed: {
    ...mapState('survey', ['survey']),
    primaryLanguage() {
      return this.targetLanguage;
    },
    imageTypes() {
      return ImageType;
    }
  },
  watch: {
    value: {
      deep: true,
      handler(updated) {
        if (!this.optionsFocused) {
          this.options = updated.options;
        }
      }
    },
    options: {
      handler(updated) {
        this.value.options = updated;
      }
    },
    imageIds: {
      handler(updated) {
        this.value.imageIds = updated;
      }
    }
  },
  methods: {
    updateTitle(optionNumber, value) {
      this.options[optionNumber - 1].languageMap[
        this.targetLanguage
      ] = value;
      this.configUpdated();
    },
    updateImage(imageNumber, imageId) {
      this.imageIds[imageNumber - 1] = imageId;
      this.configUpdated();
    },
    configUpdated() {
      const config = {
        questionType: this.value.questionType,
        options: this.options,
        canSelectMultiple: this.value.canSelectMultiple,
        imageIds: this.imageIds
      };
      this.$emit('input', config);
      this.$emit('change', config);
    },
    setOptionCount(optionCount) {
      this.optionCount = optionCount;
      this.adjustChoicesToMatchCount();
      this.configUpdated();
    },
    adjustChoicesToMatchCount() {
      if (this.options.length < this.optionCount) {
        while (this.options.length < this.optionCount) {
          this.addEmptyChoice();
        }
      } else if (this.options.length > this.optionCount) {
        while (this.options.length > this.optionCount) {
          this.removeLastChoice();
        }
      }
    },
    addEmptyChoice() {
      this.options.push({
        languageMap: { [this.primaryLanguage]: '' }
      });
      this.imageIds.push(null);
    },
    removeLastChoice() {
      this.options.pop();
      this.imageIds.pop();
    },
    option(index) {
      return index > this.options.length
        ? ''
        : this.options[index - 1].languageMap[this.primaryLanguage];
    },
    image(index) {
      return index > this.imageIds.length
        ? ''
        : this.imageIds[index - 1];
    },
    handleImageUploaded(imageNumber, response) {
      this.updateImage(imageNumber, response.id);
    },
    handleDeleteImage(imageNumber) {
      this.updateImage(imageNumber, null);
    }
  }
};
</script>
