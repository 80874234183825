<template>
  <v-dialog
    :value="show"
    width="50%"
    persistent
    @keydown.esc.stop="closeDialog()"
  >
    <v-card>
      <v-card-title class="text-h5 grey lighten-2" primary-title>
        <question-type-icon
          :question-type="questionType"
          class="mr-2 question-type-icon"
        />
        {{ questionTypeConfig.displayName }} Question Preview
      </v-card-title>

      <v-card-text class="pb-0 preview-card">
        <question-preview-video :question-type="questionType" />
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="close()">
          Close
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import QuestionTypeIcon from '@/components/Common/QuestionTypeIcon';
import QuestionPreviewVideo from './QuestionPreviewVideo';
import QuestionTypeConfig from '@/utils/QuestionTypeConfig';

export default {
  name: 'QuestionPreviewDialog',
  components: {
    QuestionTypeIcon,
    QuestionPreviewVideo
  },
  props: {
    questionType: {
      type: String,
      required: true
    },
    show: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    questionTypeConfig() {
      return QuestionTypeConfig[this.questionType];
    }
  },
  methods: {
    close() {
      this.$emit('close-question-preview');
    }
  }
};
</script>
<style lang="scss" scoped>
@import '../../../scss/main.scss';
.preview-card {
  height: 70vh;
  overflow-y: hidden;
}
</style>
