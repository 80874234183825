var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "question-form-action-butons" }, [
    _c(
      "div",
      { staticClass: "question-form-action-butons__content" },
      _vm._l(_vm.actions, function(action, i) {
        return _c("question-form-action-button", {
          key: i,
          staticClass: "ml-1",
          attrs: { action: action },
          on: {
            click: function($event) {
              _vm.$emit(action.toLowerCase().replaceAll("_", "-"))
            }
          }
        })
      }),
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }