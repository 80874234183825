<template>
  <svg
    viewBox="0 0 100 85"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    :style="style"
  >
    <linearGradient
      :id="gradientIdString"
      :gradientTransform="`rotate(${gradientRotation})`"
    >
      <stop
        v-for="color in colors"
        :key="color.offset"
        :offset="color.offset"
        :stop-color="color.stopColor"
      />
    </linearGradient>
    <path
      :fill="`url(#${gradientIdString})`"
      d="M100,26.5656349 C99.9773921,31.5335677 97.4526189,57.3108978 50.02,84.246 L50.015,84.264 C50.011,84.261 50.005,84.258 50.001,84.255 C49.997,84.258 49.991,84.261 49.987,84.264 L49.984,84.246 C-0.021,55.85 -0.118,28.746 0.013,25.949 C0.011,25.867 0,25.789 0,25.707 C0,11.51 11.828,0 26.421,0 C36.727,0 45.63,5.751 49.987,14.125 C54.342,5.751 63.245,0 73.551,0 C88.145,0 99.973,11.51 99.973,25.707 C99.973,25.707 99.9977882,25.9549861 100,26.4290087 L100,26.5656377 L100,26.5656349 Z"
    ></path>
  </svg>
</template>

<script>
import colors from 'vuetify/lib/util/colors';

export default {
  name: 'HeartSvg',
  props: {
    colors: {
      type: Array,
      default: () => [{ offset: 0, stopColor: colors.grey.lighten1 }]
    },
    gradientRotation: {
      type: Number,
      required: false,
      default: 0
    },
    scale: {
      type: Number,
      required: false,
      default: 1.0
    }
  },
  data() {
    return {
      gradientId: null
    };
  },
  computed: {
    style() {
      return { transform: `scale(${this.scale})` };
    },
    gradientIdString() {
      return `heart-gradient-${this.gradientId}`;
    }
  },
  mounted() {
    this.gradientId = this._uid;
  }
};
</script>

<style lang="scss" scoped>
svg {
  height: 100%;
  width: 100%;
}
</style>
