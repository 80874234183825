import { DrawingQuestionType } from '@/enums';

const QuestionTypeConfig = Object.freeze({
  AUDIO: {
    displayName: 'Audio',
    hasAdditionalConfig: false
  },
  CIRCLES_OVERLAP: {
    displayName: 'Circles (Overlap)',
    visualisationComponent: 'circles-overlap-dashboard-item',
    publicDisplayComponent: 'circles-overlap-public-display-item',
    publicDisplayTime: 10000,
    hasAdditionalConfig: true,
    defaultConfig: targetLanguage => ({
      options: [1, 2].map(() => ({
        languageMap: { [targetLanguage]: '' }
      }))
    }),
    configComponent: 'circle-question-config',
    translationFieldsLabel: 'Circle Labels',
    publicDisplayCompatible: true
  },
  CIRCLES_PROPORTIONAL: {
    displayName: 'Circles (Proportional)',
    visualisationComponent: 'circles-proportional-dashboard-item',
    publicDisplayComponent:
      'circles-proportional-public-display-item',
    publicDisplayTime: 10000,
    hasAdditionalConfig: true,
    defaultConfig: targetLanguage => ({
      options: [1, 2].map(() => ({
        languageMap: { [targetLanguage]: '' }
      }))
    }),
    configComponent: 'circle-question-config',
    translationFieldsLabel: 'Circle Labels',
    publicDisplayCompatible: true
  },
  CLOCK: {
    value: 'CLOCK',
    displayName: 'Clock',
    visualisationComponent: 'clock-dashboard-item',
    hasAdditionalConfig: false
  },
  COUNTRY: {
    displayName: 'Country',
    visualisationComponent: 'country-dashboard-item',
    hasAdditionalConfig: false
  },
  CROWD_DOTS: {
    displayName: 'Crowd Dots',
    hasAdditionalConfig: false
  },
  DRAWING: {
    displayName: 'Drawing',
    hasAdditionalConfig: true,
    configComponent: 'drawing-config',
    publicDisplayCompatible: true,
    publicDisplayComponent: 'image-grid-public-display-item',
    publicDisplayTime: 10000,
    hasAdditionalPublicDisplayConfig: true,
    publicDisplayOptionsComponent: 'drawing-public-display-options',
    defaultConfig: () => {
      return {
        backgroundImageId: '',
        drawingQuestionType: DrawingQuestionType.IMAGE,
        fullscreenMode: false
      };
    },
    defaultPublicDisplayOptions: () => ({
      questionType: 'DRAWING',
      gridDimensions: 'ONE_BY_ONE'
    })
  },
  EMAIL_ADDRESS: {
    displayName: 'Email Address',
    hasAdditionalConfig: false
  },
  EMOTION_WHEEL: {
    displayName: 'Emotion Wheel',
    hasAdditionalConfig: true,
    configComponent: 'emotion-wheel-config',
    visualisationComponent: 'emotion-wheel-dashboard-item',
    publicDisplayComponent: 'emotion-wheel-public-display-item',
    publicDisplayTime: 10000,
    isStatic: true,
    publicDisplayCompatible: true,
    translationFieldsLabel: 'Emotions',
    defaultConfig: () => ({
      options: [
        {
          languageMap: {
            EN: 'joy',
            AR: 'الفرح',
            FR: 'joie',
            DE: 'Freude',
            IT: 'gioia',
            ZH: '開心',
            SL: 'Veselje',
            RM: 'joy',
            PL: 'radość',
            'zh-Hans': '開心'
          }
        },
        {
          languageMap: {
            EN: 'trust',
            AR: 'ثقة',
            FR: 'confiance',
            DE: 'Vertrauen',
            IT: 'fiducia',
            ZH: '信任',
            SL: 'Zaupanje',
            RM: 'trust',
            PL: 'zaufanie',
            'zh-Hans': '信任'
          }
        },
        {
          languageMap: {
            EN: 'fear',
            AR: 'الخوف',
            FR: 'peur',
            DE: 'Angst',
            IT: 'paura',
            ZH: '恐懼',
            SL: 'Strah',
            RM: 'fear',
            PL: 'strach',
            'zh-Hans': '恐懼'
          }
        },
        {
          languageMap: {
            EN: 'surprise',
            AR: 'مفاجأة',
            FR: 'surprise',
            DE: 'Überraschung',
            IT: 'sorpresa',
            ZH: '驚喜',
            SL: 'Presenečenje',
            RM: 'surprise',
            PL: 'zaskoczenie',
            'zh-Hans': '驚喜'
          }
        },
        {
          languageMap: {
            EN: 'sadness',
            AR: 'الحزن',
            FR: 'tristesse',
            DE: 'Traurigkeit',
            IT: 'tristezza',
            ZH: '悲傷',
            SL: 'Žalost',
            RM: 'sadness',
            PL: 'smutek',
            'zh-Hans': '悲傷'
          }
        },
        {
          languageMap: {
            EN: 'disgust',
            AR: 'الاشمئزاز',
            FR: 'dégoût',
            DE: 'Abneigung',
            IT: 'disgusto',
            ZH: '厭惡',
            SL: 'Gnus',
            RM: 'disgust',
            PL: 'wstręt',
            'zh-Hans': '厭惡'
          }
        },
        {
          languageMap: {
            EN: 'anger',
            AR: 'الغضب',
            FR: 'colère',
            DE: 'Groll',
            IT: 'rabbia',
            ZH: '憤怒',
            SL: 'Jeza',
            RM: 'anger',
            PL: 'złość',
            'zh-Hans': '憤怒'
          }
        },
        {
          languageMap: {
            EN: 'interest',
            AR: 'الاهتمام',
            FR: 'intérêt',
            DE: 'Interesse',
            IT: 'interesse',
            ZH: '感興趣',
            SL: 'Zanimanje',
            RM: 'interest',
            PL: 'zaciekawienie',
            'zh-Hans': '感興趣'
          }
        }
      ]
    })
  },
  ENCLOSING_WALLS: {
    displayName: 'Enclosing Walls',
    visualisationComponent: 'enclosing-walls-dashboard-item',
    hasAdditionalConfig: false
  },
  GENDER: {
    displayName: 'Gender',
    visualisationComponent: 'gender-dashboard-item',
    hasAdditionalConfig: true,
    configComponent: 'gender-config',
    defaultConfig: () => {
      return { includeNonBinary: true };
    }
  },
  HEART: {
    displayName: 'Heart',
    visualisationComponent: 'heart-dashboard-item',
    publicDisplayComponent: 'heart-public-display-item',
    publicDisplayTime: 10000,
    hasAdditionalConfig: false,
    publicDisplayCompatible: true
  },
  MULTIPLE_CHOICE: {
    displayName: 'Multiple Choice',
    hasAdditionalConfig: true,
    configComponent: 'multiple-choice-config',
    visualisationComponent: 'multiple-choice-dashboard-item',
    publicDisplayComponent: 'multiple-choice-public-display-item',
    publicDisplayTime: 10000,
    publicDisplayCompatible: true,
    defaultConfig: targetLanguage => ({
      canSelectMultiple: false,
      options: [1, 2, 3, 4, 5].map(() => ({
        languageMap: {
          [targetLanguage]: ''
        }
      }))
    }),
    translationFieldsLabel: 'Options'
  },
  MULTIPLE_CHOICE_IMAGE: {
    displayName: 'Multiple Choice Image',
    hasAdditionalConfig: true,
    configComponent: 'multiple-choice-image-config',
    visualisationComponent: 'multiple-choice-image-dashboard-item',
    publicDisplayComponent:
      'multiple-choice-image-public-display-item',
    publicDisplayTime: 10000,
    publicDisplayCompatible: true,
    defaultConfig: targetLanguage => ({
      canSelectMultiple: false,
      options: [1, 2, 3, 4].map(() => ({
        languageMap: {
          [targetLanguage]: ''
        }
      })),
      imageIds: [1, 2, 3, 4].map(() => {
        return null;
      })
    }),
    translationFieldsLabel: 'Options'
  },
  NUMBER_FLIPPERS: {
    displayName: 'Number Flippers',
    visualisationComponent: 'number-flippers-dashboard-item',
    hasAdditionalConfig: false
  },
  // TODO: Can we look to not return the language question
  // when hitting the server for a survey's questions
  LANGUAGE: {
    displayName: 'Language',
    hidden: true,
    visualisationComponent: 'language-select-dashboard-item',
    hasAdditionalConfig: false
  },
  PEOPLE_COUNT: {
    displayName: 'People Count',
    visualisationComponent: 'people-count-dashboard-item',
    hasAdditionalConfig: false
  },
  PEOPLE_COUNT_ADULTS: {
    displayName: 'People Count (Adults)',
    visualisationComponent: 'people-count-no-kids-dashboard-item',
    hasAdditionalConfig: false
  },
  PHOTO: {
    displayName: 'Photo',
    publicDisplayCompatible: true,
    publicDisplayComponent: 'image-grid-public-display-item',
    publicDisplayTime: 10000,
    hasAdditionalConfig: false,
    hasAdditionalPublicDisplayConfig: true,
    publicDisplayOptionsComponent: 'photo-public-display-options',
    defaultPublicDisplayOptions: () => ({
      questionType: 'PHOTO',
      gridDimensions: 'ONE_BY_ONE'
    })
  },
  PIE_CHART: {
    displayName: 'Pie Chart',
    configComponent: 'pie-chart-config',
    visualisationComponent: 'horizontal-bar-chart',
    hasAdditionalConfig: true,
    defaultConfig: targetLanguage => ({
      options: [1, 2, 3, 4, 5, 6, 7, 8].map(() => ({
        languageMap: { [targetLanguage]: '' }
      }))
    }),
    translationFieldsLabel: 'Slice Labels'
  },
  POSTCODE: {
    value: 'POSTCODE',
    displayName: 'Postcode',
    visualisationComponent: 'postcode-dashboard-item',
    hasAdditionalConfig: false
  },
  RATING_1_5: {
    displayName: 'Rating (1–5)',
    visualisationComponent: 'rating-1-to-5-dashboard-item',
    hasAdditionalConfig: true,
    defaultConfig: targetLanguage => ({
      overrideLabels: false,
      left: { languageMap: { [targetLanguage]: '' } },
      right: { languageMap: { [targetLanguage]: '' } }
    }),
    configComponent: 'rating-1-to-5-question-config',
    translationFieldsLabel: 'Axis Labels'
  },
  RATING_0_10: {
    displayName: 'Rating (0–10)',
    visualisationComponent: 'rating-0-to-10-dashboard-item',
    hasAdditionalConfig: false
  },
  SLIDER: {
    displayName: 'Slider',
    hasAdditionalConfig: true,
    configComponent: 'slider-config',
    visualisationComponent: 'slider-dashboard-item',
    publicDisplayComponent: 'slider-public-display-item',
    publicDisplayTime: 10000,
    publicDisplayCompatible: true,
    defaultConfig: targetLanguage => ({
      sliders: [
        {
          title: { languageMap: { [targetLanguage]: '' } },
          left: { languageMap: { [targetLanguage]: '' } },
          middle: { languageMap: { [targetLanguage]: 'Ideal' } },
          right: { languageMap: { [targetLanguage]: '' } }
        }
      ]
    }),
    translationFieldsLabel: 'Axis Labels'
  },
  SMILEY_FACE: {
    displayName: 'Smiley Face',
    visualisationComponent: 'smiley-face-dashboard-item',
    hasAdditionalConfig: false
  },
  SPIKY_BALL: {
    displayName: 'Spiky Ball',
    visualisationComponent: 'spiky-ball-dashboard-item',
    hasAdditionalConfig: false
  },
  TEXT: {
    displayName: 'Text',
    visualisationComponent: 'free-text-dashboard-item',
    hasAdditionalConfig: false,
    publicDisplayComponent: 'text-public-display-item',
    publicDisplayTime: 10000,
    publicDisplayCompatible: true
  },
  TOP_WORDS: {
    displayName: 'Top Words',
    defaultConfig: targetLanguage => ({
      slotCount: 3,
      options: [1, 2, 3, 4, 5, 6, 7, 8].map(() => ({
        languageMap: { [targetLanguage]: '' }
      }))
    }),
    configComponent: 'top-words-config',
    visualisationComponent: 'top-words-dashboard-item',
    publicDisplayComponent: 'top-words-public-display-item',
    publicDisplayTime: 10000,
    hasAdditionalConfig: true,
    publicDisplayCompatible: true,
    translationFieldsLabel: 'Words'
  },
  WAVE_LINE: {
    displayName: 'Wave Line',
    visualisationComponent: 'wave-line-dashboard-item',
    hasAdditionalConfig: false
  },
  YES_NO: {
    displayName: 'Yes/No',
    hasAdditionalConfig: true,
    configComponent: 'yes-no-config',
    defaultConfig: () => ({
      followUpQuestions: {
        yes: 'Why yes?',
        no: 'Why no?'
      }
    }),
    visualisationComponent: 'yes-no-dashboard-item'
  },
  GUIDANCE: {
    displayName: 'Guidance',
    hasAdditionalConfig: true,
    configComponent: 'guidance-config',
    defaultConfig: targetLanguage => ({
      hideQuestionTitle: true,
      prompt: { languageMap: { [targetLanguage]: '' } }
    }),
    translationFieldsLabel: 'Guidance Text'
  }
});

export const QuestionTypes = Object.keys(QuestionTypeConfig)
  .filter(key => !QuestionTypeConfig[key].hidden)
  .sort();

export default QuestionTypeConfig;
