var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { staticClass: "pa-4 d-flex mr-5 justify-space-between align-center" },
    [
      _c(
        "div",
        { staticClass: "mr-5" },
        [_c("v-skeleton-loader", { attrs: { type: "avatar" } })],
        1
      ),
      _c("div", { staticClass: "flex-grow-1" }, [
        _c("div", [_c("v-skeleton-loader", { attrs: { type: "heading" } })], 1),
        _c("div", { staticClass: "d-flex justify-end" }, [
          _c(
            "div",
            { staticClass: "theme-loader" },
            [_c("v-skeleton-loader", { attrs: { type: "text@1" } })],
            1
          )
        ]),
        _c(
          "div",
          [
            _c(
              "v-row",
              [
                _c(
                  "v-icon",
                  {
                    staticClass: "pl-2",
                    attrs: { medium: "", color: "rgba(220, 220, 220)" }
                  },
                  [_vm._v(" circle ")]
                ),
                _c("v-skeleton-loader", {
                  staticClass: "pt-1 pl-1",
                  attrs: { width: "15%", type: "text@1" }
                })
              ],
              1
            )
          ],
          1
        )
      ]),
      _c("div", [_c("v-skeleton-loader", { attrs: { type: "text@1" } })], 1)
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }