<template>
  <div class="d-flex align-center mb-n1">
    <div>
      <v-icon :color="iconColor">{{ icon }}</v-icon>
    </div>
    <div class="mx-4 text-body-2">{{ message }}</div>
  </div>
</template>

<script>
export default {
  name: 'FormSummaryMessage',
  props: {
    message: {
      type: String,
      required: true
    },
    icon: {
      type: String,
      default: 'info'
    },
    iconColor: {
      type: String,
      default: 'primary'
    }
  }
};
</script>
