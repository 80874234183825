<template>
  <v-checkbox
    v-model="value.includeNonBinary"
    :disabled="questionHasResponses"
    label="Include non-binary gender type"
  />
</template>

<script>
export default {
  name: 'GenderConfig',
  props: {
    value: {
      type: Object,
      required: true
    },
    questionHasResponses: {
      type: Boolean,
      required: true
    }
  }
};
</script>
