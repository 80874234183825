<template>
  <div class="mt-n12">
    <div class="d-flex justify-center align-center">
      <emotion-wheel-public-display-legend
        :items="legendItems.left"
        :display-language="displayLanguage"
        :text-color="theme.textColor"
        left
        class="mt-6"
      />
      <div class="mx-16" style="width: 40%">
        <emotion-wheel
          class="flex-grow-1 mb-12"
          :slices="chartSlices"
          :size="700"
          :show-labels="false"
        />
      </div>
      <emotion-wheel-public-display-legend
        :items="legendItems.right"
        :display-language="displayLanguage"
        right
        class="mt-6"
        :text-color="theme.textColor"
      />
    </div>
  </div>
</template>

<script>
import EmotionWheel from '@/components/Visualisations/EmotionWheel';
import EmotionWheelPublicDisplayLegend from './EmotionWheelPublicDisplayLegend';
import { mapGetters } from 'vuex';
import { translateStringOrFallback } from '@/utils/language';

export default {
  name: 'EmotionWheelPublicDisplayItem',
  components: {
    EmotionWheel,
    EmotionWheelPublicDisplayLegend
  },
  props: {
    questionStat: {
      required: true,
      type: Object
    },
    displayLanguage: {
      type: String,
      required: true
    },
    theme: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapGetters('survey', ['primaryLanguage']),
    chartSlices() {
      return this.values.map((value, index) => {
        return {
          colour: this.emotionColours(index),
          index: index,
          label: translateStringOrFallback(
            this.emotionLabel(index),
            this.displayLanguage,
            this.survey ? this.primaryLanguage : this.displayLanguage
          ).string,
          value: value.average
        };
      });
    },
    legendItems() {
      const items = this.values.map((_, index) => {
        return {
          colour: this.emotionColours(index),
          label: this.emotionLabel(index),
          value: this.emotionPercent(index)
        };
      });

      const middleIndex = Math.round(items.length / 2);

      const left = items.slice(middleIndex, items.length).reverse();
      const right = items.slice(0, middleIndex);

      return { left, right };
    },
    values() {
      return this.questionStat.values;
    }
  },
  methods: {
    emotionColours(index) {
      return this.values[index].colour;
    },
    emotionLabel(index) {
      return this.values[index].label;
    },
    emotionPercent(index) {
      const percentage = this.values[index].average * 100;
      return `${percentage.toFixed(0)}%`;
    }
  }
};
</script>
