<template>
  <div>
    <div
      v-for="feedback in getRandomFeedback"
      :key="feedback"
      class="comment-container mb-2 font-italic text-justify"
    >
      {{ feedback }}
    </div>
    <div class="d-flex justify-center">
      <v-btn
        class="mt-3 show-responses-button"
        color="primary"
        @click="showAllResponsesDialog"
      >
        Show all responses
      </v-btn>
      <free-text-responses-modal
        v-if="showDialogRequested"
        :question-stat="questionStat"
        :show="showDialogRequested"
        :display-language="displayLanguage"
        @cancel="cancelAllResponsesDialog"
      />
    </div>
  </div>
</template>

<script>
import { questionVisualisationHelper } from '@/mixins/SuperUtils';
import { naiveClone } from '@/utils';
import FreeTextResponsesModal from '../../Common/FreeTextResponsesModal.vue';

export default {
  components: { FreeTextResponsesModal },
  mixins: [questionVisualisationHelper],
  props: {
    questionStat: {
      required: true,
      type: Object
    },
    displayLanguage: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      showDialog: false
    };
  },
  computed: {
    getRandomFeedback() {
      return this.getRandomValuesFromArray(
        naiveClone(this.getResponseEntries),
        3
      );
    },
    showDialogRequested() {
      return !!this.showDialog;
    },
    getResponseEntries() {
      return this.questionStat.responsesWithTimes.map(
        response => response.value
      );
    }
  },
  methods: {
    showAllResponsesDialog() {
      this.showDialog = true;
    },
    cancelAllResponsesDialog() {
      this.showDialog = false;
    }
  }
};
</script>

<style scoped lang="scss">
.comment-container {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.show-responses-button {
  gap: 10px;
  position: absolute;
  border-radius: 4px;
  justify-content: center;
  height: 30px !important;
}
</style>
