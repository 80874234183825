<template>
  <v-dialog :value="showDialog" persistent max-width="665">
    <v-card>
      <v-card-title class="pb-0">{{ title }}</v-card-title>
      <survey-form
        v-if="show"
        :survey="survey"
        :organisations="organisations"
        @close="$emit('update:show', false)"
      />
    </v-card>
  </v-dialog>
</template>

<script>
import SurveyForm from './Edit/SurveyForm';

export default {
  name: 'SurveyDialog',
  components: { SurveyForm },
  props: {
    survey: {
      type: Object,
      required: true
    },
    show: {
      type: Boolean,
      default: false
    },
    organisations: {
      type: Array,
      required: true
    }
  },
  computed: {
    showDialog() {
      return this.show == false ? this.show : this.survey;
    },
    title() {
      return this.survey.id ? 'Edit Survey' : 'New Survey';
    }
  }
};
</script>
