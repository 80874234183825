var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-row",
    _vm._l(Object.keys(_vm.labels), function(key) {
      return _c(
        "v-col",
        { key: key, attrs: { cols: "3" } },
        [
          _c("v-text-field", {
            staticClass: "text-body-2 text-capitalize",
            attrs: {
              disabled: _vm.disabled,
              value: _vm.label(key),
              label: _vm.inputLabel(key)
            },
            on: {
              input: function($event) {
                return _vm.updateLabel(key, $event)
              }
            }
          })
        ],
        1
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }