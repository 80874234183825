<template>
  <div>
    <div class="d-flex justify-space-around">
      <word-cloud
        :responses="questionStat.responses"
        :weights="weights"
        :width="responsiveWidth"
        :height="800"
      />
    </div>
  </div>
</template>

<script>
import WordCloud from '@/components/Visualisations/WordCloud';

export default {
  name: 'TextPublicDisplayItem',
  components: {
    WordCloud
  },
  props: {
    questionStat: {
      required: true,
      type: Object
    },
    displayLanguage: {
      type: String,
      required: true
    }
  },
  data: () => ({
    weights: [
      { percentage: 0.9, colour: '#b34c79' },
      { percentage: 0.8, colour: '#ba5d86' },
      { percentage: 0.7, colour: '#c26f93' },
      { percentage: 0.6, colour: '#c981a1' },
      { percentage: 0.5, colour: '#d193ae' },
      { percentage: 0.4, colour: '#d9a5bc' },
      { percentage: 0.3, colour: '#e0b7c9' },
      { percentage: 0.2, colour: '#e8c9d6' },
      { percentage: 0.1, colour: '#efdbe4' },
      { percentage: 0.0, colour: '#f7edf1' }
    ]
  }),
  computed: {
    responsiveWidth() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          return 400;
        case 'sm':
          return 600;
        case 'md':
          return 800;
        case 'lg':
          return 1200;
        case 'xl':
          return 1600;
        default:
          return 2200;
      }
    }
  }
};
</script>
