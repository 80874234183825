<template>
  <div>
    <v-card class="pa-5 px-12 mb-4">
      <v-row no-gutters class="d-flex justify-space-between">
        <div class="text-h5">Members</div>
        <v-btn fab small color="primary" @click="requestAdd">
          <v-icon>add</v-icon>
        </v-btn>
      </v-row>
      <v-row no-gutters>
        <v-simple-table class="flex-grow-1">
          <template>
            <thead>
              <tr>
                <th
                  v-for="header in ['Email', 'Role', '']"
                  :key="header"
                  class="text-left pl-0"
                >
                  {{ header }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="member in organisation.members"
                :key="member.id"
              >
                <td class="pl-0">{{ member.email }}</td>
                <td class="pl-0">{{ userRoles[member.userRole] }}</td>
                <td class="pl-0 d-flex justify-end align-center">
                  <v-btn
                    v-if="!member.hasLoggedIn"
                    x-small
                    max-width="34px"
                    min-height="34px"
                    class="mr-2"
                    @click="requestResendInvite(member)"
                  >
                    <v-icon>mail_outline</v-icon>
                  </v-btn>
                  <v-btn
                    :disabled="isCurrentUser(member)"
                    class="mr-2"
                    x-small
                    max-width="34px"
                    min-height="34px"
                    @click="requestEdit(member)"
                  >
                    <v-icon>edit</v-icon>
                  </v-btn>
                  <v-btn
                    :disabled="!canDelete(member)"
                    x-small
                    max-width="34px"
                    min-height="34px"
                    color="error"
                    @click="requestDelete(member)"
                  >
                    <v-icon>delete_outline</v-icon>
                  </v-btn>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-row>
    </v-card>
    <member-dialog
      :show.sync="memberDialog"
      :member.sync="memberInDialog"
      :organisation="organisation"
    />
    <action-modal
      v-if="deleteRequested"
      :show="deleteRequested"
      :title="deleteMemberDialogTitle"
      @cancel="cancelDelete()"
    >
      <template v-slot:body>
        <v-card-text>
          If you remove <strong>{{ memberToDelete.email }}</strong>
          they will no longer be able to log in and access their
          surveys, but any surveys they have created will not be
          deleted.
        </v-card-text>
      </template>
      <template v-slot:actions>
        <v-btn color="error" @click="confirmDelete()">
          Remove
        </v-btn>
      </template>
    </action-modal>
    <action-modal
      v-if="resendInviteRequested"
      :show="resendInviteRequested"
      title="Re-Invite User"
      @cancel="cancelResendInvite()"
    >
      <template v-slot:body>
        <v-card-text>
          This will resend the invitation email to
          <strong>{{ memberToResendInvite.email }}.</strong>
        </v-card-text>
      </template>
      <template v-slot:actions>
        <v-btn color="primary" @click="confirmResendInvite()">
          <v-icon class="mr-2">mail_outline</v-icon>
          Send
        </v-btn>
      </template>
    </action-modal>
  </div>
</template>

<script>
import MemberDialog from './MemberDialog';
import ActionModal from '@/components/Common/ActionModal';
import OrganisationUserRoles from '@/enums/organisationUserRoles';
import { getInstance } from 'auth0-js';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'MemberTable',
  components: { ActionModal, MemberDialog },
  props: {
    organisation: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      memberDialog: false,
      memberToDelete: null,
      memberToResendInvite: null,
      memberToEdit: undefined
    };
  },
  computed: {
    ...mapGetters('currentUser', ['currentUserIsSuper']),
    userRoles() {
      return OrganisationUserRoles;
    },
    deleteMemberDialogTitle() {
      return `Remove ${this.memberToDelete.email}`;
    },
    deleteRequested() {
      return !!this.memberToDelete;
    },
    resendInviteRequested() {
      return !!this.memberToResendInvite;
    },
    memberInDialog() {
      return this.memberToEdit ?? {};
    },
    organisationMembers() {
      return this.organisation.members.map(member =>
        this.memberName(member)
      );
    }
  },
  methods: {
    ...mapActions('organisation', ['removeMember', 'resendInvite']),
    requestDelete(member) {
      this.memberToDelete = member;
    },
    cancelDelete() {
      this.memberToDelete = null;
    },
    confirmDelete() {
      this.removeMember({
        organisationId: this.organisation.id,
        member: this.memberToDelete
      });
      this.memberToDelete = null;
    },
    canDelete(member) {
      if (this.currentUserIsSuper) return true; // Super users can always delete anyone
      return !this.isCurrentUser(member); // Normal users can't delete themselves
    },
    isCurrentUser(member) {
      const authService = getInstance();
      return authService.user.email == member.email;
    },
    requestResendInvite(member) {
      this.memberToResendInvite = member;
    },
    cancelResendInvite() {
      this.memberToResendInvite = null;
    },
    confirmResendInvite() {
      this.resendInvite({
        organisationId: this.organisation.id,
        member: this.memberToResendInvite
      });
      this.memberToResendInvite = null;
    },
    requestAdd() {
      this.memberToEdit = undefined;
      this.memberDialog = true;
    },
    requestEdit(member) {
      this.memberToEdit = this.organisation.members.find(
        m => m.id === member.id
      );
      this.memberDialog = true;
    }
  }
};
</script>
