<template>
  <div>
    <span class="text-caption grey--text text--darken-2"
      >Background Image</span
    >
    <v-row>
      <v-col cols="4">
        <image-upload-card
          :disable="questionHasResponses"
          :image-id="value.backgroundImageId"
          :organisation-id="survey.organisationId"
          :image-type="imageTypes.QUESTION_IMAGE"
          @image-uploaded="handleImageUploaded"
          @delete-image="handleDeleteImage"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import ImageUploadCard from '@/components/Common/ImageUploadCard';
import { mapState } from 'vuex';
import { ImageType } from '@/enums';
export default {
  name: 'DrawingImageConfig',
  components: { ImageUploadCard },
  props: {
    value: {
      type: Object,
      required: true
    },
    questionHasResponses: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    ...mapState('survey', ['survey']),
    imageTypes() {
      return ImageType;
    }
  },
  methods: {
    handleImageUploaded(response) {
      this.value.backgroundImageId = response.id;
    },
    handleDeleteImage() {
      this.value.backgroundImageId = '';
    }
  }
};
</script>
