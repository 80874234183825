<template>
  <v-container>
    <v-fade-transition v-if="!dataLoading">
      <div class="ml-6 mr-6 mt-8">
        <h1>{{ stats.title }}</h1>
        <dashboard-view
          :display-language="displayLanguage"
          @fetch-stats-with-date="fetchStatsBetweenDates"
        />
      </div>
    </v-fade-transition>
  </v-container>
</template>

<script>
import DashboardView from '@/components/Dashboard/DashboardView';
import { mapActions, mapState } from 'vuex';

export default {
  name: 'PublicDashboardPage',
  components: {
    DashboardView
  },
  props: {
    accessCode: {
      required: false,
      type: String,
      default: undefined
    }
  },
  data() {
    return {
      dataLoading: true,
      startDate: null,
      endDate: null
    };
  },
  computed: {
    ...mapState('stats', ['stats']),
    displayLanguage() {
      return this.stats.languages[0];
    },
    surveyId() {
      return this.$route.params.id;
    },
    hasResponses() {
      return this.stats.questionStats.some(
        stat => stat.totalResponses > 0
      );
    }
  },
  mounted() {
    if (this.accessCode != undefined) {
      this.validateCode({
        surveyId: this.surveyId,
        accessCode: this.accessCode
      }).then(isValid => {
        if (isValid) {
          this.reloadStats();
        } else {
          this.$router.push(
            `/surveys/${this.surveyId}/dashboard/access-code`
          );
        }
      });

      return;
    }

    this.$router.push(
      `/surveys/${this.surveyId}/dashboard/access-code`
    );
    return;
  },
  methods: {
    ...mapActions({
      validateCode: 'publicDashboard/validateCode',
      fetchStats: 'publicDashboard/fetchPublicDashboardStats'
    }),
    reloadStats() {
      return this.fetchStats({
        surveyId: this.surveyId,
        startDate: this.startDate,
        endDate: this.endDate,
        accessCode: this.accessCode
      }).then(stats => {
        this.$store.dispatch('stats/setStats', stats);
        this.dataLoading = false;
      });
    },
    fetchStatsBetweenDates(dates) {
      this.startDate = dates.startDate;
      this.endDate = dates.endDate;
      this.reloadStats();
    }
  }
};
</script>

<style lang="scss" scoped></style>
