<template>
  <v-select
    v-model="selectedTheme"
    v-bind="$attrs"
    class="text-body-2"
    :items="questionThemes"
    item-value="value"
    item-text="displayName"
    label="Question Theme"
    @input="$emit('input', selectedTheme)"
  />
</template>

<script>
import QuestionThemeConfig from '@/utils/QuestionThemeConfig';
export default {
  name: 'QuestionTypeSelector',
  props: {
    value: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      selectedTheme: this.value
    };
  },
  computed: {
    questionThemes() {
      return Object.keys(QuestionThemeConfig).map(key => ({
        value: key,
        displayName: QuestionThemeConfig[key].displayName
      }));
    }
  },
  watch: {
    value(val) {
      this.selectedTheme = val;
    }
  }
};
</script>
