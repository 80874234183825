var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      style: _vm.style,
      attrs: {
        viewBox: "0 0 100 85",
        version: "1.1",
        xmlns: "http://www.w3.org/2000/svg",
        "xmlns:xlink": "http://www.w3.org/1999/xlink"
      }
    },
    [
      _c(
        "linearGradient",
        {
          attrs: {
            id: _vm.gradientIdString,
            gradientTransform: `rotate(${_vm.gradientRotation})`
          }
        },
        _vm._l(_vm.colors, function(color) {
          return _c("stop", {
            key: color.offset,
            attrs: { offset: color.offset, "stop-color": color.stopColor }
          })
        }),
        1
      ),
      _c("path", {
        attrs: {
          fill: `url(#${_vm.gradientIdString})`,
          d:
            "M100,26.5656349 C99.9773921,31.5335677 97.4526189,57.3108978 50.02,84.246 L50.015,84.264 C50.011,84.261 50.005,84.258 50.001,84.255 C49.997,84.258 49.991,84.261 49.987,84.264 L49.984,84.246 C-0.021,55.85 -0.118,28.746 0.013,25.949 C0.011,25.867 0,25.789 0,25.707 C0,11.51 11.828,0 26.421,0 C36.727,0 45.63,5.751 49.987,14.125 C54.342,5.751 63.245,0 73.551,0 C88.145,0 99.973,11.51 99.973,25.707 C99.973,25.707 99.9977882,25.9549861 100,26.4290087 L100,26.5656377 L100,26.5656349 Z"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }