<template>
  <v-dialog
    :value="show"
    width="50%"
    persistent
    @keydown.esc.stop="closeDialog"
    @keydown.left.stop="previousQuestion"
    @keydown.right.stop="nextQuestion"
  >
    <v-card>
      <v-card-title class="text-h5 grey lighten-2" primary-title>
        {{ selectedSurvey.title }} Survey Preview
      </v-card-title>

      <v-card-text class="pb-0 preview-card">
        <v-card-title>
          <v-spacer />
          {{ questionText }}
          <v-spacer />
        </v-card-title>
        <v-row align="center">
          <v-col cols="1">
            <v-btn
              color="grey lighten-2 black--text"
              dark
              small
              fab
              :class="{
                'disabled-button': !hasPreviousQuestion,
                'grey--text': !hasPreviousQuestion
              }"
              @click="previousQuestion"
            >
              <v-icon>arrow_back</v-icon>
            </v-btn>
          </v-col>
          <v-col cols="10" class="pt-0">
            <div
              v-for="(item, i) in questionsList"
              :key="i"
              class="question-preview"
            >
              <v-fade-transition mode="out-in" hide-on-leave>
                <question-preview-video
                  v-if="i === selectedIndex"
                  :question-type="item.config.questionType"
                />
              </v-fade-transition>
            </div>
          </v-col>
          <v-col cols="1">
            <v-btn
              color="grey lighten-2 black--text"
              dark
              small
              fab
              :class="{
                'disabled-button': !hasNextQuestion,
                'grey--text': !hasNextQuestion
              }"
              @click="nextQuestion"
            >
              <v-icon>arrow_forward</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>

      <v-divider></v-divider>
      <v-card-actions>
        <span class="current-question-text text-center"
          >{{ currentQuestionDisplayNumber }}/{{
            amountOfQuestions
          }}</span
        >
        <v-spacer />
        <v-btn color="primary" text @click="closeDialog">
          Close
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapState } from 'vuex';
import QuestionPreviewVideo from './QuestionPreviewVideo';

export default {
  name: 'SurveyPreviewDialog',
  components: { QuestionPreviewVideo },
  props: {
    show: {
      type: Boolean,
      required: true
    },
    selectedSurvey: {
      type: Object,
      default: undefined
    },
    questionsList: {
      type: Array,
      required: true
    }
  },
  data: () => ({
    selectedIndex: 0
  }),
  computed: {
    ...mapState('survey', ['survey']),
    hasPreviousQuestion() {
      return this.selectedIndex - 1 !== -1;
    },
    hasNextQuestion() {
      return (
        this.currentQuestionDisplayNumber !== this.amountOfQuestions
      );
    },
    questionText() {
      return this.questionsList[this.selectedIndex] !== undefined
        ? this.questionsList[this.selectedIndex].text.languageMap[
            this.selectedSurvey.primaryLanguage
          ]
        : '';
    },
    currentQuestionDisplayNumber() {
      return this.selectedIndex + 1;
    },
    amountOfQuestions() {
      return this.questionsList.length;
    }
  },
  methods: {
    closeDialog() {
      this.selectedIndex = 0;
      this.$emit('update:show', false);
      this.$emit('close-dialog');
    },
    previousQuestion() {
      if (this.hasPreviousQuestion) this.selectedIndex--;
    },
    nextQuestion() {
      if (this.hasNextQuestion) this.selectedIndex++;
    }
  }
};
</script>
<style lang="scss" scoped>
@import '../../../scss/main.scss';
.preview-card {
  height: 70vh;
}

.question-preview {
  background-color: #1f1f1f;
}

.question-preview-video {
  height: 60vh !important;
}

.close-button {
  min-width: 10px;
}
.v-btn .v-btn--disabled {
  background-color: map-get($grey, 'lighten-2');
}
.current-question-text {
  position: absolute;
  width: 98%;
}
.disabled-button {
  pointer-events: none;
}
</style>
