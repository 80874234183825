import { naiveClone } from '@/utils';

export const questionVisualisationHelper = {
  methods: {
    isVisualisationForQuestion(questionType, visualisationType) {
      return questionType === visualisationType;
    },
    calculateAvgFor(response) {
      return response === 0
        ? 0
        : response / this.questionStat.totalResponses;
    },
    formatPercent(average) {
      return Math.round(average * 100) + '%';
    },
    groupBy(countriesList, region) {
      return countriesList.reduce((countries, country) => {
        if (country.region === region) {
          if (!countries[region]) {
            countries[region] = [];
          }
          countries[region].push(country);
        } else {
          if (!countries['rest']) {
            countries['rest'] = [];
          }
          countries['rest'].push(country);
        }
        return countries;
      }, {});
    },
    sortByCount(list) {
      const parsedList = naiveClone(list);
      return parsedList.sort(
        (a, b) => parseInt(b.count) - parseInt(a.count)
      );
    },
    getTopFiveCountriesByCount(countries) {
      return this.sortByCount(countries).slice(0, 5);
    },
    getRandomValuesFromArray(items, numberOfElements) {
      // Not completely random
      return items
        .sort(() => 0.5 - Math.random())
        .slice(0, numberOfElements);
    }
  }
};
