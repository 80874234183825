<template>
  <div>
    <!-- Public display configuration -->
    <public-display-config-card
      :survey="survey"
      class="mb-8"
    ></public-display-config-card>

    <!-- Visible questions -->
    <div class="mb-8">
      <div class="d-flex">
        <h3
          class="grey--text text--darken-1 mb-4 font-weight-medium d-flex"
        >
          <v-icon class="grey--text pr-2">visibility</v-icon>
          Visible Slides
        </h3>
        <v-btn
          class="ml-auto grey--text text--darken-1"
          :disabled="!hasVisibleQuestions"
          @click="hideAll"
        >
          <v-icon class="mr-2">visibility_off</v-icon>
          Hide all
        </v-btn>
      </div>

      <draggable
        group="public-display-questions"
        :list="visibleQuestions"
        @add="updateVisibleQuestions"
        @remove="updateVisibleQuestions"
        @update="updateVisibleQuestions"
      >
        <public-display-question
          v-for="question in visibleQuestions"
          :id="`item-${question.id}`"
          :key="question.id"
          :item-id="question.id"
          :question-public-display-config="
            questionPublicDisplayConfig(question)
          "
          :target-language="survey.primaryLanguage"
          :index="visibleIndex(question)"
          :visible="true"
          :editing="isEditingItem(question.id)"
          @hide-public-display-question="hideQuestion"
          @edit="editItem"
          @close="closeItem"
        ></public-display-question>
      </draggable>

      <div v-if="!hasVisibleQuestions">
        <p class="grey--text">
          No questions have been added to the public display.
        </p>
      </div>
    </div>

    <!-- Hidden questions -->
    <div class="d-flex">
      <h3
        class="grey--text text--darken-1 mb-4 font-weight-medium d-flex"
      >
        <v-icon class="grey--text pr-2">visibility_off</v-icon>
        Hidden Slides
      </h3>
      <v-btn
        class="ml-auto grey--text text--darken-1"
        :disabled="hasVisibleQuestions"
        @click="showAll"
      >
        <v-icon class="mr-2">visibility</v-icon>
        Show all
      </v-btn>
    </div>

    <draggable
      group="public-display-questions"
      :list="hiddenQuestions"
    >
      <public-display-question
        v-for="question in hiddenQuestions"
        :id="`item-${question.id}`"
        :key="question.id"
        :item-id="question.id"
        :question-public-display-config="
          questionPublicDisplayConfig(question)
        "
        :target-language="survey.primaryLanguage"
        :index="null"
        :visible="false"
        :editing="isEditingItem(question.id)"
        @show-public-display-question="showQuestion"
        @edit="editItem"
        @close="closeItem"
      ></public-display-question>
    </draggable>

    <div v-if="hiddenQuestions.length === 0">
      <p class="grey--text">
        All questions have been added to the public display.
      </p>
    </div>
  </div>
</template>

<script>
import draggable from 'vuedraggable';
import PublicDisplayConfigCard from './PublicDisplayConfigCard';
import PublicDisplayQuestion from './PublicDisplayQuestion';
import { EditableList } from '@/mixins/EditableList';
import { mapState, mapActions } from 'vuex';
import { naiveClone } from '@/utils';
import QuestionTypeConfig from '@/utils/QuestionTypeConfig';

export default {
  name: 'PublicDisplayConfigView',
  components: {
    draggable,
    PublicDisplayConfigCard,
    PublicDisplayQuestion
  },
  mixins: [EditableList],
  props: {},
  computed: {
    ...mapState('survey', ['survey']),
    ...mapState('publicDisplay', [
      'publicDisplayConfig',
      'configReady'
    ]),
    compatibleQuestions() {
      return this.survey.questions.filter(q => {
        const type = QuestionTypeConfig[q.config.questionType];
        return type ? type.publicDisplayCompatible : false;
      });
    },
    hasVisibleQuestions() {
      return this.visibleQuestions.length > 0;
    },
    questionPublicDisplayConfigs() {
      if (!this.configReady) {
        return [];
      }

      return this.publicDisplayConfig.questionConfigs.map(c => ({
        questionId: c.question.id,
        customTitle: c.customTitle,
        options: c.options
      }));
    },
    displayOrder() {
      return this.survey.questionOrderPublicDisplay;
    },
    visibleQuestions() {
      return this.compatibleQuestions
        .filter(q => this.displayOrder.includes(q.id))
        .sort(
          (q1, q2) =>
            this.displayOrder.indexOf(q1.id) -
            this.displayOrder.indexOf(q2.id)
        );
    },
    hiddenQuestions() {
      return this.compatibleQuestions.filter(
        q => !this.displayOrder.includes(q.id)
      );
    }
  },
  created() {
    this.fetchPublicDisplayConfig(this.survey.id);
  },
  methods: {
    ...mapActions({
      updateSurvey: 'survey/updateSurvey',
      fetchSurvey: 'survey/fetchSurvey',
      fetchPublicDisplayConfig:
        'publicDisplay/fetchPublicDisplayConfig'
    }),
    questionPublicDisplayConfig(question) {
      for (const config of this.questionPublicDisplayConfigs) {
        if (config.questionId == question.id) {
          return {
            question: question,
            customTitle: config.customTitle,
            options: config.options
          };
        }
      }

      return this.defaultQuestionPublicDisplayConfig(question);
    },
    defaultQuestionPublicDisplayConfig(question) {
      return {
        question: question,
        // Clone so that modifications don't impact the
        // question's original text.
        customTitle: naiveClone(question.text)
      };
    },
    visibleIndex(question) {
      return this.visibleQuestions.indexOf(question) + 1;
    },
    updateVisibleQuestions(draggableEvent) {
      if (
        draggableEvent.to === draggableEvent.from &&
        draggableEvent.newIndex === draggableEvent.oldIndex
      ) {
        return;
      }

      this.sendSurveyUpdate();
    },
    showAll() {
      this.visibleQuestions.push(...this.hiddenQuestions);
      this.hiddenQuestions.splice(0, this.hiddenQuestions.length);
      this.sendSurveyUpdate();
    },
    hideAll() {
      this.hiddenQuestions.push(...this.visibleQuestions);
      this.visibleQuestions.splice(0, this.visibleQuestions.length);
      this.sendSurveyUpdate();
    },
    showQuestion(question) {
      const questionIndex = this.hiddenQuestions.indexOf(question);
      this.hiddenQuestions.splice(questionIndex, 1);
      this.visibleQuestions.push(question);
      this.sendSurveyUpdate();
    },
    hideQuestion(question) {
      const questionIndex = this.visibleQuestions.indexOf(question);
      this.visibleQuestions.splice(questionIndex, 1);
      this.hiddenQuestions.push(question);
      this.sendSurveyUpdate();
    },
    sendSurveyUpdate() {
      const newQuestionOrder = this.visibleQuestions.map(q => q.id);
      this.survey.questionOrderPublicDisplay = newQuestionOrder;
      this.updateSurvey(this.survey);
    }
  }
};
</script>
