<template>
  <div>
    <v-card-title class="justify-center text-h1 font-weight-medium">
      {{ questionStatAverage }}
    </v-card-title>
    <v-card-text
      class="text-center text-h6 grey--text text--darken-2"
    >
      average
    </v-card-text>
  </div>
</template>

<script>
export default {
  props: {
    questionStat: {
      required: true,
      type: Object
    }
  },
  computed: {
    questionStatAverage() {
      return Math.round(this.questionStat.average);
    }
  }
};
</script>
