var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: { value: _vm.show, width: "50%", persistent: "" },
      on: {
        keydown: function($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
          )
            return null
          $event.stopPropagation()
          return _vm.closeDialog()
        }
      }
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            {
              staticClass: "text-h5 grey lighten-2",
              attrs: { "primary-title": "" }
            },
            [
              _c("question-type-icon", {
                staticClass: "mr-2 question-type-icon",
                attrs: { "question-type": _vm.questionType }
              }),
              _vm._v(
                " " +
                  _vm._s(_vm.questionTypeConfig.displayName) +
                  " Question Preview "
              )
            ],
            1
          ),
          _c(
            "v-card-text",
            { staticClass: "pb-0 preview-card" },
            [
              _c("question-preview-video", {
                attrs: { "question-type": _vm.questionType }
              })
            ],
            1
          ),
          _c("v-divider"),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { color: "primary", text: "" },
                  on: {
                    click: function($event) {
                      return _vm.close()
                    }
                  }
                },
                [_vm._v(" Close ")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }