<template>
  <div>
    <v-row>
      <v-col class="mb-0 text-caption grey--text text--darken-2">
        Emotions
      </v-col>
    </v-row>
    <v-row class="flex-column">
      <v-col cols="10" class="d-flex justify-space-between pt-0">
        <div v-for="emotion in emotions" :key="emotion">
          {{ emotion }}
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import QuestionTypeConfig from '@/utils/QuestionTypeConfig';

export default {
  name: 'EmotionWheelConfig',
  props: {
    targetLanguage: {
      type: String,
      required: true
    }
  },
  computed: {
    emotions() {
      return QuestionTypeConfig.EMOTION_WHEEL.defaultConfig().options.map(
        option => {
          const emotion = option.languageMap[this.targetLanguage];
          return emotion[0].toUpperCase() + emotion.substring(1);
        }
      );
    }
  }
};
</script>
