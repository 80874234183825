import { SurveyStatus } from '@/enums/index';

export const surveyHelper = {
  computed: {
    isPublished() {
      return this.survey.status === SurveyStatus.PUBLISHED;
    },
    languages() {
      return [this.survey.primaryLanguage].concat(
        this.survey.secondaryLanguages
      );
    },
    hasQuestions() {
      return this.survey.userQuestionCount > 0;
    },
    missingTranslationCount() {
      const sumTranslations = (total, status) =>
        total + status.missingTranslations;
      return this.languageTranslationStatus
        .filter(languageStatus => !languageStatus.complete)
        .reduce(sumTranslations, 0);
    },
    missingTranslations() {
      return this.survey.missingTranslationCount > 0;
    },
    languageTranslationStatus() {
      // If there aren't any questions, then none of the languages
      // have been completed (primary included)
      if (this.survey.userQuestionCount === 0) {
        return this.languages.map(language => {
          return {
            code: language,
            complete: false
          };
        });
      }
      return [
        {
          code: this.survey.primaryLanguage,
          complete: true
        }
      ].concat(
        this.survey.secondaryLanguages.map(language => {
          const missingTranslations = this.survey
            .incompleteQuestionTranslations[language];
          return {
            code: language,
            complete: !missingTranslations,
            missingTranslations: missingTranslations ?? 0
          };
        })
      );
    },
    issues() {
      const issues = [];

      if (!this.hasQuestions) {
        issues.push('No questions found');
      } else {
        if (this.missingTranslations) {
          const pluralPhrase =
            this.missingTranslationCount === 1
              ? 'translation'
              : 'translations';

          issues.push(
            `${this.missingTranslationCount} missing ${pluralPhrase}`
          );
        }
      }

      return issues;
    }
  }
};
