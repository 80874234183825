<template>
  <div>
    <div v-if="loading">
      <organisation-list-item-loader
        v-for="index in 5"
        :key="index"
      />
    </div>
    <organisation-list-item
      v-for="organisation in organisations"
      v-else
      :key="organisation.id"
      :organisation="organisation"
    />
  </div>
</template>

<script>
import OrganisationListItem from './OrganisationListItem';
import OrganisationListItemLoader from './Loader/OrganisationListItemLoader';
export default {
  components: { OrganisationListItem, OrganisationListItemLoader },
  props: {
    organisations: {
      type: Array,
      required: true
    },
    loading: {
      type: Boolean,
      required: true
    }
  }
};
</script>
