<template>
  <v-app>
    <component :is="layout">
      <router-view />
    </component>
  </v-app>
</template>

<script>
import { getInstance } from 'auth0-js';
import dropzoneBaseConfig from './config/dropzoneBaseConfig';

export default {
  name: 'App',
  computed: {
    layout() {
      const layout = this.$route.meta.layout || 'DefaultLayout';
      return () => import(`./layouts/${layout}.vue`);
    }
  },
  async created() {
    const authService = getInstance();
    await authService.finishedLoading();
    if (authService.isAuthenticated) {
      this.$store.commit('config/addConfiguration', {
        key: 'dropzoneBaseConfig',
        config: dropzoneBaseConfig({
          authToken: await authService.getTokenSilently()
        })
      });
    }
  }
};
</script>
