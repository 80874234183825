<template>
  <v-card class="pa-4 d-flex mr-5 justify-space-between align-center">
    <div class="mr-5">
      <v-skeleton-loader type="avatar"></v-skeleton-loader>
    </div>
    <div class="flex-grow-1">
      <div>
        <v-skeleton-loader type="heading"></v-skeleton-loader>
      </div>
      <div class="d-flex justify-end">
        <div class="theme-loader">
          <v-skeleton-loader type="text@1"></v-skeleton-loader>
        </div>
      </div>
      <div>
        <v-row>
          <v-icon medium class="pl-2" color="rgba(220, 220, 220)">
            circle
          </v-icon>
          <v-skeleton-loader
            class="pt-1 pl-1"
            width="15%"
            type="text@1"
          >
          </v-skeleton-loader>
        </v-row>
      </div>
    </div>
    <div>
      <v-skeleton-loader type="text@1"></v-skeleton-loader>
    </div>
  </v-card>
</template>
<script>
export default {
  name: 'QuestionLoader'
};
</script>

<style scoped>
.theme-loader {
  flex-basis: 20%;
}
</style>
