export default Object.freeze({
  DEMOGRAPHICS: {
    displayName: 'Demographics'
  },
  MARKETING: {
    displayName: 'Marketing'
  },
  EXPERIENCE: {
    displayName: 'Experience'
  },
  LOYALTY: {
    displayName: 'Loyalty'
  },
  IMPACT: {
    displayName: 'Impact'
  },
  SERVICES: {
    displayName: 'Services'
  },
  SOCIAL: {
    displayName: 'Social'
  },
  FUTURE: {
    displayName: 'Future'
  }
});
