<template>
  <v-card flat class="pa-4">
    <div class="d-flex flex-column h-100">
      <v-card-title class="pa-0 mb-2 font-weight-bold">
        <localised-text
          :language="displayLanguage"
          :string="questionStat.title"
        />
      </v-card-title>
      <div class="grey--text text--darken-1">
        <question-type-icon
          class="mr-2"
          :question-type="questionType"
        />
        <span>{{ questionTypeDisplayName }}</span>
      </div>
      <div class="grey--text text--lighten-1 mb-4">
        {{ questionStat.totalResponses }} out of
        {{ totalRespondents }} respondents
      </div>
      <v-card flat class="flex-grow-1">
        <v-container class="d-flex flex-column h-100">
          <div class="my-auto">
            <slot v-if="hasResponses" name="item" />
            <no-responses-placeholder v-else />
          </div>
        </v-container>
      </v-card>
    </div>
  </v-card>
</template>

<script>
import QuestionTypeIcon from '@/components/Common/QuestionTypeIcon';
import QuestionTypeConfig from '@/utils/QuestionTypeConfig';
import NoResponsesPlaceholder from './Items/NoResponsesDashboardItem';
import LocalisedText from '@/components/Common/LocalisedText';

export default {
  name: 'DashboardItem',
  components: {
    QuestionTypeIcon,
    NoResponsesPlaceholder,
    LocalisedText
  },
  props: {
    questionStat: {
      type: Object,
      required: true
    },
    totalRespondents: {
      type: Number,
      required: true
    },
    displayLanguage: {
      type: String,
      required: true
    }
  },
  data: () => ({
    titleMissingTranslation: false
  }),
  computed: {
    hasResponses() {
      return this.questionStat.totalResponses > 0;
    },
    questionType() {
      return this.questionStat.questionType;
    },
    questionTypeDisplayName() {
      return QuestionTypeConfig[this.questionType].displayName;
    }
  }
};
</script>

<style lang="scss" scoped>
.v-card__title {
  word-break: normal;
}
</style>
