<template>
  <div id="dropzone" ref="dropzone" class="dropzone">
    <div class="dz-message">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import Dropzone from 'dropzone';

export default {
  name: 'VueDropzone',
  props: {
    config: {
      type: Object,
      required: true
    },
    existingFileUrl: {
      type: String,
      required: false,
      default: null
    }
  },
  data: () => ({ dropzone: null }),
  computed: {
    baseDropzoneConfig() {
      let vm = this;
      return {
        init() {
          this.on('success', function onSuccess(file, response) {
            vm.$emit('success', { file, response });
          });
          this.on('addedfile', function onAddedFile(file) {
            vm.$emit('added-file', file);
          });
          this.on('sending', function onSending(file) {
            vm.$emit('sending', file);
          });
        }
      };
    }
  },
  mounted() {
    this.dropzone = new Dropzone(this.$refs.dropzone, {
      ...this.baseDropzoneConfig,
      ...this.config
    });

    if (this.existingFileUrl) {
      this.dropzone.displayExistingFile(
        { name: 'Existing Image' },
        this.existingFileUrl,
        null, // Optional callback for when the file is processed
        'Anonymous', // Added to the `img` tag for crossOrigin handling
        false // Tells Dropzone whether it should resize the image first
      );
    }
  }
};
</script>
