import ApiService from '../../../api';
import { naiveClone } from '@/utils';

const actions = {
  fetchOrganisations: {
    handler(context) {
      return ApiService.getApi()
        .get('organisation')
        .then(response => {
          context.commit('setOrganisations', response.data);
          context.commit('setLoaded', true);
        });
    }
  },
  fetchOrganisation: {
    handler(context, organisationId) {
      return ApiService.getApi()
        .get('organisation', organisationId)
        .then(response => {
          context.commit('setOrganisation', response.data);
          context.commit('setLoaded', true);
        });
    }
  },
  addOrganisation: {
    handler(context, organisation) {
      return ApiService.getApi()
        .post('organisation', organisation)
        .then(response => {
          context.commit('addOrganisation', response.data);
          return response.data;
        });
    }
  },
  editOrganisation: {
    handler(context, organisation) {
      const requestBody = naiveClone(organisation);
      if (!requestBody.ipadPassword) {
        requestBody.ipadPassword = null;
      }
      return ApiService.getApi()
        .put('organisation', organisation.id, requestBody)
        .then(response => {
          context.commit('editOrganisation', response.data);
          return response.data;
        });
    }
  },
  getImage: {
    handler(context, imageId) {
      return ApiService.getApi().get('image', imageId);
    }
  },
  addMember: {
    handler(context, { organisationId, member }) {
      return ApiService.getApi()
        .post(`organisation/${organisationId}/membership`, member)
        .then(response => {
          context.commit('addMember', {
            organisationId,
            member: response.data
          });
        });
    }
  },
  removeMember: {
    handler(context, { organisationId, member }) {
      return ApiService.getApi()
        .delete(
          `organisation/${organisationId}/membership`,
          member.id
        )
        .then(() => {
          context.commit('removeMember', { organisationId, member });
        });
    }
  },
  resendInvite: {
    handler(context, { organisationId, member }) {
      return ApiService.getApi()
        .post(
          `organisation/${organisationId}/membership/${member.id}/resendInvite`
        )
        .catch(error => {
          // Flash? idk
          console.log(error);
        });
    }
  },
  editMember: {
    handler(context, { organisationId, member }) {
      const requestBody = {
        userRole: member.userRole
      };
      return ApiService.getApi()
        .put(
          `organisation/${organisationId}/membership`,
          member.id,
          requestBody
        )
        .then(response => {
          context.commit('editMember', response.data);
          return response.data;
        });
    }
  }
};

export default actions;
