var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-row",
        { staticClass: "grey lighten-1" },
        [
          _c("v-spacer"),
          _c(
            "v-col",
            {
              staticClass: "white text-center my-0 px-0",
              attrs: { cols: _vm.getWidth }
            },
            [_c("gender-male", { staticClass: "male" })],
            1
          ),
          _c("v-spacer")
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c("v-col", { staticClass: "text-h6 font-weight-bold text-center" }, [
            _vm._v(" " + _vm._s(_vm.percentage) + "% ")
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }