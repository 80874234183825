// date: '2020-05-31T14:00:00Z' to: '2020-05-31'
export function datetimeDate(datetime) {
  return datetime.substr(0, 10);
}
// date: '2020-05-31T14:00:00Z' to: 'T14:00:00Z'
export function datetimeOffset(datetime) {
  return datetime.substr(10);
}

export const dateFormat = {
  default: 'ddd DD MMM YYYY',
  iso8601: 'YYYY-MM-DD'
};

// Clones an object by stringifying it and then
// deserialising it. This will lose any complex
// data such as: Date class objects, functions,
// etc.
export function naiveClone(obj) {
  return JSON.parse(JSON.stringify(obj));
}

export function arrayInsertAt(array, index, value) {
  array.splice(index, 0, value);
}
