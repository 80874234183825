<template>
  <v-form ref="form">
    <v-card-text>
      <v-text-field
        v-if="!isEditing"
        v-model="memberValue.email"
        prepend-icon="mail"
        label="Email Address"
        :rules="validationRules.email"
        validate-on-blur
        persistent-hint
        hint="Required *"
      ></v-text-field>
      <v-select
        v-model="memberValue.userRole"
        prepend-icon="person"
        :items="userRoles"
        item-text="name"
        item-value="key"
        :rules="validationRules.userRole"
        :hint="selectedUserRoleDescription"
        label="Role"
        persistent-hint
      ></v-select>
    </v-card-text>
    <v-card-actions class="pa-6">
      <v-spacer></v-spacer>
      <v-btn class="mr-0" @click="closeDialog">
        Cancel
      </v-btn>
      <v-btn color="success" class="ml-4" @click="submit">
        {{ submitButtonText }}
      </v-btn>
    </v-card-actions>
  </v-form>
</template>

<script>
import { mapActions } from 'vuex';
import { isValidEmail } from '@/utils/validation';

const USER_ROLES = {
  ORG_ADMIN: {
    name: 'Admin',
    key: 'ORG_ADMIN',
    description:
      'Admins can create and publish surveys and manage users within the organisation.'
  },
  SURVEY_ADMIN: {
    name: 'Survey Creator',
    key: 'SURVEY_ADMIN',
    description: 'Survey Creators can create and publish surveys.'
  }
};

export default {
  name: 'MemberForm',
  props: {
    member: {
      type: Object,
      default: () => ({
        email: undefined,
        userRole: undefined
      })
    },
    organisation: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      memberValue: this.member,
      error: undefined
    };
  },
  computed: {
    isEditing() {
      return !!this.member.id;
    },
    validationRules() {
      return {
        email: [
          v => !!v || 'Email Address is required',
          v => isValidEmail(v) || 'Not a valid email address'
        ],
        userRole: [v => !!v || 'User Role is required']
      };
    },
    submitButtonText() {
      return this.isEditing ? 'Save' : 'Add';
    },
    selectedUserRoleDescription() {
      return this.memberValue.userRole == null
        ? '* Required'
        : USER_ROLES[this.memberValue.userRole].description;
    },
    userRoles() {
      return Object.values(USER_ROLES);
    }
  },
  methods: {
    ...mapActions('organisation', ['addMember', 'editMember']),
    closeDialog() {
      this.$emit('close');
    },
    submit() {
      if (!this.$refs.form.validate()) return;

      const save = this.isEditing ? this.editMember : this.addMember;

      save({
        organisationId: this.organisation.id,
        member: this.memberValue
      })
        .then(() => {
          this.closeDialog();
        })
        .catch(error => {
          if (error.response.status == 409) {
            this.error = `${this.model.email} already belongs to ${this.organisation.name}.`;
          } else {
            this.error =
              'Something went wrong saving your changes. This may be an issue for us to fix, so please try again later.';
          }
        });
    }
  }
};
</script>
