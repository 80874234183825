<template>
  <action-modal
    title="Clear Responses"
    :show="show"
    @cancel="$emit('cancel')"
  >
    <template v-slot:body>
      <v-card-text>
        This survey has
        <span class="font-weight-bold black--text">
          {{ survey.responseCount }}</span
        >
        responses. If you choose to clear the responses, they will no
        longer be accessible and the data will be permanently deleted.
      </v-card-text>
    </template>
    <template v-slot:actions>
      <v-btn color="error" @click="$emit('clear-responses')">
        Clear Responses
      </v-btn>
    </template>
  </action-modal>
</template>

<script>
import ActionModal from '@/components/Common/ActionModal';
export default {
  components: {
    ActionModal
  },
  props: {
    survey: {
      type: Object,
      required: true
    },
    show: {
      type: Boolean,
      required: true
    }
  }
};
</script>
