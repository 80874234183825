<template>
  <div>
    <v-snackbar
      v-model="liveDataPaused"
      color="primary"
      timeout="-1"
      multi-line
      vertical
      class="pb-4"
    >
      <v-container class="pa-0 d-flex">
        <v-icon class="mr-3 mb-2">offline_bolt</v-icon>
        You've been inactive for over 15 minutes, so the data on this
        page may not be up to date. Would you like to reload your
        surveys now?
      </v-container>
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="enableLiveSurveyData()">
          Reload surveys
        </v-btn>
      </template>
    </v-snackbar>

    <survey-grid
      :surveys="surveys"
      :organisations="organisations"
      :loading="!loaded"
      @survey-action-request="handleSurveyActionRequest"
      @add-survey-card-click="showSurveyDialogForNewSurvey"
    />
    <survey-dialog
      v-if="showSurveyDialog"
      :show.sync="showDialog"
      :survey.sync="surveyInDialog"
      :organisations="organisations"
      @save="handleSurveySaved"
    />
    <survey-action-dialog
      v-else-if="showSurveyActionDialog"
      :show.sync="showDialog"
      :survey.sync="surveyInDialog"
      :action="surveyAction"
      @dialog-action-confirmed="handleDialogActionConfirmed"
    />
    <survey-duplicate-dialog
      v-else-if="showSurveyDuplicateDialog"
      :show.sync="showDialog"
      :survey.sync="surveyInDialog"
      :organisations="organisations"
    />
  </div>
</template>

<script>
import SurveyGrid from './SurveyGrid';
import SurveyDialog from '@/components/Survey/SurveyDialog';
import SurveyActionDialog from '../SurveyActionDialog';
import SurveyDuplicateDialog from '@/components/Survey/Edit/SurveyDuplicateDialog';
import { DialogState, SurveyActions, SurveyStatus } from '@/enums';
import { mapActions, mapGetters, mapState } from 'vuex';
import moment from 'moment';
import time from '@/utils/time';

export default {
  components: {
    SurveyGrid,
    SurveyDialog,
    SurveyActionDialog,
    SurveyDuplicateDialog
  },
  data: () => ({
    showDialog: false,
    dialogState: DialogState.INACTIVE,
    surveyInDialog: null,
    surveyAction: null,
    liveDataPaused: false
  }),
  computed: {
    ...mapState('organisation', ['organisations']),
    ...mapGetters('survey', ['loaded', 'surveys', 'survey']),
    showSurveyDialog() {
      return (
        this.surveyInDialog &&
        (this.dialogState == DialogState.CREATE ||
          this.dialogState == DialogState.EDIT)
      );
    },
    showSurveyActionDialog() {
      return (
        this.surveyInDialog && this.dialogState == DialogState.ACTION
      );
    },
    showSurveyDuplicateDialog() {
      return (
        this.surveyInDialog &&
        !!this.surveyInDialog.questions &&
        this.dialogState == DialogState.DUPLICATE
      );
    },
    defaultSurvey() {
      return Object.assign(
        {
          surveyStart: moment().toISOString(),
          surveyEnd: moment().toISOString(),
          imageDeleted: false,
          base64EncodedImageData: null,
          introBackgroundImageId: ''
        },
        this.defaultOrganisationDetails
      );
    },
    defaultOrganisationDetails() {
      if (this.organisations.length === 1) {
        const {
          id,
          primaryLanguage,
          secondaryLanguages
        } = this.organisations[0];
        return {
          organisationId: id,
          primaryLanguage,
          secondaryLanguages
        };
      } else {
        return {};
      }
    }
  },
  mounted() {
    this.enableLiveSurveyData();
  },
  destroyed() {
    clearInterval(this.reloadInterval);
  },
  methods: {
    ...mapActions('survey', [
      'addSurvey',
      'updateSurvey',
      'deleteSurvey',
      'fetchSurvey',
      'fetchSurveys'
    ]),
    enableLiveSurveyData() {
      this.fetchSurveys();
      this.liveDataPaused = false;
      if (this.reloadInterval) clearInterval(this.reloadInterval);
      this.reloadInterval = setInterval(
        () => this.fetchSurveys(),
        10 * time.ONE_SECOND_MS
      );
      setTimeout(() => {
        this.liveDataPaused = true;
        clearInterval(this.reloadInterval);
      }, 15 * time.ONE_MINUTE_MS);
    },
    handleSurveyActionRequest(surveyAction) {
      this.surveyInDialog = {
        ...this.defaultSurvey,
        ...surveyAction.survey
      };
      if (surveyAction.action == SurveyActions.EDIT) {
        this.dialogState = DialogState.EDIT;
      } else if (surveyAction.action == SurveyActions.DUPLICATE) {
        this.dialogState = DialogState.DUPLICATE;
        this.fetchSurvey(surveyAction.survey.id).then(res => {
          this.surveyInDialog = res;
        });
      } else {
        this.dialogState = DialogState.ACTION;
        this.surveyAction = surveyAction.action;
      }
      this.showDialog = true;
    },
    showSurveyDialogForNewSurvey() {
      this.surveyInDialog = this.defaultSurvey;
      this.dialogState = DialogState.CREATE;
      this.showDialog = true;
    },
    handleSurveySaved(survey) {
      if (!survey.id) {
        Object.assign(survey, {
          surveyGroupId: null,
          status: SurveyStatus.DRAFT,
          questionOrder: []
        });
        if (this.organisations.length === 1) {
          survey.organisationId = this.organisations[0].id;
        }
        this.addSurvey(survey).then(response => {
          this.$router.push({
            path: `/surveys/${response.id}`
          });
        });
      } else {
        survey.base64EncodedImageData = null;
        survey.imageDeleted = false;
        this.updateSurvey(survey);
      }
    },
    handleDialogActionConfirmed(action) {
      const survey = { ...this.surveyInDialog };
      if (action != SurveyActions.DELETE) {
        survey.base64EncodedImageData = null;
        survey.imageDeleted = false;
        if (survey.status == SurveyStatus.PUBLISHED) {
          survey.status = SurveyStatus.DRAFT;
        } else {
          survey.status = SurveyStatus.PUBLISHED;
        }

        this.updateSurvey(survey);
      } else this.deleteSurvey(survey.id);
    }
  }
};
</script>
