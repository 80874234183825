var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      { staticClass: "d-flex justify-space-around" },
      [
        _c("word-cloud", {
          attrs: {
            responses: _vm.questionStat.responses,
            weights: _vm.weights,
            width: _vm.responsiveWidth,
            height: 800
          }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }