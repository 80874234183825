var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "video",
    {
      staticClass: "mt-5 question-preview-video",
      attrs: { width: "100%", height: "100%", autoplay: "", loop: "" }
    },
    [
      _c("source", {
        attrs: { src: _vm.resourceLink(_vm.questionType), type: "video/mp4" }
      }),
      _vm._v(" Your browser does not support HTML video. ")
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }