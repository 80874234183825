var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    _vm._l(_vm.items, function(item, i) {
      return _c(
        "v-row",
        { key: `emotion-${i}`, staticClass: "ma-0 pa-0" },
        [
          _c(
            "v-col",
            { staticClass: "ma-0 my-n1 pa-0", attrs: { cols: "2" } },
            [
              _c("div", { staticClass: "pt-1" }, [
                _c("svg", { attrs: { width: "24", height: "24" } }, [
                  _c("circle", {
                    attrs: {
                      cx: "12",
                      cy: "12",
                      r: "10",
                      fill: item.colour,
                      "fill-opacity": "0.5",
                      stroke: item.colour,
                      "stroke-width": "2"
                    }
                  })
                ])
              ])
            ]
          ),
          _c(
            "v-col",
            { staticClass: "pa-0", attrs: { cols: "7" } },
            [
              _c("localised-text", {
                attrs: { language: _vm.displayLanguage, string: item.label }
              })
            ],
            1
          ),
          _c("v-col", { staticClass: "pa-0", attrs: { cols: "3" } }, [
            _c("div", { staticClass: "font-weight-bold" }, [
              _vm._v(_vm._s(item.value))
            ])
          ])
        ],
        1
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }