<template>
  <div class="d-inline-block">
    <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
        <v-avatar
          v-bind="attrs"
          class="white--text text-caption"
          :class="color"
          height="28"
          min-width="28"
          width="28"
          @click="$emit('click')"
          v-on="on"
        >
          {{ displayCode(language) }}
        </v-avatar>
      </template>
      <span>{{ languageName(language) }}</span>
    </v-tooltip>
  </div>
</template>

<script>
import { getLanguage } from '../../utils/LanguageOptions';

export default {
  name: 'LanguageAvatar',
  props: {
    language: {
      type: Object,
      required: true
    },
    color: {
      type: String,
      required: false,
      default: 'default'
    }
  },
  methods: {
    displayCode(language) {
      const displayCode = getLanguage(language.code).displayCode;
      return displayCode ? displayCode : language.code;
    },
    languageName(language) {
      return getLanguage(language.code).name;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../scss/main.scss';
.complete {
  background-color: var(--v-success-base);
}

.default {
  background-color: map-get($grey, 'lighten-1');
}
</style>
