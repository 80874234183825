var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("v-card-title", { staticClass: "pt-0 text-h5 font-weight-medium" }, [
        _vm._v(" " + _vm._s(_vm.region) + " ")
      ]),
      _c(
        "v-card-text",
        { staticClass: "py-0 font-weight-bold" },
        _vm._l(_vm.topNCountries, function(countryCount, i) {
          return _c(
            "v-row",
            { key: i, staticClass: "text-subtitle-1" },
            [
              _c(
                "v-col",
                {
                  staticClass: "py-2 font-weight-medium black--text",
                  attrs: { cols: "12", md: "7" }
                },
                [_vm._v(" " + _vm._s(_vm.countryName(countryCount.word)) + " ")]
              ),
              _c(
                "v-col",
                {
                  staticClass: "py-2 text-right",
                  attrs: { cols: "12", md: "5" }
                },
                [
                  _c("span", { staticClass: "black--text" }, [
                    _vm._v(" " + _vm._s(countryCount.count) + " ")
                  ]),
                  _vm._v(
                    " (" + _vm._s(_vm.percentage(countryCount.count)) + "%) "
                  )
                ]
              )
            ],
            1
          )
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }