<template>
  <div>
    <localised-text
      :language="displayLanguage"
      :string="questionStat.values"
    />
    <canvas ref="bar-chart"></canvas>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Chart from 'chart.js';
import LocalisedText from '@/components/Common/LocalisedText';
import { translateStringOrFallback } from '@/utils/language';

export default {
  components: {
    LocalisedText
  },
  props: {
    questionStat: {
      required: true,
      type: Object
    },
    displayLanguage: {
      type: String,
      required: true
    }
  },
  data: () => ({
    chart: null
  }),
  computed: {
    ...mapGetters('survey', ['primaryLanguage']),
    getValues() {
      return this.questionStat.values.map(
        value => value.average * 100
      );
    },
    getLabels() {
      return this.questionStat.values.map(
        value =>
          translateStringOrFallback(
            value.label,
            this.displayLanguage,
            this.survey ? this.primaryLanguage : this.displayLanguage
          ).string
      );
    }
  },
  watch: {
    displayLanguage() {
      this.chart.data.labels = this.getLabels;
      this.chart.update();
    }
  },
  mounted() {
    this.createChart();
  },
  methods: {
    createChart() {
      this.$refs['bar-chart'].height = 150;
      this.chart = new Chart(this.$refs['bar-chart'], {
        type: 'horizontalBar',
        data: {
          labels: this.getLabels,
          datasets: [
            {
              data: this.getValues,
              backgroundColor: this.$vuetify.theme.currentTheme
                .primary
            },
            {
              data: this.getValues.map(val => {
                return 100 - val;
              })
            }
          ]
        },
        options: {
          legend: {
            display: false
          },
          tooltips: {
            callbacks: {
              label: tooltipItem => {
                return tooltipItem.xLabel.toFixed(0) + '%';
              }
            }
          },
          scales: {
            xAxes: [
              {
                gridLines: {
                  display: false
                },
                ticks: {
                  display: false
                },
                stacked: true
              }
            ],
            yAxes: [
              {
                gridLines: {
                  display: false
                },
                ticks: {
                  fontColor: '#000000',
                  fontFamily: 'museo-sans',
                  fontSize: '16'
                },
                stacked: true
              }
            ]
          }
        }
      });
    }
  }
};
</script>
