var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: { viewBox: `0 0 ${_vm.width} ${_vm.height}`, overflow: "visible" }
    },
    [
      _c("rect", {
        attrs: {
          y: _vm.linePosY,
          width: _vm.width,
          height: _vm.lineWidth,
          fill: _vm.theme.slider.barColour
        }
      }),
      _vm._l(_vm.ticks, function(tick, i) {
        return _c("rect", {
          key: i,
          attrs: {
            x: tick,
            y: _vm.tickPosY,
            width: _vm.lineWidth,
            height: _vm.tickHeight,
            fill: _vm.theme.slider.tickColour
          }
        })
      }),
      _c(
        "linearGradient",
        { attrs: { id: "circleFill" } },
        [
          _c("stop", {
            attrs: {
              offset: "0",
              "stop-color": _vm.theme.slider.gradient.start
            }
          }),
          _c("stop", {
            attrs: {
              offset: "100%",
              "stop-color": _vm.theme.slider.gradient.end
            }
          })
        ],
        1
      ),
      _c("circle", {
        attrs: {
          cx: _vm.circlePos.x,
          cy: _vm.circlePos.y,
          r: _vm.radius,
          fill: "url(#circleFill)"
        }
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }