<template>
  <v-row>
    <v-col cols="8">
      <vertical-bar-chart :data="chartData" :config="chartConfig" />
    </v-col>
    <v-col cols="4">
      <v-container class="fill-height">
        <v-card flat>
          <v-card-text>
            <div class="text-body-1">
              {{ accompanyingStatTitle }}
            </div>
            <div
              class="text-center title black--text text-no-wrap font-weight-bold"
            >
              {{ accompanyingStatValue }}
            </div>
          </v-card-text>
        </v-card>
      </v-container>
    </v-col>
  </v-row>
</template>

<script>
import VerticalBarChart from '@/components/Visualisations/VerticalBarChart';

export default {
  name: 'Rating0To10Visualisation',
  components: { VerticalBarChart },
  props: {
    questionStat: {
      required: true,
      type: Object
    }
  },
  computed: {
    chartData() {
      return Object.values(this.questionStat.ratings);
    },
    chartConfig() {
      return {
        labels: Object.keys(this.questionStat.ratings)
      };
    },
    accompanyingStatTitle() {
      return this.hasNetPromoterScore
        ? 'Net Promoter Score'
        : 'Average';
    },
    accompanyingStatValue() {
      return this.hasNetPromoterScore
        ? this.netPromoterScore
        : this.questionAverage;
    },
    hasNetPromoterScore() {
      return !!this.questionStat.netPromoterScore;
    },
    netPromoterScore() {
      return this.questionStat.netPromoterScore.toFixed(2);
    },
    questionAverage() {
      return Math.round(this.questionStat.average);
    }
  }
};
</script>
