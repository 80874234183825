<template>
  <question-list
    class="mt-4"
    :target-language="survey.primaryLanguage"
  />
</template>

<script>
import QuestionList from './QuestionList';
import { surveyHelper } from '@/mixins/SuperUtils';

export default {
  name: 'QuestionsView',
  components: {
    QuestionList
  },
  mixins: [surveyHelper],
  props: {
    survey: {
      type: Object,
      required: true
    },
    questions: {
      type: Array,
      required: true
    },
    dataLoading: {
      type: Boolean,
      required: true
    }
  }
};
</script>
