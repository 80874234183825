var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { staticClass: "pl-7 pa-5 mb-4", on: { click: _vm.openOrganisation } },
    [
      _c("v-row", { attrs: { "no-gutters": "", align: "center" } }, [
        _vm._v(" " + _vm._s(_vm.organisation.name) + " ")
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }