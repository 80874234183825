var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      style: _vm.style,
      attrs: {
        viewBox: "0 0 50 50",
        version: "1.1",
        xmlns: "http://www.w3.org/2000/svg",
        "xmlns:xlink": "http://www.w3.org/1999/xlink",
        height: `${_vm.size}px`
      }
    },
    [
      _c(
        "linearGradient",
        {
          attrs: {
            id: _vm.gradientIdString,
            gradientTransform: `rotate(${_vm.gradientRotation})`
          }
        },
        _vm._l(_vm.colors, function(color) {
          return _c("stop", {
            key: color.offset,
            attrs: { offset: color.offset, "stop-color": color.stopColor }
          })
        }),
        1
      ),
      _c("path", {
        attrs: {
          fill: `url(#${_vm.gradientIdString})`,
          opacity: _vm.opacity,
          d:
            "M25,50 C11.19288,50 0,38.80712 0,25 C0,11.19288 11.19288,0 25,0 C38.80712,0 50,11.19288 50,25 C50,38.80712 38.80712,50 25,50 Z"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }