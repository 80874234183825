<template>
  <div>
    <v-row class="justify-center align-center">
      <v-img :src="getImgUrl" max-width="100px"></v-img>
    </v-row>
    <v-row>
      <v-card-text class="text-h6 font-weight-bold text-center pb-0">
        {{ sound }}
      </v-card-text>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    questionStat: {
      required: true,
      type: Object
    }
  },
  computed: {
    wordOptions() {
      return [
        'Inaudible',
        'Quiet',
        'Mellow',
        'Sonorous',
        'Pulsating',
        'Resonant',
        'Rhythmic',
        'Loud',
        'Extremely Loud',
        'Deafening'
      ];
    },
    getImgUrl() {
      return require('../../../assets/pngs/wave-line/' +
        this.sound.replace(/\s/g, '-').toLowerCase() +
        '.png');
    },
    sound() {
      let index = Math.round(
        this.questionStat.average * this.wordOptions.length
      );
      if (index >= this.wordOptions.length) {
        index = this.wordOptions.length - 1;
      }

      return this.wordOptions[index];
    }
  }
};
</script>
