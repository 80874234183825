const mutations = {
  setOrganisations: (state, organisations) => {
    state.organisations = organisations;
  },
  setOrganisation: (state, organisation) => {
    state.organisation = organisation;
    if (
      !state.organisations.find(org => org.id === organisation.id)
    ) {
      state.organisations.push(organisation);
    }
  },
  setLoaded: (state, loaded) => (state.loaded = loaded),
  addOrganisation: (state, organisation) =>
    state.organisations.push(organisation),
  editOrganisation: (state, modifiedOrganisation) => {
    let editOrganisation = state.organisations.find(
      organisation => organisation.id === modifiedOrganisation.id
    );

    editOrganisation = Object.assign(
      {
        members: editOrganisation.members,
        surveyGroups: editOrganisation.surveyGroups
      },
      modifiedOrganisation
    );

    state.organisations = state.organisations.map(
      existingOrganisation =>
        existingOrganisation.id === modifiedOrganisation.id
          ? editOrganisation
          : existingOrganisation
    );

    state.organisation = editOrganisation;
  },
  addMember: (state, { organisationId, member }) => {
    const correctOrganisationAvailable =
      state.organisation == null ||
      state.organisation.id != organisationId;
    if (correctOrganisationAvailable) return; // Do Nothing
    state.organisation.members.push(member);
  },
  removeMember: (state, { organisationId, member }) => {
    const correctOrganisationAvailable =
      state.organisation == null ||
      state.organisation.id != organisationId;
    if (correctOrganisationAvailable) return; // Do Nothing
    state.organisation.members.splice(
      state.organisation.members.indexOf(member),
      1
    );
  },
  editMember: (state, modifiedMember) => {
    state.organisation.members = state.organisation.members.map(
      existingMember =>
        existingMember.id === modifiedMember.id
          ? modifiedMember
          : existingMember
    );
  }
};

export default mutations;
