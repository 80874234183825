import ApiService from '../../../api';

const actions = {
  deleteQuestionResponses(context, questionId) {
    return ApiService.getApi().delete(
      'questionResponses',
      questionId
    );
  }
};

export default {
  namespaced: true,
  actions
};
