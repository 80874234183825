<template>
  <div>
    <v-row class="justify-center">
      <v-img :src="getSpikyBallImgUrl" max-width="200px" />
    </v-row>
    <v-card-text
      class="text-h6 font-weight-bold black--text text-center pt-0"
    >
      {{ emotion }}
    </v-card-text>
  </div>
</template>

<script>
export default {
  props: {
    questionStat: {
      required: true,
      type: Object
    }
  },
  computed: {
    emotion() {
      let index = Math.round(
        this.questionStat.average * this.emotionsList.length
      );
      if (index >= this.emotionsList.length) {
        index = this.emotionsList.length - 1;
      }

      return this.emotionsList[index];
    },
    getSpikyBallImgUrl() {
      return require('../../../assets/pngs/spiky-ball/' +
        this.wordBallMapping[this.emotion]);
    },
    emotionsList() {
      return Object.keys(this.wordBallMapping);
    },
    wordBallMapping() {
      return {
        Serene: 'sphere01-graph-black.png',
        'Very Comfortable': 'sphere02-graph-black.png',
        Restful: 'sphere03-graph-black.png',
        Smooth: 'sphere04-graph-black.png',
        Relaxed: 'sphere05-graph-black.png',
        Uncomfortable: 'sphere06-graph-black.png',
        Difficult: 'sphere07-graph-black.png',
        Rough: 'sphere08-graph-black.png',
        Sore: 'sphere09-graph-black.png',
        Painful: 'sphere10-graph-black.png',
        'Very Uncomfortable': 'sphere11-graph-black.png',
        Distressing: 'sphere12-graph-black.png'
      };
    }
  }
};
</script>

<style scoped lang="scss">
.spiky-ball-image {
  max-width: 50%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
</style>
