<template>
  <action-modal
    v-if="showDialog"
    show
    :title="modalTitle"
    @cancel="closeDialog()"
  >
    <template v-slot:body>
      <v-card-text>
        {{ modalText }}
        <span v-if="showFormattedResponses">
          <br />{{ formattedResponses }}
        </span>
      </v-card-text>
    </template>
    <template v-slot:actions>
      <v-btn :color="actionButtonColor" @click="confirmDialogAction">
        {{ action }}
      </v-btn>
    </template>
  </action-modal>
</template>

<script>
import ActionModal from '@/components/Common/ActionModal';
import { surveyHelper } from '@/mixins/SurveyHelper';
import { SurveyActions } from '@/enums/index';

export default {
  name: 'SurveyActionDialog',
  components: {
    ActionModal
  },
  mixins: [surveyHelper],
  props: {
    survey: {
      type: Object,
      required: true
    },
    show: {
      type: Boolean,
      required: false,
      default: null
    },
    action: {
      type: String,
      required: false,
      default: ''
    }
  },
  data() {
    return {
      actionButtons: {
        [SurveyActions.PUBLISH]: { color: 'success' },
        [SurveyActions.DELETE]: { color: 'error' }
      }
    };
  },
  computed: {
    showDialog() {
      if (this.show != null) {
        return this.show;
      } else {
        return Boolean(this.survey);
      }
    },
    formattedResponses() {
      const responseCount = this.survey.responseCount;
      if (responseCount < 1) {
        return 'This survey has no responses.';
      } else {
        return responseCount === 1
          ? 'This survey has 1 response.'
          : `This survey has ${responseCount} responses.`;
      }
    },
    modalTitle() {
      return `${this.action.toLowerCase()} Survey`;
    },
    modalText() {
      return `Are you sure you want to ${this.action.toLowerCase()} "${
        this.survey.title
      }"?`;
    },
    showFormattedResponses() {
      return this.isPublished || this.action === SurveyActions.DELETE;
    },
    actionButtonColor() {
      return this.actionButtons[this.action]
        ? this.actionButtons[this.action].color
        : 'primary';
    }
  },
  methods: {
    confirmDialogAction() {
      this.$emit('dialog-action-confirmed', this.action);
      this.closeDialog();
    },
    closeDialog() {
      if (this.show != null) {
        this.$emit('update:show', false);
      } else {
        this.$emit('update:survey', null);
      }
    }
  }
};
</script>
