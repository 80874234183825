var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "text-center" },
    _vm._l(_vm.rankedWords, function(rankedWord, i) {
      return _c(
        "div",
        { key: `word-${i}`, staticClass: "word my-0" },
        [
          _c("localised-text", {
            attrs: { language: _vm.displayLanguage, string: rankedWord.word }
          })
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }