var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("v-checkbox", {
    attrs: {
      disabled: _vm.questionHasResponses,
      label: "Include non-binary gender type"
    },
    model: {
      value: _vm.value.includeNonBinary,
      callback: function($$v) {
        _vm.$set(_vm.value, "includeNonBinary", $$v)
      },
      expression: "value.includeNonBinary"
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }