import ApiService from '../../../api';

const state = {
  currentUser: undefined
};

const actions = {
  fetchCurrentUser(context) {
    return ApiService.getApi()
      .get('/user')
      .then(response =>
        context.commit('setCurrentUser', response.data)
      );
  }
};

const mutations = {
  setCurrentUser(state, user) {
    state.currentUser = user;
  }
};

const getters = {
  currentUser: state => state.currentUser,
  currentUserIsSuper: state =>
    state.currentUser && state.currentUser.isSuper,
  currentUserIsAnOrgAdmin: state =>
    state.currentUser &&
    state.currentUser.memberships.filter(
      membership => membership.userRole === 'ORG_ADMIN'
    ).length > 0,
  currentUserHasManyOrganisations: state =>
    state.currentUser.memberships.length > 1
};

export default {
  namespaced: true,
  actions,
  mutations,
  state,
  getters
};
