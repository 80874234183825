<template>
  <v-chip>
    <span v-if="showName" class="language mr-2">{{
      language.name
    }}</span>
    <language-avatar :language="language" />
  </v-chip>
</template>

<script>
import LanguageAvatar from './LanguageAvatar';

export default {
  name: 'LanguageChip',
  components: { LanguageAvatar },
  props: {
    language: {
      type: Object || String,
      required: true
    },
    showName: {
      type: Boolean,
      required: false,
      default: true
    }
  }
};
</script>

<style scoped>
.language {
  line-height: 28px;
}
</style>
