import axios from 'axios';

const publicDashboardAxios = axios.create({
  baseURL: `${process.env.VUE_APP_API_URL}/dashboard/`
});

export default {
  namespaced: true,
  actions: {
    fetchPublicDashboardStats: {
      handler(context, { surveyId, startDate, endDate, accessCode }) {
        return publicDashboardAxios
          .get(surveyId, {
            params: {
              startDate: startDate,
              endDate: endDate
            },
            headers: { accessCode }
          })
          .then(response => {
            return response.data;
          });
      }
    },
    validateCode: {
      handler(context, { surveyId, accessCode }) {
        return publicDashboardAxios
          .get(surveyId, {
            headers: { accessCode }
          })
          .then(response => {
            return response.status == 200;
          });
      }
    }
  },
  getters: {}
};
