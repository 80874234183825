<template>
  <v-dialog
    :value="show"
    width="65%"
    persistent
    @keydown.esc.stop="close()"
  >
    <v-card>
      <v-card-title class="text-h5 grey lighten-2" primary-title>
        <question-type-icon
          :question-type="selectedQuestionType"
          class="mr-2 question-type-icon"
        />
        {{ questionTypeDisplayName(selectedQuestionType) }} Question
        Preview
      </v-card-title>

      <v-card-text class="pb-0 preview-card">
        <v-row no-gutters>
          <v-col cols="3" class="ml-n5 mr-5 question-type-column">
            <v-list class="question-type-list">
              <v-subheader>Question Types</v-subheader>
              <v-list-item-group
                v-model="selectedTypeIndex"
                value="questionTypes"
                color="primary"
              >
                <v-list-item
                  v-for="(type, i) in questionTypes"
                  :key="`question-type-selection-${i}`"
                  @click="setSelectedQuestionType(type)"
                >
                  <question-type-icon
                    :question-type="type"
                    class="mr-2 question-type-icon"
                  />
                  <v-list-item-content>
                    <v-list-item-title
                      v-text="questionTypeDisplayName(type)"
                    ></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-col>
          <v-col cols="9">
            <div v-for="(type, i) in questionTypes" :key="i">
              <question-preview-video
                v-if="type === selectedQuestionType"
                :question-type="type"
              />
            </div>
          </v-col>
        </v-row>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="close()">
          Close
        </v-btn>
        <v-btn
          color="success"
          :disabled="!questionTypeChanged"
          @click="selectQuestionType()"
        >
          Select Type
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import QuestionTypeIcon from '@/components/Common/QuestionTypeIcon';
import QuestionPreviewVideo from '../View/QuestionPreviewVideo';
import Question from '@/models/Question';
import { QuestionTypes } from '@/utils/QuestionTypeConfig';
import QuestionTypeConfig from '@/utils/QuestionTypeConfig';
import QuestionType from '@/enums/questionType';

export default {
  name: 'QuestionPreviewDialog',
  components: {
    QuestionTypeIcon,
    QuestionPreviewVideo
  },
  props: {
    preDialogSelectedType: {
      type: String,
      required: true
    },
    show: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      selectedQuestionType:
        this.preDialogSelectedType != QuestionType.NO_TYPE
          ? this.preDialogSelectedType
          : Question.defaultQuestionType(),
      selectedTypeIndex: this.questionTypeIndex(
        this.preDialogSelectedType
      )
    };
  },
  computed: {
    questionTypes() {
      return QuestionTypes;
    },
    questionTypeChanged() {
      return this.selectedQuestionType !== this.preDialogSelectedType;
    }
  },
  watch: {
    preDialogSelectedType(newSelectedType) {
      this.setSelectedQuestionType(newSelectedType);
    }
  },
  methods: {
    questionTypeDisplayName(questionType) {
      return QuestionTypeConfig[questionType].displayName;
    },
    setSelectedQuestionType(type) {
      this.selectedQuestionType = type;
    },
    isSelectedType(type) {
      return type === this.selectedQuestionType;
    },
    selectQuestionType() {
      if (this.selectedQuestionType !== this.preDialogSelectedType)
        this.$emit(
          'select-new-question-type',
          this.selectedQuestionType
        );

      this.close();
    },
    questionTypeIndex(questionType) {
      return QuestionTypes.indexOf(questionType);
    },
    close() {
      this.$emit('update:show', false);
    }
  }
};
</script>
<style lang="scss" scoped>
@import '../../../scss/main.scss';

.question-type-list {
  height: 70vh;
  overflow-y: scroll;
}
.question-type-column {
  border-right: 2px solid map-get($grey, 'lighten-2');
}
.preview-card {
  height: 70vh;
  overflow-y: hidden;
}
</style>
