import ApiService from '../../../api';
import { arrayInsertAt } from '../../../utils';

const actions = {
  saveQuestion(context, question) {
    const api = ApiService.getApi();

    const questionData = Object.assign(
      { surveyId: question.survey.id },
      ...Object.keys(question)
        .filter(key => key != 'survey')
        .map(key => ({ [key]: question[key] }))
    );

    if (!question.id) {
      const idx = question.survey.questions.filter(
        question => question.id
      ).length;
      questionData.placeAtIndex = idx;
    }
    let promise = question.id
      ? api.put('question', question.id, questionData)
      : api.post('question', questionData);

    return promise.then(res => {
      if (!question.id) {
        question.id = res.data.id;
        arrayInsertAt(
          question.survey.questionOrder,
          question.survey.questions.indexOf(question),
          question.id
        );
      }
      question.hasUnsavedChanges = false;
      return res.data;
    });
  },
  deleteQuestion: {
    handler(context, question) {
      context.dispatch('survey/removeQuestion', question, {
        root: true
      });
      if (question.id)
        return ApiService.getApi()
          .delete('question', [question.id])
          .then(() => {
            context.dispatch(
              'survey/fetchSurvey',
              question.survey.id,
              {
                root: true
              }
            );
            return question;
          });
    }
  },
  duplicateQuestion(context, { question, placeAtIndex }) {
    const questionData = Object.assign(
      { surveyId: question.survey.id, placeAtIndex },
      ...Object.keys(question)
        .filter(key => key != 'survey')
        .map(key => ({ [key]: question[key] }))
    );
    return ApiService.getApi().duplicateQuestion(questionData);
  },
  setQuestionInForm: {
    handler(context, question) {
      context.commit('setQuestionInForm', question);
    }
  }
};

export default actions;
