<template>
  <v-form ref="form">
    <v-card-text class="px-6">
      <v-text-field
        v-model="model.title"
        prepend-icon="article"
        label="Title"
        :rules="validationRules.title"
        persistent-hint
        autofocus
        hint="Required *"
      ></v-text-field>
      <v-select
        v-if="belongsToMultipleOrganisations"
        v-model="model.organisationId"
        prepend-icon="business"
        :items="organisations"
        item-text="name"
        item-value="id"
        label="Organisation"
        @change="onChangeOrganisation"
      ></v-select>
      <language-selector
        :primary-language.sync="model.primaryLanguage"
        :secondary-languages.sync="model.secondaryLanguages"
      />
      <survey-timeframe-selector
        :survey-start.sync="model.surveyStart"
        :survey-end.sync="model.surveyEnd"
      />
      <v-row class="d-flex flex-column" no-gutters>
        <v-col class="text-body-2 grey--text text--darken-2">
          <v-icon class="mr-2">insert_photo</v-icon>
          <span>Intro Background Image</span>
        </v-col>
        <v-col>
          <image-upload-card
            class="ml-8 mt-2"
            :image-id="model.introBackgroundImageId"
            :organisation-id="model.organisationId"
            :image-type="imageTypes.SURVEY_BACKGROUND_IMAGE"
            @image-uploaded="handleImageUploaded"
            @delete-image="handleDeleteImage"
          />
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions class="pa-6">
      <v-spacer></v-spacer>
      <v-btn class="mr-0" @click="closeForm">
        Cancel
      </v-btn>
      <v-btn color="success" class="ml-4" @click="submit">
        {{ submitButtonText }}
      </v-btn>
    </v-card-actions>
  </v-form>
</template>

<script>
import SurveyTimeframeSelector from './SurveyTimeframeSelector';
import LanguageSelector from '@/components/Common/LanguageSelector';
import { SurveyStatus, ImageType } from '@/enums';
import { mapActions } from 'vuex';
import ImageUploadCard from '@/components/Common/ImageUploadCard';
export default {
  name: 'SurveyForm',
  components: {
    LanguageSelector,
    SurveyTimeframeSelector,
    ImageUploadCard
  },
  props: {
    survey: {
      type: Object,
      required: true
    },
    organisations: {
      type: Array,
      required: true
    },
    questions: {
      type: Array,
      default: () => []
    },
    duplicate: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      model: Object.assign({}, this.survey)
    };
  },
  computed: {
    validationRules() {
      return {
        title: [v => !!v || 'Title is required']
      };
    },
    belongsToOneOrganisation() {
      return this.organisations.length === 1;
    },
    belongsToMultipleOrganisations() {
      return this.organisations.length > 1;
    },
    hasNoOrganisation() {
      return this.organisations.length === 0;
    },
    submitButtonText() {
      return this.duplicate
        ? 'Duplicate'
        : this.survey.id
        ? 'Save'
        : 'Create';
    },
    selectedOrganisation() {
      return this.findOrganisation(this.model.organisationId);
    },
    imageTypes() {
      return ImageType;
    }
  },
  methods: {
    ...mapActions('survey', [
      'addSurvey',
      'duplicateSurvey',
      'updateSurvey'
    ]),
    findOrganisation(id) {
      return this.organisations.find(
        organisation => organisation.id === id
      );
    },
    onChangeOrganisation() {
      const organisation = this.selectedOrganisation;

      this.$set(
        this.model,
        'primaryLanguage',
        organisation.primaryLanguage ?? ''
      );

      this.$set(
        this.model,
        'secondaryLanguages',
        organisation.secondaryLanguages ?? []
      );
    },
    closeForm() {
      this.$emit('close');
    },
    submit() {
      if (!this.$refs.form.validate()) return;
      if (!this.model.id) {
        Object.assign(this.model, {
          surveyGroupId: null,
          status: SurveyStatus.DRAFT,
          questionOrder: []
        });
        if (this.organisations.length === 1) {
          this.model.organisationId = this.organisations[0].id;
        }
        this.addSurvey(this.model).then(response => {
          this.$router.push({
            path: `/surveys/${response.id}`
          });
        });
      } else if (this.duplicate) {
        this.model.questionOrder = [...this.questions]
          .filter(question => question.duplicate)
          .map(question => question.id);

        this.duplicateSurvey({
          id: this.model.id,
          survey: this.model
        });
      } else {
        this.updateSurvey(this.model);
      }
      this.$emit('close');
    },
    handleImageUploaded(response) {
      this.model.introBackgroundImageId = response.id;
    },
    handleDeleteImage() {
      this.model.introBackgroundImageId = '';
    }
  }
};
</script>

<style scoped lang="scss">
.v-select-option {
  margin-top: 0.75em;
}
.background-image-thumbnail {
  position: relative;

  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
    cursor: pointer;
  }
}

.background-image-thumbnail .v-icon {
  position: absolute;
  top: 0;
  right: 0;
}
</style>
