import Vue from 'vue';
import axios from 'axios';
import vueShortKey from 'vue-shortkey';
import App from './App.vue';
import router from './router';
import store from './store/index';
import vuetify from './plugins/vuetify';
import ApiService from './api';
import { initAuth0 } from '../../auth0-js';
import { isDevEnv } from './utils/env';

// Wake the server immediately on page load.
// We don't wait for a response to the request
// as it will 401—but that doesn't matter, it
// will wake the server.
axios.get(process.env.VUE_APP_API_URL);

Vue.config.productionTip = false;

initAuth0(router);
ApiService.init();

Vue.use(vueShortKey);

Vue.directive('devOnly', {
  bind(el, binding, node) {
    if (!isDevEnv()) {
      node.elm.parentElement.removeChild(node.elm);
    }
  }
});

export default new Vue({
  router,
  store,
  vuetify,
  axios,
  render: h => h(App)
}).$mount('#app');
