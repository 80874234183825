var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-chip",
    { staticClass: "white--text px-7", attrs: { color: _vm.color } },
    [
      _c("v-icon", { staticClass: "mr-2", attrs: { small: "" } }, [
        _vm._v(_vm._s(_vm.icon))
      ]),
      _vm._v(" " + _vm._s(_vm.statusName) + " ")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }