<template>
  <v-row>
    <v-col class="d-flex justify-center">
      <v-card flat class="text-center" height="100%">
        <v-card-text class="pt-0">
          <svg
            :style="{ width, height }"
            :viewBox="
              `0 0 ${outerCircleRadius * 2} ${outerCircleRadius * 2}`
            "
          >
            <circle
              :cx="x1"
              :cy="y1"
              r="47%"
              :style="{
                fill: 'none',
                stroke: color,
                strokeWidth: 5
              }"
            />
            <circle
              :cx="x1"
              :cy="y1"
              r="2%"
              :style="{
                fill: color,
                stroke: color,
                strokeWidth: 2
              }"
            />
            <line
              :x1="x1"
              :y1="y1"
              :x2="minutesX2"
              :y2="minutesY2"
              :style="{ stroke: color, strokeWidth: 4 }"
            />
            <circle
              :cx="minutesX2"
              :cy="minutesY2"
              r="2%"
              :style="{
                fill: color,
                stroke: color,
                strokeWidth: 0.05
              }"
            />
            <template v-if="hours > 0">
              <line
                :x1="x1"
                :y1="y1"
                :x2="hoursX2"
                :y2="hoursY2"
                :style="{ stroke: color, strokeWidth: 5 }"
              />
              <circle
                :cx="hoursX2"
                :cy="hoursY2"
                r="2%"
                :style="{
                  fill: color,
                  stroke: color,
                  strokeWidth: 1
                }"
              />
            </template>
          </svg>
        </v-card-text>
        <v-card-text class="pt-0">
          <div class="text-h6 font-weight-bold black--text">
            {{ time }}
          </div>
          <div class="text-subtitle-1">
            on average
          </div>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    questionStat: {
      type: Object,
      required: true,
      validator: value => {
        if (
          typeof value === 'undefined' ||
          typeof value.average !== 'number'
        ) {
          return false;
        }

        return value.average >= 0 && value.average < 1440;
      }
    },
    color: {
      default: '#aaa',
      type: String
    }
  },
  data() {
    return {
      radiansPerMinute: (Math.PI / 180) * 6, // 360 degrees / 60 minutes = 6
      radiansPerHour: (Math.PI / 180) * 30, // 360 degrees / 12 hours  = 30
      outerCircleRadius: 50,
      width: '50%',
      height: '50%',
      totalMinutes: this.questionStat.average
    };
  },
  computed: {
    hours() {
      return this.totalMinutes / 60;
    },
    minutes() {
      return this.totalMinutes % 60;
    },
    minuteCircleRadius() {
      return this.outerCircleRadius - 15;
    },
    hourCircleRadius() {
      return this.minuteCircleRadius - 15;
    },
    x1() {
      return this.outerCircleRadius;
    },
    y1() {
      return this.outerCircleRadius;
    },
    minutesTheta() {
      return this.minutes * this.radiansPerMinute;
    },
    hoursTheta() {
      return this.hours * this.radiansPerHour;
    },
    minutesX2() {
      return (
        this.x1 +
        this.minuteCircleRadius * Math.sin(this.minutesTheta)
      );
    },
    minutesY2() {
      return (
        this.y1 -
        this.minuteCircleRadius * Math.cos(this.minutesTheta)
      );
    },
    hoursX2() {
      return (
        this.x1 + this.hourCircleRadius * Math.sin(this.hoursTheta)
      );
    },
    hoursY2() {
      return (
        this.y1 - this.hourCircleRadius * Math.cos(this.hoursTheta)
      );
    },
    time() {
      const hours = Math.floor(this.hours);
      const minutes = Math.floor(this.minutes);
      let timeString = '';

      if (hours > 0) {
        if (hours > 1) {
          timeString += hours + ' hours';
        } else {
          timeString += '1 hour';
        }
      }

      if (hours > 0 && minutes > 0) {
        timeString += ' and ';
      }

      if (minutes > 1) {
        timeString += minutes + ' minutes';
      } else {
        minutes + '1 minute';
      }

      return timeString;
    }
  }
};
</script>
