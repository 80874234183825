var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    [
      _c(
        "v-container",
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { staticClass: "ms-4" },
                [
                  _c("p", { staticClass: "bold" }, [_vm._v("Questions")]),
                  _c("p", { staticClass: "mt-n3" }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.survey.userQuestionCount) +
                        " " +
                        _vm._s(
                          _vm.survey.userQuestionCount === 1
                            ? "Question"
                            : "Questions"
                        ) +
                        " "
                    )
                  ]),
                  _c(
                    "v-tooltip",
                    {
                      attrs: { bottom: "" },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function({ on }) {
                            return [
                              _c(
                                "div",
                                _vm._g({ staticClass: "d-inline-flex" }, on),
                                [
                                  _c(
                                    "v-btn",
                                    _vm._g(
                                      {
                                        staticClass:
                                          "mt-4 grey--text text--darken-2 grey lighten-3 text-capitalize mt-n2",
                                        attrs: {
                                          height: "25",
                                          disabled: !_vm.surveyHasQuestions
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.$emit(
                                              "show-survey-preview"
                                            )
                                          }
                                        }
                                      },
                                      on
                                    ),
                                    [
                                      _c(
                                        "v-icon",
                                        {
                                          attrs: {
                                            small: "",
                                            left: "",
                                            color: "grey darken-2"
                                          }
                                        },
                                        [_vm._v(" play_circle_outline ")]
                                      ),
                                      _vm._v(" Preview Survey ")
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ]
                          }
                        }
                      ])
                    },
                    [_c("span", [_vm._v(_vm._s(_vm.getbuttonTooltipText()))])]
                  )
                ],
                1
              ),
              _c("v-divider", { attrs: { vertical: "" } }),
              _c("v-col", { staticClass: "bold ms-4 me-4" }, [
                _c("p", { staticClass: "bold" }, [_vm._v("Disclaimer")]),
                _c(
                  "div",
                  { staticClass: "d-flex" },
                  [
                    _c(
                      "div",
                      { staticClass: "mt-n2" },
                      [
                        _c(
                          "p",
                          {
                            staticClass:
                              "d-flex small-text grey--text text--darken-1 bold justify-content-center"
                          },
                          [_vm._v(" Include Disclaimer ")]
                        ),
                        _c("v-switch", {
                          staticClass: "mt-n3",
                          attrs: { color: "success", "hide-details": "" },
                          on: {
                            change: function($event) {
                              return _vm.toggleShowDisclaimer($event)
                            }
                          },
                          model: {
                            value: _vm.survey.showDisclaimer,
                            callback: function($$v) {
                              _vm.$set(_vm.survey, "showDisclaimer", $$v)
                            },
                            expression: "survey.showDisclaimer"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "v-tooltip",
                      {
                        attrs: { bottom: "" },
                        scopedSlots: _vm._u([
                          {
                            key: "activator",
                            fn: function({ on }) {
                              return [
                                _c(
                                  "div",
                                  _vm._g({ staticClass: "d-inline-flex" }, on),
                                  [
                                    _c(
                                      "v-btn",
                                      _vm._g(
                                        {
                                          staticClass:
                                            "ml-6 mt-4 align-self-end",
                                          attrs: {
                                            disabled: !_vm.survey
                                              .showDisclaimer,
                                            height: "30"
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.$emit(
                                                "edit-survey-disclaimer"
                                              )
                                            }
                                          }
                                        },
                                        on
                                      ),
                                      [_vm._v(" Edit ")]
                                    )
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ])
                      },
                      [_c("span", [_vm._v("Survey Disclaimer")])]
                    )
                  ],
                  1
                )
              ]),
              _vm.surveyHadMultipleLanguages
                ? _c("v-divider", { attrs: { vertical: "" } })
                : _vm._e(),
              _vm.surveyHadMultipleLanguages
                ? _c(
                    "v-col",
                    { staticClass: "ms-4" },
                    [
                      _c("p", { staticClass: "bold" }, [
                        _vm._v("Language Translations")
                      ]),
                      _vm.displayMissingLanguageCodes
                        ? _c(
                            "div",
                            { staticClass: "d-flex small-text mt-n2" },
                            [
                              _c("p", { staticClass: "pr-1" }, [
                                _vm._v("Missing")
                              ]),
                              _c("strong", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.languageTranslationStatus
                                        .filter(language => !language.complete)
                                        .map(language => language.code)
                                        .join(" ")
                                    ) +
                                    " "
                                )
                              ]),
                              _c("p", { staticClass: "pl-1" }, [
                                _vm._v("translations")
                              ])
                            ]
                          )
                        : _vm._e(),
                      _c("survey-language-status", {
                        class: _vm.displayMissingLanguageCodes
                          ? "mt-n2"
                          : "mt-8",
                        attrs: {
                          "language-status": _vm.languageTranslationStatus,
                          highlight: _vm.selectedLanguage,
                          "show-completed": ""
                        },
                        on: {
                          "language-clicked": function($event) {
                            return _vm.$emit("language-clicked", $event)
                          }
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _c("v-divider", { attrs: { vertical: "" } }),
              _c(
                "v-col",
                { staticClass: "ms-4" },
                [
                  _c("p", { staticClass: "bold mb-2" }, [
                    _vm._v("Publish Survey")
                  ]),
                  _c(
                    "p",
                    {
                      staticClass:
                        "small-text grey--text text--darken-1 bold justify-content-center"
                    },
                    [
                      !_vm.surveyPublished
                        ? _c("span", [_vm._v("Survey is in draft")])
                        : _c("span", [_vm._v("Survey is published")])
                    ]
                  ),
                  _c("v-switch", {
                    staticClass: "mt-n3",
                    attrs: { color: "success", "hide-details": "" },
                    on: {
                      change: function($event) {
                        return _vm.togglePublishSurvey($event)
                      }
                    },
                    model: {
                      value: _vm.surveyPublished,
                      callback: function($$v) {
                        _vm.surveyPublished = $$v
                      },
                      expression: "surveyPublished"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }