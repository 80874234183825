const mutations = {
  setStats: (state, stats) => {
    state.stats = stats;
  },
  setPublicDisplayStats: (state, stats) => {
    state.publicDisplayStats = stats;
  }
};

export default mutations;
