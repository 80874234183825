var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("labels-config", {
    attrs: { labels: _vm.labels, "input-labels": _vm.inputLabels },
    on: { "labels-updated": _vm.configUpdated }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }