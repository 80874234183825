import QuestionTypeConfig from '../utils/QuestionTypeConfig';
import QuestionType from '@/enums/questionType';

class Question {
  constructor(data) {
    this.id = data.id;
    this.text = data.text ?? {
      languageMap: {
        [data.language]: ''
      }
    };
    this.config = data.config ?? {
      questionType: QuestionType.NO_TYPE
    };
    this.theme = data.theme ?? '';
    this.description = null;
    this.questionGroupId = null;
    this.survey = data.survey;
    this.userConfigurable = data.userConfigurable ?? true;
    this.hasUnsavedChanges = false;
    this.responseCount = data.responseCount;
  }

  languageText(language) {
    return this.text.languageMap[language];
  }

  setConfig(questionType, targetLanguage) {
    let config = {};
    if (QuestionTypeConfig[questionType].hasAdditionalConfig) {
      config = QuestionTypeConfig[questionType].defaultConfig(
        targetLanguage
      );
    }
    config.questionType = QuestionType[questionType];
    this.config = config;
  }

  hasAdditionalConfig() {
    return (
      this.config.questionType != QuestionType.NO_TYPE &&
      QuestionTypeConfig[this.config.questionType].hasAdditionalConfig
    );
  }

  isEmpty(language) {
    return (
      this.text.languageMap[language].trim() == '' &&
      this.theme.trim() == '' &&
      this.config.questionType == QuestionType.NO_TYPE
    );
  }

  type() {
    return this.config.questionType;
  }

  isPublicDisplayCompatible() {
    return this.config.publicDisplayCompatible;
  }

  isComplete(language) {
    return (
      this.text.languageMap[language].trim() != '' &&
      this.theme.trim() != '' &&
      this.config.questionType != QuestionType.NO_TYPE
    );
  }

  textValid(language) {
    return this.text.languageMap[language].trim() != '';
  }

  typeValid() {
    return this.config.questionType != QuestionType.NO_TYPE;
  }

  themeValid() {
    return this.theme.trim() != '';
  }

  static defaultQuestionType() {
    return QuestionType.AUDIO;
  }
}

export default Question;
