var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("v-checkbox", {
        staticClass: "mb-n4 mt-2",
        attrs: { label: "Hide question title" },
        model: {
          value: _vm.value.hideQuestionTitle,
          callback: function($$v) {
            _vm.$set(_vm.value, "hideQuestionTitle", $$v)
          },
          expression: "value.hideQuestionTitle"
        }
      }),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "8" } },
            [
              _c("v-textarea", {
                attrs: {
                  value: _vm.prompt.languageMap[_vm.primaryLanguage],
                  label: "Prompt Text"
                },
                on: {
                  input: function($event) {
                    return _vm.configUpdated($event)
                  }
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }