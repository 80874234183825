<template>
  <v-fade-transition v-if="!dataLoading">
    <div v-if="hasData">
      <slider
        ref="slider"
        :options="sliderOptions"
        style="width: 100vw; height: 100vh"
      >
        <slideritem v-for="(stat, i) in questionStats" :key="i">
          <v-sheet tile width="100%" height="100%">
            <div
              class="slide text-left white--text"
              :style="slideStyle"
            >
              <public-display-slide
                :question-stat="stat"
                :display-language="displayLanguage"
                :theme="theme"
                v-bind="{ stat }"
              >
                <template v-slot:visualisation>
                  <component
                    :is="visualisationComponentForStat(stat)"
                    class="d-flex flex-column flex-grow-1 justify-center"
                    :question-stat="stat"
                    :theme="theme"
                    :display-language="displayLanguage"
                  />
                </template>
              </public-display-slide>
            </div>
          </v-sheet>
        </slideritem>
      </slider>
    </div>
    <div v-else>
      <NoPublicDisplayData
        :survey-id="surveyId"
        :survey-title="surveyTitle"
        :has-questions="hasQuestions"
        :has-responses="hasResponses"
      ></NoPublicDisplayData>
    </div>
  </v-fade-transition>
</template>

<script>
import { mapActions } from 'vuex';
import { slider, slideritem } from 'vue-concise-slider';
import PublicDisplaySlide from '@/components/PublicDisplay/PublicDisplaySlide';
import CirclesOverlapPublicDisplayItem from '@/components/PublicDisplay/CirclesOverlapPublicDisplayItem';
import CirclesProportionalPublicDisplayItem from '@/components/PublicDisplay/CirclesProportionalPublicDisplayItem';
import EmotionWheelPublicDisplayItem from '@/components/PublicDisplay/EmotionWheelPublicDisplayItem';
import HeartPublicDisplayItem from '@/components/PublicDisplay/HeartPublicDisplayItem';
import MultipleChoicePublicDisplayItem from '@/components/PublicDisplay/MultipleChoicePublicDisplayItem';
import MultipleChoiceImagePublicDisplayItem from '@/components/PublicDisplay/MultipleChoiceImagePublicDisplayItem';
import SliderPublicDisplayItem from '@/components/PublicDisplay/SliderPublicDisplayItem';
import TextPublicDisplayItem from '@/components/PublicDisplay/TextPublicDisplayItem';
import TopWordsPublicDisplayItem from '@/components/PublicDisplay/TopWordsPublicDisplayItem';
import NoPublicDisplayData from '@/components/PublicDisplay/NoPublicDisplayData';
import QuestionTypeConfig from '@/utils/QuestionTypeConfig';
import ImageGridPublicDisplayItem from '@/components/PublicDisplay/ImageGridPublicDisplayItem';
import colors from 'vuetify/lib/util/colors';

export default {
  name: 'PublicDisplayPage',
  components: {
    PublicDisplaySlide,
    CirclesOverlapPublicDisplayItem,
    CirclesProportionalPublicDisplayItem,
    EmotionWheelPublicDisplayItem,
    HeartPublicDisplayItem,
    MultipleChoicePublicDisplayItem,
    MultipleChoiceImagePublicDisplayItem,
    SliderPublicDisplayItem,
    TopWordsPublicDisplayItem,
    TextPublicDisplayItem,
    NoPublicDisplayData,
    slider,
    slideritem,
    ImageGridPublicDisplayItem
  },
  props: {
    accessCode: {
      required: false,
      type: String,
      default: undefined
    }
  },
  data() {
    return {
      meta: {},
      publicDisplayStats: [],
      dataLoading: true,
      carouselIndex: 0,
      carouselInterval: undefined,
      languageIndex: 0,
      theme: {
        background: colors.shades.black,
        primaryColor: this.$vuetify.theme.defaults.light.primary,
        secondaryColor: colors.purple.darken4,
        textColor: 'grey--text text--lighten-5',
        heart: {
          backgroundColor: [
            { offset: 0, stopColor: colors.grey.base }
          ],
          fillColor: [{ offset: 0, stopColor: colors.red.darken1 }]
        },
        slider: {
          barColour: colors.purple.darken2,
          tickColour: colors.purple.darken4,
          gradient: {
            start: '#ff7079',
            end: this.$vuetify.theme.defaults.light.primary
          }
        }
      },
      sliderOptions: {
        centeredSlides: false,
        effect: 'slide',
        loop: true,
        pagination: false,
        speed: 500
      }
    };
  },
  computed: {
    surveyTitle() {
      return this.meta.title;
    },
    displayLanguage() {
      return this.languages[this.languageIndex];
    },
    languages() {
      return this.meta.languages;
    },
    surveyId() {
      return this.$route.params.id;
    },
    questionStats() {
      return this.publicDisplayStats;
    },
    hasResponses() {
      return this.publicDisplayStats.some(
        stat => stat.totalResponses > 0
      );
    },
    hasQuestions() {
      return this.questionStats.length > 0;
    },
    hasData() {
      return this.hasQuestions && this.hasResponses;
    },
    slideStyle() {
      return {
        backgroundColor: this.theme.background
      };
    }
  },
  mounted() {
    if (this.accessCode != undefined) {
      this.validateCode({
        surveyId: this.surveyId,
        accessCode: this.accessCode
      }).then(isValid => {
        if (isValid) {
          this.fetchContent();
        } else {
          this.$router.push(
            `/surveys/${this.surveyId}/tv/access-code`
          );
        }
      });

      return;
    }

    this.$router.push(`/surveys/${this.surveyId}/tv/access-code`);
    return;
  },
  beforeDestroy() {
    this.stopTimer();
  },
  methods: {
    ...mapActions({
      validateCode: 'publicDisplay/validateCode',
      fetchStats: 'publicDisplay/fetchDisplayStats',
      fetchPublicSurveyInfo: 'publicDisplay/fetchPublicSurveyInfo'
    }),
    nextLanguage() {
      this.languageIndex =
        ++this.languageIndex % this.languages.length;

      if (this.languageIndex === 0) {
        this.nextSlide();
      }
    },
    async nextSlide() {
      this.carouselIndex++;
      if (this.carouselIndex >= this.questionStats.length) {
        this.carouselIndex = 0;
        await this.reloadStats();
      }

      this.$refs.slider.$emit('slideNext');

      this.setTimer(this.displayTime(this.carouselIndex));
    },
    visualisationComponentForStat(questionStat) {
      const type = QuestionTypeConfig[questionStat.questionType];
      return type && type.publicDisplayComponent
        ? type.publicDisplayComponent
        : undefined;
    },
    displayTime(index) {
      return QuestionTypeConfig[
        this.questionStats[index].questionType
      ].publicDisplayTime;
    },
    setTimer(displayTime) {
      this.stopTimer();

      this.carouselInterval = setInterval(
        () => this.nextLanguage(),
        displayTime
      );
    },
    fetchContent() {
      return this.fetchPublicSurveyInfo({
        surveyId: this.surveyId
      }).then(meta => {
        this.meta = meta;
        return this.reloadStats();
      });
    },
    reloadStats() {
      return this.fetchStats({
        surveyId: this.surveyId,
        accessCode: this.accessCode
      }).then(stats => {
        this.publicDisplayStats = stats;
        this.dataLoading = false;
        this.setTimer(this.displayTime(0));
      });
    },
    stopTimer() {
      clearInterval(this.carouselInterval);
    }
  }
};
</script>

<style lang="scss" scoped>
.slide {
  width: 100%;
  height: 100%;
  background-color: #203e6a;
  font-size: 1rem;
}
</style>
