var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.dataLoading
    ? _c("v-fade-transition", [
        _vm.hasData
          ? _c(
              "div",
              [
                _c(
                  "slider",
                  {
                    ref: "slider",
                    staticStyle: { width: "100vw", height: "100vh" },
                    attrs: { options: _vm.sliderOptions }
                  },
                  _vm._l(_vm.questionStats, function(stat, i) {
                    return _c(
                      "slideritem",
                      { key: i },
                      [
                        _c(
                          "v-sheet",
                          {
                            attrs: { tile: "", width: "100%", height: "100%" }
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "slide text-left white--text",
                                style: _vm.slideStyle
                              },
                              [
                                _c(
                                  "public-display-slide",
                                  _vm._b(
                                    {
                                      attrs: {
                                        "question-stat": stat,
                                        "display-language": _vm.displayLanguage,
                                        theme: _vm.theme
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "visualisation",
                                            fn: function() {
                                              return [
                                                _c(
                                                  _vm.visualisationComponentForStat(
                                                    stat
                                                  ),
                                                  {
                                                    tag: "component",
                                                    staticClass:
                                                      "d-flex flex-column flex-grow-1 justify-center",
                                                    attrs: {
                                                      "question-stat": stat,
                                                      theme: _vm.theme,
                                                      "display-language":
                                                        _vm.displayLanguage
                                                    }
                                                  }
                                                )
                                              ]
                                            },
                                            proxy: true
                                          }
                                        ],
                                        null,
                                        true
                                      )
                                    },
                                    "public-display-slide",
                                    { stat },
                                    false
                                  )
                                )
                              ],
                              1
                            )
                          ]
                        )
                      ],
                      1
                    )
                  }),
                  1
                )
              ],
              1
            )
          : _c(
              "div",
              [
                _c("NoPublicDisplayData", {
                  attrs: {
                    "survey-id": _vm.surveyId,
                    "survey-title": _vm.surveyTitle,
                    "has-questions": _vm.hasQuestions,
                    "has-responses": _vm.hasResponses
                  }
                })
              ],
              1
            )
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }