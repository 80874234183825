<template>
  <div>
    <v-container class="pa-0" no-gutters>
      <div v-if="loading">
        <v-row class="pa-0 pl-4 pr-8 no-gutters">
          <v-col cols="3" class="pb-3 mt-n1">
            <v-text-field
              append-icon="search"
              dense
              hide-details
              :placeholder="searchPlaceholder"
              disabled
            ></v-text-field>
          </v-col>
          <v-spacer />
        </v-row>
        <slot name="error"></slot>
        <v-row class="mx-0">
          <v-col
            v-for="n in loadingCardsCount"
            :key="n"
            xl="4"
            lg="4"
            md="6"
          >
            <survey-card-loader />
          </v-col>
        </v-row>
      </div>
      <v-data-iterator
        v-else
        :items="sortedSurveys"
        :search="surveyFilterText"
        no-results-text="No matching surveys found"
        :custom-filter="filterSurveys"
        hide-default-footer
        disable-pagination
      >
        <template v-slot:header>
          <v-row class="pa-0 pl-4 pr-8 no-gutters">
            <v-col cols="3" class="pb-3 mt-n1">
              <v-text-field
                v-model="surveyFilterText"
                append-icon="search"
                dense
                hide-details
                :placeholder="searchPlaceholder"
                autofocus
              ></v-text-field>
            </v-col>
            <v-spacer />
          </v-row>
          <v-row v-if="enableStatusFilter">
            <v-col cols="6">
              <survey-status-filter-toolbar
                v-model="visibleStatuses"
                :status-counters="statusCounters"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <slot name="error"></slot>
            </v-col>
          </v-row>
        </template>
        <template v-slot:default="props">
          <transition-group
            name="survey-grid"
            tag="div"
            class="row mx-0"
            appear
          >
            <v-col
              v-for="survey in props.items"
              :key="survey.id"
              xl="4"
              lg="4"
              md="6"
              class="pa-4 d-flex survey-grid-item"
            >
              <survey-card
                :show-organisation="belongsToMultipleOrganisations"
                :organisation-name="
                  organisationForSurvey(survey).name
                "
                :survey="survey"
                @survey-action-request="
                  $emit('survey-action-request', {
                    action: $event,
                    survey
                  })
                "
              />
            </v-col>
          </transition-group>
        </template>
        <template v-slot:no-data>
          <v-row class="mx-0">
            <v-col xl="4" lg="4" md="6" class="pa-4">
              <add-survey-card
                @show-survey-dialog="$emit('add-survey-card-click')"
              />
            </v-col>
          </v-row>
        </template>
      </v-data-iterator>
    </v-container>
  </div>
</template>

<script>
import SurveyCard from './SurveyCard';
import SurveyCardLoader from './Loader/SurveyCardLoader';
import SurveyStatusFilterToolbar from './SurveyStatusFilterToolbar';
import AddSurveyCard from './NoData/AddSurveyCard';
import { surveyHelper } from '@/mixins/SurveyHelper';
export default {
  name: 'SurveyGrid',
  components: {
    SurveyCard,
    SurveyCardLoader,
    SurveyStatusFilterToolbar,
    AddSurveyCard
  },
  mixins: [surveyHelper],
  props: {
    surveys: {
      type: Array,
      default: () => {
        return [];
      }
    },
    organisations: {
      type: Array,
      default: () => {
        return [];
      }
    },
    loading: {
      type: Boolean,
      required: true
    }
  },
  data: () => ({
    loadingCardsCount: 6,
    surveyFilterText: '',
    dialogSurvey: {},
    surveyDialog: false,
    duplicateDialog: false,
    actionDialog: false,
    surveyAction: '',
    STATUSES: ['PUBLISHED', 'DRAFT'],
    visibleStatuses: ['PUBLISHED', 'DRAFT']
  }),
  computed: {
    statusCounters() {
      return this.STATUSES.reduce((statusCounters, status) => {
        return Object.assign({}, statusCounters, {
          [status]: this.surveys.filter(s => s.status === status)
            .length
        });
      }, {});
    },
    sortedSurveys() {
      return [...this.surveys].sort((surveyA, surveyB) => {
        return (
          new Date(surveyB.updatedAt) - new Date(surveyA.updatedAt)
        );
      });
    },
    belongsToMultipleOrganisations() {
      return this.organisations.length > 1;
    },
    enableStatusFilter() {
      return (
        Object.values(this.statusCounters).filter(count => count > 0)
          .length > 1
      );
    },
    searchPlaceholder() {
      return this.belongsToMultipleOrganisations
        ? 'Search by survey title or organisation name'
        : 'Search by survey title';
    }
  },
  methods: {
    filterSurveys(surveys, search) {
      return surveys.filter(survey =>
        this.filterByTitleOrganisationAndStatus(survey, search)
      );
    },
    filterByTitleOrganisationAndStatus(survey, search) {
      return (
        (this.belongsToMultipleOrganisations
          ? this.matchTitleOrOrganisation(survey, search)
          : this.matchTitle(survey, search)) &&
        this.matchStatus(survey.status)
      );
    },
    matchTitleOrOrganisation(survey, search) {
      return (
        this.matchTitle(survey, search) ||
        this.matchOrganisation(survey, search)
      );
    },
    matchTitle(survey, search) {
      return RegExp(search, 'i').test(survey.title);
    },
    matchOrganisation(survey, search) {
      return RegExp(search, 'i').test(
        this.organisationForSurvey(survey).name
      );
    },
    matchStatus(status) {
      // Return if status filter is not enabled
      // (i.e. only one status has surveys)
      return this.enableStatusFilter
        ? this.visibleStatuses.includes(status)
        : true;
    },
    organisationForSurvey(survey) {
      return this.organisations.find(
        organisation => organisation.id == survey.organisationId
      );
    }
  }
};
</script>
<style scoped>
.survey-grid-item {
  transition: all 0.5s;
}
.survey-grid-enter,
.survey-grid-leave-to {
  opacity: 0;
  transform: translateY(30px);
}
.survey-grid-leave-active {
  position: absolute;
}
</style>
