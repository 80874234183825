var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-tooltip",
    {
      attrs: { top: "" },
      scopedSlots: _vm._u(
        [
          {
            key: "activator",
            fn: function({ on }) {
              return [
                _c(
                  "div",
                  _vm._g({}, on),
                  [
                    _c("v-icon", { attrs: { left: "", color: "amber" } }, [
                      _vm._v("warning")
                    ]),
                    _vm._t("default", function() {
                      return [_vm._v(_vm._s(_vm.message))]
                    })
                  ],
                  2
                )
              ]
            }
          }
        ],
        null,
        true
      )
    },
    [_c("span", [_vm._v(_vm._s(_vm.message))])]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }