var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "d-inline-block" },
    [
      _c(
        "v-tooltip",
        {
          attrs: { top: "" },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function({ on, attrs }) {
                return [
                  _c(
                    "v-avatar",
                    _vm._g(
                      _vm._b(
                        {
                          staticClass: "white--text text-caption",
                          class: _vm.color,
                          attrs: {
                            height: "28",
                            "min-width": "28",
                            width: "28"
                          },
                          on: {
                            click: function($event) {
                              return _vm.$emit("click")
                            }
                          }
                        },
                        "v-avatar",
                        attrs,
                        false
                      ),
                      on
                    ),
                    [_vm._v(" " + _vm._s(_vm.displayCode(_vm.language)) + " ")]
                  )
                ]
              }
            }
          ])
        },
        [_c("span", [_vm._v(_vm._s(_vm.languageName(_vm.language)))])]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }