<template>
  <v-card class="pl-7 pa-5 mb-4" @click="openOrganisation">
    <v-row no-gutters align="center">
      {{ organisation.name }}
    </v-row>
  </v-card>
</template>

<script>
export default {
  props: {
    organisation: {
      type: Object,
      required: true
    }
  },
  methods: {
    openOrganisation() {
      this.$router.push(`/organisation/${this.organisation.id}`);
    }
  }
};
</script>
