<template>
  <div>
    <page-header-bar>
      <div class="d-flex p-relative">
        <div class="flex-grow-1 d-flex flex-column">
          <h2 class="white--text font-weight-medium">
            {{ title }}
          </h2>
        </div>
      </div>
    </page-header-bar>
    <v-container d-flex justify-center pa-2>
      <div class="d-flex flex-column justify-center">
        <div class="pa-8">
          {{ message }}
        </div>
        <div class="d-flex justify-center">
          <v-btn color="primary" @click="home">
            {{ button }}
          </v-btn>
        </div>
      </div>
    </v-container>
  </div>
</template>

<script>
import PageHeaderBar from '../components/Common/PageHeaderBar';
export default {
  name: 'ErrorPage',
  components: {
    PageHeaderBar
  },
  props: {
    title: {
      type: String,
      default: 'Oops! Something went wrong.'
    },
    message: {
      type: String,
      default: 'An unknown error occurred.'
    },
    button: {
      type: String,
      default: 'Go to home screen'
    }
  },
  methods: {
    home() {
      // For authentication related errors, we need to
      // fully reload the authentication, not just
      // reroute them to a different path. Using this
      // instead of $router causes a full page reload.
      window.location = '/';
    }
  }
};
</script>
