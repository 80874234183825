<template>
  <v-dialog :value="showDialog" persistent max-width="560">
    <v-card>
      <v-card-title>{{ title }}</v-card-title>
      <organisation-form
        v-if="show"
        :organisation="organisation"
        @close="$emit('update:show', false)"
      />
    </v-card>
  </v-dialog>
</template>

<script>
import OrganisationForm from './Edit/OrganisationForm';

export default {
  name: 'OrganisationDialog',
  components: { OrganisationForm },
  props: {
    organisation: {
      type: Object,
      default: () => ({})
    },
    show: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    organisationInForm() {
      return this.organisation.id ? this.organisation : {};
    },
    showDialog() {
      return this.show != null ? this.show : this.organisation;
    },
    title() {
      return this.organisation.id
        ? 'Edit Organisation Details'
        : 'Create Organisation';
    }
  }
};
</script>
