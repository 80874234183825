<template>
  <v-row>
    <v-col v-for="key in Object.keys(labels)" :key="key" cols="3">
      <v-text-field
        class="text-body-2 text-capitalize"
        :disabled="disabled"
        :value="label(key)"
        :label="inputLabel(key)"
        @input="updateLabel(key, $event)"
      ></v-text-field>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  name: 'LabelsConfig',
  props: {
    inputLabels: {
      type: Array,
      required: false,
      default: () => undefined
    },
    labels: {
      type: Object,
      required: true
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    ...mapGetters('survey', ['primaryLanguage']),
    targetLanguage() {
      return this.primaryLanguage;
    }
  },
  methods: {
    inputLabel(key) {
      if (this.inputLabels) {
        return this.inputLabels[
          Object.keys(this.labels).indexOf(key)
        ];
      } else {
        return `label ${key}`;
      }
    },
    label(key) {
      return this.labels[key].languageMap[this.targetLanguage];
    },
    updateLabel(key, value) {
      this.labels[key].languageMap[this.targetLanguage] = value;
      this.$emit('labels-updated', this.labels);
    }
  }
};
</script>
