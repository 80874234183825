var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "p-relative" },
    [
      _c("heart-svg", {
        staticClass: "background",
        attrs: { colors: _vm.backgroundColor }
      }),
      _c("heart-svg", {
        staticClass: "p-absolute foreground",
        attrs: { colors: _vm.fillColor, scale: _vm.fillPercent }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }