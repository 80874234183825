<template>
  <v-card class="p-relative pa-4 mb-4" hover>
    <div class="pa-2">
      <div class="d-flex align-center mb-4">
        <h2 class="mr-8 font-weight-medium">
          Public Display Configuration
        </h2>
        <v-btn
          :to="{ name: 'public-display', query: { accessCode } }"
          target="_blank"
          small
        >
          <v-icon small left>launch</v-icon>Open Public Display
        </v-btn>
      </div>

      <div class="d-flex">
        <div class="flex-row mr-16">
          <p class="grey--text text--darken-1 mb-0">
            Public Access URL
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-icon
                  class="mb-1"
                  color="primary"
                  size="18"
                  v-on="on"
                >
                  help
                </v-icon>
              </template>
              <span>
                Share this link to offer access to the public display
                without needing to log into muse.
              </span>
            </v-tooltip>
          </p>
          <span>
            <a :href="publicAccessUrl" target="_blank" class="mr-2">
              {{ publicAccessUrl }}
            </a>
            <v-btn x-small class="x-small-square" @click="copyUrl">
              <v-icon x-small icon tile>
                content_copy
              </v-icon>
            </v-btn>
          </span>
        </div>
      </div>
    </div>
  </v-card>
</template>

<script>
export default {
  name: 'PublicDisplayConfigCard',
  components: {},
  props: {
    survey: {
      type: Object,
      required: true
    }
  },
  computed: {
    accessCode() {
      return this.survey.publicDisplayAccessCode;
    },
    publicAccessUrl() {
      const protocol = window.location.protocol;
      const hostname = window.location.hostname;
      const port =
        window.location.port != '' ? `:${window.location.port}` : '';
      return `${protocol}//${hostname}${port}/#/tv/${this.survey.shortId}/${this.survey.publicDisplayAccessCode}`;
    }
  },
  methods: {
    copyUrl() {
      navigator.clipboard.writeText(this.publicAccessUrl);
    }
  }
};
</script>

<style lang="scss" scoped>
.v-btn.x-small-square {
  width: 20px !important;
  min-width: 20px !important;
}
</style>
