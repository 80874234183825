// enum which contains the dialog states
const DialogState = Object.freeze({
  ACTION: 'ACTION',
  CREATE: 'CREATE',
  DUPLICATE: 'DUPLICATE',
  EDIT: 'EDIT',
  INACTIVE: 'INACTIVE'
});

export default DialogState;
