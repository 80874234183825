<template>
  <div>
    <v-btn
      v-for="status in visibleStatuses"
      :key="status.type"
      class="ml-2"
      rounded
      :class="buttonClass(status.type)"
      @click="toggleStatusVisibility(status.type)"
    >
      <v-badge
        v-if="statusCounters[status.type] > 0"
        inline
        :content="statusCounters[status.type]"
        :color="badgeColor(status.type)"
      >
        <v-icon small class="mr-2">{{ status.icon }}</v-icon>
        {{ status.label }}
      </v-badge>
      <span v-else>
        <v-icon small>{{ status.icon }}</v-icon>
        {{ status.label }}
      </span>
    </v-btn>
  </div>
</template>

<script>
const STATUSES = [
  { type: 'DRAFT', label: 'Draft', icon: 'list_alt' },
  {
    type: 'PUBLISHED',
    label: 'Published',
    icon: 'check_circle'
  }
];

export default {
  name: 'SurveyStatusFilterToolbar',
  props: {
    value: {
      type: Array,
      required: true
    },
    statusCounters: {
      type: Object,
      required: true
    }
  },
  computed: {
    visibleStatuses() {
      return STATUSES.filter(s => this.statusCounters[s.type] > 0);
    }
  },
  methods: {
    statusVisible(status) {
      return this.value.includes(status);
    },
    buttonClass(status) {
      if (this.statusVisible(status)) {
        switch (status) {
          case 'DRAFT':
            return 'primary';
          case 'PUBLISHED':
            return 'success';
          default:
            return 'error';
        }
      } else {
        return 'grey lighten-2';
      }
    },
    badgeColor(status) {
      if (this.statusVisible(status)) {
        switch (status) {
          case 'DRAFT':
            return 'primary darken-2';
          case 'PUBLISHED':
            return 'success darken-2';
          default:
            return 'error darken-2';
        }
      } else {
        return 'grey darken-2';
      }
    },
    toggleStatusVisibility(status) {
      const updatedStatuses = this.statusVisible(status)
        ? this.value.filter(s => s != status)
        : this.value.concat([status]);

      this.$emit('input', updatedStatuses);
    }
  }
};
</script>

<style scoped>
.language {
  line-height: 28px;
}
</style>
