<template>
  <div>
    <grid-dimensions
      :public-display-config="publicDisplayConfig"
      @options-changed="$emit('options-changed')"
    />
  </div>
</template>

<script>
import GridDimensions from './GridDimensions.vue';

export default {
  name: 'PhotoPublicDisplayOptions',
  components: { GridDimensions },
  props: {
    publicDisplayConfig: {
      type: Object,
      required: true
    }
  }
};
</script>
