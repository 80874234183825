<template>
  <div>
    <v-container>
      <v-row class="pl-6">
        <v-col
          class="font-weight-bold text-overline ml-10"
          cols="auto"
        >
          Type
        </v-col>
        <v-col class="font-weight-bold text-overline">
          Question in Primary Language
        </v-col>
        <v-col class="font-weight-bold text-overline">
          {{ languageName }} Translation
        </v-col>
        <v-col cols="1"></v-col>
      </v-row>
      <v-row
        v-for="(question, index) in savedQuestions"
        :key="index + question.id"
        class="translation-row"
      >
        <v-container
          v-if="question.userConfigurable"
          class="item-container"
        >
          <v-card>
            <v-card-text class="px-6">
              <div class="d-flex">
                <div class="d-flex align-center question-type mr-4">
                  <span
                    class="question-number grey--text text--darken-1"
                  >
                    {{ questionNumber(question) }}.
                  </span>
                  <question-type-icon
                    :question-type="question.config.questionType"
                    :size="30"
                    @click.native="
                      showQuestionPreview(
                        question.config.questionType
                      )
                    "
                  />
                </div>
                <div class="d-flex flex-grow-1 align-center">
                  <span class="details text-body-1 black--text ml-2">
                    {{ question.text.languageMap[primaryLanguage] }}
                  </span>
                  <v-text-field
                    v-model="
                      question.text.languageMap[translationLanguage]
                    "
                    class="details mb-n3 ml-n2"
                    :placeholder="languageName + ' translation'"
                    dense
                    @input="saveTranslation(question, 'title')"
                  />
                </div>
                <div class="update-progress">
                  <v-progress-circular
                    v-if="fieldIsLoading(question, 'title')"
                    color="primary"
                    indeterminate
                  />
                  <v-icon
                    v-else-if="fieldIsEdited(question, 'title')"
                    class="update-progress green--text"
                  >
                    done
                  </v-icon>
                </div>
              </div>
              <v-container
                v-if="hasTranslatableFields(question)"
                class="pl-0 pb-0 pt-6"
                fluid
              >
                <div
                  class="mb-2 text-caption grey--text text--darken-2"
                >
                  {{ translationFieldsLabel(question) }}
                </div>
                <div v-if="isVerticalTextFieldQuestion(question)">
                  <v-row
                    v-for="field in translationFields(question)"
                    :key="field.key + index + question.id"
                    class="mt-n2"
                  >
                    <v-col cols="8">
                      <v-text-field
                        v-model="
                          field.value.languageMap[translationLanguage]
                        "
                        :label="
                          field.value.languageMap[primaryLanguage]
                        "
                        dense
                        @input="saveTranslation(question, field.key)"
                      />
                    </v-col>
                    <v-progress-circular
                      v-if="fieldIsLoading(question, field.key)"
                      color="primary"
                      indeterminate
                    />
                    <v-icon
                      v-else-if="fieldIsEdited(question, field.key)"
                      class="update-progress green--text pt-6"
                    >
                      done
                    </v-icon>
                  </v-row>
                </div>
                <v-row v-else>
                  <v-col
                    v-for="field in translationFields(question)"
                    :key="field.key + index + question.id"
                    class="py-0"
                    cols="12"
                    :sm="countColsForSmallScreen(question)"
                    :md="countColsForMediumScreen(question)"
                  >
                    <div class="d-flex">
                      <div
                        v-if="isTextAreaQuestion(question)"
                        class="prompt-text"
                      >
                        {{
                          question.config.prompt.languageMap[
                            primaryLanguage
                          ]
                        }}
                      </div>
                      <v-textarea
                        v-if="isTextAreaQuestion(question)"
                        v-model="
                          field.value.languageMap[translationLanguage]
                        "
                        class="ml-8 mr-n3 mt-n1"
                        :placeholder="languageName + ' translation'"
                        dense
                        @input="saveTranslation(question, field.key)"
                      >
                      </v-textarea>
                      <v-text-field
                        v-else
                        v-model="
                          field.value.languageMap[translationLanguage]
                        "
                        :label="
                          field.value.languageMap[primaryLanguage]
                        "
                        dense
                        @input="saveTranslation(question, field.key)"
                      />
                      <div
                        :class="
                          additionalFieldProgressClass(question)
                        "
                      >
                        <v-progress-circular
                          v-if="fieldIsLoading(question, field.key)"
                          color="primary"
                          indeterminate
                        />
                        <v-icon
                          v-else-if="
                            fieldIsEdited(question, field.key)
                          "
                          class="update-progress green--text"
                        >
                          done
                        </v-icon>
                      </div>
                    </div>
                  </v-col>
                </v-row>
              </v-container>
              <v-container
                v-if="hasReadonlyConfig(question)"
                class="pl-0 pb-0 pt-6"
                fluid
              >
                <component
                  :is="questionConfigComponent(question)"
                  :target-language="translationLanguage"
                />
              </v-container>
            </v-card-text>
          </v-card>
        </v-container>
      </v-row>
    </v-container>
    <question-preview-dialog
      v-if="selectedQuestionType"
      :question-type="selectedQuestionType"
      :show="selectedQuestionType"
      @close-question-preview="closeQuestionPreview"
    />
  </div>
</template>

<script>
import { LanguageOptions } from '@/utils/LanguageOptions';
import QuestionPreviewDialog from '../View/QuestionPreviewDialog';
import QuestionTypeIcon from '@/components/Common/QuestionTypeIcon';
import questionType from '@/enums/questionType';
import QuestionTypeConfig from '@/utils/QuestionTypeConfig';
import EmotionWheelConfig from './QuestionConfig/EmotionWheelConfig';

export default {
  components: {
    QuestionPreviewDialog,
    QuestionTypeIcon,
    EmotionWheelConfig
  },
  props: {
    survey: {
      type: Object,
      required: true
    },
    translationLanguage: {
      type: String,
      required: true
    }
  },
  data: function() {
    return {
      selectedQuestionType: undefined,
      debounceTime: 1000,
      translationState: {
        loading: [],
        edited: []
      }
    };
  },
  computed: {
    primaryLanguage() {
      return this.survey.primaryLanguage;
    },
    languageName() {
      return Object.values(LanguageOptions).find(
        language => language.code === this.translationLanguage
      ).extended;
    },
    translationData() {
      let result = {};
      for (let question of this.survey.questions) {
        // Get the title matching the translation
        result[question.id] = {
          text: question.text.languageMap[this.translationLanguage]
        };
        // If the question has options, then we want
        // to get the translations for the options too
        if (question.config.options) {
          result[question.id].options = question.config.options.map(
            option => option.languageMap[this.translationLanguage]
          );
        } else if (question.config.labels) {
          result[question.id].labels = {};
          Object.keys(question.config.labels).forEach(key => {
            result[question.id].labels[key] =
              question.config.labels[key].languageMap[
                this.translationLanguage
              ];
          });
        }
      }
      return result;
    },
    savedQuestions() {
      // Questions with an undefined id have not yet been saved
      return this.survey.questions.filter(
        question => question.id !== undefined
      );
    }
  },
  methods: {
    fieldIsLoading(question, fieldKey) {
      return this.translationState.loading.includes(
        this.fieldKey(question, fieldKey)
      );
    },
    fieldIsEdited(question, fieldKey) {
      return this.translationState.edited.includes(
        this.fieldKey(question, fieldKey)
      );
    },
    fieldKey(question, fieldKey) {
      return `${question.id}:${fieldKey}`;
    },
    translationFields(question) {
      const needsTranslation = object => {
        // A field needs translation if:
        //   1. it is not null
        //   2. it is a translatable field (has a language map)
        //   3. it has a value in the primary language (not empty, not null)
        return (
          object != null &&
          object.languageMap &&
          object.languageMap[this.primaryLanguage]
        );
      };

      const translationFields = [];
      // Builds an array of key => object references to all objects within the the question.config
      // that contain a languageMap, so they can be presented to the user for translation.
      for (const [key, value] of Object.entries(question.config)) {
        if (needsTranslation(value)) {
          translationFields.push({ key, value });
        } else if (Array.isArray(value)) {
          value
            .filter(element => needsTranslation(element))
            .forEach((_, i) => {
              translationFields.push({
                key: `${key}[${i}]`,
                value: value[i]
              });
            });
        }
      }

      return translationFields;
    },
    hasTranslatableFields(question) {
      return (
        this.translationFields(question).length > 0 &&
        !QuestionTypeConfig[question.type()].isStatic
      );
    },
    translationFieldsLabel(question) {
      return (
        QuestionTypeConfig[question.type()].translationFieldsLabel ||
        'Question Config'
      );
    },
    hasReadonlyConfig(question) {
      return QuestionTypeConfig[question.type()].isStatic;
    },
    questionNumber(question) {
      return this.survey.questionOrder.indexOf(
        this.survey.questionOrder.find(id => id === question.id)
      );
    },
    loadingFinished(question, updated) {
      const fieldKey = this.fieldKey(question, updated);
      this.translationState.loading.splice(
        this.translationState.loading.indexOf(fieldKey),
        1
      );
      if (!this.translationState.edited.includes(fieldKey))
        this.translationState.edited.push(fieldKey);
    },
    showQuestionPreview(type) {
      this.selectedQuestionType = type;
    },
    closeQuestionPreview() {
      this.selectedQuestionType = undefined;
    },
    findQuestion(id) {
      return this.survey.questions.find(
        question => question.id === id
      );
    },
    saveTranslation(question, itemKey) {
      if (question.timeoutId) clearTimeout(question.timeoutId);
      question.timeoutId = setTimeout(() => {
        this.translationState.loading.push(
          this.fieldKey(question, itemKey)
        );
        this.$emit('update-translations', question, () => {
          this.loadingFinished(question, itemKey);
        });
      }, this.debounceTime);
    },
    isTextAreaQuestion(question) {
      return question.type() === questionType.GUIDANCE;
    },
    additionalFieldProgressClass(question) {
      return this.isTextAreaQuestion(question)
        ? 'update-progress pl-3'
        : 'update-progress';
    },
    countColsForSmallScreen(question) {
      return this.isTextAreaQuestion(question) ? '12' : '6';
    },
    countColsForMediumScreen(question) {
      return this.isTextAreaQuestion(question) ? '12' : '3';
    },
    isVerticalTextFieldQuestion(question) {
      return question.type() === questionType.MULTIPLE_CHOICE;
    },
    questionConfigComponent(question) {
      return QuestionTypeConfig[question.config.questionType]
        .configComponent;
    }
  }
};
</script>

<style lang="scss" scoped>
.flex-basis-1 {
  flex-basis: 100%;
}

.details {
  flex: 0 0 50%;
}

.question-type-icon {
  cursor: pointer;
  &:hover {
    color: var(--v-primary-base) !important;
  }
}

.update-progress {
  width: 32px;
  height: 32px;
}

.question-number {
  font-size: 20px;
  min-width: 40px;
}

.prompt-text {
  color: black;
  width: 50%;
  margin-right: 1px;
}
</style>
