<template>
  <v-card outlined class="loader pa-0">
    <v-container class="pa-0">
      <v-row>
        <v-col class="pt-0">
          <v-skeleton-loader
            type="card-avatar"
            class="header"
          ></v-skeleton-loader>
        </v-col>
      </v-row>
      <v-row class="body px-4 py-0 d-flex align-items-center">
        <v-col cols="6" class="d-flex">
          <v-skeleton-loader
            type="heading"
            class="question-count align-self-center"
          ></v-skeleton-loader>
        </v-col>
        <v-col cols="6" class="d-flex justify-end">
          <v-skeleton-loader
            type="chip"
            class="language-chip"
          ></v-skeleton-loader>
          <v-skeleton-loader
            type="chip"
            class="language-chip ml-2"
          ></v-skeleton-loader>
          <v-skeleton-loader
            type="chip"
            class="language-chip ml-2"
          ></v-skeleton-loader>
        </v-col>
      </v-row>
      <v-row class="pt-1">
        <v-col cols="3">
          <v-skeleton-loader
            type="chip"
            class="survey-issue-status-chip ml-4"
          ></v-skeleton-loader>
        </v-col>
        <v-col cols="9">
          <v-skeleton-loader
            type="text@2"
            class="survey-issues pr-4"
          ></v-skeleton-loader>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-skeleton-loader
            type="chip"
            class="survey-status-chip ml-4 pb-2 pt-1"
          ></v-skeleton-loader>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
export default {
  name: 'SurveyCardLoader'
};
</script>

<style lang="scss" scoped>
@import '../../../../scss/variables.scss';

.header {
  height: 100px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.question-count {
  width: 100% !important;
}

.survey-issue-status-chip {
  border-radius: 20px;
  width: 32px;
}

.language-chip {
  width: 32px;
  border-radius: 20px;
}
</style>
