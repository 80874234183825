<template>
  <question-form-action-dialog
    v-bind="$props"
    @close="$emit('close')"
    @confirm="$emit('confirm')"
  >
    <template v-slot:title>
      Clear Responses
    </template>
    <template v-slot:content>
      <p>
        This survey has
        <span class="font-weight-bold black--text">
          {{ responseCountText }}</span
        >. If you choose to clear the responses, they will no longer
        be accessible and the data will be permanently deleted.
      </p>
    </template>
    <template v-slot:confirm-btn-text>
      clear responses
    </template>
  </question-form-action-dialog>
</template>

<script>
import QuestionFormActionDialog from './QuestionFormActionDialog';
import QuestionActionConfirmationDialog from '@/mixins/QuestionActionConfirmationDialog';
export default {
  components: {
    QuestionFormActionDialog
  },
  mixins: [QuestionActionConfirmationDialog],
  props: {
    questionTitle: {
      type: String,
      required: true
    },
    questionType: {
      type: String,
      required: true
    },
    questionOrderIndex: {
      type: Number,
      required: true
    },
    questionResponseCount: {
      type: Number,
      required: true
    }
  }
};
</script>
