var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-select",
    _vm._b(
      {
        staticClass: "text-body-2",
        attrs: {
          items: _vm.questionThemes,
          "item-value": "value",
          "item-text": "displayName",
          label: "Question Theme"
        },
        on: {
          input: function($event) {
            return _vm.$emit("input", _vm.selectedTheme)
          }
        },
        model: {
          value: _vm.selectedTheme,
          callback: function($$v) {
            _vm.selectedTheme = $$v
          },
          expression: "selectedTheme"
        }
      },
      "v-select",
      _vm.$attrs,
      false
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }