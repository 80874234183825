export function translateStringOrFallback(
  stringObject,
  targetLanguage,
  fallbackLanguage
) {
  if (Array.isArray(stringObject)) {
    return {
      translated: stringObject.every(
        element =>
          element.label.languageMap[targetLanguage] != undefined
      )
    };
  } else {
    const map = stringObject.languageMap;
    const string = map[targetLanguage];
    return string
      ? { string, translated: true }
      : {
          string: map[fallbackLanguage],
          translated: false
        };
  }
}
