<template>
  <div>
    <page-header-bar>
      <div class="d-flex p-relative">
        <div class="flex-grow-1 d-flex justify-space-between">
          <h5 class="white--text text-h5">
            Surveys
          </h5>

          <div class="justify-center">
            <v-btn color="accent" @click="showSurveyDialog">
              Create Survey
            </v-btn>
          </div>
        </div>
      </div>
    </page-header-bar>
    <v-container class="pa-8">
      <surveys-view-controller
        ref="surveysViewController"
        :survey-filter-text="surveyFilterText"
      />
    </v-container>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import PageHeaderBar from '@/components/Common/PageHeaderBar';
import SurveysViewController from '@/components/Survey/List/SurveysViewController';
export default {
  name: 'OrganisationHomePage',
  components: {
    PageHeaderBar,
    SurveysViewController
  },
  data: () => ({
    surveyFilterText: undefined,
    surveyAction: '',
    surveyDialog: false,
    dialogSurvey: {},
    errorText: undefined
  }),
  computed: {
    ...mapState('organisation', ['organisations', 'loaded']),
    ...mapGetters('survey', ['surveys', 'survey']),
    surveyData() {
      return this.surveys ? this.surveys : [];
    }
  },
  created() {
    this.fetchOrganisations().then(() => {
      if (this.organisations.length < 1) {
        this.$router.push('/error/no-organisations');
      }
    });
  },
  methods: {
    ...mapActions('organisation', ['fetchOrganisations']),
    scrollToTop() {
      this.$vuetify.goTo(0);
    },
    setError(action, title) {
      this.errorText = `Failed to ${action} survey "${title}"`;
      this.scrollToTop();
    },
    clearError() {
      this.errorText = '';
    },
    showSurveyDialog() {
      this.$refs.surveysViewController.showSurveyDialogForNewSurvey();
    }
  }
};
</script>

<style lang="scss" scoped>
.header {
  line-height: 2rem !important;
}
.fab-container {
  /* float 2em to the right of the page content at all times */
  right: -2em;
}
.floating-button {
  top: 14em;
}
</style>
