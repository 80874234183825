var render = function render(_c, _vm) {
  return _c("v-card", { staticClass: "pa-5 px-12 mb-4" }, [
    _c(
      "div",
      { staticClass: "pt-5" },
      [
        _c("v-card-title", [_vm._v("iPad Login Details")]),
        _c(
          "v-card-text",
          [
            _c(
              "v-col",
              [
                _c("v-row", {}, [_vm._v(" iPad Username ")]),
                _c(
                  "v-row",
                  { staticClass: "black--text mt-2" },
                  [
                    _c("v-skeleton-loader", {
                      attrs: { type: "text@1", "min-width": "200px" }
                    })
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "pt-5" },
      [
        _c("v-card-title", [_vm._v("Users")]),
        _c(
          "v-card-text",
          [
            _c(
              "v-simple-table",
              [
                [
                  _c("thead", [
                    _c(
                      "tr",
                      _vm._l(["Name", "Email", "Role"], function(header) {
                        return _c(
                          "th",
                          { key: header, staticClass: "text-left" },
                          [_vm._v(" " + _vm._s(header) + " ")]
                        )
                      }),
                      0
                    )
                  ]),
                  _c(
                    "tbody",
                    _vm._l(5, function(x) {
                      return _c(
                        "tr",
                        { key: x },
                        _vm._l(3, function(y) {
                          return _c(
                            "td",
                            { key: y },
                            [
                              _c("v-skeleton-loader", {
                                attrs: { type: "text" }
                              })
                            ],
                            1
                          )
                        }),
                        0
                      )
                    }),
                    0
                  )
                ]
              ],
              2
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }